package org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.restaurant_tab.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.borderTop
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.HorizontalScrollBehavior
import org.hs_soft.runmynesto.domain.config.LetterSpacing
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.config.overflowText
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.kitchen_info.ProductAdditions
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.restaurant_data.AdditionModel
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.HeaderTitle
import org.hs_soft.runmynesto.pages.components.grid_function.GridFooter
import org.hs_soft.runmynesto.pages.components.SearchBarWithAddIcon
import org.hs_soft.runmynesto.pages.components.Separator
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.vh

@Composable
fun KitchenInformation(
    listAddition: List<AdditionModel>?,
    kitchenInfoItems: List<ProductAdditions?>?,
    descending: Boolean?,
    windowSizeMode: WindowSizeModeEnum,
    onKitchenInfoTitleSortClick: () -> Unit,
    onSearchKitchenInfo: (String) -> Unit,
    onRefreshClick: () -> Unit,
    activeKitchenInfo: (String) -> Unit,
    deActivateKitchenInfo: (String) -> Unit,
    onAddClick: () -> Unit,
    onKitchenInfoItemClick: (ProductAdditions?) -> Unit,
)
{
    Column(
        modifier = Modifier
            .fillMaxWidth()
    ){

        SpanText(
            text = Strings.kitchenInformation,
            modifier = Modifier
                .color(Theme.Gray3E.rgb)
                .fontFamily(FontFamily.Montserrat)
                .fontWeight(FontWeight.Normal)
                .fontSize(FontSizes.extraExtraLargeFontSize)
                .margin(top = Dimen.largePadding)
        )

        SearchBarWithAddIcon(
            onSearchInfo={
                onSearchKitchenInfo(it)
            },
            onAddClick={
                onAddClick()
            },
        )


        KitchenInfoTable(
            windowSizeMode=windowSizeMode,
            kitchenInfoItems=kitchenInfoItems,
            descendingSort = descending,
            listAddition=listAddition,
            onKitchenInfoTitleSortClick = {
                onKitchenInfoTitleSortClick()
            },
            onRefreshClick={
                onRefreshClick()
            },
            activateKitchenInfo = {
                activeKitchenInfo(it)
            },
            onKitchenInfoItemClick = {
                onKitchenInfoItemClick(it)
            },
            deActivateKitchenInfo = {
                deActivateKitchenInfo(it)
            }
        )


    }
}




@Composable
private fun KitchenInfoTable(
    kitchenInfoItems: List<ProductAdditions?>?,
    descendingSort: Boolean?,
    onKitchenInfoTitleSortClick: () -> Unit,
    onRefreshClick: () -> Unit,
    listAddition: List<AdditionModel>?,
    activateKitchenInfo: (String) -> Unit,
    deActivateKitchenInfo: (String) -> Unit,
    onKitchenInfoItemClick: (ProductAdditions?) -> Unit,
    windowSizeMode: WindowSizeModeEnum,
) {
    if (windowSizeMode==WindowSizeModeEnum.WINDOWS){
        WindowTable(
            windowSizeMode=windowSizeMode,
            descendingSort=descendingSort,
            onKitchenInfoItemClick = {
                onKitchenInfoItemClick(it)
            },
            kitchenInfoItems = kitchenInfoItems,
            listAddition = listAddition,
            activateKitchenInfo = {
                activateKitchenInfo(it)
            },
            onKitchenInfoTitleSortClick = {
                onKitchenInfoTitleSortClick()
            },
            onRefreshClick = {
                onRefreshClick()
            },
            deActivateKitchenInfo = {
                deActivateKitchenInfo(it)
            }

        )
    }else{
        PhoneAndTabletTable(
            windowSizeMode=windowSizeMode,
            descendingSort=descendingSort,
            onKitchenInfoItemClick = {
                onKitchenInfoItemClick(it)
            },
            kitchenInfoItems = kitchenInfoItems,
            listAddition = listAddition,
            activateKitchenInfo = {
                activateKitchenInfo(it)
            },
            onKitchenInfoTitleSortClick = {
                onKitchenInfoTitleSortClick()
            },
            onRefreshClick = {
                onRefreshClick()
            },
            deActivateKitchenInfo = {
                deActivateKitchenInfo(it)
            }

        )
    }




}


@Composable
fun PhoneAndTabletTable(
    windowSizeMode:WindowSizeModeEnum,
    descendingSort: Boolean?,
    onKitchenInfoTitleSortClick: () -> Unit,
    kitchenInfoItems: List<ProductAdditions?>?,
    listAddition: List<AdditionModel>?,
    activateKitchenInfo: (String) -> Unit,
    onKitchenInfoItemClick: (ProductAdditions?) -> Unit,
    deActivateKitchenInfo:(String)->Unit,
    onRefreshClick: () -> Unit
)
{


    Row(
        modifier = Modifier
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayAlpha12.rgb
            )
            .borderRadius(Dimen.lowBorderRadius)
            .width(Constants.Responsive.kitchenTableWidth.vh)
    )
    {

        Row(
            verticalAlignment = Alignment.CenterVertically
        )
        {
            Column(
                modifier = Modifier
                    .padding(left = Dimen.smallPadding)
                    .backgroundColor(Theme.GrayAlpha12.rgb)
                    .width(Constants.Responsive.KitchenTableHeaderWidth.vh)
            ) {
                HeaderTitles(
                    windowSizeMode=windowSizeMode,
                    descendingSort=descendingSort,
                    fillMaxWidth = false,
                    onKitchenInfoTitleSortClick={
                        onKitchenInfoTitleSortClick()
                    }
                )

            }

            Row(
                modifier = HorizontalScrollBehavior.toModifier()
                    .width(Constants.Responsive.kitchenInfoVariantWidth.vh)

            )
            {
                kitchenInfoItems?.forEach {model->

                    val additionId= remember { mutableStateOf<String?>(null) }

                    val isChecked= model?.id?.let { id ->
                        listAddition?.any { it.productAdditionId == id } ?: false
                    } ?: false

                    LaunchedEffect(listAddition){
                        additionId.value = if (isChecked) {
                            listAddition?.find { it.productAdditionId == model?.id }?.id
                        } else {
                            null
                        }
                    }



                    Column(
                        modifier = Modifier
                            .fillMaxWidth()
                            .onClick {
                                onKitchenInfoItemClick(model)
                            }
                    ) {

                        CheckboxComponent(
                            modifier = Modifier
                                .margin(left = Dimen.normalPadding)
                                .height(Dimen.kitchenInfoTitleHeight),
                            checkStatus = isChecked,
                            title = "",
                            onChecked = {
                                activateKitchenInfo(model?.id ?: "")
                            },
                            onUnChecked = {
                                deActivateKitchenInfo(additionId.value ?: "")
                            },
                        )

                        Separator()

                        InfoTitle(
                            modifier = Modifier
                                .margin(left = Dimen.normalPadding),
                            title = model?.name ?: "",
                            width = Dimen.headerLargeWidth
                        )

                        Separator()

                        model?.variants?.forEach {
                            InfoTitle(
                                modifier = Modifier.margin(
                                    left = Dimen.normalPadding,
                                ),
                                title = if (it.isNullOrEmpty()) "-" else it,
                            )
                            Separator()
                        }
                    }

                }


            }
        }


    }


}


@Composable
private fun WindowTable(
    windowSizeMode: WindowSizeModeEnum,
    descendingSort: Boolean?,
    onKitchenInfoTitleSortClick: () -> Unit,
    kitchenInfoItems: List<ProductAdditions?>?,
    listAddition: List<AdditionModel>?,
    activateKitchenInfo: (String) -> Unit,
    deActivateKitchenInfo:(String)->Unit,
    onKitchenInfoItemClick: (ProductAdditions?) -> Unit,
    onRefreshClick: () -> Unit
) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayAlpha12.rgb
            )
            .borderRadius(Dimen.lowBorderRadius)
    ) {

        Row(
            modifier = Modifier
                .fillMaxWidth()
                .height(Dimen.gridHeaderHeight)
                .borderBottom(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayAlpha12.rgb
                )
                .backgroundColor(Theme.GrayAlpha04.rgb)
                .padding(leftRight = Dimen.normalPadding),
            verticalAlignment = Alignment.CenterVertically
        ) {

            HeaderTitles(
                windowSizeMode = windowSizeMode,
                descendingSort=descendingSort,
                fillMaxWidth = true,
                onKitchenInfoTitleSortClick={
                    onKitchenInfoTitleSortClick()
                },
            )

        }

        Column(
            modifier = VerticalScrollBehavior.toModifier()
                .height(Dimen.kitchenInfoItemsHeight)
                .fillMaxWidth()
        )
        {
            kitchenInfoItems?.forEach { model ->

                val additionId= remember { mutableStateOf<String?>(null) }

                val isChecked= model?.id?.let { id ->
                    listAddition?.any { it.productAdditionId == id } ?: false
                } ?: false

                LaunchedEffect(listAddition){
                    additionId.value = if (isChecked) {
                        listAddition?.find { it.productAdditionId == model?.id }?.id
                    } else {
                        null
                    }
                }

                WindowKitchenInfoItem(
                    model = model,
                    additionId=additionId.value?:"",
                    isChecked=isChecked,
                    activeKitchenInfo = {
                        activateKitchenInfo(it)
                    },
                    onItemClick = {
                        onKitchenInfoItemClick(model)
                    },
                    deActiveKitchenInfo = {
                        deActivateKitchenInfo(it)
                    }
                )
            }
        }


        GridFooter(
            title = Strings.template,
            width = null,
            height = Dimen.normalGridFooterHeight,
            dataSize = kitchenInfoItems?.size ?: 0,
            onRefreshClick = {
                onRefreshClick()
            }
        )

    }
}

@Composable
private fun HeaderTitles(
    windowSizeMode: WindowSizeModeEnum,
    descendingSort: Boolean?,
    fillMaxWidth: Boolean,
    onKitchenInfoTitleSortClick: () -> Unit,
) {
    HeaderTitle(
        title = Strings.activate,
        arrangement =if (windowSizeMode==WindowSizeModeEnum.WINDOWS) Arrangement.Center
        else Arrangement.Start,
        width = Dimen.headerSmallWidth,
    )

    SeparatorForTabletAndPhone(windowSizeMode=windowSizeMode)


    HeaderTitle(
        title = Strings.title,
        width = Dimen.headerLargeWidth,
        descending = descendingSort,
        onTitleClick = {
            onKitchenInfoTitleSortClick()
        }
    )

    SeparatorForTabletAndPhone(windowSizeMode=windowSizeMode)

    if (windowSizeMode==WindowSizeModeEnum.WINDOWS){
        Row(
            modifier = Modifier
                .fillMaxWidth()
        )
        {
            Variants(
                fillMaxWidth=fillMaxWidth,
                windowSizeMode=windowSizeMode,
            )
        }
    }else{
        Column(
            modifier = Modifier
                .fillMaxWidth()
        )
        {
            Variants(
                fillMaxWidth=fillMaxWidth,
                windowSizeMode = windowSizeMode
            )
        }
    }



}

@Composable
private fun SeparatorForTabletAndPhone(
    windowSizeMode: WindowSizeModeEnum
){
    if (windowSizeMode!=WindowSizeModeEnum.WINDOWS)
        Separator()
}

@Composable
private fun Variants(
    fillMaxWidth: Boolean,
    windowSizeMode: WindowSizeModeEnum
) {
    HeaderTitle(
        title = Strings.variant1,
        fillMaxWidth = fillMaxWidth,
    )
    SeparatorForTabletAndPhone(windowSizeMode=windowSizeMode)

    HeaderTitle(
        title = Strings.variant2,
        fillMaxWidth = fillMaxWidth,
    )
    SeparatorForTabletAndPhone(windowSizeMode=windowSizeMode)

    HeaderTitle(
        title = Strings.variant3,
        fillMaxWidth = fillMaxWidth,
    )
    SeparatorForTabletAndPhone(windowSizeMode=windowSizeMode)

    HeaderTitle(
        title = Strings.variant4,
        fillMaxWidth = fillMaxWidth,
    )
    SeparatorForTabletAndPhone(windowSizeMode=windowSizeMode)

    HeaderTitle(
        title = Strings.variant5,
        fillMaxWidth = fillMaxWidth,
    )
    SeparatorForTabletAndPhone(windowSizeMode=windowSizeMode)

    HeaderTitle(
        title = Strings.variant6,
        fillMaxWidth = fillMaxWidth,
    )
    SeparatorForTabletAndPhone(windowSizeMode=windowSizeMode)

    HeaderTitle(
        title = Strings.variant7,
        fillMaxWidth = fillMaxWidth,
    )

    SeparatorForTabletAndPhone(windowSizeMode=windowSizeMode)

    HeaderTitle(
        title = Strings.variant8,
        fillMaxWidth = fillMaxWidth,
    )

    SeparatorForTabletAndPhone(windowSizeMode=windowSizeMode)

    HeaderTitle(
        title = Strings.variant9,
        fillMaxWidth = fillMaxWidth,
    )

    SeparatorForTabletAndPhone(windowSizeMode=windowSizeMode)

    HeaderTitle(
        title = Strings.variant10,
        fillMaxWidth = fillMaxWidth,
    )
}

@Composable
private fun WindowKitchenInfoItem(
    model: ProductAdditions?,
    additionId:String,
    isChecked:Boolean,
    activeKitchenInfo:(String)->Unit,
    deActiveKitchenInfo:(String)->Unit,
    onItemClick:(ProductAdditions?)->Unit,
)
{

    Row(
        modifier = Modifier
            .onClick {
                onItemClick(model)
            }
            .fillMaxWidth()
            .borderBottom(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayAlpha12.rgb
            )
            .borderTop(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayAlpha12.rgb
            )
            .padding(leftRight = Dimen.normalPadding),
        verticalAlignment = Alignment.CenterVertically
    ) {

        ItemContent(
            isChecked=isChecked,
            model=model,
            additionId=additionId,
            activeKitchenInfo={
                activeKitchenInfo(it)
            },
            deActiveKitchenInfo={
                deActiveKitchenInfo(it)
            },
        )


    }

}

@Composable
private fun ItemContent(
    isChecked: Boolean,
    activeKitchenInfo: (String) -> Unit,
    model: ProductAdditions?,
    deActiveKitchenInfo: (String) -> Unit,
    additionId:String,
) {

    Box(
        modifier = Modifier
            .width(
                Dimen.headerSmallWidth,
            )
    ) {
        CheckboxComponent(
            modifier = Modifier
                .align(Alignment.Center),
            checkStatus = isChecked,
            title = "",
            onChecked = {
                activeKitchenInfo(model?.id ?: "")
            },
            onUnChecked = {
                deActiveKitchenInfo(additionId)
            },
        )
    }


    InfoTitle(
        title = model?.name ?: "",
        width = Dimen.headerLargeWidth,
//        fillMaxWidth = true
    )
    Row(
        modifier = Modifier.fillMaxWidth()
    ){
        model?.variants?.forEach {
            InfoTitle(
                title = if (it.isNullOrEmpty()) "-" else it,
                fillMaxWidth = true
            )
        }
    }

}

@Composable
private fun InfoTitle(
    modifier: Modifier=Modifier,
    title:String,
    width: CSSSizeValue<CSSUnit.px> =Dimen.headerMediumWidth,
    fillMaxWidth:Boolean=false,
)
{
    SpanText(
        text = title,
        modifier = modifier
            .width(width)
            .thenIf(
                fillMaxWidth,
                Modifier.fillMaxWidth()
            )
            .height(Dimen.kitchenInfoTitleHeight)
            .padding(topBottom = Dimen.smallPadding)
            .cursor(Cursor.Pointer)
            .fontWeight(FontWeight.Normal)
            .fontSize(FontSizes.semiMediumFontSize)
            .lineHeight(FontLineHeight.lineMedium)
            .letterSpacing(LetterSpacing.semiMedium)
            .fontFamily(FontFamily.Montserrat)
            .color(Theme.Primary.rgb)
            .overflowText()
    )
}


