package org.hs_soft.runmynesto.data.api_datasource.options.restaurant_manager

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.delete_room.DeleteRestaurantRoomModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.DeleteRoomRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class DeleteRoomRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): DeleteRoomRepository
{
    override suspend fun deleteRoom(roomId: String): DeleteRestaurantRoomModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                mutation deleteRoom(${'$'}id: ID!) {
                  cashAssist {
                    deleteRoom(id:${'$'}id)
                    __typename
                  }
                } 

            """.trimIndent())

            putJsonObject("variables") {
                put("id",roomId)
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }


    }


}