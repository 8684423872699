package org.hs_soft.runmynesto.domain.model.home.options

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.TitleEnum

enum class OptionInfoHeaders(override val title: String): TitleEnum
{
    OPERATION(Strings.operation.uppercase()),
    SALES_GROUP(Strings.salesGroups.uppercase()),
    KITCHEN_LOCATIONS(Strings.kitchenLocations.uppercase()),
    CASH_FLOW(Strings.cashFlow.uppercase()),
    VIRTUAL_CARD(Strings.virtualCard.uppercase()),
    CASH_REGISTER_CONFIG(Strings.cashRegisterConfig.uppercase()),
    MENU_CARD_IMPORT(Strings.menuCardImport.uppercase()),
    RESTAURANT_MANAGER(Strings.restaurantManager.uppercase())

}