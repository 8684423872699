package org.hs_soft.runmynesto.di.media_center

import org.hs_soft.runmynesto.data.api_datasource.media_center.tabs.media.AddNewMediaRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.media_center.tabs.media.AddTagRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.media_center.tabs.media.DeleteTagRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.media_center.tabs.media.MediaDetailRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.media_center.tabs.media.MediaListRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.media_center.tabs.media.MediaTagsRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.media_center.tabs.media.LoadAppConstantsRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.media_center.tabs.media.UpdateBase64ImgRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.media_center.tabs.media.UpdateMediaRepositoryImpl
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.AddNewMediaRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.AddTagRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.DeleteTagRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.LoadAppConstantsRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.MediaDetailRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.MediaListRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.MediaTagsRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.UpdateBase64ImgRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.UpdateMediaRepository
import org.koin.dsl.module

val mediaCenterRepositoryModule= module {

    single<MediaListRepository> {
        MediaListRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }

    single<MediaDetailRepository> {
        MediaDetailRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }
    single<AddNewMediaRepository> {
        AddNewMediaRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<MediaTagsRepository>{
        MediaTagsRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }
    single<UpdateMediaRepository>{
        UpdateMediaRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }
    single<UpdateBase64ImgRepository>{
        UpdateBase64ImgRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }
    single<LoadAppConstantsRepository>{
        LoadAppConstantsRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }


    single<DeleteTagRepository>{
        DeleteTagRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<AddTagRepository>{
        AddTagRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }



}
