package org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.create_furniture


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("createFurniture")
    val createFurniture: List<CreateFurniture?>?,
    @SerialName("__typename")
    val typename: String?
)