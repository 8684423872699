package org.hs_soft.runmynesto.pages.home.sub_page.media_center

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.DisplaySizeEnum
import org.hs_soft.runmynesto.domain.util.animatePageTransition
import org.hs_soft.runmynesto.pages.components.LoadingProgress
import org.hs_soft.runmynesto.pages.home.sub_page.media_center.component.GridMediaCenterBar
import org.hs_soft.runmynesto.pages.home.sub_page.media_center.component.MediaCenterDialogs
import org.hs_soft.runmynesto.pages.home.sub_page.media_center.component.media_center_content.MediaCenterContent
import org.hs_soft.runmynesto.pages.home.sub_page.media_center.mvi.MediaCenterEvent
import org.hs_soft.runmynesto.pages.home.sub_page.media_center.mvi.MediaCenterViewModel

@Composable
fun MediaCenter(
    windowSizeMode: WindowSizeModeEnum,
    viewModel: MediaCenterViewModel,
)
{
    val state by viewModel.state.collectAsState()

    LaunchedEffect(viewModel){
        viewModel.onEvent(MediaCenterEvent.GetCheckoutData)
        viewModel.onEvent(MediaCenterEvent.GetMediaList)
        viewModel.onEvent(MediaCenterEvent.GetMediaTags)
        viewModel.onEvent(MediaCenterEvent.GetShopList)
        viewModel.onEvent(MediaCenterEvent.LoadAppConstants)
    }

    LaunchedEffect(state.selectedMedia){
        viewModel.onEvent(MediaCenterEvent
            .SetDisplaySize(DisplaySizeEnum.STANDARD))
    }
    Column(
        modifier = Modifier
            .scrollBehavior(ScrollBehavior.Smooth)
            .fillMaxSize()
    )
    {

        Row(
            modifier = Modifier
                .fillMaxHeight()
                .fillMaxWidth()
                .margin(top = Dimen.smallPadding),
            horizontalArrangement =
            if (windowSizeMode==WindowSizeModeEnum.PHONE)
                Arrangement.Center
            else Arrangement.Start
        )
        {
            if (state.firstPageActive) {
                GridMediaCenterBar(
                    windowSizeMode=windowSizeMode,
                    selectedItem = state.selectedMedia,
                    searchValue = state.mainGridSearchValue,
                    infoList = state.mediaItems?: listOf() ,
                    titleDescending = state.isMainGridNameSortedAsc,
                    numberDescending = state.isMainGridNumberSortedAsc,
                    showNumberSortIcon = state.showMainGridNumberSortIcon,
                    showTitleSortIcon = state.showMainGridNameSortIcon,
                    showFilterComponent = state.showItemsFilterComponent,
                    selectedFilterOption=state.selectedFilterItemsType,
                    onSearchValueChange = {
                        viewModel.onEvent(MediaCenterEvent.OnSearchMediaInputChange(it))
                    },
                    onGridItemClick = { id ->
                        viewModel.onEvent(
                            MediaCenterEvent.OnMediaItemClick(
                                id = id,
                                switchTheSecondPage = {
                                    if (windowSizeMode == WindowSizeModeEnum.PHONE)
                                        viewModel.onEvent(MediaCenterEvent.OnChangePageClick)
                                }
                            )
                        )
                    },
                    onAddRecord = {
                        viewModel.onEvent(MediaCenterEvent.OnAddRecordIconClick)
                    },
                    onTitleSortClick = {
                        viewModel.onEvent(MediaCenterEvent.OnMainGridTitleSortClick)
                    },
                    onNumberSortClick = {
                        viewModel.onEvent(MediaCenterEvent.OnMainGridNumberSortClick)
                    },
                    refreshList = {
                        viewModel.onEvent(MediaCenterEvent.RefreshProductsCategoryList)
                    },
                    onSwipeRight = {
                        viewModel.onEvent(MediaCenterEvent.OnChangePageClick)
                    },
                    onFilterComponentClick = {
                        viewModel.onEvent(
                            MediaCenterEvent.SetFilterItemsComponentStatus(
                                !state.showItemsFilterComponent
                            )
                        )
                    },
                    onCloseFilterClick = {
                        viewModel.onEvent(MediaCenterEvent.SetFilterItemsComponentStatus(false))
                    },
                    selectFilterType = {
                        viewModel.onEvent(MediaCenterEvent.SelectFilterType(it))
                    },
                    submitFilterItems = {
                        viewModel.onEvent(MediaCenterEvent.SubmitFilterItems)
                    }
                )

                if (windowSizeMode != WindowSizeModeEnum.PHONE) {
                    MediaCenterContent(
                        windowSizeMode=windowSizeMode,
                        state=state,
                        event=viewModel::onEvent
                    )

                }

            } else {

                MediaCenterContent(
                    windowSizeMode=windowSizeMode,
                    state=state,
                    event=viewModel::onEvent,
                )


            }

        }



    }

    LoadingProgress(
        visibility = state.dataLoadingProgressVisible
    )

    MediaCenterDialogs(
        state=state,
        windowSizeMode=windowSizeMode,
        event=viewModel::onEvent
    )





}


