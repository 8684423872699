package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media

enum class TimeIntervalEnum(
    val title: String,
    val value: String,
) {
    T00_00(title = "00:00", value = "1899-12-30T00:00:00.000Z"),
    T00_15(title = "00:15", value = "1899-12-30T00:15:00.000Z"),
    T00_30(title = "00:30", value = "1899-12-30T00:30:00.000Z"),
    T00_45(title = "00:45", value = "1899-12-30T00:45:00.000Z"),
    T01_00(title = "01:00", value = "1899-12-30T01:00:00.000Z"),
    T01_15(title = "01:15", value = "1899-12-30T01:15:00.000Z"),
    T01_30(title = "01:30", value = "1899-12-30T01:30:00.000Z"),
    T01_45(title = "01:45", value = "1899-12-30T01:45:00.000Z"),
    T02_00(title = "02:00", value = "1899-12-30T02:00:00.000Z"),
    T02_15(title = "02:15", value = "1899-12-30T02:15:00.000Z"),
    T02_30(title = "02:30", value = "1899-12-30T02:30:00.000Z"),
    T02_45(title = "02:45", value = "1899-12-30T02:45:00.000Z"),
    T03_00(title = "03:00", value = "1899-12-30T03:00:00.000Z"),
    T03_15(title = "03:15", value = "1899-12-30T03:15:00.000Z"),
    T03_30(title = "03:30", value = "1899-12-30T03:30:00.000Z"),
    T03_45(title = "03:45", value = "1899-12-30T03:45:00.000Z"),
    T04_00(title = "04:00", value = "1899-12-30T04:00:00.000Z"),
    T04_15(title = "04:15", value = "1899-12-30T04:15:00.000Z"),
    T04_30(title = "04:30", value = "1899-12-30T04:30:00.000Z"),
    T04_45(title = "04:45", value = "1899-12-30T04:45:00.000Z"),
    T05_00(title = "05:00", value = "1899-12-30T05:00:00.000Z"),
    T05_15(title = "05:15", value = "1899-12-30T05:15:00.000Z"),
    T05_30(title = "05:30", value = "1899-12-30T05:30:00.000Z"),
    T05_45(title = "05:45", value = "1899-12-30T05:45:00.000Z"),
    T06_00(title = "06:00", value = "1899-12-30T06:00:00.000Z"),
    T06_15(title = "06:15", value = "1899-12-30T06:15:00.000Z"),
    T06_30(title = "06:30", value = "1899-12-30T06:30:00.000Z"),
    T06_45(title = "06:45", value = "1899-12-30T06:45:00.000Z"),
    T07_00(title = "07:00", value = "1899-12-30T07:00:00.000Z"),
    T07_15(title = "07:15", value = "1899-12-30T07:15:00.000Z"),
    T07_30(title = "07:30", value = "1899-12-30T07:30:00.000Z"),
    T07_45(title = "07:45", value = "1899-12-30T07:45:00.000Z"),
    T08_00(title = "08:00", value = "1899-12-30T08:00:00.000Z"),
    T08_15(title = "08:15", value = "1899-12-30T08:15:00.000Z"),
    T08_30(title = "08:30", value = "1899-12-30T08:30:00.000Z"),
    T08_45(title = "08:45", value = "1899-12-30T08:45:00.000Z"),
    T09_00(title = "09:00", value = "1899-12-30T09:00:00.000Z"),
    T09_15(title = "09:15", value = "1899-12-30T09:15:00.000Z"),
    T09_30(title = "09:30", value = "1899-12-30T09:30:00.000Z"),
    T09_45(title = "09:45", value = "1899-12-30T09:45:00.000Z"),
    T10_00(title = "10:00", value = "1899-12-30T10:00:00.000Z"),
    T10_15(title = "10:15", value = "1899-12-30T10:15:00.000Z"),
    T10_30(title = "10:30", value = "1899-12-30T10:30:00.000Z"),
    T10_45(title = "10:45", value = "1899-12-30T10:45:00.000Z"),
    T11_00(title = "11:00", value = "1899-12-30T11:00:00.000Z"),
    T11_15(title = "11:15", value = "1899-12-30T11:15:00.000Z"),
    T11_30(title = "11:30", value = "1899-12-30T11:30:00.000Z"),
    T11_45(title = "11:45", value = "1899-12-30T11:45:00.000Z"),
    T12_00(title = "12:00", value = "1899-12-30T12:00:00.000Z"),
    T12_15(title = "12:15", value = "1899-12-30T12:15:00.000Z"),
    T12_30(title = "12:30", value = "1899-12-30T12:30:00.000Z"),
    T12_45(title = "12:45", value = "1899-12-30T12:45:00.000Z"),
    T13_00(title = "13:00", value = "1899-12-30T13:00:00.000Z"),
    T13_15(title = "13:15", value = "1899-12-30T13:15:00.000Z"),
    T13_30(title = "13:30", value = "1899-12-30T13:30:00.000Z"),
    T13_45(title = "13:45", value = "1899-12-30T13:45:00.000Z"),
    T14_00(title = "14:00", value = "1899-12-30T14:00:00.000Z"),
    T14_15(title = "14:15", value = "1899-12-30T14:15:00.000Z"),
    T14_30(title = "14:30", value = "1899-12-30T14:30:00.000Z"),
    T14_45(title = "14:45", value = "1899-12-30T14:45:00.000Z"),
    T15_00(title = "15:00", value = "1899-12-30T15:00:00.000Z"),
    T15_15(title = "15:15", value = "1899-12-30T15:15:00.000Z"),
    T15_30(title = "15:30", value = "1899-12-30T15:30:00.000Z"),
    T15_45(title = "15:45", value = "1899-12-30T15:45:00.000Z"),
    T16_00(title = "16:00", value = "1899-12-30T16:00:00.000Z"),
    T16_15(title = "16:15", value = "1899-12-30T16:15:00.000Z"),
    T16_30(title = "16:30", value = "1899-12-30T16:30:00.000Z"),
    T16_45(title = "16:45", value = "1899-12-30T16:45:00.000Z"),
    T17_00(title = "17:00", value = "1899-12-30T17:00:00.000Z"),
    T17_15(title = "17:15", value = "1899-12-30T17:15:00.000Z"),
    T17_30(title = "17:30", value = "1899-12-30T17:30:00.000Z"),
    T17_45(title = "17:45", value = "1899-12-30T17:45:00.000Z"),
    T18_00(title = "18:00", value = "1899-12-30T18:00:00.000Z"),
    T18_15(title = "18:15", value = "1899-12-30T18:15:00.000Z"),
    T18_30(title = "18:30", value = "1899-12-30T18:30:00.000Z"),
    T18_45(title = "18:45", value = "1899-12-30T18:45:00.000Z"),
    T19_00(title = "19:00", value = "1899-12-30T19:00:00.000Z"),
    T19_15(title = "19:15", value = "1899-12-30T19:15:00.000Z"),
    T19_30(title = "19:30", value = "1899-12-30T19:30:00.000Z"),
    T19_45(title = "19:45", value = "1899-12-30T19:45:00.000Z"),
    T20_00(title = "20:00", value = "1899-12-30T20:00:00.000Z"),
    T20_15(title = "20:15", value = "1899-12-30T20:15:00.000Z"),
    T20_30(title = "20:30", value = "1899-12-30T20:30:00.000Z"),
    T20_45(title = "20:45", value = "1899-12-30T20:45:00.000Z"),
    T21_00(title = "21:00", value = "1899-12-30T21:00:00.000Z"),
    T21_15(title = "21:15", value = "1899-12-30T21:15:00.000Z"),
    T21_30(title = "21:30", value = "1899-12-30T21:30:00.000Z"),
    T21_45(title = "21:45", value = "1899-12-30T21:45:00.000Z"),
    T22_00(title = "22:00", value = "1899-12-30T22:00:00.000Z"),
    T22_15(title = "22:15", value = "1899-12-30T22:15:00.000Z"),
    T22_30(title = "22:30", value = "1899-12-30T22:30:00.000Z"),
    T22_45(title = "22:45", value = "1899-12-30T22:45:00.000Z"),
    T23_00(title = "23:00", value = "1899-12-30T23:00:00.000Z"),
    T23_15(title = "23:15", value = "1899-12-30T23:15:00.000Z"),
    T23_30(title = "23:30", value = "1899-12-30T23:30:00.000Z"),
    T23_45(title = "23:45", value = "1899-12-30T23:45:00.000Z");
}