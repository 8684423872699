package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.app_constants


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.app_constants.Data

@Serializable
data class AppConstantModel(
    @SerialName("data")
    val  data : Data?
)