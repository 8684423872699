package org.hs_soft.runmynesto.di.home

import org.hs_soft.runmynesto.data.api_datasource.authentication.FetchIdTokenRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.authentication.SignOutRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.home.HSSoftAppsRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.home.ShopListRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.home.UserProfileDataRepositoryImpl
import org.hs_soft.runmynesto.data.local_datasource.local_storage.home.GetSavedCheckOutRepositoryImpl
import org.hs_soft.runmynesto.data.local_datasource.local_storage.home.GetShopListRepositoryImpl
import org.hs_soft.runmynesto.data.local_datasource.local_storage.home.SaveCheckOutRepositoryImpl
import org.hs_soft.runmynesto.domain.repository.local_datasource.home.GetSavedCheckOutRepository
import org.hs_soft.runmynesto.domain.repository.local_datasource.home.GetSavedShopllistRepository
import org.hs_soft.runmynesto.domain.repository.local_datasource.home.SaveCheckOutRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.home.FetchIdTokenRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.home.HSSoftAppsRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.home.SignOutRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.home.UserProfileDataRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.ShopListRepository
import org.koin.dsl.module

val homeRepositoryModule= module {

    single<FetchIdTokenRepository> {
        FetchIdTokenRepositoryImpl()
    }
    single<GetSavedCheckOutRepository> {
        GetSavedCheckOutRepositoryImpl()
    }

    single<SaveCheckOutRepository> {
        SaveCheckOutRepositoryImpl()
    }

    single<SignOutRepository>{
        SignOutRepositoryImpl()
    }
    single<UserProfileDataRepository> {
        UserProfileDataRepositoryImpl(get(),get())
    }

    single<HSSoftAppsRepository>{
        HSSoftAppsRepositoryImpl(get(),get())
    }
    single<ShopListRepository>{
        ShopListRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }
    single<GetSavedShopllistRepository>{
        GetShopListRepositoryImpl()
    }
}