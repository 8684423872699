package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.shop_list


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ShopModel(
    @SerialName("data")
    val `data`: Data?
)