package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_tab


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("listTags")
    val listTags: List<MediaTag>?,
    @SerialName("__typename")
    val typename: String?
)