package org.hs_soft.runmynesto.pages.components.top_toolbar

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.home.hssoft_apps_list.HSWebApps
import org.hs_soft.runmynesto.domain.model.home.home.user_profile_info.GetUserProfileData
import org.hs_soft.runmynesto.domain.util.openInNewTab
import org.hs_soft.runmynesto.pages.components.dialog.dialog.CompaniesDialog
import org.hs_soft.runmynesto.pages.components.dialog.dialog.ProfileBadgeDialog
import org.hs_soft.runmynesto.pages.components.top_toolbar.component.CarouselButton
import org.hs_soft.runmynesto.pages.components.top_toolbar.component.TopToolbarPageInfo
import org.hs_soft.runmynesto.pages.components.top_toolbar.component.TopToolbarUserInfo

@Composable
fun TopPageToolbar(
    modifier: Modifier = Modifier,
    profileInfo: GetUserProfileData?,
    windowSizeMode: WindowSizeModeEnum = WindowSizeModeEnum.WINDOWS,
    title: String,
    iconPath: String,
    showSettingDialog: Boolean,
    showSearchBarInMobileMode: Boolean,
    showCompanies: Boolean,
    carouselVisibility:Boolean,
    listOfHSSoftApps: List<HSWebApps?>?,
    onToolbarCompaniesMenuIconClick: () -> Unit,
    changeShowingSearchBarInMobileStatus: () -> Unit,
    onSweepPage: () -> Unit,
    onToolbarSettingIconClick: () -> Unit,
    onLogoutClick: () -> Unit,
)
{
    Column(
        modifier=modifier
            .fillMaxWidth()
            .margin(bottom = Dimen.normalPadding)
            .height(Dimen.topToolbarHeight)
            .padding(
                left =if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
                    Dimen.normalPadding
                else Dimen.extraExtraLargePadding,
                right = Dimen.largePadding
            ),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center,

    )
    {
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .margin(
                    left = Dimen.extraLargePadding,
                    right = Dimen.largePadding
                )
                .height(Dimen.topToolbarHeight) ,
            verticalAlignment = Alignment.CenterVertically
        )
        {
            if (windowSizeMode!=WindowSizeModeEnum.PHONE ||
                (!showSearchBarInMobileMode && windowSizeMode==WindowSizeModeEnum.PHONE)
            ){
                TopToolbarPageInfo(
                    iconPath=iconPath,
                    title=title,
                    windowSizeMode=windowSizeMode
                )

            }



            Spacer()

            TopToolbarUserInfo(
                windowSizeMode =windowSizeMode,
                profileInfo = profileInfo,
                showSearchBarInMobileMode =showSearchBarInMobileMode,
                onToolbarCompaniesMenuIconClick = {
                    onToolbarCompaniesMenuIconClick()
                },
                onToolbarSettingIconClick ={
                    onToolbarSettingIconClick()
                },
                changeShowingSearchBarInMobileStatus = {
                    changeShowingSearchBarInMobileStatus()
                },
            )


            if(
                windowSizeMode== WindowSizeModeEnum.PHONE &&
                carouselVisibility
            ){
                CarouselButton(
                    onScrollButtonClick ={
                        onSweepPage()
                    }
                )
            }


        }


        Column(
            modifier=Modifier
                .fillMaxWidth(),
            horizontalAlignment = Alignment.End
        ) {
            if (showSettingDialog){
                ProfileBadgeDialog(
                    onLogoutClick = {
                        onLogoutClick()
                    }
                )
            }


            if (showCompanies){
                CompaniesDialog(
                    listOfHSSoftApps=listOfHSSoftApps,
                    onItemClick = {
                        openInNewTab(it)
                    }
                )
            }
        }





    }
}






