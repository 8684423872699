package org.hs_soft.runmynesto.pages.home.sub_page.media_center.component.media_center_content.tabs.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.config.borderSetting
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_detail.WithProduct
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.select_days.SelectedItem
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.px

@Composable
fun ChooseAnArticle(
    windowSizeMode: WindowSizeModeEnum,
    onBoxClick: () -> Unit,
    selectedArticles: List<WithProduct>?,
    deleteArticle:(String)->Unit,
) {

    val isHovered = remember { mutableStateOf(false) }


    Column(
        modifier = VerticalScrollBehavior.toModifier()
            .cursor(Cursor.Pointer)
            .position(Position.Relative)
            .rippleEffect {
                onBoxClick()
            }
            .borderSetting()
            .onMouseOver { isHovered.value=true }
            .onMouseLeave {isHovered.value=false }
            .width(Dimen.semiLargeEditTextWidth)
            .minHeight(Dimen.outlinedBoxHeight)
            .maxHeight((4*(Dimen.outlinedBoxHeight.value.toInt())).px)
    ){
        selectedArticles?.forEach {
            SelectedItem(
                name = it.name?:"",
                onDeleteClick = {
                    deleteArticle(it.id?:"")
                }
            )
        }

    }






}