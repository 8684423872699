package org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager

import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.create_furniture.CreateFurnitureModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.CreateFurnitureRepository

class CreateFurnitureUseCase(
    private val repository: CreateFurnitureRepository,
) {
    suspend operator fun invoke(
        caption: String?,
        componentColor: String?,
        floorId: String,
        height: Int,
        objectKind: String,
        objectType: String,
        positionLeft: Int,
        positionTop: Int,
        projectId: String,
        roomId: String,
        shape: String,
        width: Int
    ): CreateFurnitureModel {
        return repository.createFurniture(
            caption = caption,
            componentColor = componentColor,
            floorId = floorId,
            height = height,
            objectKind = objectKind,
            objectType = objectType,
            positionLeft = positionLeft,
            positionTop = positionTop,
            projectId = projectId,
            roomId = roomId,
            shape = shape,
            width = width
        )
    }
}