package org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.update_furniture


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("__typename")
    val typename: String?,
    @SerialName("updateFurniture")
    val updateFurniture: List<UpdateFurniture?>?
)