package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.restaurant_manager.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.borderSetting
import org.hs_soft.runmynesto.domain.config.generalDialogSetting
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.ObjectKinds
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.backgroundImage
import org.jetbrains.compose.web.css.px

@Composable
fun RestaurantWall(
    onSubmitClick:(ObjectKinds)->Unit,
)
{
    val wall= remember { mutableStateOf<ObjectKinds?>(null) }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .borderSetting()
            .margin(top = Dimen.normalPadding)
    )
    {
        SpanText(
            text = Strings.wall,
            modifier = Modifier
                .minHeight(Dimen.minimumContentTitleHeight)
                .margin(left = Dimen.normalPadding)
                .fontWeight(FontWeight.Normal)
                .lineHeight(FontLineHeight.lineLarge)
                .color(Theme.Secondary.rgb)
                .fontFamily(FontFamily.Montserrat)
        )

        Row()
        {
            WallItem(
                backgroundColor = when(wall.value){
                    ObjectKinds.HORIZONTAL_WALL -> Theme.YellowAlpha20.rgb
                    else -> Theme.Transparent.rgb
                },
                onClick = {
                    wall.value=ObjectKinds.HORIZONTAL_WALL
                },
                content = {
                    Box(
                        modifier = Modifier
                            .width(Dimen.wallBigSide)
                            .height(Dimen.wallSmallSide)
                            .border(
                                width = Dimen.smallThickness,
                                color = Theme.GrayCD.rgb,
                                style = LineStyle.Solid
                            )

                    )
                }
            )

            WallItem(
                backgroundColor = when(wall.value){
                    ObjectKinds.VERTICAL_WALL -> Theme.YellowAlpha20.rgb
                    else -> Theme.Transparent.rgb
                },
                onClick = {
                    wall.value=ObjectKinds.VERTICAL_WALL
                },
                content = {
                    Box(
                        modifier = Modifier
                            .height(Dimen.wallBigSide)
                            .width(Dimen.wallSmallSide)
                            .border(
                                width = Dimen.smallThickness,
                                color = Theme.GrayCD.rgb,
                                style = LineStyle.Solid
                            )
                    )
                }
            )

            WallItem(
                backgroundColor = when(wall.value){
                    ObjectKinds.HORIZONTAL_WINDOW -> Theme.YellowAlpha20.rgb
                    else -> Theme.Transparent.rgb
                },
                onClick = {
                    wall.value=ObjectKinds.HORIZONTAL_WINDOW
                },
                content = {
                    Box(
                        modifier = Modifier
                            .width(Dimen.wallBigSide)
                            .height(Dimen.wallSmallSide)
                            .border(
                                width = Dimen.smallThickness,
                                color = Theme.GrayCD.rgb,
                                style = LineStyle.Solid
                            )
                            .styleModifier {
                                backgroundImage(
                                    "repeating-linear-gradient(45deg, lightblue, " +
                                            "lightblue 2px, " +
                                            "transparent 2px," +
                                            " transparent 4px)")
                            }

                    )
                }
            )

            WallItem(
                backgroundColor = when(wall.value){
                    ObjectKinds.VERTICAL_WINDOW -> Theme.YellowAlpha20.rgb
                    else -> Theme.Transparent.rgb
                },
                onClick = {
                    wall.value=ObjectKinds.VERTICAL_WINDOW
                },
                content = {
                    Box(
                        modifier = Modifier
                            .height(Dimen.wallBigSide)
                            .width(Dimen.wallSmallSide)
                            .border(
                                width = Dimen.smallThickness,
                                color = Theme.GrayCD.rgb,
                                style = LineStyle.Solid
                            )
                            .styleModifier {
                                backgroundImage(
                                    "repeating-linear-gradient(45deg, lightblue, " +
                                            "lightblue 2px, " +
                                            "transparent 2px," +
                                            " transparent 4px)")
                            }
                    )
                }
            )


        }



        CustomButton(
            modifier = Modifier,
            topBottomMargin = Dimen.normalPadding,
            title = Strings.add,
            leftRightMargin = 0.px,
            isClickable = wall.value!=null,
            defaultTextColor = Theme.White.rgb,
            onButtonClick = {
                onSubmitClick(
                    wall.value!!
                )

            },
        )


    }


}

@Composable
private fun WallItem(
    backgroundColor:CSSColorValue,
    onClick: () -> Unit,
    content: @Composable () -> Unit,
) {
    Box(
        modifier = Modifier
            .rippleEffect {
                onClick()
            }
            .generalDialogSetting()
            .margin(right = Dimen.mediumPadding)
            .size(Dimen.restaurantTableRectSize)
            .backgroundColor(
                backgroundColor
            )
    )
    {
        Box(modifier = Modifier.align(Alignment.Center)){
            content()
        }

    }
}