package org.hs_soft.runmynesto.pages.home.sub_page.customer_card

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.pages.components.LoadingProgress
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.CustomerCardContent
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.CustomerCardDialogs
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.grid_customer_card_bar.GridCustomerCardBar
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.mvi.CustomerCardEvent
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.mvi.CustomerCardViewModel

@Composable
fun CustomerCard(
    windowSizeMode: WindowSizeModeEnum,
    viewModel: CustomerCardViewModel,
)
{
    val state by viewModel.state.collectAsState()

    LaunchedEffect(Unit){
        viewModel.onEvent(CustomerCardEvent.GetLoyaltyCards())
        viewModel.onEvent(CustomerCardEvent.GetCreditClient)
    }


    Row(
        modifier = Modifier
            .scrollBehavior(ScrollBehavior.Smooth)
            .fillMaxSize()
    )
    {
        if (state.firstPageActive) {
            GridCustomerCardBar(
                loyaltyCardItems=state.loyaltyCards,
                selectedItemId = state.selectedLoyaltyCard?.id?:"",
                showFilterComponent = state.showGridCustomerFilter,
                selectedFilterOption = state.gridCustomerCardFilterType,
                windowSizeMode=windowSizeMode,
                setFilterComponentVisibility={
                    viewModel.onEvent(
                        CustomerCardEvent.SetGridCustomerFilterVisibility(status = it)
                    )
                },
                onSearchValueChange = {
                    viewModel.onEvent(
                        CustomerCardEvent.OnSearchInLoyaltyCards(searchValue = it)
                    )
                },
                onAddRecord = {
                    viewModel.onEvent(
                        CustomerCardEvent.ShowAddCustomerCardDialog
                    )
                },
                selectFilterType = {
                    viewModel.onEvent(
                        CustomerCardEvent.SetGridCustomerFilterType(it)
                    )
                },
                submitFilterItems = {
                    viewModel.onEvent(
                        CustomerCardEvent.FilterGridCustomerCards
                    )
                },
                onSwipeRight = {},
                onItemClick = {
                    viewModel.onEvent(
                        CustomerCardEvent.OnSelectLoyaltyCard(
                            id = it,
                            switchTheSecondPage = {
                                if (windowSizeMode == WindowSizeModeEnum.PHONE)
                                    viewModel.onEvent(CustomerCardEvent.OnChangePageClick)
                            }
                        )
                    )
                },
                loadMoreData = {
                    viewModel.onEvent(
                        CustomerCardEvent.GetLoyaltyCards(
                            isActive = state.gridCustomerCardFilterType.value
                        )
                    )
                }
            )

            if (windowSizeMode != WindowSizeModeEnum.PHONE) {
                CustomerCardContent(
                    state=state,
                    event=viewModel::onEvent,
                    windowSizeMode=windowSizeMode,
                )

            }
        }else{
            CustomerCardContent(
                state=state,
                event=viewModel::onEvent,
                windowSizeMode=windowSizeMode,
            )
        }

    }

    CustomerCardDialogs(
        windowSizeMode=windowSizeMode,
        state=state,
        event=viewModel::onEvent
    )


    LoadingProgress(
        visibility = state.dataLoadingProgressVisible
    )

}

