package org.hs_soft.runmynesto.domain.usecase.product.tab_product_general

import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.GetDataAssistantMessageHistoryRepository

class GetDataAssistantMessageHistoryUseCase
    (private val repository: GetDataAssistantMessageHistoryRepository) {
    suspend operator fun invoke(email: String): String {
        return repository.getDataAssistantMessageHistory(email = email)
    }
}
