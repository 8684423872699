package org.hs_soft.runmynesto.di.options

import org.hs_soft.runmynesto.data.api_datasource.options.cash_flow.GetAppConfigRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.cash_flow.UpdateAppConfigRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.cash_register_config.GetCashRegisterConfigRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.cash_register_config.UpdateCashRegisterConfigRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.kitchen_location.UpdateKitchenLocationRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.menu_card_import.GetMenuCardRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.operation.OptionsDataRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.operation.UpdateOptionRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.restaurant_manager.CreateFloorRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.restaurant_manager.CreateFurnitureRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.restaurant_manager.DeleteFloorRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.restaurant_manager.DeleteRoomRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.restaurant_manager.RestaurantProjectsRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.restaurant_manager.UpdateFloorRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.restaurant_manager.UpdateFurnitureRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.sale_group.AddNewSaleGroupRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.sale_group.SingleSaleGroupRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.sale_group.UpdateSaleGroupRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.virtual_card.GetVirtualCardInfoRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.options.virtual_card.UpdateVirtualCardInfoRepositoryImpl
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.cash_flow.GetAppConfigRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.cash_flow.UpdateAppConfigRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.cash_register_config.GetCashRegisterConfigRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.cash_register_config.UpdateCashRegisterConfigRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.kitchen_location.UpdateKitchenLocationRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.menu_card_import.GetMenuCardRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.operation.OptionDataRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.operation.UpdateOptionRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.CreateFloorRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.CreateFurnitureRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.DeleteFloorRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.DeleteRoomRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.RestaurantProjectsRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.UpdateFloorRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.UpdateFurnitureRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.sales_group.AddNewSaleGroupRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.sales_group.SingleSaleGroupRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.sales_group.UpdateSaleGroupRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.virtual_card.GetVirtualCardInfoRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.virtual_card.UpdateVirtualCardInfoRepository
import org.koin.dsl.module

val optionRepositoryModule= module {

    single<UpdateKitchenLocationRepository> {
        UpdateKitchenLocationRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }

    single<OptionDataRepository> {
        OptionsDataRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }

    single<AddNewSaleGroupRepository> {
        AddNewSaleGroupRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }


    single<SingleSaleGroupRepository> {
        SingleSaleGroupRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }
    single<UpdateSaleGroupRepository>{
        UpdateSaleGroupRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }


    single<UpdateOptionRepository> {
        UpdateOptionRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }

    single<UpdateVirtualCardInfoRepository>{
        UpdateVirtualCardInfoRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }

    single<GetAppConfigRepository>{
        GetAppConfigRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }
    single<UpdateAppConfigRepository>{
        UpdateAppConfigRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }
    single<GetCashRegisterConfigRepository>{
        GetCashRegisterConfigRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }
    single<UpdateCashRegisterConfigRepository>{
        UpdateCashRegisterConfigRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }
    single<GetVirtualCardInfoRepository>{
        GetVirtualCardInfoRepositoryImpl(
            apiClient = get(),
            requestManager = get()
        )
    }

    single<GetMenuCardRepository>{
        GetMenuCardRepositoryImpl(get())
    }

    single<RestaurantProjectsRepository> {
        RestaurantProjectsRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<CreateFurnitureRepository> {
        CreateFurnitureRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }
    single<UpdateFurnitureRepository>{
        UpdateFurnitureRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<CreateFloorRepository>{
        CreateFloorRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<UpdateFloorRepository>{
        UpdateFloorRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }
    single<DeleteFloorRepository>{
        DeleteFloorRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<DeleteRoomRepository>{
        DeleteRoomRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

}