package org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media

import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_detail.MediaDetailModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.AddNewMediaRepository


class AddNewMediaUseCase(
    private val repository: AddNewMediaRepository
) {
    suspend operator fun invoke(name:String): MediaDetailModel {
         return repository.addNewMedia(
             name=name
         )
    }
}