package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.mvi
import org.hs_soft.runmynesto.domain.model.home.closing_reports.FinalReportsGroupEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodDaysEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel


sealed class ClosingReportsEvent
{
    data class SetClosingDailyReportDialogStatus(val status:Boolean)
        :ClosingReportsEvent()

    data class CloseDailyReport(val reason:String)
        :ClosingReportsEvent()

    data object ExtractAllDevicesIds:ClosingReportsEvent()

    data class SetFinancialFilterDialog(val status: Boolean)
        :ClosingReportsEvent()

    data class UpdateCashRegisterIds(
        val newCashRegisterIds:List<String>,
    ):ClosingReportsEvent()

    data class SubmitFinancialGroup(
        val group:FinalReportsGroupEnum
    ):ClosingReportsEvent()

    data class SetFinancialGroupStatus(
        val status:Boolean
    ):ClosingReportsEvent()


    data class SubmitClosingReportsDaysFilter(
        val from:String,
        val to:String,
        val periodDaysType:PeriodDaysEnum,
        val cashRegisterIds:List<String>,
    ):ClosingReportsEvent()

    data object SetCompanyProfileId:ClosingReportsEvent()

    data class SetNewCalendarHeaderYear(val year:Int):ClosingReportsEvent()

    data object OnChangePageClick:ClosingReportsEvent()

    data class SubmitClosingReportsCalendarFilter(
        val from:String,
        val to:String,
        val cashRegisterIds:List<String>,
    ):ClosingReportsEvent()

    data class OnSelectDate(
        val selectedDate: MonthModel,
        val cashRegisterIds:List<String>,
    ): ClosingReportsEvent()

    data class GetClosingReports(
        val from:String,
        val to:String,
        val cashRegisterIds:List<String>,
    ):ClosingReportsEvent()

    data class SetCalendarFilterDialogStatus(val status:Boolean)
        :ClosingReportsEvent()

    data class SearchInClosingReports(val value:String)
        :ClosingReportsEvent()

    data class OnClosingReportClick(
        val id:String
    ):ClosingReportsEvent()

    data class SetDaysFilterDialogStatus(val show:Boolean)
        :ClosingReportsEvent()

    data object GetCheckoutData: ClosingReportsEvent()

    data class OnSearchCheckoutItems(val value:String):
        ClosingReportsEvent()


    data class SetReportsTableAndDevicesVisibilityStatus(val showReportsTableAndDevices:Boolean):
        ClosingReportsEvent()

}