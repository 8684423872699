package org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.create_floor


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("createFloor")
    val createFloor: CreateFloor?=null,
    @SerialName("__typename")
    val typename: String?
)