package org.hs_soft.runmynesto.data.local_datasource.local_storage.home

import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import kotlinx.serialization.json.Json
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.shop_list.ShopModel
import org.hs_soft.runmynesto.domain.repository.local_datasource.home.GetSavedShopllistRepository
import org.hs_soft.runmynesto.domain.util.IdUtils

class GetShopListRepositoryImpl:GetSavedShopllistRepository
{
    override suspend fun getShopList(): ShopModel? {
        return withContext(Dispatchers.Default) {
            val jsonData = window.sessionStorage.getItem(IdUtils.shopList)

            jsonData?.let {
                Json.decodeFromString(it)
            }
        }
    }
}