package org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.delete_floor


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class DeleteRestaurantFloorModel(
    @SerialName("data")
    val `data`: Data?
)