package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs.general

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.thenIf
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.general.ModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.general.PriceLevelHouseButtonEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config.GetCashRegister
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.Children
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenX
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenXX
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.DropDownItem
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun GeneralTabCashRegisterConfig(
    event: (OptionsEvent) -> Unit,
    windowSizeMode: WindowSizeModeEnum,
    cashList: List<Children>?,
    selectedOffice: Children?,
    selectedRegion: ChildrenX?,
    selectedBranch: ChildrenXX?,
    cashRegisterConfigData: GetCashRegister?,
) {
    val layoutModifier = Modifier
        .fillMaxSize()
        .padding(Dimen.normalPadding)
        .borderRadius(Dimen.lowBorderRadius)
        .border(
            width = Dimen.smallThickness,
            style = LineStyle.Solid,
            color = Theme.GrayCD.rgb
        )

    if (windowSizeMode == WindowSizeModeEnum.WINDOWS) {
        Row(modifier = layoutModifier) {
            LeftColumnContent(
                event = event,
                cashList = cashList,
                selectedOffice = selectedOffice,
                selectedRegion = selectedRegion,
                selectedBranch = selectedBranch,
                cashRegisterConfigData = cashRegisterConfigData
            )
            RightColumnContent(
                event = event,
                cashRegisterConfigData = cashRegisterConfigData,
                windowSizeMode=windowSizeMode,
            )
        }
    } else {
        Column(modifier = layoutModifier) {
            LeftColumnContent(
                event = event,
                cashList = cashList,
                selectedOffice = selectedOffice,
                selectedRegion = selectedRegion,
                selectedBranch = selectedBranch,
                cashRegisterConfigData = cashRegisterConfigData
            )
            RightColumnContent(
                event = event,
                cashRegisterConfigData = cashRegisterConfigData,
                windowSizeMode=windowSizeMode,
            )
        }
    }
}

@Composable
fun LeftColumnContent(
    event: (OptionsEvent) -> Unit,
    cashList: List<Children>?,
    selectedOffice: Children?,
    selectedRegion: ChildrenX?,
    selectedBranch: ChildrenXX?,
    cashRegisterConfigData: GetCashRegister?
) {
    Column(modifier = Modifier) {

        DropDownItem(
            modifier = Modifier,
            itemWidth = Dimen.semiLargeEditTextWidth,
            options = cashList ?: listOf(),
            label = Strings.office,
            selectedItem = selectedOffice,
            content = {
                if (it is Children)
                    Text(it.name ?: "")
            },
            onOptionClick = {
                val option = cashList?.find { item ->
                    it == item.toString()
                }
                event(OptionsEvent.OnSelectOffice(option))
            }
        )

        DropDownItem(
            modifier = Modifier
                .margin(top = Dimen.mediumPadding),
            itemWidth = Dimen.semiLargeEditTextWidth,
            options = selectedOffice?.children ?: listOf(),
            label = Strings.region,
            selectedItem = selectedRegion,
            content = {
                if (it is ChildrenX)
                    Text(it.name ?: "")
            },
            onOptionClick = {
                val option = selectedOffice?.children?.find { item ->
                    item.toString() == it
                }
                event(OptionsEvent.OnSelectRegion(option))
            }
        )

        DropDownItem(
            modifier = Modifier
                .margin(top = Dimen.mediumPadding),
            itemWidth = Dimen.semiLargeEditTextWidth,
            options = selectedRegion?.children ?: listOf(),
            label = Strings.branch,
            selectedItem = selectedBranch,
            content = {
                if (it is ChildrenXX)
                    Text(it.name ?: "")
            },
            onOptionClick = {
                val option = selectedRegion?.children?.find { item ->
                    item.toString() == it
                }
                event(OptionsEvent.OnSelectShop(option))
            }
        )

        CustomEditText(
            marginTop = Dimen.mediumPadding,
            value = cashRegisterConfigData?.name ?: "",
            isOutlinedEditText = true,
            marginBottom = 0.px,
            isRequired = false,
            label = Strings.cashierName,
            onFocusOut = {
                event(
                    OptionsEvent.UpdateCashRegisterConfigData(
                        title = ApiUtils.Field.name,
                        value = it,
                        valueIsInParameters = false
                    )
                )
            },
            onFocusIn = {},
            onInputChange = {},
        )
    }
}

@Composable
fun RightColumnContent(
    event: (OptionsEvent) -> Unit,
    cashRegisterConfigData: GetCashRegister?,
    windowSizeMode: WindowSizeModeEnum,
) {
    Column(modifier = Modifier
        .thenIf(
            windowSizeMode==WindowSizeModeEnum.WINDOWS,
            Modifier.margin(left = Dimen.normalPadding)
        )
    )
    {
        DropDownItem(
            modifier = Modifier,
            itemWidth = Dimen.semiLargeEditTextWidth,
            options = PriceLevelHouseButtonEnum.entries,
            label = Strings.priceLevels,
            selectedItem = PriceLevelHouseButtonEnum.entries.find {
                it.value == cashRegisterConfigData?.priceLevelOut
            },
            content = {
                Text(it)
            },
            onOptionClick = {
                val option: PriceLevelHouseButtonEnum? =
                    PriceLevelHouseButtonEnum.entries.find { item ->
                        item.toString() == it
                    }
                event(
                    OptionsEvent.UpdateCashRegisterConfigData(
                        title = ApiUtils.Field.priceLevelOut,
                        value = option?.value ?: 0,
                        valueIsInParameters = false
                    )
                )
            },
        )

        DropDownItem(
            modifier = Modifier
                .margin(top = Dimen.mediumPadding),
            itemWidth = Dimen.semiLargeEditTextWidth,
            options = PriceLevelHouseButtonEnum.entries,
            label = Strings.priceLevelInHouseButton,
            selectedItem = PriceLevelHouseButtonEnum.entries.find {
                it.value == cashRegisterConfigData?.priceLevelIn
            },
            content = {
                if (it is PriceLevelHouseButtonEnum)
                    Text(it.title)
            },
            onOptionClick = {
                val option: PriceLevelHouseButtonEnum? =
                    PriceLevelHouseButtonEnum.entries.find { item ->
                        item.toString() == it
                    }
                event(
                    OptionsEvent.UpdateCashRegisterConfigData(
                        title = ApiUtils.Field.priceLevelIn,
                        value = option?.value ?: 0,
                        valueIsInParameters = false
                    )
                )
            }
        )

        DropDownItem(
            modifier = Modifier
                .margin(top = Dimen.mediumPadding),
            itemWidth = Dimen.semiLargeEditTextWidth,
            options = ModeEnum.entries,
            label = Strings.mode,
            selectedItem = ModeEnum.entries.find {
                it.value == cashRegisterConfigData?.mode
            },
            content = {
                if (it is ModeEnum)
                    Text(it.title)
            },
            onOptionClick = {
                val mode: ModeEnum? = ModeEnum.entries.find { item ->
                    item.toString() == it
                }
                if (mode != null) {
                    event(
                        OptionsEvent.UpdateCashRegisterConfigData(
                            title = ApiUtils.Field.mode,
                            value = mode.value,
                            valueIsInParameters = false
                        )
                    )
                }
            }
        )
    }
}