package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.delete_tag


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("deleteTag")
    val deleteTag: DeleteTag?,
    @SerialName("__typename")
    val typename: String?
)