package org.hs_soft.runmynesto.data.api_datasource.options.restaurant_manager

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.create_floor.CreateFloorModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.CreateFloorRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class CreateFloorRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): CreateFloorRepository
{

    override suspend fun createFloor(
        number: Int,
        projectId: String,
    ): CreateFloorModel {
        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                mutation createFloor(${'$'}fields: CreateFloorFields!, 
                ${'$'}defaultRoomCaption: String!) {
                  cashAssist {
                    createFloor(fields:${'$'}fields,
                     defaultRoomCaption:${'$'}defaultRoomCaption) {
                      ${ApiUtils.Field.id}
                      ${ApiUtils.Field.projectId}
                      ${ApiUtils.Field.number}
                      ${ApiUtils.Field.objectType}
                      rooms {
                        ${ApiUtils.Field.id}
                        ${ApiUtils.Field.projectId}
                        ${ApiUtils.Field.floorId}
                        ${ApiUtils.Field.caption}
                        ${ApiUtils.Field.mod_ver}
                        ${ApiUtils.Field.objectType}
                        ${ApiUtils.Field.__typename}
                      }
                      ${ApiUtils.Field.__typename}
                    }
                    ${ApiUtils.Field.__typename}
                  }
                }


            """.trimIndent())

            putJsonObject("variables") {
                put("defaultRoomCaption","Neues Zimmer")
                putJsonObject("fields") {
                    put("number",number)
                    put("projectId",projectId)
                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }

    }


}