package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_tab


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class MediaTag(
    @SerialName("id")
    val id: String?,
    @SerialName("name")
    val name: String?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("uid")
    val uid: String?
)