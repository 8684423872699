package org.hs_soft.runmynesto.domain.usecase.home

import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.shop_list.ShopModel
import org.hs_soft.runmynesto.domain.repository.local_datasource.home.GetSavedShopllistRepository


class GetSavedShopListUseCase(
    private val repository: GetSavedShopllistRepository
) {
    suspend operator fun invoke(): ShopModel? {
         return repository.getShopList()
    }
}