package org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.click_collect_tab

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.product_item_model.Properties
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun ClickAndCollectTabContent(
    properties: Properties?,
    updateClickAndCollect: (Double?,Double?,Double?) -> Unit,
    windowSizeMode: WindowSizeModeEnum,
)
{
    val minOrder= remember { mutableStateOf(properties?.clickCollectOrderMin) }
    val maxOrder= remember { mutableStateOf(properties?.clickCollectOrderMax) }
    val timeLeadsOrder= remember { mutableStateOf(properties?.clickCollectLeadDaysCount) }

    LaunchedEffect(properties){
        minOrder.value=properties?.clickCollectOrderMin
        maxOrder.value=properties?.clickCollectOrderMax
        timeLeadsOrder.value=properties?.clickCollectLeadDaysCount
    }



    Column(
        Modifier
            .fillMaxWidth()
            .fillMaxHeight()
            .padding(Dimen.normalPadding)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
    )
    {

        if (windowSizeMode== WindowSizeModeEnum.WINDOWS){
            Row()
            {
                MinAndMaxContent(
                    minOrderValue = minOrder,
                    maxOrderValue = maxOrder,
                    updateMinOrder={
                        updateClickAndCollect(minOrder.value,maxOrder.value,timeLeadsOrder.value)
                    },
                    updateMaxOrder={
                        updateClickAndCollect(minOrder.value,maxOrder.value,timeLeadsOrder.value)
                    },
                    windowSizeMode = windowSizeMode
                )

            }
        }else{
            MinAndMaxContent(
                minOrderValue = minOrder,
                maxOrderValue = maxOrder,
                updateMinOrder={
                    updateClickAndCollect(minOrder.value,maxOrder.value,timeLeadsOrder.value)
                },
                updateMaxOrder={
                    updateClickAndCollect(minOrder.value,maxOrder.value,timeLeadsOrder.value)
                },
                windowSizeMode = windowSizeMode
            )
        }


        CustomEditText(
            modifier = Modifier,
            width = Dimen.mediumEditTextWidth,
            label = Strings.deliveryTimeInDays,
            value = timeLeadsOrder.value.toString(),
            isRequired = false,
            inputType = InputType.Number,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint = "",
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {
               updateClickAndCollect(minOrder.value,maxOrder.value,timeLeadsOrder.value)
            },
            onInputChange = {
                timeLeadsOrder.value=it.toDouble()
            },
        )




    }
}

@Composable
private fun MinAndMaxContent(
    windowSizeMode: WindowSizeModeEnum,
    minOrderValue:MutableState<Double?>,
    maxOrderValue:MutableState<Double?>,
    updateMinOrder: (String) -> Unit,
    updateMaxOrder: (String) -> Unit
) {
    CustomEditText(
        modifier = Modifier,
        width = Dimen.mediumEditTextWidth,
        label = Strings.minimumOrder,
        value =minOrderValue.value.toString(),
        isRequired = false,
        inputType = InputType.Number,
        defaultBorderColor = Theme.GrayCD.rgb,
        hint = "",
        isOutlinedEditText = true,
        onFocusIn = {},
        onFocusOut = {
            updateMinOrder(it)
        },
        onInputChange = {
            minOrderValue.value=it.toDouble()
        },
    )

    if (windowSizeMode== WindowSizeModeEnum.WINDOWS)
        Box(modifier = Modifier.margin(Dimen.mediumPadding))

    CustomEditText(
        modifier = Modifier,
        width = Dimen.mediumEditTextWidth,
        label = Strings.maximumOrder,
        value =maxOrderValue.value.toString(),
        isRequired = false,
        inputType = InputType.Number,
        defaultBorderColor = Theme.GrayCD.rgb,
        hint = "",
        isOutlinedEditText = true,
        onFocusIn = {},
        onFocusOut = {
            updateMaxOrder(it)
        },
        onInputChange = {
            maxOrderValue.value=it.toDouble()
        },
    )
}