package org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component.dashboard_chart.component.chart.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.DisposableEffect
import androidx.compose.runtime.NoLiveLiterals
import kotlinx.browser.document
import kotlinx.browser.window
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.formatToTwoDecimals
import org.hs_soft.runmynesto.domain.util.jsObject
import org.hs_soft.runmynesto.modules.Chart
import org.hs_soft.runmynesto.modules.React
import org.hs_soft.runmynesto.modules.ReactDom
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Div
import org.w3c.dom.HTMLElement
import kotlin.js.Json
import kotlin.js.json

const val TODAY= Strings.today

@Composable
fun AreaChart(
    windowSizeMode:WindowSizeModeEnum,
    id: String,
    firstFillColor: String,
    firstStrokeColor: String,
    secondFillColor: String,
    secondStrokeColor: String,
    firstChartTitle:String,
    secondChartTitle:String,
    mainData: List<Double?>?,
    comparedData: List<Double?>?,
    interval: List<String?>?,
) {

    Div(attrs = {
        id(id)
        style {
            width(
                if(windowSizeMode==WindowSizeModeEnum.PHONE)
                    window.innerWidth.px else
                    Dimen.chartWidth.px
            )
            height(Dimen.chartHeight.px)
        }
    }) {
        DisposableEffect(
            mainData,
            comparedData,
            interval,
            firstChartTitle,
            secondChartTitle,
        ) {
            val container = document.getElementById(id) as HTMLElement
            createChart(
                windowSizeMode=windowSizeMode,
                mainData=mainData,
                comparedData=comparedData,
                interval=interval,
                container=container,
                firstFillColor = firstFillColor,
                firstChartTitle = firstChartTitle,
                firstStrokeColor = firstStrokeColor,
                secondFillColor = secondFillColor,
                secondChartTitle = secondChartTitle,
                secondStrokeColor = secondStrokeColor,
            )
            onDispose { }
        }
    }
}



private fun createChart(
    windowSizeMode: WindowSizeModeEnum,
    container: HTMLElement,
    firstFillColor: String,
    firstStrokeColor: String,
    firstChartTitle: String,
    secondFillColor: String,
    secondStrokeColor: String,
    secondChartTitle: String,
    mainData: List<Double?>?,
    comparedData: List<Double?>?,
    interval: List<String?>?,
) {
    val jsonList: Array<Json> = mainData?.mapIndexed { index, value ->

        val intervalLabel = interval?.getOrNull(index)
            ?.split("-")?.firstOrNull() ?: "N/A"

        val secondChartValue = comparedData?.getOrNull(index)?.let {
            if (it == 0.0) "0.0" else formatToTwoDecimals(it)
        } ?: "0.0"

        if (secondChartTitle==firstChartTitle){
            json(
                "name" to intervalLabel,
                TODAY to (formatToTwoDecimals(value)),
                "amt" to 2400
            )
        }else{
            json(
                "name" to intervalLabel,
                TODAY to (formatToTwoDecimals(value)),
                secondChartTitle to secondChartValue,
                "amt" to 2400
            )
        }


    }?.toTypedArray() ?: emptyArray()




    renderChart(
        windowSizeMode=windowSizeMode,
        container = container,
        data = jsonList,
        firstFillColor = firstFillColor,
        firstStrokeColor = firstStrokeColor,
        secondFillColor =secondFillColor ,
        secondStrokeColor =secondStrokeColor ,
        secondChartTitle=secondChartTitle,

    )




}

@NoLiveLiterals
private fun renderChart(
    windowSizeMode: WindowSizeModeEnum,
    container: HTMLElement,
    data: Array<dynamic>,
    firstFillColor: String,
    firstStrokeColor: String,
    secondFillColor: String,
    secondStrokeColor: String,
    secondChartTitle: String,
) {
    val leftRightPadding=when(windowSizeMode){
        WindowSizeModeEnum.WINDOWS->0
        WindowSizeModeEnum.TABLET->0
        WindowSizeModeEnum.PHONE->Dimen.largePadding.value.toInt()
    }
    val topBottomPadding=Dimen.normalPadding.value.toInt()

    val root = ReactDom.createRoot(container)
    root.render(
        React.createElement(
            Chart.ResponsiveContainer, jsObject<Any> {
                width =Dimen.chartWidth
                height = Dimen.chartHeight
            },
            React.createElement(
                Chart.AreaChart, jsObject<Any> {
                    this.data = data
                    margin = jsObject<Any> {
                        top = topBottomPadding
                        right =leftRightPadding
                        left = 0
                        bottom = topBottomPadding
                    }
                },
                React.createElement(Chart.CartesianGrid, jsObject<Any> {
                    strokeDasharray = "0 0"
                }),
                React.createElement(Chart.XAxis, jsObject<Any> {
                    dataKey = "name"
                }),
                React.createElement(Chart.YAxis, jsObject<Any> {}),
                React.createElement(Chart.Tooltip, null),
//                React.createElement(Chart.Legend, null),//Showing title beneath the chart
                React.createElement(Chart.Area, jsObject<Any> {
                    type = "monotone"
                    dataKey = TODAY
                    stroke = firstStrokeColor
                    fill = firstFillColor
                }),
                React.createElement(Chart.Area, jsObject<Any> {
                    type = "monotone"
                    dataKey = secondChartTitle
                    stroke = secondStrokeColor
                    fill = secondFillColor
                })
            )
        )
    )
}
