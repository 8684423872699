package org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.restaurant_project


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Furniture(
    @SerialName("caption")
    val caption: String?,
    @SerialName("componentColor")
    val componentColor: String?,
    @SerialName("floorId")
    val floorId: String?,
    @SerialName("height")
    val height: Int?,
    @SerialName("id")
    val id: String?,
    @SerialName("mod_ver")
    val modVer: Int?,
    @SerialName("objectKind")
    val objectKind: String?,
    @SerialName("objectType")
    val objectType: String?,
    @SerialName("positionLeft")
    val positionLeft: Int?,
    @SerialName("positionTop")
    val positionTop: Int?,
    @SerialName("projectId")
    val projectId: String?,
    @SerialName("roomId")
    val roomId: String?,
    @SerialName("rotationDeg")
    val rotationDeg: Int?,
    @SerialName("shape")
    val shape: String?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("width")
    val width: Int?
)