package org.hs_soft.runmynesto.pages.components.checkout

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.config.scrollBar
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.Children
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenX
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenXX
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenXXX
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.getCheckoutWidth
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.Separator
import org.hs_soft.runmynesto.pages.components.checkout.component.DeviceItem
import org.hs_soft.runmynesto.pages.components.checkout.component.DeviceLocation
import org.hs_soft.runmynesto.pages.components.checkout.component.StoreItem
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.CSSUnitLength
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh

@Composable
fun CheckoutTabContent(
    modifier:Modifier=Modifier,
    height: CSSSizeValue<CSSUnit.vh> =
        Constants.Responsive.templateGridContentHeight.vh,
    fillMaxWidth:Boolean=true,
    padding: CSSSizeValue<CSSUnit.px> = Dimen.largePadding,
    marginBottom:CSSSizeValue<CSSUnit.px> = Dimen.normalPadding,
    cashList: List<Children>?,
    cashRegisterIds: List<String>?,
    borderLess:Boolean=false,
    windowSizeMode: WindowSizeModeEnum,
    isExpandAll: Boolean,
    searchValue: String,
    showCheckBoxes:Boolean=true,
    width: CSSSizeValue<out CSSUnitLength> =getCheckoutWidth(windowSizeMode),
    updateCheckout: (MutableSet<String>) -> Unit,
    onSearchValueChange: (String) -> Unit,
    onItemClick:(String)->Unit = {},
) {
    val checkList = remember { mutableStateOf(mutableSetOf<String>()) }


    LaunchedEffect(cashRegisterIds) {
        if (cashRegisterIds != null) {
            checkList.value = cashRegisterIds.toMutableSet()
        }
    }

    Column(
        modifier = modifier
            .fillMaxHeight()
            .margin(
                bottom = marginBottom,
            )
            .then(
                if (fillMaxWidth) Modifier.fillMaxWidth()
                else Modifier
            )
            .padding(padding)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width =if (!borderLess) Dimen.smallThickness else 0.px,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            ),
        horizontalAlignment = if (windowSizeMode== WindowSizeModeEnum.WINDOWS) Alignment.Start
            else Alignment.CenterHorizontally
    ) {

        CustomEditText(
            modifier=Modifier,
            width = width,
            label = "",
            value = searchValue,
            endIconPath = Res.PathIcon.searchBox,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint = Strings.search,
            onFocusIn = {},
            onFocusOut = {},
            onInputChange = {
                onSearchValueChange(it)
            },
        )


        LocationItems(
            modifier =VerticalScrollBehavior
                .toModifier()
                .weight(1f),
            height = height,
            showCheckBoxes =showCheckBoxes,
            windowSizeMode =windowSizeMode,
            isExpandAll =isExpandAll,
            cashList =cashList,
            checkList =checkList,
            updateCheckout ={
                updateCheckout(it)
            },
            onItemClick = {
                onItemClick(it)
            }
        )


        Spacer()
    }
}

@Composable
private fun LocationItems(
    modifier: Modifier =VerticalScrollBehavior.toModifier(),
    height: CSSSizeValue<CSSUnit.vh>,
    cashList: List<Children>?,
    checkList: MutableState<MutableSet<String>>,
    updateCheckout: (MutableSet<String>) -> Unit,
    isExpandAll: Boolean,
    windowSizeMode: WindowSizeModeEnum,
    showCheckBoxes: Boolean,
    onItemClick:(String)->Unit,
) {
    Box(
        modifier = modifier
            .scrollBar()
            .width(getCheckoutWidth(windowSizeMode))
            .fillMaxHeight()
            .border(
                color = Theme.GrayCD.rgb,
                style = LineStyle.Solid,
                width = Dimen.smallThickness
            )
            .borderRadius(Dimen.lowBorderRadius)

    )
    {
        Column(
            modifier=Modifier
                .fillMaxWidth()
                .height(height)
        )
        {
            cashList?.forEach {

                it.children?.forEachIndexed { index, model ->

                    val totalItems = model.children?.sumOf { it.children.size } ?: 0
                    val checkedItems = model.children?.sumOf { child ->
                        child.children.count { checkList.value.contains(it.id) }
                    } ?: 0

                    if (totalItems!=0){
                        DeviceLocation(
                            windowSizeMode =windowSizeMode,
                            isExpandAll =isExpandAll,
                            showCheckBox =showCheckBoxes,
                            ratioChecked = "$checkedItems/$totalItems",
                            checkedValue = model.children?.all { child ->
                                child.children.all { model ->
                                    checkList.value.contains(model.id)
                                }
                            } ?: false,
                            title = model.name ?: "",
                            checkItem = {
                                checkLocationItem(
                                    model = model,
                                    checkList = checkList,
                                    updateUserCheckout = {
                                        updateCheckout(it)
                                    }
                                )
                            },
                            unCheckItem = {
                                uncheckLocationItem(
                                    model=model,
                                    checkList = checkList,
                                    updateUserCheckout = {
                                        updateCheckout(it)
                                    }
                                )
                            },
                            content = {
                                model.children?.forEachIndexed { index, subModel ->
                                    StoreItemsList(
                                        windowSizeMode=windowSizeMode,
                                        subModel = subModel,
                                        checkList = checkList,
                                        children = subModel.children,
                                        isExpandAll = isExpandAll,
                                        showCheckBoxes = showCheckBoxes,
                                        updateUserCheckout = {
                                            updateCheckout(it)
                                        },
                                        onItemClick = {
                                            onItemClick(it)
                                        }
                                    )
                                }
                            }
                        )
                    }


                    if (index!=cashList.size-1){
                        Separator()
                    }
                }

                Box(
                    modifier=Modifier
                        .height(Dimen.normalPadding)
                )
            }

        }
    }

}



@Composable
fun StoreItemsList(
    showCheckBoxes: Boolean,
    subModel: ChildrenXX,
    isExpandAll: Boolean,
    children: List<ChildrenXXX>,
    windowSizeMode: WindowSizeModeEnum,
    checkList: MutableState<MutableSet<String>>,
    updateUserCheckout: (MutableSet<String>) -> Unit,
    onItemClick:(String)->Unit,

) {
    val subRelevantCheckList =
        subModel.children.mapNotNull { it.id }.filter { checkList.value.contains(it) }


    StoreItem(
        showCheckBox=showCheckBoxes,
        windowSizeMode=windowSizeMode,
        isExpandAll = isExpandAll,
        ratioChecked = "${subRelevantCheckList.size}" +
                "/${subModel.children.size}",
        checkedValue = subModel.children.all { model ->
            checkList.value.contains(model.id)
        },
        title = subModel.name ?: "",
        checkItem = {
            subModel.children.forEach {
                if (!checkList.value.contains(it.id))
                    checkList.value.add(it.id ?: "")
            }
            updateUserCheckout(checkList.value)
        },
        unCheckItem = {
            subModel.children.forEach {
                if (checkList.value.contains(it.id))
                    checkList.value.remove(it.id)
            }
            updateUserCheckout(checkList.value)
        },

        content = {
            subModel.children.forEachIndexed { index, item ->

                Separator()

                DeviceItem(
                    windowSizeMode=windowSizeMode,
                    checkedValue = checkList.value.contains(item.id),
                    title = item.name ?: "",
                    isLastItem = index == children.size - 1,
                    showCheckBoxes = showCheckBoxes,
                    deviceType = item.deviceType ?: "",
                    unCheckItem = {
                        if (checkList.value.contains(item.id)) {
                            checkList.value.remove(item.id)
                            updateUserCheckout(checkList.value)
                        }
                    },
                    checkItem = {
                        checkList.value.add(item.id ?: "")
                        updateUserCheckout(checkList.value)
                    },
                    onItemClick = {
                        onItemClick(item.id?:"")
                    }
                )
            }
        }
    )
}

private fun uncheckLocationItem(
    checkList: MutableState<MutableSet<String>>,
    updateUserCheckout: (MutableSet<String>) -> Unit,
    model: ChildrenX
) {

    model.children?.forEach { child ->
        child.children.forEach {
            if (checkList.value.contains(it.id)) {
                checkList.value.remove(it.id)
            }
        }

    }
    updateUserCheckout(checkList.value)
}

private fun checkLocationItem(
    model: ChildrenX,
    checkList: MutableState<MutableSet<String>>,
    updateUserCheckout: (MutableSet<String>) -> Unit
) {
    model.children?.forEach {
        it.children.forEach { model ->
            if (!checkList.value.contains(model.id)) {
                checkList.value.add(model.id ?: "")
            }
        }
    }
    updateUserCheckout(checkList.value)
}



fun getArrowImg(expanded:Boolean): String {
    return if (expanded)
        Res.PathIcon.arrowTop
    else Res.PathIcon.arrowBelow

}


