package org.hs_soft.runmynesto.domain.config

import org.jetbrains.compose.web.css.px

object FontFamily{
    const val  Montserrat="Montserrat"
}



object FontSizes {
    val smallFontSize=6.px
    val semiNormalFontSize=8.px
    val normalFontSize=10.px
    val semiMediumFontSize=12.px
    val mediumFontSize=14.px
    val highMediumFontSize=15.px
    val semiLargeFontSize=16.px
    val largeFontSize=18.px
    val semiExtraLargeFontSize=20.px
    val extraLargeFontSize=22.px
    val extraExtraLargeFontSize=24.px
}

object FontLineHeight
{
    val lineNormal=14.px
    val lineMedium=20.px
    val lineSemiLarge=24.px
    val lineLarge=32.px
}


object LetterSpacing
{
    val small=0.15.px
    val semiMedium=0.17.px

    val large=0.25.px
    val extraLarge=0.40.px
}