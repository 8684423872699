package org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager

import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.update_floor.UpdateFloorModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.UpdateFloorRepository

class UpdateFloorUseCase(
    private val repository: UpdateFloorRepository,
) {
    suspend operator fun invoke(
        number:Int,
        floorId:String,
    ):UpdateFloorModel {
        return repository.updateFloor(
            number=number,
            floorId=floorId
        )
    }
}