package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.config.transitionBackground
import org.hs_soft.runmynesto.domain.util.Res
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent

@Composable
fun CircleIcon(
    onIconClick: () -> Unit,
    modifier: Modifier=Modifier,
    img:String=Res.PathIcon.close,
    size:Int=Dimen.smallIconSize,
    borderColor:CSSColorValue=Theme.GrayAlpha56.rgb,
    hoveredBackground: CSSColorValue =Theme.GrayAlpha12.rgb,
    defaultBackground:CSSColorValue =Theme.Transparent.rgb,
    tintColor:String=Theme.GrayAlpha56.hex,
) {

    val isHovered= remember { mutableStateOf(false) }
        Box(
            modifier = modifier
                .rippleEffect{
                    onIconClick()
                }
                .onClick {
                    it.stopPropagation()
                }
                .padding(Dimen.extraSmallPadding)
                .borderRadius(50.percent)
                .border(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = borderColor
                )
                .onMouseOver {
                    isHovered.value = true
                }
                .onMouseLeave {
                    isHovered.value = false
                }
                .transitionBackground()
                .backgroundColor(
                    if (isHovered.value) {
                        hoveredBackground
                    } else {
                        defaultBackground
                    }
                )
        ) {
            VectorIcon(
                modifier = Modifier,
                pathData = img,
                width = size,
                height = size,
                color = tintColor,
            )
        }

}