package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.kitchen_locations

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.borderSetting
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.printer_location.PrinterLocation
import org.hs_soft.runmynesto.domain.util.getGridWidth
import org.hs_soft.runmynesto.pages.components.grid_function.GridFunction
import org.hs_soft.runmynesto.pages.components.grid_function.GridItemsList
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsState
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun KitchenLocationsTab(
    state: OptionsState,
    event: (OptionsEvent) -> Unit,
    windowSizeMode: WindowSizeModeEnum
) {

    LaunchedEffect(Unit){
        event(OptionsEvent.GetPrintersLocation)
    }
    Column(
        modifier = Modifier
            .fillMaxSize()
            .margin(
                leftRight = Dimen.mediumPadding,
                bottom = Dimen.normalPadding
            )
            .borderSetting()
    )
    {
        SpanText(
            text = Strings.kitchenLocations,
            modifier = Modifier
                .margin(topBottom = Dimen.normalPadding)
                .fontWeight(FontWeight.Medium)
                .fontSize(FontSizes.mediumFontSize)
                .color(Theme.Primary.rgb)
                .fontFamily(FontFamily.Montserrat)
        )

        GridFunction(
            modifier = Modifier,
            width =  getGridWidth(windowSizeMode),
            title = Strings.name,
            infoTitle = Strings.number,
            footerTitle = Strings.items,
            showAddItemIcon = true,
            infoList = state.printersLocation?: listOf(),
            showFooter = false,
            onAddItemClick = {
                event(OptionsEvent.ShowAddPrinterLocationRecordDialog)
            },
            content = {
                GridItemsList(
                    items = state.printersLocation?: listOf(),
                    onItemClick = {
                        if (it is PrinterLocation){
                            event(OptionsEvent.ShowUpdatePrinterLocationRecordDialog(
                                status=true,
                                dbIndex=it.dbIndex,
                                name = it.value
                            ))
                        }

                    },
                    id ="",
                )
            },


        )
    }






}