package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.app_constants


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class LoadAppConstant(
    @SerialName("id")
    val id: String?,
    @SerialName("label")
    val label: String?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("value")
    val value: String?
)