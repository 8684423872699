package org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.abc_statistic.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.HorizontalScrollBehavior
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.abc_statics.ABCStatisticTableHeadersEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.abc_statics.abc_statistic_data.ABCStatisticItem
import org.hs_soft.runmynesto.domain.util.extractDate
import org.hs_soft.runmynesto.domain.util.extractTime
import org.hs_soft.runmynesto.domain.util.formatToTwoDecimals
import org.hs_soft.runmynesto.pages.components.HeaderTitle
import org.hs_soft.runmynesto.pages.components.InfiniteScrollGrid
import org.hs_soft.runmynesto.pages.components.TableItemRow
import org.hs_soft.runmynesto.pages.components.grid_function.GridFooter
import org.jetbrains.compose.web.css.LineStyle


@Composable
fun ABCStatisticTable(
    windowSizeMode: WindowSizeModeEnum,
    items: List<ABCStatisticItem>?

)
{
    Column(
        modifier = Modifier
            .fillMaxWidth()
            .height(Dimen.abcStatisticTableHeight)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayAlpha12.rgb
            )
            .borderRadius(Dimen.lowBorderRadius)
    )
    {

        Column(
            modifier = HorizontalScrollBehavior.toModifier()
                .fillMaxWidth()
                .weight(1f)
        )
        {
            ABCStatisticTableHeaders(
                windowSizeMode=windowSizeMode,
            )

            Column(
                modifier = VerticalScrollBehavior
                    .toModifier()
                    .fillMaxWidth()
                    .thenIf(
                        windowSizeMode==WindowSizeModeEnum.PHONE,
                        Modifier.width(Dimen.abcTablePhoneWidth)
                    )
                    .weight(1f)
            ){
                if (!items.isNullOrEmpty()){
                     InfiniteScrollGrid(
                        items = items,
                        onLoadMore = {}
                    ){
                        val model= items[it]

                        ABCStatisticTableItem(
                            model = model,
                            onItemClick = {

                            }
                        )

                    }
                }
            }

        }


        Spacer()

        GridFooter(
            title = Strings.items,
            width = null,
            showRefreshIcon = false,
            dataSize =items?.size?:0,
            onRefreshClick = {

            }
        )





    }

}

@Composable
private fun ABCStatisticTableHeaders(
    windowSizeMode: WindowSizeModeEnum
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .thenIf(
                windowSizeMode==WindowSizeModeEnum.PHONE,
                Modifier.width(Dimen.abcTablePhoneWidth)
            )
            .backgroundColor(Theme.GrayAlpha04.rgb)
            .padding(
                leftRight = Dimen.normalPadding,
                topBottom = Dimen.smallPadding,
            )
            .borderBottom(
                width = Dimen.smallThickness,
                color = Theme.GrayCD.rgb,
                style = LineStyle.Solid,
            )
    )
    {
        ABCStatisticTableHeadersEnum.entries.forEach {
            HeaderTitle(
                title = it.title,
                fillMaxWidth = true,
                arrangement = it.arrangement
            )
        }


    }
}


@Composable
fun ABCStatisticTableItem(
    model: ABCStatisticItem,
    onItemClick:(String)->Unit
)
{
    TableItemRow(
        id = model.productId?:"",
        isSelected =false,
        onItemClick = {
            onItemClick(it)
        }
    ){
        HeaderTitle(
            title = model.code?:"",
            fillMaxWidth = true,
        )
        HeaderTitle(
            title = model.name?:"",
            fillMaxWidth = true,
        )
        HeaderTitle(
            title = model.salesGroup,
            fillMaxWidth = true,
        )
        HeaderTitle(
            title = formatToTwoDecimals(model.number) ,
            fillMaxWidth = true,
            arrangement = Arrangement.End
        )
        HeaderTitle(
            title = formatToTwoDecimals(model.total),
            fillMaxWidth = true,
            arrangement = Arrangement.End
        )
        HeaderTitle(
            title = formatToTwoDecimals(model.weight) ,
            fillMaxWidth = true,
            arrangement = Arrangement.End
        )

        HeaderTitle(
            title =if (model.firstSale!=null){
                extractDate(model.firstSale)+" , "+ extractTime(model.firstSale)
            }else "",
            fillMaxWidth = true,
        )

        HeaderTitle(
            title = if (model.lastSale!=null){
                extractDate(model.lastSale)+" , "+ extractTime(model.lastSale)
            }else "",
            fillMaxWidth = true,
        )
    }
}