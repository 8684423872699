package org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager

import org.hs_soft.runmynesto.domain.util.Res

enum class ObjectKinds(
    val value:String,
    val width:Int,
    val height:Int,
    val type:ObjectKindTypes,
    val shape:ShapeEnum?=null,
    val img:String ?=null,
)
{
    //------------Decor-------------------------------------
    TREE(
        value = "tree",
        width = 30,
        height = 30,
        type = ObjectKindTypes.INTERIOR_DECOR,
        img = Res.PathIcon.plant1,
        shape = ShapeEnum.ROUND,
    ),
    BUSH(
        value = "bush" ,
        width = 40 ,
        height =40,
        type = ObjectKindTypes.INTERIOR_DECOR,
        img = Res.PathIcon.plant2,
        shape = ShapeEnum.ROUND,
    ),
    PLANT(
        value = "plant",
        width =50,
        height =50,
        type = ObjectKindTypes.INTERIOR_DECOR,
        img = Res.PathIcon.plant3,
        shape = ShapeEnum.ROUND,
    ),
    DOOR_LEFT(
        value ="doorLeft",
        width = 35,
        height =45,
        type = ObjectKindTypes.INTERIOR_DECOR,
        img = Res.PathIcon.doorSingular1,
        shape = ShapeEnum.RECTAGNLE,
    ),
    DOOR_RIGHT(
        value = "doorRight",
        width =35,
        height =45,
        type = ObjectKindTypes.INTERIOR_DECOR,
        img = Res.PathIcon.doorSingular2,
        shape = ShapeEnum.RECTAGNLE,
    ),
    DOUBLE_DOOR(
        value = "doubleDoors",
        width =70,
        height = 45,
        type = ObjectKindTypes.INTERIOR_DECOR,
        img = Res.PathIcon.doubleDoors,
        shape = ShapeEnum.RECTAGNLE,
    ),

    CHAIR(
        value = "chair",
        width =40 ,
        height =40,
        type = ObjectKindTypes.INTERIOR_DECOR,
        img = Res.PathIcon.chair,
        shape = ShapeEnum.RECTAGNLE,
    ),
    BAR_CHAIR(
        value = "barChair",
        width = 40,
        height = 40,
        type = ObjectKindTypes.INTERIOR_DECOR,
        img = Res.PathIcon.barStool,
        shape = ShapeEnum.RECTAGNLE,
    ),
    SOFA(
        value = "sofa",
        width = 140,
        height = 70,
        type = ObjectKindTypes.INTERIOR_DECOR,
        img = Res.PathIcon.sofa_1_2,
        shape = ShapeEnum.RECTAGNLE,
    ),
    BAR(
        value = "bar",
        width =40,
        height =40,
        type = ObjectKindTypes.INTERIOR_DECOR,
        shape = ShapeEnum.RECTAGNLE,
        img = Res.PathIcon.bar,
    ),
    COLUMN(
        value = "column",
        width =25,
        height =25,
        type = ObjectKindTypes.INTERIOR_DECOR,
        shape = ShapeEnum.ROUND,
        img = Res.PathIcon.column,
    ),
    EXIT(
        value = "exit",
        width = 40,
        height = 40,
        type = ObjectKindTypes.INTERIOR_DECOR,
        shape = ShapeEnum.RECTAGNLE,
        img = Res.PathIcon.exit,
    ),
    WC(
        value = "wc",
        width = 40,
        height = 40,
        type = ObjectKindTypes.INTERIOR_DECOR,
        shape = ShapeEnum.RECTAGNLE,
        img = Res.PathIcon.wc,
    ),

    //--------Table------------------------------------------------

    TABLE(
        value = "table",
        width = 60,
        height = 60,
        type = ObjectKindTypes.TABLE,
    ),

    //------Wall------------------------------------------------------

    HORIZONTAL_WALL(
        value ="wall" ,
        width =60 ,
        height =10 ,
        type = ObjectKindTypes.WALL,
        shape=ShapeEnum.RECTAGNLE,
    ),
    VERTICAL_WALL(
        value ="wall" ,
        width =10 ,
        height = 60,
        type = ObjectKindTypes.WALL,
        shape=ShapeEnum.RECTAGNLE,
    ),
    HORIZONTAL_WINDOW(
        value ="window",
        width =60 ,
        height =10 ,
        type = ObjectKindTypes.WALL,
        shape=ShapeEnum.RECTAGNLE,
    ),
    VERTICAL_WINDOW(
        value ="window" ,
        width =60,
        height =10 ,
        shape=ShapeEnum.RECTAGNLE,
        type = ObjectKindTypes.WALL,

    ),
}