package org.hs_soft.runmynesto.domain.util

import com.varabyte.kobweb.browser.http.AbortController
import com.varabyte.kobweb.browser.http.http
import kotlinx.browser.window
import kotlinx.coroutines.withTimeoutOrNull
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonObject
import org.hs_soft.runmynesto.modules.Sentry


suspend fun <T> withTokenRefresh(
    refreshToken: suspend () -> Unit,
    block: suspend () -> T
): T {
    return try {
        block()
    } catch (e: Exception) {
        if (e.toString().contains(ApiUtils.Status.ERR_401)) {
            refreshToken() // Wait for token refresh to complete
            try {
                block() // Retry the block after token refresh
            } catch (retryException: Exception) {
                // If it fails again, throw the new exception
                throw retryException
            }
        } else {
            throw e
        }
    }
}

fun handleError(error: Exception) {
    Sentry.captureException(error)
    console.log(error.toString())
}


suspend fun requestResult(
    graphQLQuery: JsonObject,
    token: String,
    resource: String,
    json:Json=Json
): ByteArray? {
    return withTimeoutOrNull(Constants.timeout){
        window.http.post(
            resource = resource,
            body = json.encodeToString<JsonObject>(graphQLQuery).encodeToByteArray(),
            headers = mapOf(
                "Authorization" to token,
                "Content-Type" to "application/json",
            ),
            abortController = AbortController(),

            )
    }

}

inline fun <reified T> String?.parseData():T {
    return Json.decodeFromString(this.toString())
}