package org.hs_soft.runmynesto.pages.home.sub_page.media_center.mvi

import org.hs_soft.runmynesto.domain.model.home.media_center.MediaCenterInfoHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.DisplaySizeEnum
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.app_constants.LoadAppConstant
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_detail.GetMedia
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_model.MediaItem
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_tab.MediaTag
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.shop_list.ShopItem
import org.hs_soft.runmynesto.domain.model.home.product.products_list_model.ProductItem
import org.hs_soft.runmynesto.domain.model.home.template.GridItemsFilterEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.YearModel
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.Children
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.getYearsData

data class MediaCenterState(
    val mediaItems:List<MediaItem> ?=null,
    val firstPageActive:Boolean=true,
    val dataLoadingProgressVisible:Boolean=false,
    val mainGridSearchValue:String="",
    val isMainGridNameSortedAsc:Boolean=false,
    val isMainGridNumberSortedAsc:Boolean=false,
    val showMainGridNumberSortIcon:Boolean=false,
    val showMainGridNameSortIcon:Boolean=false,
    val showItemsFilterComponent:Boolean=false,
    val selectedFilterItemsType:GridItemsFilterEnum=GridItemsFilterEnum.ACTIVE_ITEMS,
    val selectedTab:MediaCenterInfoHeaderEnum=MediaCenterInfoHeaderEnum.MEDIA,
    val cashList: List<Children> ?= null,
    val mediaItemsIsActiveFilter:Boolean?=null,
    val selectedMedia: GetMedia ?=null,
    val showAddRecordDialog:Boolean=false,
    val newRecordName:String="",
    val isAddNewRecordSubmitClickable:Boolean=false,
    val addRecordLoading:Boolean=false,
    val addRecordErrorMessage:String="",
    val mediaTags:List<MediaTag>?=null,
    val showFromCalendar:Boolean=false,
    val showToCalendar:Boolean=false,
    val selectedFromDate: MonthModel = MonthModel(),
    val selectedToDate:MonthModel= MonthModel(),
    val calendarData: YearModel = getYearsData(
        startYear = Constants.startYear,
        endYear = Constants.endYear
    ),
    val mediaImg:String?=null,
    val mediaImg169:String?=null,
    val shopList:List<ShopItem>?=null,
    val animationType:List<LoadAppConstant> ?=null,
    val showAddGroupDialog:Boolean=false,
    val selectedDisplaySize: DisplaySizeEnum=DisplaySizeEnum.STANDARD,
    val showArticleDialog:Boolean=false,
    val addNewProductToArticleErrMsg:String="",
    val allProducts:List<ProductItem> ?=null,
    val isCheckoutExpandAll:Boolean=false,
    val checkoutSearchValue:String="",


)
