package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class MediaItem(
    @SerialName("fromTime")
    val fromTime: String?,
    @SerialName("id")
    val id: String?,
    @SerialName("mod_ver")
    val modVer: Int?,
    @SerialName("name")
    val name: String?,
    @SerialName("timeInterval")
    val timeInterval: TimeInterval?,
    @SerialName("__typename")
    val typename: String?
)