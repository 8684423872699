package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ListMedia(
    @SerialName("cursors")
    val cursors: Cursors?,
    @SerialName("hasNextPage")
    val hasNextPage: Boolean?,
    @SerialName("items")
    val items: List<MediaItem>?,
    @SerialName("total")
    val total: Int?,
    @SerialName("__typename")
    val typename: String?
)