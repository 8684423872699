package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.thenIf
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Constants.Responsive.closingReportsCheckoutHeight
import org.hs_soft.runmynesto.domain.util.animatedWidth
import org.hs_soft.runmynesto.domain.util.getCheckoutWidth
import org.hs_soft.runmynesto.pages.components.LoadingProgress
import org.hs_soft.runmynesto.pages.components.checkout.CheckoutTabContent
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.ClosingReportsDialog
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.closing_reports_content.ClosingReportsContent
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.ClosingReportsTable
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.mvi.ClosingReportsEvent
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.mvi.ClosingReportsState
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.mvi.ClosingReportsViewModel
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh

@Composable
fun ClosingReports(
    windowSizeMode:WindowSizeModeEnum,
    viewModel: ClosingReportsViewModel,
)
{
    val state by viewModel.state.collectAsState()

    LaunchedEffect(state.cashList){
        viewModel.onEvent(ClosingReportsEvent.ExtractAllDevicesIds)
    }
    LaunchedEffect(state.selectedClosingReport){
        viewModel.onEvent(ClosingReportsEvent.ExtractAllDevicesIds)
    }
    LaunchedEffect(Unit){
        viewModel.onEvent(ClosingReportsEvent.SetCompanyProfileId)
        viewModel.onEvent(ClosingReportsEvent.GetCheckoutData)
    }

    when(windowSizeMode)
    {
        WindowSizeModeEnum.WINDOWS ->{
            Row(
                modifier = Modifier
                    .fillMaxSize()
            )
            {
                CheckoutAndClosingReportsTable(
                    windowSizeMode=windowSizeMode,
                    state=state,
                    event =viewModel::onEvent
                )

                ClosingReportsContent(
                    windowSizeMode=windowSizeMode,
                    state=state,
                    event=viewModel::onEvent
                )

            }
        }

        WindowSizeModeEnum.TABLET ->{
            Column(
                modifier = Modifier
                    .fillMaxSize()
            )
            {
                CheckoutAndClosingReportsTable(
                    windowSizeMode=windowSizeMode,
                    state=state,
                    event =viewModel::onEvent
                )



                ClosingReportsContent(
                    windowSizeMode=windowSizeMode,
                    state=state,
                    event=viewModel::onEvent
                )

            }
        }

        WindowSizeModeEnum.PHONE ->{
            Row(
                modifier = Modifier
                    .fillMaxSize()
            )
            {
                if (state.showFirstPage)
                {
                    CheckoutAndClosingReportsTable(
                        windowSizeMode=windowSizeMode,
                        state=state,
                        event =viewModel::onEvent
                    )
                }else{
                    ClosingReportsContent(
                        windowSizeMode=windowSizeMode,
                        state=state,
                        event=viewModel::onEvent
                    )
                }



            }
        }
    }


    ClosingReportsDialog(
        windowSizeMode=windowSizeMode,
        state=state,
        event =viewModel::onEvent
    )



    LoadingProgress(
        visibility = state.loadingProgress
    )


}

@Composable
private fun CheckoutAndClosingReportsTable(
    windowSizeMode: WindowSizeModeEnum,
    state: ClosingReportsState,
    event:(ClosingReportsEvent)->Unit,
) {
    if (windowSizeMode == WindowSizeModeEnum.TABLET) {

        val targetHeight = if (state.showReportsTableAndDevices)
            "${Dimen.closingReportsTabletModeHeight}"
            else "0px"
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .attrsModifier {
                    style {
                        property("height", targetHeight)
                        property("opacity",
                            if (state.showReportsTableAndDevices) "1" else "0")
                        property("overflow",
                            "hidden")
                        property("transition",
                            "height ${Constants.mediumTransition} ease, " +
                                    "opacity ${Constants.mediumTransition} ease")
                    }
                }
        )
        {
            DevicesAndClosingReportsTable(
                windowSizeMode = windowSizeMode,
                state = state,
                cashRegisterIds =state.selectedCashRegisterIds?: listOf(),
                event = event
            )
        }
    } else {
        Column(
            modifier = Modifier
                .fillMaxHeight()
                .thenIf(
                    windowSizeMode==WindowSizeModeEnum.WINDOWS,
                    Modifier
                        .animatedWidth(
                            widthValue =
                            if (state.showReportsTableAndDevices)
                            {
                                (getCheckoutWidth(windowSizeMode).value
                                    + Dimen.largePadding.value
                                ).px
                            } else 0.px,
                            duration = Constants.mediumExpandingTime
                    )
                ),
            verticalArrangement = Arrangement.Center,

        )
        {
            DevicesAndClosingReportsTable(
                windowSizeMode = windowSizeMode,
                state = state,
                cashRegisterIds = state.selectedCashRegisterIds?: listOf(),
                event = event
            )


        }
    }
}


@Composable
private fun DevicesAndClosingReportsTable(
    windowSizeMode: WindowSizeModeEnum,
    state: ClosingReportsState,
    cashRegisterIds:List<String>,
    event: (ClosingReportsEvent)->Unit,
) {
    CheckoutTabContent(
        modifier = Modifier,
        fillMaxWidth = true,
        padding = 0.px,
        windowSizeMode = windowSizeMode,
        marginBottom = if (windowSizeMode==WindowSizeModeEnum.WINDOWS)
                Dimen.normalPadding
            else 0.px,
        cashList = state.cashList,
        width =when(windowSizeMode){
            WindowSizeModeEnum.TABLET -> Constants.Responsive.ClosingReportsCheckoutTablet.px
            WindowSizeModeEnum.WINDOWS ->getCheckoutWidth(windowSizeMode)
            WindowSizeModeEnum.PHONE ->Constants.Responsive.ClosingReportsTablePhone.px
        } ,
        height = closingReportsCheckoutHeight.vh,
        borderLess = true,
        cashRegisterIds = cashRegisterIds,
        isExpandAll = state.isDevicesExpandAll,
        searchValue = "",
        onSearchValueChange = {
            event(
                ClosingReportsEvent.OnSearchCheckoutItems(it)
            )
        },
        updateCheckout = {
            event(
                ClosingReportsEvent.UpdateCashRegisterIds(
                    it.toMutableList()
                )
            )

        },

    )

    ClosingReportsTable(
        windowSizeMode = windowSizeMode,
        event = event,
        cashRegisterIds = cashRegisterIds,
        selectedClosingReport = state.selectedClosingReport,
        closingReportsList = state.closingReports,
        selectedPeriodType = state.selectedPeriodType,
        showDaysFilterDialog = state.showDaysFilterDialog,
        showCalendarFilterDialog = state.showCalendarFilterDialog,
        selectedDate = state.selectedDate,
        selectedFrom = state.selectedFromDate ?: "",
        selectedTo = state.selectedToDate ?: "",
        selectedCalendarHeaderYear = state.selectedCalendarHeaderYear,
        showReportsTableAndDevices = state.showReportsTableAndDevices,

    )
}




