package org.hs_soft.runmynesto.domain.usecase.product.tab_product_general

import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.GetDataAssistantAnswerRepository


class GetDataAssistantAnswerUseCase
    (private val repository: GetDataAssistantAnswerRepository) {
    suspend operator fun invoke(email: String, question: String): String {
        return repository.getDataAssistantAnswer(email = email, question = question)
    }
}