package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.calendar_filter_dialog.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CircleIcon
import org.hs_soft.runmynesto.pages.components.VectorIcon

@Composable
fun SelectYearTopCalendarHeader(
    selectedCalendarHeaderYear: Int,
    onCloseFilterClick: () -> Unit,
    setNewCalendarHeaderYear:(Int)->Unit,
) {


    Box(
        modifier = Modifier
            .margin(bottom = Dimen.mediumPadding)
            .fillMaxWidth(),

    )
    {
        Row(
            modifier = Modifier.align(Alignment.Center),
            verticalAlignment = Alignment.CenterVertically,
        )
        {
            VectorIcon(
                modifier = Modifier
                    .onClick {
                        if (selectedCalendarHeaderYear>Constants.closingReportsCalendarStartYear)
                        setNewCalendarHeaderYear(selectedCalendarHeaderYear-1)
                    }
                    .margin(right = Dimen.normalPadding),
                pathData = Res.PathIcon.previous,
                color = Theme.GrayAlpha56.hex,
                width = Dimen.mediumIconSize,
                height = Dimen.mediumIconSize,
            )
            SpanText(
                text =selectedCalendarHeaderYear.toString(),
                modifier = Modifier
                    .fontFamily(FontFamily.Montserrat)
                    .fontSize(FontSizes.mediumFontSize)
                    .fontWeight(FontWeight.Medium)
                    .color(Theme.Primary.rgb)

            )

            VectorIcon(
                modifier = Modifier
                    .onClick {
                        if (selectedCalendarHeaderYear<Constants.closingReportsCalendarEndYear)
                        setNewCalendarHeaderYear(selectedCalendarHeaderYear+1)
                    }
                    .margin(left = Dimen.normalPadding),
                pathData = Res.PathIcon.next,
                color = Theme.GrayAlpha56.hex,
                width = Dimen.mediumIconSize,
                height = Dimen.mediumIconSize,
            )

        }


        CircleIcon(
            modifier = Modifier.align(Alignment.CenterEnd),
            size = Dimen.semiLargeIconSize,
            img = Res.PathIcon.largeClose,
            borderColor = Theme.Transparent.rgb,
            onIconClick = {
                onCloseFilterClick()
            },
        )




    }


}