package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.update_media_img


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UpdateBase64ImgModel(
    @SerialName("data")
    val `data`: Data?
)