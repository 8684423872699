package org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager

import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.restaurant_project.RestaurantProjectModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.RestaurantProjectsRepository


class RestaurantProjectsUseCase(private val repository: RestaurantProjectsRepository) {
    suspend operator fun invoke(): RestaurantProjectModel {
        return repository.getRestaurantProjects()
    }
}