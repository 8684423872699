package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.closing_reports_content

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model.GetClosing
import org.hs_soft.runmynesto.domain.util.extractDate
import org.hs_soft.runmynesto.domain.util.extractTime
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.closing_reports_content.component.ClosingReportsContentHeader
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.closing_reports_content.component.ClosingReportsSalesGroup
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.closing_reports_content.component.Currency
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.closing_reports_content.component.CashBookAndCoupons
 import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.mvi.ClosingReportsEvent
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.mvi.ClosingReportsState
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun ClosingReportsContent(
    windowSizeMode: WindowSizeModeEnum,
    state: ClosingReportsState,
    event:(ClosingReportsEvent)->Unit
) {
    val selectedReport=state.selectedClosingReport?.data?.cashAssist?.getClosing

    Column(
        modifier = Modifier
            .fillMaxSize()
            .backgroundColor(Theme.White.rgb)
    )
    {
        ClosingReportsContentHeader(
            windowSizeMode=windowSizeMode,
            title = if (selectedReport!=null){
                    "${Strings.numberDot} ${selectedReport.number?:""}," +
                            "${extractDate(selectedReport.dateTime?:"")?:""} " +
                            (extractTime(selectedReport.dateTime?:"")?:"")
                }else "",
            showTableAndDevices = state.showReportsTableAndDevices,
            isFilterClickable =state.selectedClosingReport!=null,
            financialFilterDialogShowingStatus =state.financialFilterDialogShowingStatus ,
            isCloseDailyReportInActive = state.selectedClosingReport
                ?.data?.cashAssist?.getClosing?.inactive?:false,
            onSweepPageClick = {
                event(ClosingReportsEvent.SetReportsTableAndDevicesVisibilityStatus(
                    !state.showReportsTableAndDevices
                ))
            },
            showFinancialGroupDialog = {
                event(
                    ClosingReportsEvent.SetFinancialGroupStatus(true)
                )
            },
            setFinancialFilterDialogStatus = {
                event(
                    ClosingReportsEvent.SetFinancialFilterDialog(!state.financialFilterDialogShowingStatus)
                )
            },
            showCloseDailyReport = {
                event(
                    ClosingReportsEvent.SetClosingDailyReportDialogStatus(true)
                )
            }

        )

        Content(
            companyProfileId=state.companyProfileId,
            model=selectedReport,
            windowSizeMode=windowSizeMode,
        )

    }




}

@Composable
private fun Content(
    model: GetClosing?,
    windowSizeMode: WindowSizeModeEnum,
    companyProfileId: String?,
)
{
    Column(
        modifier = VerticalScrollBehavior.toModifier()
            .margin(top = Dimen.normalPadding)
            .fillMaxWidth()
            .fillMaxHeight()
            .padding(leftRight = Dimen.mediumPadding, )
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
    ){
        ClosingReportsSalesGroup(
            data =model?.salesGroups,
            windowSizeMode=windowSizeMode,
        )

        Currency(
            companyProfileId=companyProfileId,
            data=model?.currency,
            windowSizeMode=windowSizeMode,
        )

        CashBookAndCoupons(
            windowSizeMode = windowSizeMode,
            cashBook =model?.cashbook,
            coupon =model?.coupons,
            paymentMethod = model?.paymentMethods,
        )


    }
}




