package org.hs_soft.runmynesto.data.api_datasource.media_center.tabs.media

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_detail.MediaDetailModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.AddNewMediaRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.MediaDetailRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class AddNewMediaRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): AddNewMediaRepository {


    override suspend fun addNewMedia(name: String): MediaDetailModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                mutation createMedia(${'$'}required: MediaReqFields!,
                ${'$'}fields: MediaFields,${'$'}copyId: ID) {
                  cashAssist {
                    createMutation:createMedia(required: ${'$'}required
                    ,fields: ${'$'}fields,copyId: ${'$'}copyId) {
                       ${ApiUtils.Field.id}
                       ${ApiUtils.Field.mod_ver}
                      ...mediamain
                       ${ApiUtils.Field.cashRegisterId}
                       ${ApiUtils.Field.__typename}
                    }
                    ${ApiUtils.Field.__typename}
                  }
                }
                
                fragment mediamain on Media {
                   ${ApiUtils.Field.name}
                   ${ApiUtils.Field.refreshTime}
                   ${ApiUtils.Field.tagId}
                  dateInterval {
                     ${ApiUtils.Field.to}
                     ${ApiUtils.Field.from}
                     ${ApiUtils.Field.__typename}
                  }
                  timeInterval {
                     ${ApiUtils.Field.to}
                     ${ApiUtils.Field.from}
                     ${ApiUtils.Field.__typename}
                  }
                   ${ApiUtils.Field.isActive}
                   ${ApiUtils.Field.stayActive}
                   ${ApiUtils.Field.animationType}
                   ${ApiUtils.Field.weekDays}
                   ${ApiUtils.Field.shopId}
                  withProducts {
                     ${ApiUtils.Field.id}
                     ${ApiUtils.Field.name}
                     ${ApiUtils.Field.__typename}
                  }
                  img {
                     ${ApiUtils.Field.id}
                     ${ApiUtils.Field.uid}
                     ${ApiUtils.Field.key}
                     ${ApiUtils.Field.__typename}
                  }
                  img16_9 {
                     ${ApiUtils.Field.id}
                     ${ApiUtils.Field.uid}
                     ${ApiUtils.Field.key}
                     ${ApiUtils.Field.__typename}
                  }
                  imgMobile {
                     ${ApiUtils.Field.id}
                     ${ApiUtils.Field.uid}
                     ${ApiUtils.Field.key}
                     ${ApiUtils.Field.__typename}
                  }
                   ${ApiUtils.Field.__typename}
                }
                


            """.trimIndent())

            putJsonObject("variables") {
                putJsonObject("required"){
                    put("name",name)
                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }


    }

}
