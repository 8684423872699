package org.hs_soft.runmynesto.data.api_datasource.media_center.tabs.media

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.app_constants.AppConstantModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.LoadAppConstantsRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class LoadAppConstantsRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): LoadAppConstantsRepository {

    override suspend fun getAppConstants(language: String, type: Int): AppConstantModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                query loadAppConstants(${'$'}type: ID!,${'$'}language: Language!) {
                  cashAssist {
                    loadAppConstants(language:${'$'}language, type:${'$'}type) {
                      ${ApiUtils.Field.id}
                      ${ApiUtils.Field.label}: ${ApiUtils.Field.value}
                      ${ApiUtils.Field.value}: ${ApiUtils.Field.code}
                      ${ApiUtils.Field.__typename}
                    }
                    ${ApiUtils.Field.__typename}
                  }
                }



            """.trimIndent())

            putJsonObject("variables") {
                put("language",language)
                put("type",type)
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }

    }


}
