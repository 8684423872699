package org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager

import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.delete_floor.DeleteRestaurantFloorModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.DeleteFloorRepository

class DeleteFloorUseCase(
    private val repository: DeleteFloorRepository,
) {
    suspend operator fun invoke(
        floorId:String
    ): DeleteRestaurantFloorModel {
        return repository.deleteFloor(floorId =floorId)
    }
}