package org.hs_soft.runmynesto.domain.util

import androidx.compose.runtime.NoLiveLiterals
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.outline
import com.varabyte.kobweb.compose.ui.styleModifier
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.Instant
import kotlinx.datetime.LocalDate
import kotlinx.datetime.LocalDateTime
import kotlinx.datetime.Month
import kotlinx.datetime.TimeZone
import kotlinx.datetime.atStartOfDayIn
import kotlinx.datetime.atTime
import kotlinx.datetime.internal.JSJoda.DateTimeFormatter
import kotlinx.datetime.internal.JSJoda.DateTimeParseException
import kotlinx.datetime.internal.JSJoda.ZoneId
import kotlinx.datetime.internal.JSJoda.ZonedDateTime
import kotlinx.datetime.minus
import kotlinx.datetime.number
import kotlinx.datetime.plus
import kotlinx.datetime.toInstant
import kotlinx.datetime.toLocalDateTime
import org.hs_soft.runmynesto.modules.Amplify
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.DayModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.YearModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.select_days.DayWeekEnum
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.Children
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenXXX
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.CSSUnitLength
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.w3c.dom.HTMLInputElement


@NoLiveLiterals
fun configureAmplify()
{
    val amplifyConfig = jsObject<Any> {
        Auth = jsObject<Any> {
            Cognito = jsObject<Any> {
                this["userPoolId"] = ApiUtils.Cognito.USER_POOL_ID
                this["userPoolClientId"] = ApiUtils.Cognito.USER_POOL_CLIENT_ID
                this["identityPoolId"] = ApiUtils.Cognito.IDENTITY_POOL_ID
                loginWith = jsObject<Any> {
                    this["username"] = true
                }
                mfa = jsObject<Any> {
                    this["status"] = "on"
                    this["totpEnabled"] = true
                    this["smsEnabled"] = true
                }
//                mfaTypes = arrayOf("SMS", "TOTP")
//                mfaConfiguration = "ON"
            }

            cookieStorage=jsObject<Any> {
                this["domain"]=ApiUtils.Cognito.Domain
                this["secure"] = true
                this["path"] = "/"
                this["sameSite"] = "None"
            }
        }
        API = jsObject<Any> {
            GraphQL= jsObject<Any> {
                this["endpoint"]= ApiUtils.Cognito.API_URL
                this["defaultAuthMode"]= "apiKey"
                this["region"] = ApiUtils.Cognito.REGION
            }
        }
        Storage = jsObject<Any> {
            S3 = jsObject<Any> {
                this["bucket"]= ApiUtils.Cognito.BUCKET
                this["region"]= ApiUtils.Cognito.REGION
            }
        }

    }
    Amplify.Amplify.configure(amplifyConfig)

}


fun openLocalForSelectingFile() {
    js("document.querySelector('input[type=\"file\"]').click()")
}

fun openLocalForUniqueSelectingFile(inputId: String) {
    val inputElement = document.getElementById(inputId) as? HTMLInputElement
    if (inputElement != null) {
        console.log("Opening file input for: $inputId")
        inputElement.click()
    } else {
        console.error("File input not found for ID: $inputId")
    }
}

fun Float.formatToTwoDecimalPlaces(): String {
    val factor = 100
    val roundedValue = (this * factor).toInt() / factor.toFloat()
    val result = roundedValue.toString()

    return if (result.contains('.')) {
        val parts = result.split('.')
        if (parts[1].length < 2) "${result}0" else result
    } else {
        "$result.00"
    }
}

fun downloadFile(url: String?, fileName: String) {
    if (url!=null){
        val anchor = window.document.createElement("a")
                as org.w3c.dom.HTMLAnchorElement
        anchor.href = url
        anchor.download = fileName
        anchor.click()
    }

}

fun formatDate(dateString: String): String {
    val instant = Instant.parse(dateString)
    val localDate = instant.toLocalDateTime(TimeZone.UTC).date
    return "${localDate.dayOfMonth.toString().
    padStart(2, '0')}.${localDate.monthNumber.toString()
        .padStart(2, '0')}.${localDate.year}"
}
fun getFromDate(monthModel: MonthModel): String {
    val date = LocalDate(monthModel.yearNumber, monthModel.monthNumber, monthModel.dayNumber)
    return date.atStartOfDayIn(TimeZone.UTC).toString()
}

fun getToDate(monthModel: MonthModel): String {
    val date = LocalDate(monthModel.yearNumber, monthModel.monthNumber, monthModel.dayNumber)
    return date.atTime(23, 59, 59, 999_999_999).toInstant(TimeZone.UTC).toString()
}


fun getCurrentMonth(clock: kotlinx.datetime.internal.JSJoda.Clock): Int {
    val instant = clock.instant()
    val zoneId = ZoneId.systemDefault()
    val zonedDateTime = instant.atZone(zoneId)
    return zonedDateTime.toLocalDate().monthValue().toInt()
}


fun getStartOfMonth(month: Int): String {
    val year = Clock.System.now().toLocalDateTime(TimeZone.UTC).year
    val date = LocalDate(year, month, 1)
    return date.atStartOfDayIn(TimeZone.UTC).toString()
}

fun getEndOfMonth(month: Int): String {
    val year = Clock.System.now().toLocalDateTime(TimeZone.UTC).year
    val date = LocalDate(year, month, 1).plus(1, DateTimeUnit.MONTH).minus(1, DateTimeUnit.DAY)
    return date.atTime(23, 59, 59, 999_999_999).toInstant(TimeZone.UTC).toString()
}

fun getStartOfQuarter(quarter: Int): String {
    val month = (quarter - 1) * 3 + 1
    return getStartOfMonth(month)
}

fun getEndOfQuarter(quarter: Int): String {
    val month = quarter * 3
    return getEndOfMonth(month)
}

fun getStartOfYear(offset: Int): String {
    val year = Clock.System.now().toLocalDateTime(TimeZone.UTC).year + offset
    val date = LocalDate(year, 1, 1)
    return date.atStartOfDayIn(TimeZone.UTC).toString()
}

fun getEndOfYear(offset: Int): String {
    val year = Clock.System.now().toLocalDateTime(TimeZone.UTC).year + offset
    val date = LocalDate(year, 12, 31)
    return date.atTime(23, 59, 59, 999_999_999).toInstant(TimeZone.UTC).toString()
}

fun getStartOfHalfYear(half: Int): String {
    val month = if (half == 1) 7 else 1
    return getStartOfMonth(month)
}

fun getEndOfHalfYear(half: Int): String {
    val month = if (half == 1) 12 else 6
    return getEndOfMonth(month)
}

fun String.getUpdatedSelectedYear(selectedYear:Int): String {
    val startRange=0
    val endRange=4
    return this.replaceRange(startRange,endRange,selectedYear.toString())
}


fun getStartOfDay(daysOffset: Int): String {
    val today = Clock.System.now().toLocalDateTime(TimeZone.UTC).date
    val targetDate = today.plus(daysOffset, DateTimeUnit.DAY)
    return targetDate.atStartOfDayIn(TimeZone.UTC).toString()
}

fun getEndOfDay(daysOffset: Int): String {
    val today = Clock.System.now().toLocalDateTime(TimeZone.UTC).date
    val targetDate = today.plus(daysOffset, DateTimeUnit.DAY)
    return targetDate.atTime(23, 59, 59, 999_999_999)
        .toInstant(TimeZone.UTC).toString()
}

fun getStartOfWeek(weeksOffset: Int): String {
    val today = Clock.System.now().toLocalDateTime(TimeZone.UTC).date
    val startOfWeek = today.minus(today.dayOfWeek.ordinal, DateTimeUnit.DAY)
    val targetDate = startOfWeek.plus(weeksOffset * 7, DateTimeUnit.DAY)
    return targetDate.atStartOfDayIn(TimeZone.UTC).toString()
}
fun getEndOfWeek(weeksOffset: Int): String {
    val today = Clock.System.now().toLocalDateTime(TimeZone.UTC).date
    val endOfWeek = today.plus(6 - today.dayOfWeek.ordinal, DateTimeUnit.DAY)
    val targetDate = endOfWeek.plus(weeksOffset * 7, DateTimeUnit.DAY)
    return targetDate.atTime(23, 59, 59, 999_999_999).toInstant(TimeZone.UTC).toString()
}

fun parseDateStringToMonthModel(dateString: String?): MonthModel {
    if (dateString==null)
        return MonthModel()

    val instant = Instant.parse(dateString)
    val localDateTime = instant.toLocalDateTime(TimeZone.UTC)

    return MonthModel(
        dayNumber = localDateTime.dayOfMonth,
        yearNumber = localDateTime.year,
        monthNumber = localDateTime.monthNumber,
    )
}
fun monthModelToDateString(model: MonthModel): String {
    val localDate = LocalDate(model.yearNumber, model.monthNumber, model.dayNumber)
    val localDateTime = LocalDateTime(localDate.year, localDate.monthNumber, localDate.dayOfMonth, 0, 0)
    val instant = localDateTime.toInstant(TimeZone.UTC)
    return instant.toString()
}

fun <T> jsObject(init: dynamic.() -> Unit): dynamic {
    val obj = js("{}")
    init(obj)
    return obj
}

fun rotateDayNames(
    dayMap: Map<String, String>,
    startDayFullName: String
): List<String> {
    val dayNames = dayMap.keys.toList()
    val startIndex = dayNames.indexOf(startDayFullName)
    return dayNames.drop(startIndex) + dayNames.take(startIndex)
}

fun extractAllDevicesIds(cashList: List<Children>?): List<String> {
    val ids = mutableListOf<String>()

    cashList?.forEach { children ->
        children.children?.forEach { childrenX->
            childrenX.children?.forEach { childrenXX ->
                childrenXX.children?.forEach {childrenXXX->
                    childrenXXX.id?.let { ids.add(it) }
                }

            }
        }
    }

    return ids
}
fun extractAllCashRegisters(cashList: List<Children>?): List<ChildrenXXX> {
    val cashRegisters = mutableListOf<ChildrenXXX>()

    cashList?.forEach { children ->
        children.children?.forEach { childrenX ->
            childrenX.children?.forEach { childrenXX->
                childrenXX.children.forEach{ childrenXXX->
                    cashRegisters.add(childrenXXX)
                }

            }
        }
    }

    return cashRegisters
}

fun Modifier.animatePageTransition(isActive: Boolean): Modifier = this.then(
    attrsModifier {
        style {
            property("transition", "background-color" +
                    " ${Constants.fastTransition} ease-in-out")
            property("width", if (isActive)
                "${Dimen.activePageSelectorWidth}px" else
                    "${Dimen.inactivePageSelectorWidth}px")
            property("height", if (isActive)
                "${Dimen.activePageSelectorHeight}px" else
                    "${Dimen.inactivePageSelectorHeight}px")
        }
    }
)


fun showSelectedDate(selectedDate: MonthModel):String
{
    return if ((
            selectedDate.dayNumber +
                    selectedDate.monthNumber +
                    selectedDate.yearNumber
            ) == 0
    ) ""
    else{
        val date = LocalDate(
            year = selectedDate.yearNumber,
            monthNumber = selectedDate.monthNumber,
            dayOfMonth = selectedDate.dayNumber,
        )
       return date.toString()
    }

}

fun getNextDay(selectedDate: MonthModel): String {
    if ((selectedDate.dayNumber + selectedDate.monthNumber + selectedDate.yearNumber) == 0) {
        return ""
    }

    val date = LocalDate(
        year = selectedDate.yearNumber,
        monthNumber = selectedDate.monthNumber,
        dayOfMonth = selectedDate.dayNumber,
    ).plus(1, DateTimeUnit.DAY)

    return date.toString() // Format as "YYYY-MM-DD"
}


fun getYearsData(startYear: Int,endYear: Int): YearModel
{
    val months = mutableListOf<MonthModel>()

    for (year in startYear..endYear) {
        var currentDate = LocalDate(year, Month.JANUARY, 1)

        while (currentDate.year == year) {
            val month = currentDate.month
            val days = mutableListOf<DayModel>()

            while (currentDate.month == month) {
                days.add(
                    DayModel(
                        dayNumber = currentDate.dayOfMonth,
                        dayName = currentDate.dayOfWeek.name.lowercase().capitalize()
                    )
                )
                currentDate = currentDate.plus(1, DateTimeUnit.DAY)
            }

            months.add(
                MonthModel(
                    dayNumber = year*10+month.number,
                    monthName = month.name.lowercase().capitalize(),
                    days = days,
                    yearNumber = year,
                    monthNumber = month.number,
                )
            )
        }


    }



    return YearModel(monthsOfYear = months)
}



fun parseWeekDaysNum(num: Int): List<DayWeekEnum> {
    val options = DayWeekEnum.entries.toList()
    val selectOptions = options.drop(1)
    val possibleOptions = selectOptions.map { it.value }

    val res = mutableListOf<DayWeekEnum>()
    if (num == DayWeekEnum.ALL_DAYS.value) {
        return options // Return 'All Days'
    } else if (possibleOptions.contains(num)) {
        res.add(selectOptions[possibleOptions.indexOf(num)])
    } else {
        var sum = 0
        for ((index, option) in selectOptions.withIndex().reversed()) {
            val value = option.value
            if (num >= sum + value) {
                sum += value
                res.add(option)
            }
        }
    }
    return res.sortedBy { it.value }
}

fun extractDate(dateTimeString: String): String? {
    return try {
        if (dateTimeString.isBlank()) return null
        val zonedDateTime = ZonedDateTime.parse(dateTimeString)
        val dateFormatter = DateTimeFormatter.ofPattern("dd.MM.yyyy")
        zonedDateTime.format(dateFormatter)
    } catch (e: DateTimeParseException) {
        null
    }
}

fun extractTime(dateTimeString: String): String? {
    return try {
        if (dateTimeString.isBlank()) return null
        val zonedDateTime = ZonedDateTime.parse(dateTimeString)
        val timeFormatter = DateTimeFormatter.ofPattern("HH:mm")
        zonedDateTime.format(timeFormatter)
    } catch (e: DateTimeParseException) {
        null
    }
}

fun MonthModel.toLocalDate(): LocalDate {
    return LocalDate(yearNumber, monthNumber, dayNumber)
}

fun isMacDevice(): Boolean {
    val platform = window.navigator.platform
    val userAgent = window.navigator.userAgent


    val macosPlatforms = listOf("Macintosh", "MacIntel", "MacPPC", "Mac68K", "Mac")

    val macosVersions = listOf(
        "Sonoma", "Ventura", "Monterey", "Big Sur", "Catalina", "Mojave",
        "High Sierra", "Sierra", "El Capitan", "Yosemite", "Mavericks",
        "Mountain Lion", "Lion", "Snow Leopard"
    )


    return (macosPlatforms.any { platform.contains(it, ignoreCase = true) }
            || macosVersions.any { userAgent.contains(it, ignoreCase = true) })
}




fun Modifier.noBorder(): Modifier {
    return this
        .border(
            width =0.px,
            style = LineStyle.None,
            color = Colors.Transparent,
        )
        .outline(
            width =0.px,
            style = LineStyle.None,
            color = Colors.Transparent,
        )
}

fun Modifier.animatedWidth(
    widthValue: CSSSizeValue<out CSSUnit>,
    duration: Double = Constants.fastExpandingTime
):
        Modifier = this.then(
            Modifier.styleModifier {
                width(widthValue)
                // Use a string to specify the duration in seconds
                property("transition",
                    "width ${duration}s ease-in-out")
            }
)

fun getGeneralItemRatio(
    windowSizeMode: WindowSizeModeEnum,
    size:CSSSizeValue<CSSUnit.px>):CSSSizeValue<CSSUnit.px>{
    val value=size.value.toInt()

    return when(windowSizeMode){
        WindowSizeModeEnum.WINDOWS-> (value*1.0).px
        WindowSizeModeEnum.PHONE -> (value*0.85).px
        WindowSizeModeEnum.TABLET -> (value*0.95).px
    }
}


fun getGridWidth(
    windowSizeMode: WindowSizeModeEnum,
): CSSSizeValue<out CSSUnitLength>
{
    val screenWidth = window.screen.width
    return if (windowSizeMode== WindowSizeModeEnum.PHONE)
        (screenWidth* Constants.Responsive.gridPhoneRatio).px
    else if(windowSizeMode== WindowSizeModeEnum.TABLET)
        (screenWidth* Constants.Responsive.girdTabletRatio).px
    else Dimen.gridWidth
}

fun getAllergensWidth(
    windowSizeMode: WindowSizeModeEnum,
): CSSSizeValue<out CSSUnitLength>
{
    val screenWidth = window.screen.width
    return if (windowSizeMode== WindowSizeModeEnum.PHONE)
        (screenWidth* Constants.Responsive.allergenPhoneRatio).px
    else if(windowSizeMode== WindowSizeModeEnum.TABLET)
        (screenWidth* Constants.Responsive.allergenTabletRatio).px
    else Dimen.allergensHeaderWidth
}


fun getArticleListWidth(windowSizeMode: WindowSizeModeEnum): CSSSizeValue<out CSSUnitLength> {
    val screenWidth = window.screen.width
    return if (windowSizeMode== WindowSizeModeEnum.PHONE)
        (screenWidth* Constants.Responsive.articlePhoneRatio).px
    else if (windowSizeMode== WindowSizeModeEnum.TABLET)
        (screenWidth* Constants.Responsive.articleTabletRatio).px
    else Dimen.articleListWidth
}


fun getCheckoutWidth(windowSizeMode: WindowSizeModeEnum): CSSSizeValue<out CSSUnitLength> {
    val screenWidth = window.screen.width
    return if (windowSizeMode== WindowSizeModeEnum.PHONE)
        (screenWidth* Constants.Responsive.checkoutPhoneRatio).px
    else if (windowSizeMode== WindowSizeModeEnum.TABLET)
        (screenWidth* Constants.Responsive.checkoutTabletRatio).px
    else Dimen.checkOutItemWidth
}

fun Double?.toTwoDecimalString(usingDotForDigit: Boolean): String {
    if (this == null) return ""
    val rounded = kotlin.math.floor(this * 100) / 100  // Round to two decimal places
    val decimalSeparator = if (usingDotForDigit) '.' else ','
    val stringRepresentation = rounded.toString().replace('.', decimalSeparator)
    val decimalIndex = stringRepresentation.indexOf(decimalSeparator)
    return if (decimalIndex == -1) {
        // No decimal point, add one
        "$stringRepresentation${decimalSeparator}00"
    } else {
        // Ensure two digits after the decimal
        val decimals = stringRepresentation.length - decimalIndex - 1
        when (decimals) {
            0 -> "$stringRepresentation${decimalSeparator}00"
            1 -> "${stringRepresentation}0"
            else -> stringRepresentation.substring(0, decimalIndex + 3)
        }
    }
}


fun openInNewTab(url: String) {
    js("window.open(url, '_blank')")
}

fun formatToTwoDecimals(value: Double?): String {
    if (value==null) return ""
    if (value==0.0) return ""

    val roundedValue = (value * 100).toInt() / 100.0
    return roundedValue.toString().let {
        if (it.contains('.')) {
            it.padEnd(it.indexOf('.') + 3, '0')
        } else {
            "$it.00"
        }
    }
}

