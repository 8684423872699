package org.hs_soft.runmynesto.pages.home.sub_page.dashboard.mvi

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.dashboard.dashboard_config.ListShopItem
import org.hs_soft.runmynesto.domain.model.home.dashboard.get_shop_statics.ShopStaticItem
import org.hs_soft.runmynesto.domain.model.home.options.CountriesEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.usecase.dashboard.CustomerFrequencyUseCase
import org.hs_soft.runmynesto.domain.usecase.dashboard.DashboardConfigUseCase
import org.hs_soft.runmynesto.domain.usecase.dashboard.InitShopStaticsUseCase
import org.hs_soft.runmynesto.domain.usecase.dashboard.ShopStaticsUseCase
import org.hs_soft.runmynesto.domain.usecase.home.GetCompanyProfileIdUseCase
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.getFromDate
import org.hs_soft.runmynesto.domain.util.getNextDay
import org.hs_soft.runmynesto.domain.util.getToDate
import org.hs_soft.runmynesto.domain.util.handleError
import org.hs_soft.runmynesto.domain.util.helper.BaseViewModel
import org.hs_soft.runmynesto.domain.util.showSelectedDate
import org.hs_soft.runmynesto.domain.util.withTokenRefresh

class DashboardViewModel(
    private val requestManager: RequestManager,
    private val refreshToken:()->Unit,
    private val initShopStaticsUseCase: InitShopStaticsUseCase,
    private val shopStaticsUseCase:ShopStaticsUseCase,
    private val dashboardConfigUseCase: DashboardConfigUseCase,
    private val getCompanyProfileIdUseCase: GetCompanyProfileIdUseCase,
    private val customerFrequencyUseCase: CustomerFrequencyUseCase,

): BaseViewModel() {

    private val _state = MutableStateFlow(DashboardState())
    val state: StateFlow<DashboardState> = _state


    override fun clear() {
        super.clear()
        requestManager.cancelAll()
    }

    init {
        initCalendarToToday()
        initCurrencyAndTimeType()
    }

    private fun initCurrencyAndTimeType() {
        val result=getCompanyProfileIdUseCase()
        _state.value=_state.value.copy(
            selectedCompanyProfile = result
        )

        val currencyType= if (state.value.selectedCompanyProfile== CountriesEnum.SWITZERLAND.id)
            ApiUtils.CURRENCY_TYPE.CHF
        else Strings.euro

        val timeType=if (state.value.selectedCompanyProfile== CountriesEnum.SWITZERLAND.id)
            Constants.stDot
        else Constants.hr

        _state.value=_state.value.copy(
            currencyType = currencyType,
            timeType = timeType,
        )

    }

    private fun initCalendarToToday() {
        val today = Clock.System.now().toLocalDateTime(TimeZone.UTC).date

        _state.value = _state.value.copy(
            selectedDate = MonthModel(
                dayNumber = today.dayOfMonth,
                yearNumber = today.year,
                monthNumber = today.monthNumber,
            )
        )
        _state.value=_state.value.copy(
            today = state.value.selectedDate
        )
    }



    fun onEvent(event: DashboardEvent) {

        when(event)
        {

            is DashboardEvent.FilterBasedOnRegion->{
                filterBasedOnRegion(id=event.id)
            }

            is DashboardEvent.GetOtherDaysCustomerFrequency->{
                getOtherDaysCustomerFrequency(
                    selectedItemId=state.value.selectedDashboardItem?.shopId?:"",
                    comparedDate=event.comparedDate,
                )
            }

            is DashboardEvent.GetCustomerFrequency->{
                getCustomerFrequency(
                    selectedItemId=state.value.selectedDashboardItem?.shopId?:"",
                    selectedDate=event.selectedDate,
                )
            }

            is DashboardEvent.OnDetailBackClick->{
                _state.value=_state.value.copy(
                    showDashboardDetail = false
                )
            }
            is DashboardEvent.OnDashboardItemClick->{
                onDashboardItemClick(
                    dashboardItem=event.item,
                    itemName=event.itemName,
                )
            }

            is DashboardEvent.SetCompareChartDialogStatus->{
                _state.value=_state.value.copy(
                    showCompareChartDialog = event.status
                )
            }


            is DashboardEvent.GetDashboardConfigs->{
                getDashBoardConfig()
            }

            is DashboardEvent.GetShopStatics->{
                getShopStatics(requestId = state.value.requestId?:"")
            }


            is DashboardEvent.InitShopStatics->{
                initShopStatics(
                    cashRegisterIds =getCashRegisterIds(
                        cashRegisters =state.value.listShop
                    ),
                    from = getFromDate(state.value.selectedDate),
                    to= getToDate(state.value.selectedDate),
                )
            }

            is DashboardEvent.SetCalendarStatus->{
                _state.value=_state.value.copy(showCalendarDialog = event.status)
            }

            is DashboardEvent.GetShopListItems->{
                chooseDate(
                    selectedDate=event.selectedDate,
                )
            }


        }


    }


    private fun getOtherDaysCustomerFrequency(
        selectedItemId: String,
        comparedDate: MonthModel
    ) {
        if (comparedDate!=state.value.selectedDate){
            launch {
                try {
                    withTokenRefresh(refreshToken) {
                        _state.value = _state.value.copy(
                            dataLoadingProgressVisible = true,
                            showCalendarDialog = false,
                            comparedDate =  comparedDate,
                        )

                        val result = customerFrequencyUseCase(
                            cashRegisterIds = getCashRegisterIds(
                                filteredId = selectedItemId,
                                cashRegisters = state.value.listShop
                            ),
                            periodStart = showSelectedDate(comparedDate),
                            periodEnd = getNextDay(comparedDate),
                        )


                        _state.value = _state.value.copy(
                            comparedCustomerFrequencies = result.data?.cashAssist?.getCustomerFrequencies,
                            dataLoadingProgressVisible = false,
                            showCompareChartDialog = false
                        )


                    }

                } catch (e: Exception) {
                    _state.value = _state.value.copy(
                        dataLoadingProgressVisible = false,
                    )
                    handleError(error = e)
                }
            }
        }


    }

    private fun filterBasedOnRegion(
        id: String?,
    ) {
        _state.value=_state.value.copy(
            selectedShopId = id
        )

    }

    private fun getCustomerFrequency(
        selectedItemId:String,
        selectedDate: MonthModel,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    _state.value = _state.value.copy(
                        dataLoadingProgressVisible = true,
                        showCalendarDialog = false,
                        selectedDate =  selectedDate,
                    )
                    val result=customerFrequencyUseCase(
                        cashRegisterIds =getCashRegisterIds(
                            filteredId = selectedItemId,
                            cashRegisters =state.value.listShop
                        ),
                        periodStart = showSelectedDate(selectedDate),
                        periodEnd = getNextDay(selectedDate),
                    )


                    _state.value = _state.value.copy(
                        customerFrequencies = result.data?.cashAssist?.getCustomerFrequencies,
                        dataLoadingProgressVisible = false,
                        showDashboardDetail = true,
                    )
                }

            }catch (e: Exception) {
                _state.value = _state.value.copy(
                    dataLoadingProgressVisible = false,
                )
                handleError(error = e)
            }

        }




    }

    private fun onDashboardItemClick(
        dashboardItem: ShopStaticItem,
        itemName: String
    ) {
        _state.value=_state.value.copy(
            selectedDashboardItem=dashboardItem,
            dashboardItemName=itemName,
            comparedCustomerFrequencies = listOf(),
            comparedDate = state.value.selectedDate,
        )
        getCustomerFrequency(
            selectedItemId=dashboardItem.shopId?:"",
            selectedDate = state.value.selectedDate,
        )
    }

    private fun getDashBoardConfig( ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    _state.value = _state.value.copy(
                        dataLoadingProgressVisible = true
                    )
                    val result = dashboardConfigUseCase()
                        .data?.cashAssist?.listShops?.items?.toMutableList()

                    result?.add(
                        ListShopItem(
                            name = Strings.allTheRegions,
                        )
                    )
                    _state.value=_state.value.copy(
                        listShop = result,
                        dataLoadingProgressVisible = false
                    )


                }

            }catch (e: Exception) {
                _state.value = _state.value.copy(
                    dataLoadingProgressVisible = false,
                )
                handleError(error = e)
            }

        }


    }




    private fun chooseDate(
        selectedDate: MonthModel,
    ) {
        _state.value = _state.value.copy(
            showCalendarDialog = false,
            selectedDate =  selectedDate,
        )
        initShopStatics(
            cashRegisterIds =getCashRegisterIds(
                cashRegisters =state.value.listShop
            ),
            from = getFromDate(selectedDate),
            to= getToDate(selectedDate),
        )


    }


    private fun getShopStatics(requestId:String) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    _state.value = _state.value.copy(
                        dataLoadingProgressVisible = true
                    )
                    val result = shopStaticsUseCase(
                        requestId = requestId
                    ).data?.cashAssist?.getStatisticsData?.data
                    if (result==null){
                        getShopStatics(requestId=requestId)
                    }else{
                        _state.value = _state.value.copy(
                            dataLoadingProgressVisible = false,
                            shopStaticsData = result
                        )
                    }


                }

            }catch (e: Exception) {
                _state.value = _state.value.copy(
                    dataLoadingProgressVisible = false,
                )
                handleError(error = e)
            }

        }


    }

    private fun initShopStatics(
        cashRegisterIds:List<String>,
        from:String,
        to:String,
    ) {
        if (state.value.listShop!=null) {
            launch {
                try {
                    withTokenRefresh(refreshToken) {
                        _state.value = _state.value.copy(
                            dataLoadingProgressVisible = true
                        )
                        val result = initShopStaticsUseCase(
                            cashRegisterIds = cashRegisterIds,
                            from = from,
                            to = to,
                        )
                        _state.value=_state.value.copy(
                            requestId = result.data?.cashAssist?.initShopStatistics?:""
                        )

                        getShopStatics(
                            requestId = state.value.requestId?:""
                        )
                    }

                }catch (e: Exception) {
                    _state.value = _state.value.copy(
                        dataLoadingProgressVisible = false,
                    )
                    handleError(error = e)
                }
        }


    }



    }


    fun getCashRegisterIds(
        filteredId: String?=null,
        cashRegisters: List<ListShopItem?>?
    ): List<String> {
        return cashRegisters
            ?.filter { it != null && (filteredId == null || it.id == filteredId) }
            ?.flatMap { it?.cashRegisters.orEmpty() }
            ?.mapNotNull { it?.id }
            ?: emptyList()
    }


}
