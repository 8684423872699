package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class TimeInterval(
    @SerialName("from")
    val from: String?,
    @SerialName("__typename")
    val typename: String?
)