package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media

import org.hs_soft.runmynesto.domain.config.Strings

enum class DisplaySizeEnum(
    val title:String,
    val value:String,
)
{
    STANDARD(
        title = Strings.standard_1024_600,
        value = ""
    ),
    SELF_CHECKOUT(
        title = Strings.selfCheckout_1080_1920,
        value = ""
    )
}