package org.hs_soft.runmynesto.pages.home.sub_page.product.component

import androidx.compose.runtime.Composable
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.dialog.dialog.AddRecordDialog
import org.hs_soft.runmynesto.pages.components.dialog.dialog.DataAssistantAIDialog
import org.hs_soft.runmynesto.pages.components.dialog.dialog.ImageAIDialog
import org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.restaurant_tab.component.KitchenAddItemDialog
import org.hs_soft.runmynesto.pages.home.sub_page.product.mvi.ProductEvent
import org.hs_soft.runmynesto.pages.home.sub_page.product.mvi.ProductState

@Composable
fun ProductDialogs(
    state: ProductState,
    windowSizeMode: WindowSizeModeEnum,
    event: (ProductEvent) -> Unit
) {
    if (state.showGenerateAIImgDialog) {
        ImageAIDialog(
            windowSizeMode = windowSizeMode,
            isSubmitClickable = state.imageAiGeneratorDescription.isNotEmpty()
                    && state.cashBidImgAIGeneratorUrl!= Res.PathIcon.defaultRectPhoto,
            id = state.selectedProduct?.id ?: "",
            description = state.imageAiGeneratorDescription,
            imgGeneratedUrl = state.cashBidImgAIGeneratorUrl,
            loading = state.isImageAiGeneratorLoading,
            onDescriptionInputChange = {
                event(ProductEvent.OnChangeAiImageDescription(it))
            },
            onBackgroundClick = {},
            onCancelClick = {
                event(ProductEvent.SetGenerateCashBidAIImgDialogStatus(false))
            },
            onSubmitClick = { id, imgAiGeneratorUrl ->
                event(
                    ProductEvent.OnSelectCashBidImg(
                        id = id,
                        url = imgAiGeneratorUrl,
                    )
                )
            },
            onGenerateImgClick = { desc ->
                event(
                    ProductEvent.GenerateProductCashBidImg(
                        description = desc,
                    )
                )
            },
            onSelectImg = {url:String->
                event(
                    ProductEvent.SetLocalCashBidImg(
                        url = url,
                    ))
            }

        )
    }

    if (state.showAddRecordDialog) {
        AddRecordDialog(
            windowSizeMode = windowSizeMode,
            dialogTitle = Strings.addNewProduct,
            nameFieldTitle = Strings.name,
            numberFieldTitle = Strings.number,
            nameValue = state.newRecordName,
            showNumberField = true,
            numberValue = state.newRecordNumber,
            errorMessage = state.addRecordErrorMessage,
            isSubmitClickable = state.isAddNewRecordSubmitClickable,
            loading = state.addRecordLoading,
            onNumberValueChange = {
                event(ProductEvent.OnChangeRecordNumber(it))
            },
            onNameValueChange = {
                event(ProductEvent.OnChangeRecordName(it))
            },
            onCancelClick = {
                event(ProductEvent.OnCancelRecordDialogClick)
            },
            onSubmitClick = {
                event(ProductEvent.OnSubmitAddRecordDialogClick(windowSizeMode))
            },
            onBackgroundClick = {
                event(ProductEvent.OnAddRecordDialogBackgroundClick)
            }
        )
    }

    if (state.showKitchenAddItemDialog){
        KitchenAddItemDialog(
            selectedItem=state.selectedKitchenInfoItem,
            windowSizeMode=windowSizeMode,
            loading = state.kitchenInfoDialogLoading,
            onCancelClick = {
                event(ProductEvent.OnCancelKitchenItemDialog)
            },
            onSubmitClick={name:String,variants:List<String?> ->
                event(ProductEvent.OnSubmitKitchenInfoDialog(
                    id = state.selectedKitchenInfoItem?.id?:"",
                    name=name,
                    variants=variants,
                ))
            },
            activateKitchenItem = {
                event(ProductEvent.UpdateRestaurantData(
                    title = ApiUtils.Field.additions,
                    value = listOf(mapOf(ApiUtils.Field.productAddionId to it))
                ))
            },
            deActivateKitchenItem = {

            }
        )
    }

    if (state.showDataAssistantDialog){
        DataAssistantAIDialog(
            windowSizeMode=windowSizeMode,
            onBackgroundClick = {},
            onCancelDataAssistantButtonClick = {
                event(ProductEvent.SetDataAssistantDialogStatus(false))
            },
            onGenerateAnswerClick = { companyID, question, messages ->
                event(
                    ProductEvent.GenerateDataAssistantAnswer(
                        companyID = companyID,
                        question = question,
                        messages = messages
                    )
                )
            },
            onQuestionInputChange = {
                event(ProductEvent.OnChangeAIDataAssistantQuestion(it))
            },
            loading = state.isDataAssistantAIGeneratorLoading,
            question = state.answerAIGeneratorQuestion,
            messages = state.chatMessages,
            sendIsClickable = state.sendQuestionButtonIsClickable,
            info = state.virtualCardInfo,
            getDataAssistantMessageHistory = {
                event(ProductEvent.GetDataAssistantMessageHistory(it))
            },
        )
    }



}
