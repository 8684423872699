package org.hs_soft.runmynesto.domain.util

import kotlinx.browser.window
import org.jetbrains.compose.web.css.CSSNumericValue
import org.jetbrains.compose.web.css.CSSUnitTime
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.s


object Constants {

    const val pageFadingTime=1000L
    const val stDot="ST."
    const val hr="hr"
    const val limitItemsForPagination=100
    const val SearchRequestDelay=1500L
    const val sunmiTypeLength=13
    const val infiniteScrollRatio=1.5
    const val fetchItemsStep=50
    const val MFA_LENGTH=6
    const val timeout:Long= 60000L
    const val databaseVersion=1
    const val startYear=2024
    const val endYear=2030
    const val closingReportsCalendarStartYear=2020
    const val closingReportsCalendarEndYear=2024
    const val maxReceiveTemplateImgSize=150
    const val delayLoadingCircle=1000L
    const val TemplateDefaultPositionAdded=1000
    const val dataDigitLimit: Int=4
    val editTextTransition: CSSNumericValue<out CSSUnitTime> = 1000.ms
    const val fastExpandingTime: Double=0.3
    const val mediumExpandingTime:Double=0.5
    const val slowExpandingTime: Double=1.0
    const val verySlowExpandingTime:Double=1.5
    const val expandingTimeOut=600
    const val touchTime=200L
    val fastTransition=0.3.s
    val mediumTransition=0.5.s
    const val minPassLen=8

    object ZIndex{
        const val largeZIndex=5f
        const val semiLargeZIndex=4f
        const val mediumZIndex=3f
        const val normalZIndex=2f
        const val smallZIndex=1f
    }
    object RestaurantDesign{
        const val tableHeight=60
        const val tableWidth=60
        const val tableObjectKind="table"
        const val objectType="object"
        const val roundShape="round"
    }

    object Responsive{
        val ClosingReportsTablePhone= (window.innerWidth*0.90)
        val ClosingReportsTableTablet= (window.innerWidth*0.50)
        val ClosingReportsCheckoutTablet=(window.innerWidth*0.40)
        const val CashConfigPrinterLocationHeight=45
        const val gridContentHeight=70
        const val templateGridContentHeight=74
        const val dashboardReportsHeight=83
        const val closingReportsCheckoutHeight=30
        const val templateArticleListHeight=30
        const val dialogPhoneResize=0.5
        const val kitchenInfoVariantWidth=23
        const val KitchenTableHeaderWidth=12//15
        const val kitchenTableWidth=40
        const val windowsAllergensHeightRatio=70
        const val phoneAllergensHeightRatio=67
        const val tabletAllergensHeightRatio=70
        const val checkoutPhoneRatio=0.8
        const val checkoutTabletRatio=0.50
        const val gridPhoneRatio=0.90
        const val allergenPhoneRatio=0.88
        const val allergenTabletRatio=0.60
        const val girdTabletRatio=0.40
        const val articlePhoneRatio=0.85
        const val articleTabletRatio=0.50
        const val SwipeWidth: Double=80.0
        const val MaxPhoneWidth=500
        const val MaxTabletWidth=1050



    }




}
