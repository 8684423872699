package org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media

import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_model.MediaModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.MediaListRepository


class MediaListUseCase(
    private val repository: MediaListRepository
) {
    suspend operator fun invoke(isActive:Boolean?): MediaModel {
         return repository.getMediaList(
             isActive=isActive
         )
    }
}