package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.shop_list


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("listShops")
    val listShops: ListShops?,
    @SerialName("__typename")
    val typename: String?
)