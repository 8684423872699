package org.hs_soft.runmynesto.pages.home.sub_page.dashboard.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.YearModel
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.date_picker.DatePickerDialog

@Composable
fun DashboardDialogs(
    windowSizeMode: WindowSizeModeEnum,
    selectedDate:MonthModel,
    calendarData:YearModel,
    showCalendarDialog:Boolean,
    onSelectDay:(MonthModel)->Unit,
    onBackgroundClick:()->Unit,
    closeCalendarDialog:()->Unit,
)
{

    if (showCalendarDialog) {

        DatePickerDialog(
            selectedDay = selectedDate,
            modifier = Modifier,
            calendarData =calendarData ,
            onSelectDay = {
                onSelectDay(it)
            },
            onBackgroundClick = {
                onBackgroundClick()
            },
            closeCalendarDialog = {
                closeCalendarDialog()
            }
        )
    }


}