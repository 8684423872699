package org.hs_soft.runmynesto.pages.home.sub_page.media_center.mvi

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.media_center.MediaCenterInfoHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_model.MediaItem
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_tab.MediaTag
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.Children
import org.hs_soft.runmynesto.domain.usecase.home.GetSavedCheckOutUseCase
import org.hs_soft.runmynesto.domain.usecase.home.GetSavedShopListUseCase
import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.AddNewMediaUseCase
import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.AddTagUseCase
import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.DeleteTagUseCase
import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.LoadAppConstantsUseCase
import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.MediaDetailUseCase
import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.MediaListUseCase
import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.MediaTagsUseCase
import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.UpdateBase64ImgUseCase
import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.UpdateMediaUseCase
import org.hs_soft.runmynesto.domain.usecase.product.GetImgUrlUseCase
import org.hs_soft.runmynesto.domain.usecase.product.tab_product_general.cache.GetCachedProductCategoriesUseCase
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.IdUtils
import org.hs_soft.runmynesto.domain.util.handleError
import org.hs_soft.runmynesto.domain.util.helper.BaseViewModel
import org.hs_soft.runmynesto.domain.util.monthModelToDateString
import org.hs_soft.runmynesto.domain.util.withTokenRefresh
import org.hs_soft.runmynesto.pages.home.sub_page.user.mvi.UserEvent

class MediaCenterViewModel(
    private val requestManager: RequestManager,
    private val refreshToken:()->Unit,
    private val getSavedCheckOutUseCase: GetSavedCheckOutUseCase,
    private val mediaListUseCase: MediaListUseCase,
    private val mediaDetailUseCase: MediaDetailUseCase,
    private val addNewMediaUseCase: AddNewMediaUseCase,
    private val mediaTagsUseCase: MediaTagsUseCase,
    private val updateMediaUseCase: UpdateMediaUseCase,
    private val imgUrlUseCase: GetImgUrlUseCase,
    private val updateBase64ImgUseCase: UpdateBase64ImgUseCase,
    private val getSavedShopListUseCase: GetSavedShopListUseCase,
    private val loadAppConstantsUseCase: LoadAppConstantsUseCase,
    private val deleteTagUseCase: DeleteTagUseCase,
    private val addTagUseCase: AddTagUseCase,
    private val getCachedProductCategoriesUseCase: GetCachedProductCategoriesUseCase,
): BaseViewModel() {

    private val _state = MutableStateFlow(MediaCenterState())
    val state: StateFlow<MediaCenterState> = _state
    private var tempCheckoutList: List<Children>? = listOf()

    private var tempMediaItems:List<MediaItem> = listOf()

    override  fun clear() {
        super.clear()
        requestManager.cancelAll()
    }

    init {
        onEvent(MediaCenterEvent.GetAllProducts)
    }

    fun onEvent(event: MediaCenterEvent)
    {
        when(event){

            is MediaCenterEvent.UpdateCheckout->{
                updateMedia(
                    id=state.value.selectedMedia?.id?:"",
                    dataTitle = ApiUtils.Field.cashRegisterId,
                    dataValue=event.dataValue.toList(),
                    updateMediaType = ApiUtils.Type.updateMediaCashRegisters
                )

            }

            is MediaCenterEvent.OnSearchCheckoutItems->{
                searchCheckoutItems(value=event.value)
            }

            is MediaCenterEvent.DeleteArticle->{
                deleteArticle(id=event.id)
            }

            is MediaCenterEvent.AddItemToArticleList->{
                addArticle(id=event.id)
            }
            is MediaCenterEvent.SetArticleDialogStatus->{
                _state.value=_state.value.copy(
                    showArticleDialog = event.status,
                    addRecordErrorMessage = ""
                )
            }

            is MediaCenterEvent.GetAllProducts->{
                getAllProducts()
            }
            is MediaCenterEvent.SetDisplaySize->{
                _state.value=_state.value.copy(
                    selectedDisplaySize = event.displaySize
                )
            }

            is MediaCenterEvent.SetAddNewGroupDialogStatus->{
                _state.value=_state.value.copy(
                    showAddGroupDialog = event.status
                )
            }

            is MediaCenterEvent.DeleteTag->{
                deleteTag(
                    id=event.tagId
                )
            }
            is MediaCenterEvent.AddTag->{
                addTag(
                    name =event.name,
                )
            }

            is MediaCenterEvent.LoadAppConstants->{
                loadAppConstants()
            }
            is MediaCenterEvent.GetShopList->{
                getShopList()
            }
            is MediaCenterEvent.UpdateBase64Img->{
                updateBase64Img(
                    file=event.file,
                    type=event.type,
                    id=state.value.selectedMedia?.id?:""
                )
            }

            is MediaCenterEvent.OnSelectFromDate->{
                selectFromDate(
                    selectedDate = event.date
                )
            }
            is MediaCenterEvent.SetFromCalendarStatus->{
                setFromCalendarStatus(
                    status = event.status,
                )
            }
            is MediaCenterEvent.OnSelectToDate->{
                selectToDate(
                    selectedDate = event.date
                )
            }
            is MediaCenterEvent.SetToCalendarStatus->{
                setToCalendarStatus(status=event.status)
            }

            is MediaCenterEvent.UpdateMedia->{
                updateMedia(
                    id = state.value.selectedMedia?.id?:"",
                    dataTitle = event.title,
                    dataValue = event.value,
                    updateMediaType = ApiUtils.Field.updateMediaMain,
                )
            }
            is MediaCenterEvent.GetMediaTags->{
                getMediaTags()
            }
            is MediaCenterEvent.OnChangeRecordName->{
                onChangeRecordName(event.value)
            }
            is MediaCenterEvent.OnCancelRecordDialogClick->{
                dismissAddRecordDialog()
            }
            is MediaCenterEvent.OnSubmitRecordDialogClick->{
                onSubmitRecordDialogClick(
                    windowMode = event.screenMode,
                    name = state.value.newRecordName
                )
            }
            is MediaCenterEvent.OnAddRecordDialogBackgroundClick->{

            }

            is MediaCenterEvent.OnChangePageClick->{
                _state.value=_state.value.copy(
                    firstPageActive = !state.value.firstPageActive
                )
            }
            is MediaCenterEvent.GetCheckoutData->{
                getCheckoutData()
            }
            is MediaCenterEvent.OnTopTabClick->{
                onTopTabClick(tab=event.tab)
            }
            is MediaCenterEvent.GetMediaList->{
                getMediaList(
                    isActive=state.value.mediaItemsIsActiveFilter
                )
            }

            is MediaCenterEvent.OnSearchMediaInputChange->{
                onSearchMediaInputChange(value = event.value)
            }
            is MediaCenterEvent.OnMediaItemClick->{
                onMediaItemClick(
                    id = event.id,
                    switchTheSecondPage =event.switchTheSecondPage
                )
            }
            is MediaCenterEvent.OnAddRecordIconClick->{
                onAddRecordIconClick()
            }
            is MediaCenterEvent.OnMainGridTitleSortClick->{
                onMainGridTitleSortClick()
            }
            is MediaCenterEvent.OnMainGridNumberSortClick->{
                onMainGridNumberSortClick()
            }
            is MediaCenterEvent.RefreshProductsCategoryList->{

            }
            is MediaCenterEvent.SetFilterItemsComponentStatus->{

            }
            is MediaCenterEvent.SubmitFilterItems->{
                getMediaList(
                    isActive =state.value.selectedFilterItemsType.value
                )
            }
            is MediaCenterEvent.SelectFilterType->{
                _state.value=_state.value.copy(
                    selectedFilterItemsType = event.type
                )
            }


        }
    }



    private fun searchCheckoutItems(value: String)
    {
        _state.value= _state.value.copy(cashList =tempCheckoutList )

        if (value == "") {
            _state.value = _state.value.copy(isCheckoutExpandAll = false,)
        } else {
            val searchedList = ArrayList<Children>()
            state.value.cashList?.forEach { model ->
                var matchFound = model.name?.lowercase()?.contains(value.lowercase()) ?: false
                model.children?.forEach { childX ->
                    if (childX.name?.lowercase()?.contains(value.lowercase()) == true) {
                        matchFound = true
                    }
                    childX.children?.forEach { childXX ->
                        if (childXX.name?.lowercase()?.contains(value.lowercase()) == true) {
                            matchFound = true
                        }
                        childXX.children.forEach {childXXX->
                            if (childXXX.name?.lowercase()?.contains(value.lowercase()) == true) {
                                matchFound = true
                            }
                        }
                    }
                }
                if (matchFound) {
                    searchedList.add(model)
                }
            }


            _state.value=_state.value.copy(
                isCheckoutExpandAll = true,
                cashList = searchedList
            )

        }
    }


    private fun addArticle(id: String) {
        val productIds=state.value.selectedMedia?.withProducts?.map { it.id }
            ?.toMutableSet()

        productIds?.add(id)

        updateMedia(
            id=state.value.selectedMedia?.id?:"",
            dataTitle = ApiUtils.Field.productsId,
            dataValue = productIds?.toList(),
            updateMediaType = ApiUtils.Field.updateMediaMain,
        )

        _state.value=_state.value.copy(
            showArticleDialog = false
        )


    }

    private fun deleteArticle(
        id: String,
    ) {
        val productIds=state.value.selectedMedia?.withProducts?.map { it.id }
            ?.toMutableSet()

        productIds?.remove(id)
        console.log(id)
        console.log(productIds?.toList())
        updateMedia(
            id=state.value.selectedMedia?.id?:"",
            dataTitle = ApiUtils.Field.productsId,
            dataValue = productIds?.toList(),
            updateMediaType = ApiUtils.Field.updateMediaMain,
        )

        _state.value=_state.value.copy(
            showArticleDialog = false
        )
    }

    private fun getAllProducts() {
        if (state.value.allProducts==null){
            getCachedProductCategoriesUseCase{
                _state.value=_state.value.copy(
                    allProducts = it?: listOf()
                )
            }
        }




    }

    private fun deleteTag(id: String) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    deleteTagUseCase(id = id).data?.cashAssist?.deleteTag

                    val newTags: MutableList<MediaTag>? = _state.value.mediaTags?.toMutableList()

                    val deletedPosition = newTags?.indexOfFirst { it.id == id }

                    if (deletedPosition != null && deletedPosition >= 0) {
                        newTags.removeAt(deletedPosition)
                    }
                    _state.value = _state.value.copy(
                        mediaTags = newTags
                    )


                    hideProgress()
                }



            }catch (e:Exception){
                hideProgress()
                handleError(error = e)
            }

        }


    }

    private fun addTag(
        name: String
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=addTagUseCase(
                        name = name,
                    ).data?.cashAssist?.createTag

                    if (result!=null){
                        getMediaTags()
                    }else{
                        hideProgress()
                    }
                }



            }catch (e:Exception){
                hideProgress()
                handleError(error = e)
            }

        }


    }

    private fun loadAppConstants() {

        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=loadAppConstantsUseCase(
                        type =IdUtils.animationAppConstantsType ,
                        language ="German"//TODO (Must be fixed)
                    ).data?.cashAssist?.loadAppConstants

                    _state.value=_state.value.copy(
                        animationType=result
                    )


                    hideProgress()
                }



            }catch (e:Exception){
                hideProgress()
                handleError(error = e)
            }

        }


    }

    private fun getShopList() {
        try {
            launch {
                val result=getSavedShopListUseCase()
                    ?.data?.cashAssist?.listShops?.items
                _state.value=_state.value.copy(
                    shopList = result,
                )
            }
        }catch (e:Exception){
            handleError(e)
        }

    }

    private fun getBase64Img(
        keyMedia169:String?=null,
        keyMedia:String?=null,
    ) {
        try {
            launch {
                if (keyMedia!=null){
                    val resultMedia = imgUrlUseCase(keyMedia)
                    _state.value=_state.value.copy(
                        mediaImg =resultMedia
                    )
                }
                if (keyMedia169!=null){
                    val resultMedia169 = imgUrlUseCase(keyMedia169)
                    _state.value=_state.value.copy(
                        mediaImg169 =resultMedia169
                    )
                }




            }

        } catch (e: Exception) {
            handleError(e)
        }
    }

    private fun updateBase64Img(
        file: String,
        type: String,
        id: String
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=updateBase64ImgUseCase(
                        file=file,
                        type = type,
                        id = id,
                    ).data?.cashAssist?.img

                    when(type){
                        ApiUtils.Type.Media->{
                            getBase64Img(keyMedia = result?.key)
                        }
                        ApiUtils.Type.Media9_16->{
                            getBase64Img(keyMedia169 = result?.key)
                        }
                    }

                    hideProgress()
                }



            }catch (e:Exception){
                hideProgress()
                handleError(error = e)
            }

        }

    }

    private fun setToCalendarStatus(status: Boolean) {
        _state.value=_state.value.copy(
            showToCalendar = status
        )
    }

    private fun selectToDate(
        selectedDate:MonthModel
    ) {
        _state.value=_state.value.copy(
            showToCalendar = false,
            selectedToDate = selectedDate,
        )
        val json= buildJsonObject {
            put(ApiUtils.Field.to , monthModelToDateString(state.value.selectedToDate))
        }
        updateMedia(
            id = state.value.selectedMedia?.id?:"",
            dataTitle = Strings.dateInterval ,
            dataValue = json,
            updateMediaType = ApiUtils.Field.updateMediaMain,
        )
    }

    private fun setFromCalendarStatus(status:Boolean) {
        _state.value=_state.value.copy(
            showFromCalendar = status
        )
    }

    private fun selectFromDate(
        selectedDate:MonthModel
    ) {
        _state.value = _state.value.copy(
            showFromCalendar = false,
            selectedFromDate =  selectedDate,
        )
        val json= buildJsonObject {
            put(ApiUtils.Field.from , monthModelToDateString(state.value.selectedFromDate))
        }


        updateMedia(
            id = state.value.selectedMedia?.id?:"",
            dataTitle = Strings.dateInterval ,
            dataValue = json,
            updateMediaType = ApiUtils.Field.updateMediaMain,
        )


    }

    private fun updateMedia(
        id:String,
        dataTitle:String,
        dataValue:dynamic,
        updateMediaType:String,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=updateMediaUseCase(
                        id=id,
                        dataTitle=dataTitle,
                        dataValue=dataValue,
                        updateMediaType=updateMediaType,
                    ).data?.cashAssist?.updateMedia

                    _state.value=_state.value.copy(
                        selectedMedia = result,
                    )
                    hideProgress()
                }



            }catch (e:Exception){
                hideProgress()
                _state.value=_state.value.copy(
                    selectedMedia = state.value.selectedMedia,
                )
                handleError(error = e)
            }

        }


    }

    private fun getMediaTags() {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=mediaTagsUseCase().data?.cashAssist?.listTags
                    _state.value=_state.value.copy(
                        mediaTags=result
                    )
                    hideProgress()
                }



            }catch (e:Exception){
                hideProgress()
                handleError(error = e)
            }

        }

    }

    private fun onSubmitRecordDialogClick(
        name: String,
        windowMode: WindowSizeModeEnum,
    ) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    _state.value=_state.value.copy(addRecordLoading = true)


                    val model=addNewMediaUseCase(name=name)

                    _state.value=_state.value.copy(
                        addRecordLoading = false,
                        showAddRecordDialog = false,
                        addRecordErrorMessage = "",
                        newRecordName = "",
                        firstPageActive = windowMode != WindowSizeModeEnum.PHONE,
                        selectedMedia = model.data?.cashAssist?.createMedia
                    )
                    getMediaList(isActive=state.value.mediaItemsIsActiveFilter)
                }



            }catch (e:Exception){
                _state.value=_state.value.copy(addRecordLoading = false)
                _state.value=_state.value.copy(
                    addRecordErrorMessage = e.message.toString()
                )
                handleError(
                    error = e,
                )
            }

        }

    }

    private fun onAddRecordIconClick() {
        _state.value=_state.value.copy(
            showAddRecordDialog = true
        )
    }

    private fun dismissAddRecordDialog() {
        _state.value=_state.value.copy(
            showAddRecordDialog = false,
            addRecordErrorMessage ="",
            newRecordName = ""
        )
    }
    private fun onChangeRecordName(value: String) {
        _state.value=_state.value.copy(
            newRecordName = value
        )
        checkAddRecordSubmitClickable()
    }

    private fun checkAddRecordSubmitClickable(){
        if (state.value.newRecordName.isNotEmpty()){
            _state.value=_state.value.copy(
                isAddNewRecordSubmitClickable = true
            )
        }else _state.value=_state.value.copy(
            isAddNewRecordSubmitClickable = false
        )
    }

    private fun onMainGridNumberSortClick() {
        val newData:List<MediaItem>
        if (_state.value.isMainGridNumberSortedAsc) {
            newData=_state.value.mediaItems?.sortedByDescending { it.timeInterval?.from }?: listOf()
        }else{
            newData=_state.value.mediaItems?.sortedBy { it.timeInterval?.from }?.toList()?: listOf()
        }
        _state.value=_state.value.copy(
            mediaItems = newData,
            isMainGridNumberSortedAsc =!_state.value.isMainGridNumberSortedAsc,
            showMainGridNumberSortIcon = true,
            showMainGridNameSortIcon = false
        )
    }

    private fun onMainGridTitleSortClick() {
        val newData:List<MediaItem>
        if (_state.value.isMainGridNameSortedAsc) {
            newData=_state.value.mediaItems?.sortedByDescending { it.name?.lowercase() }?: listOf()
        }else{
            newData=_state.value.mediaItems?.sortedBy { it.name?.lowercase() }?.toList()?: listOf()
        }
        _state.value=_state.value.copy(
            mediaItems = newData,
            isMainGridNameSortedAsc =!_state.value.isMainGridNameSortedAsc,
            showMainGridNameSortIcon = true,
            showMainGridNumberSortIcon = false
        )
    }

    private fun onMediaItemClick(
        id: String,
        switchTheSecondPage: () -> Unit={},
    ) {
        if (id!=state.value.selectedMedia?.id){
            launch {
                try {
                    withTokenRefresh(refreshToken) {
                        showProgress()
                        val result=mediaDetailUseCase(id = id)
                            .data?.cashAssist?.getMedia
                        _state.value=_state.value.copy(
                            selectedMedia = result,
                        )
                        getBase64Img(
                            keyMedia = result?.img?.key,
                            keyMedia169 = result?.img169?.key
                        )

                        hideProgress()
                        switchTheSecondPage()
                    }

                }catch (e:Exception){
                    hideProgress()
                    handleError(
                        error = e,
                    )
                }

            }
        }else{
            switchTheSecondPage()
        }

    }

    private fun onSearchMediaInputChange(value: String) {
        _state.value=_state.value.copy(
            mediaItems = tempMediaItems
        )

        val newData=_state.value.mediaItems?.filter {model ->
            (
                    model?.name?.contains(value, ignoreCase = true) == true ||
                            model?.timeInterval?.from?.contains(value, ignoreCase = true) == true
                    )
        }
        _state.value=_state.value.copy(
            mediaItems = newData
        )

    }


    private fun getMediaList(isActive: Boolean?) {
        launch {
            try {
                withTokenRefresh(refreshToken) {
                    showProgress()
                    val result=mediaListUseCase(isActive=isActive)
                        .data?.cashAssist?.listMedia?.items?: listOf()
                    _state.value=_state.value.copy(
                        mediaItems= result
                    )
                    tempMediaItems=result

                    if(
                        !state.value.mediaItems.isNullOrEmpty()
                        && state.value.selectedMedia==null
                    ){
                        onMediaItemClick(
                            id = state.value.mediaItems?.get(0)?.id?:"",
                        )
                    }else{
                        hideProgress()
                    }

                }

            }catch (e:Exception){
                hideProgress()
                handleError(
                    error = e,
                )
            }

        }



    }

    private fun onTopTabClick(tab: MediaCenterInfoHeaderEnum) {
        _state.value=_state.value.copy(
            selectedTab = tab
        )
    }

    private fun getCheckoutData(  ) {
        launch {
            if (state.value.cashList==null){

                val result=getSavedCheckOutUseCase()

                _state.value=_state.value.copy(
                    cashList = result?.data?.cashAssist?.children
                )
                tempCheckoutList=state.value.cashList
            }

        }

    }

    private fun showProgress()
    {
        _state.value=_state.value.copy(
            dataLoadingProgressVisible = true
        )
    }
    private fun hideProgress()
    {
        _state.value=_state.value.copy(
            dataLoadingProgressVisible = false
        )
    }

}