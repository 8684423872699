package org.hs_soft.runmynesto.di.options

import org.hs_soft.runmynesto.domain.usecase.options.cash_flow.GetAppConfigUseCase
import org.hs_soft.runmynesto.domain.usecase.options.cash_flow.UpdateAppConfigUseCase
import org.hs_soft.runmynesto.domain.usecase.options.cash_register_config.GetCashRegisterConfigUseCase
import org.hs_soft.runmynesto.domain.usecase.options.cash_register_config.UpdateCashRegisterConfigUseCase
import org.hs_soft.runmynesto.domain.usecase.options.kitchen_locatoin.UpdateKitchenLocationUseCase
import org.hs_soft.runmynesto.domain.usecase.options.menu_card_import.GetMenuCardUseCase
import org.hs_soft.runmynesto.domain.usecase.options.operation.OptionDataUseCase
import org.hs_soft.runmynesto.domain.usecase.options.operation.UpdateOptionUseCase
import org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager.CreateFloorUseCase
import org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager.CreateFurnitureUseCase
import org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager.DeleteFloorUseCase
import org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager.DeleteRoomUseCase
import org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager.RestaurantProjectsUseCase
import org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager.UpdateFloorUseCase
import org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager.UpdateFurnitureUseCase
import org.hs_soft.runmynesto.domain.usecase.options.sales_group.AddNewSaleGroupUseCase
import org.hs_soft.runmynesto.domain.usecase.options.sales_group.SingleSaleGroupUseCase
import org.hs_soft.runmynesto.domain.usecase.options.sales_group.UpdateSaleGroupUseCase
import org.hs_soft.runmynesto.domain.usecase.options.virtual_card.GetVirtualCardInfoUseCase
import org.hs_soft.runmynesto.domain.usecase.options.virtual_card.UpdateVirtualCardInfoUseCase
import org.koin.dsl.module

val optionsUseCaseModule= module {
    single {
        UpdateKitchenLocationUseCase(get())
    }

    single {
        OptionDataUseCase(get())
    }
    single {
        AddNewSaleGroupUseCase(get())
    }
    single {
        SingleSaleGroupUseCase(get())
    }
    single {
        UpdateSaleGroupUseCase(get())
    }

    single {
        UpdateOptionUseCase(get())
    }

    single {
        UpdateVirtualCardInfoUseCase(get())
    }

    single {
        GetAppConfigUseCase(get())
    }

    single {
        UpdateAppConfigUseCase(get())
    }
    single {
        GetCashRegisterConfigUseCase(get())
    }
    single {
        UpdateCashRegisterConfigUseCase(get())
    }

    single {
        GetVirtualCardInfoUseCase(get())
    }

    single {
        GetMenuCardUseCase(get())
    }
    single {
        RestaurantProjectsUseCase(get())
    }
    single {
        CreateFurnitureUseCase(get())
    }
    single {
        UpdateFurnitureUseCase(get())
    }
    single {
        CreateFloorUseCase(get())
    }
    single {
        UpdateFloorUseCase(get())
    }

    single {
        GetVirtualCardInfoUseCase(get())
    }
    single {
        DeleteFloorUseCase(get())
    }
    single {
        DeleteRoomUseCase(get())
    }

}