package org.hs_soft.runmynesto.pages.home.sub_page.media_center.component.media_center_content

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.media_center.MediaCenterInfoHeaderEnum
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.pages.components.TopTabItems
import org.hs_soft.runmynesto.pages.components.checkout.CheckoutTabContent
import org.hs_soft.runmynesto.pages.home.sub_page.media_center.component.media_center_content.tabs.Media
import org.hs_soft.runmynesto.pages.home.sub_page.media_center.mvi.MediaCenterEvent
import org.hs_soft.runmynesto.pages.home.sub_page.media_center.mvi.MediaCenterState

@Composable
fun MediaCenterContent(
    state: MediaCenterState,
    windowSizeMode: WindowSizeModeEnum,
    event: (MediaCenterEvent) -> Unit,
) {

    Column(
        modifier = Modifier
            .margin(left = Dimen.normalPadding)
            .fillMaxSize()
    )
    {
        SpanText(
            text=state.selectedMedia?.name?:"",
            modifier = Modifier
                .minHeight(Dimen.minimumContentTitleHeight)
                .fontSize(FontSizes.extraLargeFontSize)
                .fontWeight(FontWeight.Normal)
                .lineHeight(FontLineHeight.lineLarge)
                .color(Theme.Secondary.rgb)
                .fontFamily(FontFamily.Montserrat)
        )
        TopTabItems(
            windowSizeMode=windowSizeMode,
            selectedTab=state.selectedTab,
            tabItems = MediaCenterInfoHeaderEnum.entries,
            onTabClick={
                event(MediaCenterEvent.OnTopTabClick(it))
            }
        )

        when(state.selectedTab)
        {
            MediaCenterInfoHeaderEnum.MEDIA->{
                Media(
                    windowSizeMode=windowSizeMode,
                    selectedMedia=state.selectedMedia,
                    mediaTags = state.mediaTags,
                    mediaImg = state.mediaImg,
                    mediaImg169 = state.mediaImg169,
                    shopList = state.shopList,
                    selectedDisplaySize = state.selectedDisplaySize,
                    animationType=state.animationType,
                    onChangeDayCheckStatus = {
                        event(
                            MediaCenterEvent.UpdateMedia(
                            title = ApiUtils.Field.weekDays,
                            value   = it
                        ))
                    },
                    updateMediaInfo = {title: String, value: dynamic ->
                        event(
                            MediaCenterEvent.UpdateMedia(
                                title = title,
                                value = value
                            )
                        )
                    },
                    showToCalendarDialog = {
                        event(
                            MediaCenterEvent.SetToCalendarStatus(true)
                        )
                    },
                    showFromCalendarDialog = {
                        event(
                            MediaCenterEvent.SetFromCalendarStatus(true)
                        )
                    },
                    updateMediaBase64Img = {file:String,type:String->
                        event(
                            MediaCenterEvent.UpdateBase64Img(
                                file=file,
                                type=type
                            )
                        )
                    },
                    showAddGroupDialog = {
                        event(MediaCenterEvent.SetAddNewGroupDialogStatus(true))
                    },
                    setDisplaySize = {
                        event(MediaCenterEvent.SetDisplaySize(it))
                    },
                    showArticleDialog = {
                        event(MediaCenterEvent.SetArticleDialogStatus(true))
                    },
                    deleteArticle = {
                        event(MediaCenterEvent.DeleteArticle(it))
                    }
                )
            }
            MediaCenterInfoHeaderEnum.DEVICES->{

                CheckoutTabContent(
                    cashList =state.cashList,
                    cashRegisterIds = state.selectedMedia?.cashRegisterId?: listOf(),
                    windowSizeMode = windowSizeMode,
                    updateCheckout = {
                        event(MediaCenterEvent.UpdateCheckout(it))
                    },
                    isExpandAll = state.isCheckoutExpandAll,
                    searchValue = state.checkoutSearchValue,
                    onSearchValueChange = {
                        event(MediaCenterEvent.OnSearchCheckoutItems(it))
                    },

                )
            }


        }



    }


}