package org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.update_floor


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Room(
    @SerialName("caption")
    val caption: String?,
    @SerialName("floorId")
    val floorId: String?,
    @SerialName("furniture")
    val furniture: List<Furniture?>?,
    @SerialName("id")
    val id: String?,
    @SerialName("mod_ver")
    val modVer: Int?,
    @SerialName("objectType")
    val objectType: String?,
    @SerialName("projectId")
    val projectId: String?,
    @SerialName("__typename")
    val typename: String?
)