package org.hs_soft.runmynesto.data.api_datasource.options.restaurant_manager

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.addJsonObject
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonArray
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.create_furniture.CreateFurnitureModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.CreateFurnitureRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class CreateFurnitureRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): CreateFurnitureRepository
{
    override suspend fun createFurniture(
        caption: String?,
        componentColor: String?,
        floorId: String,
        height: Int,
        objectKind: String,
        objectType: String,
        positionLeft: Int,
        positionTop: Int,
        projectId: String,
        roomId: String,
        shape: String,
        width: Int
    ): CreateFurnitureModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                mutation createFurniture(${'$'}fields: [CreateFurnitureFields]!) {
                  cashAssist {
                    createFurniture(fields:${'$'}fields) {
                      ...furnitureFragment
                      ${ApiUtils.Field.__typename}
                    }
                      ${ApiUtils.Field.__typename}
                  }
                }
    
                fragment furnitureFragment on Furniture {
                  ${ApiUtils.Field.id}
                  ${ApiUtils.Field.mod_ver}
                  ${ApiUtils.Field.projectId}
                  ${ApiUtils.Field.floorId}
                  ${ApiUtils.Field.roomId}
                  ${ApiUtils.Field.objectType}
                  ${ApiUtils.Field.objectKind}
                  ${ApiUtils.Field.caption}
                  ${ApiUtils.Field.componentColor}
                  ${ApiUtils.Field.positionTop}
                  ${ApiUtils.Field.positionLeft}
                  ${ApiUtils.Field.rotationDeg}
                  ${ApiUtils.Field.height}
                  ${ApiUtils.Field.width}
                  ${ApiUtils.Field.shape}
                  ${ApiUtils.Field.__typename}
                }

            """.trimIndent())

            putJsonObject("variables") {
                putJsonArray("fields") {
                    addJsonObject {
                        put("projectId", projectId)
                        put("floorId", floorId)
                        put("roomId", roomId)
                        put("positionLeft", positionLeft)
                        put("positionTop", positionTop)
                        put("objectType", objectType)
                        put("objectKind", objectKind)
                        put("width", width)
                        put("height", height)
                        if (componentColor!=null)
                             put("componentColor", componentColor)
                        if (caption!=null)
                            put("caption", caption)
                        put("shape", shape)
                    }
                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }
    }


}