package org.hs_soft.runmynesto.modules
// imports
import androidx.compose.runtime.NoLiveLiterals
import org.hs_soft.runmynesto.domain.util.jsObject

@JsModule("@sentry/browser")
@JsNonModule
external val Sentry:dynamic

@NoLiveLiterals
fun configureSentry() {
    // Import Sentry explicitly
    val SentryModule = js("require('@sentry/browser')")

    val sentryConfig = jsObject<Any> {
        dsn = "https://7cb5817e91623c6a9cdae742da1c1438@o4504242163613696.ingest.us.sentry.io/4507887647916032"
        integrations = arrayOf(js("new SentryModule.BrowserTracing()"))
        tracesSampleRate = 1.0
        beforeSend = { event: dynamic, _: dynamic ->
            event.setTag("security_token", "2510bb2469c211ef955242e291135258")
            event
        }
        headers = jsObject<Any> {
            this["X-Sentry-Token"] = "2510bb2469c211ef955242e291135258"
        }
        allowUrls = arrayOf("*")
    }

    SentryModule.init(sentryConfig)
}