package org.hs_soft.runmynesto.data.api_datasource.media_center.tabs.media

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_tab.MediaTagModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.MediaTagsRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class MediaTagsRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): MediaTagsRepository {

    override suspend fun getMediaTags(): MediaTagModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
               query listTags(${'$'}type: TagType!) {
                 cashAssist {
                   listTags(type:${'$'}type) {
                     ${ApiUtils.Field.id}
                     ${ApiUtils.Field.uid}
                     ${ApiUtils.Field.name}
                     ${ApiUtils.Field.__typename}
                   }
                   ${ApiUtils.Field.__typename}
                 }
               }


            """.trimIndent())

            putJsonObject("variables") {
                put("type","MediaTag")
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }

    }

}
