package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.thenIf
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.config.transitionBackground
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position

@Composable
fun TableItemRow(
    id:String,
    isSelected:Boolean,
    foreground: CSSColorValue?=null,
    onItemClick:(String)->Unit,
    content:@Composable ()->Unit,
)
{

    val isHovered= remember { mutableStateOf(false) }
    Row(
        modifier = Modifier
            .position(Position.Relative)
            .thenIf(
                !isSelected,
                Modifier.rippleEffect {
                    onItemClick(id)
                }
            )
            .fillMaxWidth()
            .minHeight(Dimen.mediumTableItemHeight)
            .padding(
                leftRight = Dimen.normalPadding,
                topBottom = Dimen.smallPadding,
            )
            .backgroundColor(
                foreground
                    ?: when {
                        isSelected -> Theme.GrayAlpha12.rgb
                        isHovered.value -> Theme.GrayAlpha08.rgb
                        else -> Theme.Transparent.rgb
                    }

            )
            .transitionBackground()
            .borderBottom(
                width = Dimen.smallThickness,
                color = Theme.GrayCD.rgb,
                style = LineStyle.Solid,
            )
            .onMouseOver { isHovered.value=true }
            .onMouseLeave { isHovered.value=false },
        verticalAlignment = Alignment.CenterVertically
    ){
        content()
    }

}