package org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media

import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.add_tag.AddTagModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.AddTagRepository


class AddTagUseCase(
    private val repository: AddTagRepository
) {
    suspend operator fun invoke(
        name:String,
    ): AddTagModel {
         return repository.addTag(
            name=name ,
         )
    }
}