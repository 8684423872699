package org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.restaurant_project


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RestaurantProject(
    @SerialName("caption")
    val caption: String?,
    @SerialName("floors")
    val floors: List<Floor?>?,
    @SerialName("id")
    val id: String?,
    @SerialName("mod_ver")
    val modVer: Int?,
    @SerialName("objectType")
    val objectType: String?,
    @SerialName("__typename")
    val typename: String?
)