package org.hs_soft.runmynesto.domain.model.home.options.menu_card_import.menu_card_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class MenuProductItemModel(
    var name: String?,
    var description: String?,
    var price: String?,
    var weight: String?,
    var allergens: List<String>?,
    var category: String?,
    @SerialName("turnovergroup")
    var turnoverGroup: String?,
    var template: String?,
    var ingredients: String?,
    var alcoholic: Boolean?,
    var vegan: Boolean?,
    var vegetarian: Boolean?,
    @SerialName("product_number")
    var productNumber: String?,
    // val image: String?,
)
