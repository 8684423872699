package org.hs_soft.runmynesto.pages.home.sub_page.statistic

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.transitionPageBackground
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.StatisticHeaderEnum
import org.hs_soft.runmynesto.pages.components.LoadingProgress
import org.hs_soft.runmynesto.pages.components.TopTabItems
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.StaticDialogs
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.abc_statistic.ABCStatistic
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.bill_search.BillSearch
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.mvi.StatisticEvent
import org.hs_soft.runmynesto.pages.home.sub_page.statistic.mvi.StatisticViewModel

@Composable
fun Statistic(
    windowSizeMode: WindowSizeModeEnum,
    viewModel: StatisticViewModel,
)
{

    val state by viewModel.state.collectAsState()
    LaunchedEffect(Unit){
        viewModel.onEvent(StatisticEvent.GetCashRegisterIds)
    }

    Column(
        modifier = Modifier
            .scrollBehavior(ScrollBehavior.Smooth)
            .fillMaxSize()
    ){

        TopTabItems(
            windowSizeMode=windowSizeMode,
            selectedTab=state.selectedTab,
            tabItems = StatisticHeaderEnum.entries,
            onTabClick={
                viewModel.onEvent(StatisticEvent.OnTabHeaderClick(it))
            }
        )
        when(state.selectedTab){

            StatisticHeaderEnum.ABC_STATISTIC->{
                ABCStatistic(
                    windowSizeMode=windowSizeMode,
                    state=state,
                    event=viewModel::onEvent
                )

            }

            StatisticHeaderEnum.BILL_SEARCH->{

                BillSearch(
                    windowSizeMode=windowSizeMode,
                    state=state,
                    event=viewModel::onEvent
                )


            }

        }

    }

    StaticDialogs(
        state=state,
        event = viewModel::onEvent,
        windowSizeMode = windowSizeMode,
    )

    LoadingProgress(
        visibility = state.loadingProgress
    )

}

