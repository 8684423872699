package org.hs_soft.runmynesto.di.closing_reports

import org.hs_soft.runmynesto.data.api_datasource.closing_report.ClosingDailyReportRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.closing_report.ClosingReportDetailRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.closing_report.ClosingReportsRepositoryImpl
import org.hs_soft.runmynesto.data.api_datasource.closing_report.PrintClosingReportsRepositoryImpl
import org.hs_soft.runmynesto.domain.repository.network_datasource.closing_report.CloseDailyReportRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.closing_report.ClosingReportDetailRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.closing_report.ClosingReportsRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.closing_report.PrintClosingReportsRepository
import org.koin.dsl.module


val closingReportsRepositoryModule= module {

    single<ClosingReportsRepository> {
        ClosingReportsRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<ClosingReportDetailRepository> {
        ClosingReportDetailRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }

    single<PrintClosingReportsRepository> {
        PrintClosingReportsRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }


    single<CloseDailyReportRepository> {
        ClosingDailyReportRepositoryImpl(
            apiClient = get(),
            requestManager = get(),
        )
    }






}