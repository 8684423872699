package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.add_tag


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("createTag")
    val createTag: CreateTag?,
    @SerialName("__typename")
    val typename: String?
)