package org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.update_floor


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UpdateFloorModel(
    @SerialName("data")
    val `data`: Data?
)