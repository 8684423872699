package org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media

import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.update_media_img.UpdateBase64ImgModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.UpdateBase64ImgRepository

class UpdateBase64ImgUseCase(
    private val repository: UpdateBase64ImgRepository
) {
    suspend operator fun invoke(
        id:String,
        file:String,
        type:String,
    ): UpdateBase64ImgModel {
        return repository.updateBase64Img(
            id=id,
            file=file,
            type = type
        )
    }
}