package org.hs_soft.runmynesto.pages.home.sub_page.media_center.component.media_center_content.tabs.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.LetterSpacing
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.config.borderSetting
import org.hs_soft.runmynesto.domain.config.generalDialogSetting
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_tab.MediaTag
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CircleIcon
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.jetbrains.compose.web.css.px

@Composable
fun AddGroupDialog(
    windowSizeMode:WindowSizeModeEnum,
    mediaTags:List<MediaTag>?,
    deleteTag:(String)->Unit,
    addTag:(String)->Unit,
    closeDialog:()->Unit,
)
{
    val newMediaTags = remember {
        mutableStateOf(mediaTags)
    }
    LaunchedEffect(mediaTags){
        newMediaTags.value=mediaTags
    }

    val newTag= remember { mutableStateOf("") }

    Box(
        modifier = Modifier
            .fillMaxSize()
            .onClick {
                closeDialog()
            }
            .backgroundColor(Theme.GrayAlpha12.rgb)
            .zIndex(Constants.ZIndex.normalZIndex)
    )
    {
        Column(
            modifier = Modifier
                .align(Alignment.Center)
                .generalDialogSetting()
                .onClick {
                        event: SyntheticMouseEvent -> event.stopPropagation()
                }
        )
        {
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .padding(
                        topBottom = Dimen.normalPadding,
                        leftRight = Dimen.largePadding
                    ),
                verticalAlignment = Alignment.CenterVertically
            )
            {
                SpanText(
                    text = Strings.editGroup ,
                    modifier = Modifier
                        .fontSize(FontSizes.largeFontSize)
                        .fontWeight(FontWeight.Medium)
                        .color(Theme.Secondary.rgb)
                        .fontFamily(FontFamily.Montserrat)
                        .lineHeight(FontLineHeight.lineLarge)
                        .letterSpacing(LetterSpacing.small)
                )

                Spacer()


                CircleIcon(
                    size = Dimen.semiLargeIconSize,
                    img = Res.PathIcon.largeClose,
                    borderColor = Theme.Transparent.rgb,
                    onIconClick = {
                        closeDialog()
                    },
                    modifier = Modifier
                )
            }


            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(Dimen.smallThickness)
                    .backgroundColor(Theme.GrayAlpha12.rgb)
            )

            Column(
                modifier = Modifier
                    .fillMaxSize()
                    .padding(
                        leftRight = Dimen.largePadding,
                        topBottom = Dimen.mediumPadding,
                    ),
                horizontalAlignment = Alignment.CenterHorizontally,
            )
            {
                CustomEditText(
                    marginTop = 0.px,
                    width = Dimen.largeEditTextWidth,
                    label = Strings.addNewGroup,
                    value =newTag.value,
                    endIconPath = Res.PathIcon.add,
                    onEndIconClick = {
                        addTag(newTag.value)
                    },
                    defaultBorderColor = Theme.GrayCD.rgb,
                    isOutlinedEditText = true,
                    onFocusIn = {},
                    onFocusOut = {},
                    onInputChange = {
                        newTag.value=it
                    },

                )

                Column(
                    modifier = VerticalScrollBehavior.toModifier()
                        .fillMaxWidth()
                        .height(Dimen.AddGroupDialogItemsHeight)
                )
                {
                    newMediaTags.value?.forEach {
                        GroupItem(
                            title = it.name?:"",
                            deleteItem = {
                                 deleteTag(it.id?:"")
                            }
                        )
                    }


                }

                CustomButton(
                    modifier = Modifier
                        .fillMaxWidth(),
                    leftRightMargin = Dimen.mediumPadding,
                    title = Strings.submit,
                    onButtonClick = {
                        closeDialog()
                    },
                    defaultTextColor = Theme.White.rgb,
                )


            }

        }

    }
}

@Composable
private fun GroupItem(
    title:String,
    deleteItem:()->Unit,
)
{
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .margin(topBottom = Dimen.smallPadding)
            .borderSetting()
            .height(Dimen.mediumOutlinedEditTextHeight),
        verticalAlignment = Alignment.CenterVertically
    )
    {
        SpanText(
            text = title,
            modifier = Modifier
                .fontFamily(FontFamily.Montserrat)
                .fontSize(FontSizes.mediumFontSize)
                .color(Theme.Primary.rgb)
        )

        Spacer()

        CircleIcon(
            size = Dimen.normalIconSize,
            img = Res.PathIcon.mediumClose,
            borderColor = Theme.Transparent.rgb,
            onIconClick = {
                deleteItem()
            },
        )

    }




}