package org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager

import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.delete_room.DeleteRestaurantRoomModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.DeleteRoomRepository

class DeleteRoomUseCase(
    private val repository: DeleteRoomRepository,
) {
    suspend operator fun invoke(
        roomId:String
    ): DeleteRestaurantRoomModel {
        return repository.deleteRoom(
            roomId = roomId
        )
    }
}