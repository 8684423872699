package org.hs_soft.runmynesto.pages.home.sub_page.user.component.tabs.general_tab

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.user.single_user.GetUser
import org.hs_soft.runmynesto.domain.util.openLocalForSelectingFile
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.UploadImage
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun UserTabContent(
    windowSizeMode: WindowSizeModeEnum,
    selectedUser: GetUser?,
    imgUrl: String,
    userInfoDetailPasswordVisibility: Boolean,
    onUserInfoDetailPassEyeClick: () -> Unit,
    updateNameValue: (String) -> Unit,
    updatePrintNameValue: (String) -> Unit,
    updateShortNameValue: (String) -> Unit,
    updatePasswordValue: (String) -> Unit,
    updateActivity: (Boolean) -> Unit,
    onSelectImage: (String) -> Unit,
) {


    if (windowSizeMode == WindowSizeModeEnum.WINDOWS) {
        Row(
            modifier = Modifier
                .fillMaxHeight()
                .fillMaxWidth()
                .padding(Dimen.largePadding)
                .borderRadius(Dimen.lowBorderRadius)
                .border(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayCD.rgb
                )
        )
        {
            Column()
            {

                MediumContentTitle(
                    title = Strings.general,
                    fontSize = FontSizes.extraExtraLargeFontSize,
                )

                UserInfoDetail(
                    name = selectedUser?.name ?: "",
                    printName = selectedUser?.printName ?: "",
                    password = selectedUser?.password ?: "",
                    isActive = selectedUser?.isActive?:false,
                    passwordVisible = userInfoDetailPasswordVisibility,
                    shortName = selectedUser?.shortName ?: "",
                    onVisiblePassClick = {
                        onUserInfoDetailPassEyeClick()
                    },
                    updateNameValue = {
                        updateNameValue(it)
                    },
                    updatePrintNameValue = {
                        updatePrintNameValue(it)
                    },
                    updateShortNameValue = {
                        updateShortNameValue(it)
                    },
                    updatePasswordValue = {
                        updatePasswordValue(it)
                    },
                    updateActivityValue = {
                        updateActivity(it)
                    },
                )
            }

            Column(
                modifier = Modifier.margin(left = Dimen.mediumPadding),
                horizontalAlignment = Alignment.CenterHorizontally,
            )
            {
                MediumContentTitle(
                    title = Strings.userPhoto,
                    marginBottom = Dimen.mediumPadding,
                    fontSize = FontSizes.extraExtraLargeFontSize,
                )

                UploadImage(
                    width = Dimen.selectCirclePhotoSize,
                    height = Dimen.selectCirclePhotoSize,
                    imgUrl = imgUrl,
                    setImageDirectlyStatus = true,
                    circleShape = true,
                    onImgContentClick = {
                        openLocalForSelectingFile()
                    },
                    setImageDirectly ={
                        onSelectImage(it)
                    },
                )


            }

        }
    } else {
        Column(
            modifier = Modifier
                .fillMaxHeight()
                .fillMaxWidth()
                .margin(leftRight = Dimen.mediumPadding)
                .padding(Dimen.normalPadding)
                .borderRadius(Dimen.lowBorderRadius)
                .border(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayCD.rgb
                )
        )
        {
            MediumContentTitle(
                title = Strings.general,
                marginTop = Dimen.mediumPadding,
                marginBottom = Dimen.mediumPadding,
            )

            UserInfoDetail(
                name = selectedUser?.name ?: "",
                printName = selectedUser?.printName ?: "",
                shortName = selectedUser?.shortName ?: "",
                password = selectedUser?.password ?: "",
                isActive = selectedUser?.isActive?:false,

                passwordVisible = userInfoDetailPasswordVisibility,
                onVisiblePassClick = {
                    onUserInfoDetailPassEyeClick()
                },
                updateNameValue = {
                    updateNameValue(it)
                },
                updateShortNameValue = {
                    updateShortNameValue(it)
                },
                updatePrintNameValue = {
                    updatePrintNameValue(it)
                },
                updatePasswordValue = {
                    updatePasswordValue(it)
                },
                updateActivityValue = {
                    updateActivity(it)
                },
            )

            Box(modifier = Modifier.height(Dimen.mediumPadding))

            MediumContentTitle(
                title = Strings.userPhoto,
                marginTop = Dimen.mediumPadding,
                marginBottom = Dimen.mediumPadding,
            )

            UploadImage(
                width = Dimen.selectCirclePhotoSize,
                height = Dimen.selectCirclePhotoSize,
                imgUrl = imgUrl,
                setImageDirectlyStatus = true,
                circleShape = true,
                onImgContentClick = {
                    openLocalForSelectingFile()
                },
                setImageDirectly = {

                },
            )


        }


    }
}


