package org.hs_soft.runmynesto.data.api_datasource.home

import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.TimeoutCancellationException
import kotlinx.coroutines.withContext
import kotlinx.serialization.encodeToString
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.shop_list.ShopModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.ShopListRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.IdUtils

class ShopListRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): ShopListRepository {

    override suspend fun getShopList(): ShopModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
              query listShops {
                cashAssist {
                  listShops {
                    items {
                      ${ApiUtils.Field.id}
                      ${ApiUtils.Field.name}
                      ${ApiUtils.Field.codePrefix}
                      ${ApiUtils.Field.__typename}
                    }
                    ${ApiUtils.Field.hasNextPage}
                    ${ApiUtils.Field.__typename}
                  }
                  ${ApiUtils.Field.__typename}
                }
              } 
              
            """.trimIndent())

            putJsonObject("variables") {}
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return result?.let {
            val decodedResult: ShopModel = Json.decodeFromString(it.decodeToString())
            withContext(Dispatchers.Default) {
                val jsonData = Json.encodeToString(decodedResult)
                window.sessionStorage.setItem(IdUtils.shopList, jsonData)
            }
            decodedResult
        } ?: throw Exception(Strings.timeoutErr)


    }

}

