package org.hs_soft.runmynesto.pages.home.sub_page.product.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onTouchEnd
import com.varabyte.kobweb.compose.ui.modifiers.onTouchMove
import com.varabyte.kobweb.compose.ui.modifiers.onTouchStart
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.tabs.ProductInfoHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.product.product_item_model.GetProduct
import org.hs_soft.runmynesto.domain.model.home.product.product_item_model.Properties
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.checked_ingredients_list.CheckedIngredient
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_allergen.ingeredient_list.Ingredients
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.sale_group.SaleItem
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.kitchen_info.ProductAdditions
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.printer_location.PrinterLocation
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.restaurant_data.GetRestaurant
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.Children
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.pages.components.checkout.CheckoutTabContent
import org.hs_soft.runmynesto.pages.components.TopTabItems
import org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.allergens_tab.Allergens
import org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.click_collect_tab.ClickAndCollectTabContent
import org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.product_general_tab.ProductTabContent
import org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.restaurant_tab.RestaurantTabContent
import org.jetbrains.compose.web.css.px
import org.w3c.dom.get

@Composable
fun ProductContent(
    windowSizeMode: WindowSizeModeEnum,
    ingredients: List<Ingredients>?,
    selectedProduct: GetProduct?,
    salesGroup: List<SaleItem>?,
    selectedSaleGroupId: String?,
    properties: Properties?,
    selectedVat: String?,
    selectedVat2: String?,
    checkOutData: List<Children>?,
    kitchenInfo: List<ProductAdditions?>?,
    isCheckoutExpandAll: Boolean,
    checkoutSearchValue: String,
    showNameSortIcon:Boolean,
    showNumberSortIcon:Boolean,
    profileCountryId:String,
    numberAscending:Boolean,
    checkedIngredients:List<CheckedIngredient?>?,
    nameAscending:Boolean,
    tempItemsDescendingSort:Boolean?,
    onNameSortClick:()->Unit,
    onNumberSortClick:()->Unit,
    restaurantData: GetRestaurant?,
    printerLocation: List<PrinterLocation?>?,
    selectedTab: ProductInfoHeaderEnum,
    onTabClick: (ProductInfoHeaderEnum) -> Unit,
    updateReceiptName: (String) -> Unit,
    updateActivation: (Boolean) -> Unit,
    updateCodeValue: (String) -> Unit,
    updateNoDiscountPossible: (Boolean) -> Unit,
    updateEmployeeNetItems: (Boolean) -> Unit,
    updateEmployeeConsumption: (Boolean) -> Unit,
    updateProductCheckout: (MutableSet<String>) -> Unit,
    onCheckoutSearch: (String) -> Unit,
    updateClickAndCollect:(Double?,Double?,Double?)->Unit,
    updatePrintOnlyInCafe : (Boolean) -> Unit,
    updateIsAdditionRequired: (Boolean) -> Unit,
    getRestaurantData: () -> Unit,
    getPrinterLocations: () -> Unit,
    updatePrinterLocation: (List<Boolean?>?) -> Unit,
    getSalesGroupData: () -> Unit,
    getVirtualCardInfo: () -> Unit,
    updateSaleGroup: (String) -> Unit,
    updateVatCode: (String) -> Unit,
    updateVatCode2: (String) -> Unit,
    updatePrice: (List<Double?>) -> Unit,
    updatePriceInCafe: (List<Double?>) -> Unit,
    getIngredients: () -> Unit,
    onSearchInIngredients:(String)->Unit,
    onSwipeLeft:()->Unit,
    showImgAIGeneratorDialog:()->Unit,
    showDataAssistantDialog:()->Unit,
    updateAllergenContent:(Boolean,String)->Unit,
    getCheckedIngredients:()->Unit,
    getKitchenInfo:()->Unit,
    getImgUrl:(MutableState<String>,String)->Unit,
    onKitchenInfoTitleSortClick:()->Unit,
    onSearchKitchenInfo:(String)->Unit,
    onRefreshKitchenInfoClick:()->Unit,
    onAddClick:()->Unit,
    onKitchenInfoItemClick:(ProductAdditions?)->Unit,
    activeKitchenInfo:(String)->Unit,
    deActiveKitchenInfo:(String)->Unit,
)
{
    var startX by remember { mutableStateOf(0.0) }
    var endX by remember { mutableStateOf(0.0) }
    var tracking by remember { mutableStateOf(false) }



    Column(
        modifier = Modifier
            .fillMaxSize()
            .overflow(Overflow.Auto)
            .onTouchStart { event ->
                startX = event.touches[0]!!.clientX.toDouble()
                tracking = true
            }
            .onTouchMove { event ->
                if (tracking) {
                    endX = event.touches[0]!!.clientX.toDouble()
                }
            }
            .onTouchEnd {
                if (tracking && startX + Constants.Responsive.SwipeWidth < endX) {
//                    onSwipeLeft() //TODO(Must be fixed)
                }
                tracking = false
            }
            .margin(
                left =
                if (windowSizeMode==WindowSizeModeEnum.PHONE)
                    0.px
                else Dimen.mediumPadding,
                bottom = Dimen.smallPadding
            ),
        horizontalAlignment = Alignment.Start
    )
    {
        SpanText(
            text=selectedProduct?.name?:"",
            modifier = Modifier
                .minHeight(Dimen.minimumContentTitleHeight)
                .fontSize(FontSizes.extraExtraLargeFontSize)
                .fontWeight(FontWeight.Normal)
                .lineHeight(FontLineHeight.lineLarge)
                .color(Theme.Secondary.rgb)
                .fontFamily(FontFamily.Montserrat)
        )

        TopTabItems(
            windowSizeMode=windowSizeMode,
            selectedTab=selectedTab,
            tabItems = ProductInfoHeaderEnum.entries,
            onTabClick={
                onTabClick(it)
            }
        )

        when(selectedTab)
        {
            ProductInfoHeaderEnum.GENERAL->{
                ProductTabContent(
                    windowSizeMode=windowSizeMode,
                    selectedProduct=selectedProduct,
                    selectedSaleGroupId=selectedSaleGroupId,
                    salesGroup = salesGroup,
                    selectedVat=selectedVat,
                    profileCountryId = profileCountryId,
                    selectedVat2=selectedVat2,
                    updateCodeValue={
                        updateCodeValue(it)
                    },
                    updateActivation={
                        updateActivation(it)
                    },
                    updateReceiptName={
                        updateReceiptName(it)
                    },
                    updateEmployeeConsumption={
                        updateEmployeeConsumption(it)
                    },
                    updateEmployeeNetItems = {
                        updateEmployeeNetItems(it)
                    },
                    updateNoDiscountPossible = {
                        updateNoDiscountPossible(it)
                    },
                    getSalesGroupData={
                        getSalesGroupData()
                    },
                    updateSaleGroup = {
                        updateSaleGroup(it)
                    },
                    updateVatCode = {
                        updateVatCode(it)
                    },
                    updateVatCode2 = {
                        updateVatCode2(it)
                    },
                    updatePrice = {
                        updatePrice(it)
                    },
                    updatePriceInCafe = {
                        updatePriceInCafe(it)
                    },
                    showImgAIGeneratorDialog={
                        showImgAIGeneratorDialog()
                    },
                    getImgUrl = { imageUrl: MutableState<String>, key:String->
                        getImgUrl(
                            imageUrl,
                            key
                        )
                    },
                    showDataAssistantDialog = {
                        showDataAssistantDialog()
                    },
                    getVirtualCardInfo = {
                        getVirtualCardInfo()
                    }
                )
            }
            ProductInfoHeaderEnum.CHECKOUT->{

                CheckoutTabContent(
                    modifier = Modifier,
                    windowSizeMode =windowSizeMode,
                    cashList =checkOutData,
                    cashRegisterIds =selectedProduct?.cashRegisterId?: listOf(),
                    updateCheckout = {
                        updateProductCheckout(it)
                    },
                    isExpandAll = isCheckoutExpandAll,
                    searchValue = checkoutSearchValue,
                    onSearchValueChange = {
                        onCheckoutSearch(it)
                    },

                )
            }
            ProductInfoHeaderEnum.RESTAURANT->{

                RestaurantTabContent(
                    selectedProduct=selectedProduct,
                    windowSizeMode=windowSizeMode,
                    restaurantData =restaurantData,
                    printerLocation=printerLocation,
                    kitchenInfo = kitchenInfo,
                    tempItemsDescendingSort = tempItemsDescendingSort ,
                    updateIsAdditionRequired = {
                        updateIsAdditionRequired(it)
                    },
                    updatePrintOnlyInCafe = {
                        updatePrintOnlyInCafe(it)
                    },
                    getRestaurantData={
                        getRestaurantData()
                    },
                    getPointerLocations = {
                        getPrinterLocations()
                    },
                    updatePrinterLocation = {
                        updatePrinterLocation(it)
                    },
                    getKitchenInfo = {
                        getKitchenInfo()
                    },
                    onKitchenInfoTitleSortClick = {
                        onKitchenInfoTitleSortClick()
                    },
                    onSearchKitchenInfo = {
                        onSearchKitchenInfo(it)
                    },
                    onRefreshKitchenInfoClick = {
                        onRefreshKitchenInfoClick()
                    },
                    onAddClick = {
                        onAddClick()
                    },
                    onKitchenInfoItemClick = {
                        onKitchenInfoItemClick(it)
                    },
                    activeKitchenInfo = {
                        activeKitchenInfo(it)
                    },
                    deActiveKitchenInfo = {
                        deActiveKitchenInfo(it)
                    }
                )
            }

            ProductInfoHeaderEnum.CLICK_AND_CONNECT->{
                ClickAndCollectTabContent(
                    windowSizeMode=windowSizeMode,
                    properties =properties,
                    updateClickAndCollect = {min,max,timeLead->
                        updateClickAndCollect(min,max,timeLead)
                    },


                )
            }

            ProductInfoHeaderEnum.ALLERGENES->{
                Allergens(
                    selectedProduct=selectedProduct,
                    windowSizeMode=windowSizeMode,
                    ingredients=ingredients,
                    checkedIngredients=checkedIngredients,
                    showNameSortIcon = showNameSortIcon,
                    showNumberSortIcon = showNumberSortIcon,
                    nameAscending = nameAscending,
                    numberAscending = numberAscending,
                    onNameSortClick = {
                        onNameSortClick()
                    },
                    onNumberSortClick = {
                        onNumberSortClick()
                    },
                    onSearchValueChange ={
                        onSearchInIngredients(it)
                    },
                    getIngredients = {
                        getIngredients()
                    },
                    updateAllergenData = {content,ingredientId->
                        updateAllergenContent(content,ingredientId)
                    },
                    getCheckedIngredients = {
                        getCheckedIngredients()
                    }

                )
            }

        }


    }







}


