package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_model.ClosingReportItem
import org.hs_soft.runmynesto.domain.util.extractDate
import org.hs_soft.runmynesto.domain.util.formatToTwoDecimals
import org.hs_soft.runmynesto.pages.components.HeaderTitle
import org.hs_soft.runmynesto.pages.components.TableItemRow


@Composable
fun ReportsTableItem(
    model: ClosingReportItem,
    onItemClick: (String) -> Unit,
    isSelected: Boolean,
)
{

    TableItemRow(
        id = model.id?:"",
        foreground =if (model.inactive == true)Theme.Red.rgb else null ,
        isSelected =isSelected,
        onItemClick = {
            onItemClick(it)
        }
    ){
        HeaderTitle(
            title = extractDate(model.dateTime?:""),
            width = Dimen.headerSmallWidth,
            fontWeight = FontWeight.Normal,
            textColor = getTextColor(
                isInActive=model.inactive,
                isSelected=isSelected
            )
        )
        HeaderTitle(
            title = extractDate(model.dateTime?:""),
            width = Dimen.headerSmallWidth,
            fontWeight = FontWeight.Normal,
            textColor = getTextColor(
                isInActive=model.inactive,
                isSelected=isSelected
            )
        )
        HeaderTitle(
            title =model.number.toString() ,
            width = Dimen.headerSmallWidth,
            fontWeight = FontWeight.Normal,
            arrangement = Arrangement.End,
            textColor = getTextColor(
                isInActive=model.inactive,
                isSelected=isSelected
            )
        )
        HeaderTitle(
            title =model.cashRegister?.name?:"",
            width = Dimen.headerExtraLargeWidth,
            fontWeight = FontWeight.Normal,
            textColor = getTextColor(
                isInActive=model.inactive,
                isSelected=isSelected
            )
        )

        Spacer()

        HeaderTitle(
            title = formatToTwoDecimals(model.total) ,
            arrangement = Arrangement.End,
            textColor = getTextColor(
                isInActive=model.inactive,
                isSelected=isSelected
            )
        )
    }



}

private fun getTextColor(
    isInActive:Boolean?,
    isSelected: Boolean
) = if (isInActive==true) {
    if (isSelected) Theme.Primary.rgb else Theme.Gray3E.rgb
} else Theme.Primary.rgb

