package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.virtual_card.virtual_card_config.GetVirtualCardConfig
import org.hs_soft.runmynesto.domain.model.home.options.virtual_card.CustomerCardInfoEnum
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.IdUtils
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.openLocalForSelectingFile
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.UploadImage
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent

@Composable
fun DigitalCustomerCardInfo(
    windowSizeMode: WindowSizeModeEnum,
    info: GetVirtualCardConfig?,
    event: (OptionsEvent) -> Unit,
){
    val chunk=if (windowSizeMode==WindowSizeModeEnum.PHONE) 1 else 2


    Column()
    {

        CheckboxComponent(
            modifier = Modifier.margin(
                topBottom = Dimen.normalPadding
            ),
            checkStatus = info?.isActive?:false,
            title = Strings.activeCustomersCard ,
            onChecked = {
                event(OptionsEvent.UpdateVirtualCardInfo(
                    dataTitle = ApiUtils.Field.isActive,
                    dataValue = true
                ))
            },
            onUnChecked = {
                event(OptionsEvent.UpdateVirtualCardInfo(
                    dataTitle = ApiUtils.Field.isActive,
                    dataValue = false
                ))
            },
        )

        CustomerCardInfoEnum.entries.chunked(chunk).forEachIndexed {rowIndex, rowItems ->
            Row()
            {
                rowItems.forEachIndexed { colIndex, model ->
                    CustomEditText(
                        modifier = Modifier,
                        marginBottom = Dimen.smallPadding,
                        label = model.titleShown,
                        value =  getPropertyValue(
                            info=info,
                            title = model.title
                        ),
                        isRequired = false,
                        isOutlinedEditText = true,
                        onFocusIn = {},
                        onFocusOut = {
                            event(OptionsEvent.UpdateVirtualCardInfo(
                                dataTitle = model.title,
                                dataValue = it
                            ))
                        },
                        onInputChange = {

                        },
                    )
                    Box(
                        modifier = Modifier.width(Dimen.normalPadding)
                    )

                }
            }



        }

        Column()
        {
            MediumContentTitle(
                title = Strings.companyLogo,
                marginBottom = Dimen.mediumPadding,
            )

            Row(
                modifier = Modifier,
                verticalAlignment =Alignment.CenterVertically
            )
            {
                UploadImage(
                    imgUrl = info?.logoImg?:Res.PathIcon.defaultCirclePhoto,
                    setImageDirectlyStatus = true,
                    circleShape = true,
                    imgId = IdUtils.logoImg,
                    width = Dimen.selectCirclePhotoSize,
                    height = Dimen.selectCirclePhotoSize,
                    onImgContentClick = {
                        openLocalForSelectingFile()
                    },
                    setImageDirectly = {
                        event(OptionsEvent.UpdateVirtualCardInfo(
                            dataTitle = ApiUtils.Field.logoImg,
                            dataValue = it
                        ))
                    }
                )

                BoxColor(
                    modifier = Modifier.margin(
                        leftRight = Dimen.mediumPadding,
                    ),
                    color = info?.backgroundColor,
                    title = Strings.backgroundColor,
                    onColorBoxClick = {
                        event(OptionsEvent.OnColorBoxClick(
                            selectedColorField = ApiUtils.Field.backgroundColor
                        ))
                    }
                )
            }

        }

    }


}

private fun getPropertyValue(info: GetVirtualCardConfig?, title: String): String {
    info ?: return ""
    return when (title) {
        ApiUtils.VirtualCardFields.ISSUER_NAME -> info.issuerName ?: ""
        ApiUtils.VirtualCardFields.ISSUER_EMAIL -> info.issuerEmail ?: ""
        ApiUtils.VirtualCardFields.PROGRAM_NAME -> info.programName ?: ""
        ApiUtils.VirtualCardFields.ACCOUNT_ID_LABEL -> info.accountIdLabel ?: ""
        ApiUtils.VirtualCardFields.REWARDS_TIER_LABEL -> info.rewardsTierLabel ?: ""
        ApiUtils.VirtualCardFields.REWARDS_TIER -> info.rewardsTier ?: ""
        ApiUtils.VirtualCardFields.SECONDARY_REWARDS_TIER_LABEL -> info.secondaryRewardsTierLabel ?: ""
        ApiUtils.VirtualCardFields.SECONDARY_REWARDS_TIER -> info.secondaryRewardsTier ?: ""

        ApiUtils.VirtualCardFields.PHONE_LINK->info.linkPhoneUri?.link?:""
        ApiUtils.VirtualCardFields.PHONE_DESCRIPTION->info.linkPhoneUri?.description?:""
        ApiUtils.VirtualCardFields.HOME_PAGE_LINK->info.homepageUri?.link?:""
        ApiUtils.VirtualCardFields.HOME_PAGE_DESCRIPTION->info.homepageUri?.description?:""


        else -> ""
    }
}