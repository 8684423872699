package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onTouchEnd
import com.varabyte.kobweb.compose.ui.modifiers.onTouchMove
import com.varabyte.kobweb.compose.ui.modifiers.onTouchStart
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.CustomerCardHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.options.OptionInfoHeaders
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.pages.components.TabItem
import org.hs_soft.runmynesto.pages.components.TopTabItems
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.CustomerCardTab
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.series_tab.SeriesTab
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.mvi.CustomerCardEvent
import org.hs_soft.runmynesto.pages.home.sub_page.customer_card.mvi.CustomerCardState
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent
import org.jetbrains.compose.web.css.px
import org.w3c.dom.get

@Composable
fun CustomerCardContent(
    state: CustomerCardState,
    event:(CustomerCardEvent)->Unit,
    windowSizeMode: WindowSizeModeEnum
) {
    var startX by remember { mutableStateOf(0.0) }
    var endX by remember { mutableStateOf(0.0) }
    var tracking by remember { mutableStateOf(false) }



    Column(
        modifier = Modifier
            .fillMaxSize()
            .overflow(Overflow.Auto)
            .onTouchStart { event ->
                startX = event.touches[0]!!.clientX.toDouble()
                tracking = true
            }
            .onTouchMove { event ->
                if (tracking) {
                    endX = event.touches[0]!!.clientX.toDouble()
                }
            }
            .onTouchEnd {
                if (tracking && startX + Constants.Responsive.SwipeWidth < endX) {
//                    onSwipeLeft() //TODO(Must be fixed)
                }
                tracking = false
            }
            .margin(
                left =
                if (windowSizeMode==WindowSizeModeEnum.PHONE)
                    0.px
                else Dimen.mediumPadding,
                bottom = Dimen.smallPadding
            ),
        horizontalAlignment = Alignment.Start
    )
    {
        SpanText(
            text=state.selectedLoyaltyCard?.name?:"",
            modifier = Modifier
                .minHeight(Dimen.minimumContentTitleHeight)
                .fontSize(FontSizes.extraLargeFontSize)
                .fontWeight(FontWeight.Normal)
                .lineHeight(FontLineHeight.lineLarge)
                .color(Theme.Secondary.rgb)
                .fontFamily(FontFamily.Montserrat)
        )



        Column(
            modifier = Modifier
                .fillMaxWidth()
        ) {
            Row(modifier = Modifier ,
                verticalAlignment = Alignment.CenterVertically
            )
            {
                CustomerCardHeaderEnum.entries.forEach { tab->
                    TabItem(
                        windowSizeMode=windowSizeMode,
                        title = tab.title,
                        isActive = tab==state.selectedCustomerCardTab,
                        onTabClick = {
                            event(CustomerCardEvent.OnCustomerTabClick(tab))
                        },
                    )

                }
            }

            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(Dimen.smallThickness)
                    .backgroundColor(Theme.GrayAlpha12.rgb)
            )
        }

        when(state.selectedCustomerCardTab)
        {
            CustomerCardHeaderEnum.CUSTOMER_CARD->{

                CustomerCardTab(
                    modifier = VerticalScrollBehavior
                        .toModifier()
                        .weight(1f),
                    windowSizeMode=windowSizeMode,
                    state=state,
                    event=event,
                )

            }

            CustomerCardHeaderEnum.SERIES->{

                SeriesTab(
                    modifier =VerticalScrollBehavior
                        .toModifier()
                        .weight(1f),
                    windowSizeMode=windowSizeMode,
                    state=state,
                    event=event,
                )
            }

        }


    }



}
