package org.hs_soft.runmynesto.pages.home.sub_page.product.mvi

import androidx.compose.runtime.MutableState
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.tabs.ProductInfoHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.chat_model.ChatMessagesModel
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.kitchen_info.ProductAdditions
import org.hs_soft.runmynesto.domain.model.home.template.GridItemsFilterEnum
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent

sealed class ProductEvent{

    data class OnSubmitKitchenInfoDialog(
        val id:String,
        val name:String,
        val variants:List<String?>
    ):ProductEvent()

    data object OnAddKitchenItemIconClick:ProductEvent()
    data object OnCancelKitchenItemDialog:ProductEvent()
    data class OnKitchenInfoItemClick(val info: ProductAdditions?):ProductEvent()

    data object OnRefreshKitchenInfoClick:ProductEvent()
    data class OnSearchKitchenInfo(val value:String):ProductEvent()
    data object GetKitchenInfo:ProductEvent()
    data object RefreshProductsCategoryList:ProductEvent()
    data object GetProductsCategoryList:ProductEvent()
    data object GetCheckedIngredients:ProductEvent()

    data class GetCachedBidImg(
        val imageUrl:MutableState<String>,
        val key:String,
    ):ProductEvent()


    data object GetCompanyProfileId:ProductEvent()

    data class UpdateAllergenContent(
        val content:Boolean,
        val ingredientId:String
    ):ProductEvent()

    data class OnProductItemClick(
        val id:String,
        val switchTheSecondPage:()->Unit={}
    ):ProductEvent()

    data class UpdateProductInfo(
        val title:String,
        val value:dynamic
    ):ProductEvent()

    data class UpdateProductPrice(
        val title: String,
        val value:List<Double?>
    ):ProductEvent()

    data object GetVirtualCardInfo: ProductEvent()


    data class OnSearchProductInputChange(val value:String):ProductEvent()

    data class UpdateCheckout(
        val dataValue:MutableSet<String>
    ): ProductEvent()

    data object GetCheckoutData: ProductEvent()


    data class UpdateProductProperties(
        val title:String,
        val minOrder:Double?,
        val maxOrder:Double?,
        val timeLeadDays:Double?
    ): ProductEvent()

    data object GetProductSalesGroup:ProductEvent()



    data object GetRestaurantData:ProductEvent()

    data object GetPrinterLocationNames:ProductEvent()


    data object GetIngredients:ProductEvent()


    data class UpdateRestaurantData(
        val title:String,
        val value:dynamic
    ): ProductEvent()


    //Search
    data class OnSearchCheckoutItems(val value:String):ProductEvent()

    data class OnSearchIngredientItems(val value:String):ProductEvent()

    data object OnMainGridTitleSortClick:ProductEvent()

    data object OnMainGridNumberSortClick:ProductEvent()



    //Other
    data class OnTabClick(val selectedTab: ProductInfoHeaderEnum):ProductEvent()

    data object OnChangePageClick: ProductEvent()
    data object OnIngredientsNameHeaderClick:ProductEvent()
    data object OnIngredientsNumberHeaderClick:ProductEvent()


    //Image Proccessing

    data class OnChangeAiImageDescription(
        val value:String
    ): ProductEvent()

    data class OnChangeAIDataAssistantQuestion(
        val value:String
    ): ProductEvent()

    data class SetLocalCashBidImg(
        val url:String,
    ):ProductEvent()

    data class OnSelectCashBidImg(
        val url:String,
        val id:String
    ):ProductEvent()

    data class SetGenerateCashBidAIImgDialogStatus(
        val status:Boolean
    ):ProductEvent()

    data class SetDataAssistantDialogStatus(
        val status:Boolean
    ):ProductEvent()

    data class GenerateProductCashBidImg(
        val description:String
    ):ProductEvent()

    data class GenerateDataAssistantAnswer(
        val companyID:String,
        val question:String,
        val messages:ChatMessagesModel
    ):ProductEvent()

    data class GetDataAssistantMessageHistory(
        val companyID: String
    ):ProductEvent()

    data object OnAddRecordIconClick: ProductEvent()
    data object OnAddRecordDialogBackgroundClick: ProductEvent()
    data object OnCancelRecordDialogClick: ProductEvent()
    data class OnSubmitAddRecordDialogClick(val windowMode: WindowSizeModeEnum): ProductEvent()
    data class OnChangeRecordNumber(val value:String): ProductEvent()
    data class OnChangeRecordName(val value:String): ProductEvent()


    //Filter
    data class SetFilterItemsComponentStatus(val status:Boolean): ProductEvent()

    data object SubmitFilterItems: ProductEvent()
    data class SelectFilterType(val type: GridItemsFilterEnum):ProductEvent()


    data object OnKitchenInfoTitleClick:ProductEvent()

}