package org.hs_soft.runmynesto.data.api_datasource.template.tab_general

import com.varabyte.kobweb.browser.http.http
import kotlinx.browser.window
import org.hs_soft.runmynesto.domain.repository.network_datasource.template.tabs.tab_general.GetDishPictureRepository
import org.hs_soft.runmynesto.domain.util.ApiPath
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.parseData

import kotlinx.coroutines.*
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.util.Constants
import kotlin.time.Duration.Companion.seconds

class GetDishPictureRepositoryImpl(
    private val requestManager: RequestManager
) : GetDishPictureRepository {



    override suspend fun getDishPicture(description: String): String {

        return withContext(Dispatchers.Default) {
            retry(times = 3, initialDelay = 2.seconds) {
                withTimeout(Constants.timeout.seconds) {
                    fetchDishPicture(description)
                }
            }
        }
    }

    private suspend fun fetchDishPicture(description: String): String {

        val abortController = requestManager.createController()

        val headers = mapOf(
            "Content-Type" to "application/json",
            "x-api-key" to ApiUtils.API_KEY.RESTAURANTS_API_KEY_VALUE,
        )

        val result = window.http.post(
            resource = ApiUtils.Domain.baseDomain +
                    ApiPath.generateDish +
                    "?${ApiUtils.Field.description}=$description",
            headers = headers,
            abortController = abortController,
        ).decodeToString()

        return result.parseData<String>()
    }


    private suspend fun <T> retry(
        times: Int,
        initialDelay: kotlin.time.Duration,
        factor: Double = 2.0,
        block: suspend () -> T
    ): T {
        var currentDelay = initialDelay
        repeat(times - 1) {
            try {
                return block()
            } catch (e: Exception) {
                // You can add logging here
            }
            delay(currentDelay)
            currentDelay *= factor
        }
        return block() // last attempt
    }
}
