package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.onTouchEnd
import com.varabyte.kobweb.compose.ui.modifiers.onTouchMove
import com.varabyte.kobweb.compose.ui.modifiers.onTouchStart
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.template.TemplateInfoHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.GetCategory
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.ArticleModel
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.Children
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.pages.components.checkout.CheckoutTabContent
import org.hs_soft.runmynesto.pages.components.TopTabItems
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_options.TemplateOptionContent
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_surcharge_in_house.SurchargeInHouse
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.TabTemplateGeneralContent
import org.w3c.dom.get


@Composable
fun TemplateContent(
    modifier: Modifier = Modifier,
    selectedTab: TemplateInfoHeaderEnum,
    checkOutData: List<Children>?,
    selectedCategory: GetCategory?,
    isCheckoutExpandAll: Boolean,
    priceLevelList: List<String>,
    imgUrl: String?,
    selectedDays:Int,
    selectedDate: MonthModel,
    windowSizeMode: WindowSizeModeEnum,
    articleList: List<ArticleModel>,
    showTitleSortIcon: Boolean,
    checkoutSearchValue: String,
    selectedPriceLevelIndex: Int?,
    selectedColor:String,
    openColorPanel:()->Unit,
    onCheckoutSearch: (String) -> Unit,
    updateTemplateCheckout: (MutableSet<String>) -> Unit,
    onRefreshClick: () -> Unit,
    onSearchValueChange: (String) -> Unit,
    onTitleSortClick: () -> Unit,
    onChangeActivity: (String, Boolean) -> Unit,
    onChangeReceiptName: (String, String) -> Unit,
    onTabClick: (TemplateInfoHeaderEnum) -> Unit,
    getPriceLevels: () -> Unit,
    updatePriceLevel: (Int) -> Unit,
    updateSurcharge: (String, List<Int?>) -> Unit,
    setCalendarStatus: (Boolean) -> Unit,
    onChangeDayCheckStatus:(Int)->Unit,
    onAddProductIconClick:()->Unit,
    onSwipeLeft: () -> Unit = {},
    showImgAIGeneratorDialog:()->Unit,
    updateProductListAfterDragAndDrop: (List<dynamic>, List<String>, List<Int>,List<String>) -> Unit,

    ) {
    var startX by remember { mutableStateOf(0.0) }
    var endX by remember { mutableStateOf(0.0) }
    var tracking by remember { mutableStateOf(false) }

    Column(
        modifier=Modifier
            .margin(leftRight = Dimen.mediumPadding)
            .fillMaxSize()
            .onTouchStart { event ->
                startX = event.touches[0]!!.clientX.toDouble()
                tracking = true
            }
            .onTouchMove { event ->
                if (tracking) {
                    endX = event.touches[0]!!.clientX.toDouble()
                }
            }
            .onTouchEnd {
                if (tracking && startX + Constants.Responsive.SwipeWidth < endX) {
//                    onSwipeLeft()//TODO(Must be fixed)
                }
                tracking = false
            }
    )
    {
        SpanText(
            text=selectedCategory?.name?:"",
            modifier = Modifier
                .minHeight(Dimen.minimumContentTitleHeight)
                .fontSize(FontSizes.extraExtraLargeFontSize)
                .margin(left = Dimen.normalPadding)
                .fontWeight(FontWeight.Normal)
                .lineHeight(FontLineHeight.lineLarge)
                .color(Theme.Secondary.rgb)
                .fontFamily(FontFamily.Montserrat)
        )

        TopTabItems(
            windowSizeMode=windowSizeMode,
            selectedTab=selectedTab,
            tabItems = TemplateInfoHeaderEnum.entries,
            onTabClick={
                onTabClick(it)
            }
        )

        when(selectedTab)
        {
            TemplateInfoHeaderEnum.GENERAL->{

                TabTemplateGeneralContent(
                    modifier = modifier,
                    showTitleSortIcon = showTitleSortIcon,
                    titleSorting = true,
                    selectedDays = selectedDays,
                    selectedDate=selectedDate,
                    windowSizeMode = windowSizeMode,
                    articleList = articleList,
                    imgUrl = imgUrl,
                    selectedColor = selectedColor,
                    selectedCategory = selectedCategory,
                    onChangeActivity = {infoId,it->
                        onChangeActivity(infoId,it)
                    },
                    onChangeReceiptName ={infoId,it->
                        onChangeReceiptName(infoId,it)
                    } ,
                    onRefreshClick = {
                        onRefreshClick()
                    },
                    onSearchValueChange = {
                        onSearchValueChange(it)
                    },
                    onTitleSortClick ={
                        onTitleSortClick()
                    } ,
                    setCalendarStatus = {
                        setCalendarStatus(it)
                    },
                    openColorPanel = {
                        openColorPanel()
                    },
                    onChangeDayCheckStatus = {
                        onChangeDayCheckStatus(it)
                    },
                    showImgAIGeneratorDialog = {
                        showImgAIGeneratorDialog()
                    },
                    updateListAfterDragAndDrop = { newList,idList,positionList,productIdList ->
                        updateProductListAfterDragAndDrop(
                            newList,idList,positionList,productIdList
                        )
                    },
                    onAddProductIconClick = {
                        onAddProductIconClick()
                    }
                )
            }

            TemplateInfoHeaderEnum.CHECKOUT->{
                CheckoutTabContent(
                    windowSizeMode =windowSizeMode,
                    cashList =checkOutData,
                    cashRegisterIds = selectedCategory?.cashRegisterId,
                    updateCheckout = {
                        updateTemplateCheckout(it)
                    },
                    isExpandAll = isCheckoutExpandAll,
                    searchValue = checkoutSearchValue,
                    onSearchValueChange = {
                        onCheckoutSearch(it)
                    },

                )
            }

            TemplateInfoHeaderEnum.SURCHARGE_IN_HOUSE->{

                SurchargeInHouse(
                    windowSizeMode=windowSizeMode,
                    surchargeFrom=selectedCategory?.surchargeFrom,
                    surchargeAmount=selectedCategory?.surchargeAmount,
                    surchargePercent=selectedCategory?.surchargePercent,
                    updateSurcharge = {fieldTitle,newList->
                        updateSurcharge(fieldTitle,newList)
                    }
                )

            }

            TemplateInfoHeaderEnum.OPTIONS->{

                TemplateOptionContent(
                    priceLevelList = priceLevelList,
                    selectedIndex = selectedPriceLevelIndex,
                    getPriceLevels = {
                        getPriceLevels()
                    },
                    updatePriceLevel = {
                        updatePriceLevel(it)
                    }
                )



            }




        }
    }



}

