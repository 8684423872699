package org.hs_soft.runmynesto.data.api_datasource.options.restaurant_manager

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.update_floor.UpdateFloorModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.UpdateFloorRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateFloorRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateFloorRepository
{
    override suspend fun updateFloor(
        number: Int,
        floorId: String,
    ): UpdateFloorModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                mutation updateFloor(${'$'}fields: [UpdateFloorFields]!) {
                  cashAssist {
                    updateFloor(fields: ${'$'}fields) {
                      ${ApiUtils.Field.id}
                      ${ApiUtils.Field.projectId}
                      ${ApiUtils.Field.number}
                      ${ApiUtils.Field.objectType}
                      rooms {
                        ${ApiUtils.Field.objectType}
                        ${ApiUtils.Field.caption}
                        ${ApiUtils.Field.id}
                        ${ApiUtils.Field.projectId}
                        ${ApiUtils.Field.floorId}
                        ${ApiUtils.Field.mod_ver}
                        furniture {
                          ...furnitureFragment
                          ${ApiUtils.Field.__typename}
                        }
                        ${ApiUtils.Field.__typename}
                      }
                      ${ApiUtils.Field.__typename}
                    }
                    ${ApiUtils.Field.__typename}
                  }
                }

                fragment furnitureFragment on Furniture {
                  ${ApiUtils.Field.id}
                  ${ApiUtils.Field.mod_ver}
                  ${ApiUtils.Field.projectId}
                  ${ApiUtils.Field.floorId}
                  ${ApiUtils.Field.roomId}
                  ${ApiUtils.Field.objectType}
                  ${ApiUtils.Field.objectKind}
                  ${ApiUtils.Field.caption}
                  ${ApiUtils.Field.componentColor}
                  ${ApiUtils.Field.positionTop}
                  ${ApiUtils.Field.positionLeft}
                  ${ApiUtils.Field.rotationDeg}
                  ${ApiUtils.Field.height}
                  ${ApiUtils.Field.width}
                  ${ApiUtils.Field.shape}
                  ${ApiUtils.Field.__typename}
                }



            """.trimIndent())

            putJsonObject("variables") {
                 putJsonObject("fields"){
                     put("id",floorId)
                     put("number",number)
                 }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }


    }


}