package org.hs_soft.runmynesto.pages.components.dialog.dialog

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.transitionDuration
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import kotlinx.browser.window
import kotlinx.coroutines.delay
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.generalDialogSetting
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.openLocalForSelectingFile
import org.hs_soft.runmynesto.pages.components.CustomTextArea
import org.hs_soft.runmynesto.pages.components.LoadingProgress
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.hs_soft.runmynesto.pages.components.dialog.component.SubmitButtons
import org.hs_soft.runmynesto.pages.components.UploadImage
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.backgroundColor
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.dom.Input
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.HTMLCanvasElement
import org.w3c.dom.HTMLImageElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.url.URL

@Composable
fun ImageAIDialog(
    windowSizeMode: WindowSizeModeEnum,
    imgGeneratedUrl:String,
    description:String,
    id:String,
    isSubmitClickable: Boolean,
    loading:Boolean,
    onBackgroundClick: () -> Unit,
    onDescriptionInputChange: (String) -> Unit,
    onCancelClick: () -> Unit,
    onSubmitClick: (String,String) -> Unit,
    onGenerateImgClick: (String) -> Unit,
    onSelectImg:(String)->Unit,
)
{
    Box(
        modifier = Modifier
            .fillMaxSize()
            .onClick {
                onBackgroundClick()
            }
            .backgroundColor(Theme.GrayAlpha12.rgb)
            .zIndex(Constants.ZIndex.normalZIndex)
    ){

        Column(
            modifier = Modifier
                .onClick {
                    event: SyntheticMouseEvent -> event.stopPropagation()
                }
                .align(Alignment.Center)
                .width(Dimen.imageAIDialogWidth)
                .height(Dimen.imageAIDialogHeight)
                .generalDialogSetting(),
            horizontalAlignment = Alignment.CenterHorizontally
        ){

            Header()


            Column(
                modifier = Modifier
                    .padding(Dimen.mediumPadding)
                    .fillMaxSize(),
                horizontalAlignment = Alignment.CenterHorizontally
            )
            {
                SetImgFromLocal(
                    getImg = {url:String ->
                        onSelectImg(url)
                    }
                )

                UploadImage(
                    modifier = Modifier.margin(top = Dimen.normalPadding),
                    width =Dimen.selectRectPhoneWidth,
                    height = Dimen.selectRectPhoneHeight,
                    imgUrl = imgGeneratedUrl,
                    onImgContentClick = {
                        openLocalForSelectingFile()
                    },
                )


                GenerateImg(
                    description=description,
                    onDescriptionInputChange={
                        onDescriptionInputChange(it)
                    },
                    onGenerateImgClick = {
                        onGenerateImgClick(it)
                    },
                )



                if (loading){
                    LoadingComponent(loading=loading)
                }


                Spacer()

                SubmitButtons(
                    windowSize=windowSizeMode,

                    onCancelClick = {
                        onCancelClick()
                    },
                    onSubmitClick = {
                        onSubmitClick(
                            id,
                            imgGeneratedUrl,
                        )
                    },
                    isSubmitClickable = isSubmitClickable,
                    arrangement = Arrangement.Center,
                )
            }



        }


        if (loading){
            LoadingProgress(
                visibility = loading,
                modifier = Modifier
                    .zIndex(2)
                    .borderRadius(
                        Dimen.lowBorderRadius
                    )
                , backgroundColor = Theme.Transparent.rgb
            )
        }




    }



}

@Composable
private fun LoadingComponent(loading: Boolean) {
    var showFirst by remember { mutableStateOf(true) }
    var showSecond by remember { mutableStateOf(false) }
    var showThird by remember { mutableStateOf(false) }

    LaunchedEffect(Unit) {
        while (loading) {
            showFirst = true
            showSecond = false
            showThird = false
            delay(Constants.delayLoadingCircle)
            showFirst = false
            showSecond = true
            showThird = false
            delay(Constants.delayLoadingCircle)
            showFirst = false
            showSecond = false
            showThird = true
            delay(Constants.delayLoadingCircle)
        }
    }

    Column(
        modifier = Modifier.margin(top = Dimen.normalPadding),
        horizontalAlignment = Alignment.CenterHorizontally
    )
    {
        SpanText(
            text = Strings.imageAiDialogWaitingMsg,
            modifier = Modifier
                .fontSize(FontSizes.mediumFontSize)
                .fontFamily(FontFamily.Montserrat)
                .fontWeight(FontWeight.Medium)
                .color(Theme.DarkBlue.rgb)
                .padding(leftRight = Dimen.smallPadding)
                .onClick {

                }
        )
        Row()
        {
            Box(
                modifier = Modifier
                    .margin(Dimen.extraExtraSmallPadding)
                    .size(Dimen.circleItemProgressSize)
                    .borderRadius(50.percent)
                    .styleModifier {
                        backgroundColor(
                            if (showFirst)
                                Theme.DarkBlue.rgb
                            else Theme.LightBlue.rgb
                        )
                        transitionDuration(Constants.fastTransition)
                    }
            )

            Box(
                modifier = Modifier
                    .margin(Dimen.extraExtraSmallPadding)
                    .size(Dimen.circleItemProgressSize)
                    .borderRadius(50.percent)
                    .styleModifier {
                        backgroundColor(
                            if (showSecond)
                                Theme.DarkBlue.rgb
                            else Theme.LightBlue.rgb
                        )
                        transitionDuration(Constants.fastTransition)
                    }
            )
            Box(
                modifier = Modifier
                    .margin(Dimen.extraExtraSmallPadding)
                    .size(Dimen.circleItemProgressSize)
                    .borderRadius(50.percent)
                    .styleModifier {
                        backgroundColor(
                            if (showThird)
                                Theme.DarkBlue.rgb
                            else Theme.LightBlue.rgb
                        )
                        transitionDuration(Constants.fastTransition)
                    }
            )

        }


    }
}

@Composable
fun SetImgFromLocal(
    id:String="",
    getImg:(String)->Unit,
)
{
    Input(
        type = InputType.File,
        attrs = {
            style {
                property("display", "none")
            }
            id(id)
            onChange { event ->

                val inputElement = event.target as HTMLInputElement
                val file = inputElement.files?.item(0)
                file?.let { originalFile ->
                    // Create an image element to load the file
                    val img = window.document.createElement("img")
                            as HTMLImageElement
                    img.onload = {
                        val canvas = window.document.createElement("canvas")
                                as HTMLCanvasElement
                        val ctx = canvas.getContext("2d") as CanvasRenderingContext2D

                        // Set canvas size to the image size
                        canvas.width = img.width
                        canvas.height = img.height

                        // Draw image on canvas
                        ctx.drawImage(img, 0.0, 0.0)

                        // Function to adjust quality to reduce size to under 150 KB
                        fun compressAndCheckSize(quality: Double): String? {
                            val dataUrl = canvas.toDataURL("image/jpeg", quality)
                            val base64Data = dataUrl.split(",")[1]
                            val byteSize = (base64Data.length * (3 / 4)) -
                                    (if (base64Data.endsWith("==")) 2
                                    else if (base64Data.endsWith("=")) 1 else 0)

                            if (byteSize <= Constants.maxReceiveTemplateImgSize * 1024) {
                                return dataUrl
                            } else if (quality > 0.1) {
                                return compressAndCheckSize(quality - 0.1)
                            }
                            return null
                        }

                        // Start compression with high quality
                        val compressedDataDataUrl = compressAndCheckSize(0.9)

                        if (compressedDataDataUrl != null) {
                            getImg(
                                compressedDataDataUrl,
//                                originalFile.name,
                            )
                        } else {
                            console.error("Unable to compress image to desired size")
                        }
                    }

                    img.src = URL.createObjectURL(originalFile)
                }
            }
        }
    )
}

@Composable
private fun GenerateImg(
    onDescriptionInputChange: (String) -> Unit,
    onGenerateImgClick: (String) -> Unit,
    description: String,
) {
    SpanText(
        text = Strings.generate,
        modifier = Modifier
            .margin(top = Dimen.mediumPadding)
            .width(Dimen.selectRectPhoneWidth)
            .cursor(Cursor.Pointer)
            .textAlign(TextAlign.Start)
            .fontSize(FontSizes.semiLargeFontSize)
            .fontFamily(FontFamily.Montserrat)
            .fontWeight(FontWeight.Normal)
            .color(Theme.Primary.rgb)
    )

    CustomTextArea(
        value = description,
        label = Strings.prompt,
        width = Dimen.selectRectPhoneWidth,
        height = Dimen.mediumTextAreaHeight,
        maxHeight = Dimen.mediumTextAreaHeight,
        onFocusOut ={

        },
        onValueChange = {
            onDescriptionInputChange(it)
        }
    )

    Box(
        modifier = Modifier
            .margin(top = Dimen.normalPadding )
            .fillMaxWidth()
    ) {
        Row(
            modifier = Modifier
                .margin(right = Dimen.mediumPadding,)
                .align(Alignment.CenterEnd)
                .onClick {
                    if (description.isNotEmpty())
                        onGenerateImgClick(description)
                }
                .borderRadius(
                    Dimen.lowBorderRadius
                )
                .height(Dimen.submitButtonHeight)
                .width(Dimen.generateButtonWidth)
                .backgroundColor(
                    if (description.isNotEmpty())
                        Theme.Blue.rgb
                    else Theme.GrayCD.rgb
                ),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Center
        )
        {
            VectorIcon(
                pathData = Res.PathIcon.star,
                width = Dimen.normalIconSize,
                height = Dimen.normalIconSize,
                color = if (description.isNotEmpty()) Theme.White.hex
                else Theme.Gray3E.hex
            )

            SpanText(
                text = Strings.generate,
                modifier = Modifier
                    .margin(left = Dimen.normalPadding)
                    .cursor(Cursor.Pointer)
                    .textAlign(TextAlign.Start)
                    .fontSize(FontSizes.mediumFontSize)
                    .fontFamily(FontFamily.Montserrat)
                    .fontWeight(FontWeight.Medium)
                    .color(
                        if (description.isNotEmpty())
                            Theme.White.rgb else
                            Theme.Primary.rgb
                    )
            )

        }
    }
}

@Composable
private fun UploadImg(
    getImgFromLocal:()->Unit,
) {
    val isFocused= remember { mutableStateOf(false) }
    Box(
        modifier = Modifier
            .height(Dimen.mediumOutlinedEditTextHeight)
            .width(Dimen.selectRectPhoneWidth)
            .onMouseOver { isFocused.value=true }
            .onMouseLeave { isFocused.value=false }
            .onClick {
                getImgFromLocal()
            }
            .margin(
                top = Dimen.mediumPadding,
                bottom = Dimen.mediumPadding,
            )
    )
    {
        SpanText(
            text = Strings.uploadFromPc,
            modifier = Modifier
                .zIndex(2f)
                .margin(left = Dimen.normalPadding)
                .padding(leftRight = Dimen.smallPadding)
                .backgroundColor(Theme.White.rgb)
                .align(Alignment.TopStart)
                .color(Theme.GrayAlpha56.rgb)
                .fontSize(FontSizes.normalFontSize)
        )
        Row(
            modifier = Modifier
                .fillMaxWidth()
                .padding(Dimen.normalPadding)
                .borderRadius(Dimen.lowBorderRadius)
                .border(
                    width = Dimen.smallThickness,
                    color =if (isFocused.value) Theme.Primary.rgb
                        else Theme.GrayCD.rgb,
                    style = LineStyle.Solid
                )
                .margin(top = Dimen.normalPadding)
        )
        {
            SpanText(
                text = "",
                modifier = Modifier
                    .zIndex(2f)
                    .color(Theme.Primary.rgb)
                    .fontSize(FontSizes.mediumFontSize)
            )

            Spacer()

            VectorIcon(
                width = Dimen.mediumIconSize,
                height = Dimen.mediumIconSize,
                pathData = Res.PathIcon.fromLocal
            )
        }


    }
}



@Composable
private fun Header() {
    SpanText(
        text = Strings.newPicture,
        modifier = Modifier
            .margin(top = Dimen.normalPadding)
            .width(Dimen.selectRectPhoneWidth)
            .cursor(Cursor.Pointer)
            .textAlign(TextAlign.Start)
            .fontSize(FontSizes.mediumFontSize)
            .fontFamily(FontFamily.Montserrat)
            .fontWeight(FontWeight.Medium)
            .color(Theme.Primary.rgb)
    )

    Box(
        modifier = Modifier
            .fillMaxWidth()
            .margin(top=Dimen.normalPadding)
            .height(Dimen.smallThickness)
            .backgroundColor(Theme.GrayCD.rgb)
    )
}