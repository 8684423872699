package org.hs_soft.runmynesto.pages.home.sub_page.user.component.tabs.general_tab

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.jetbrains.compose.web.attributes.InputType

@Composable
fun UserInfoDetail(
    name: String,
    printName: String,
    shortName: String,
    password: String,
    isActive: Boolean,
    passwordVisible: Boolean,
    onVisiblePassClick: () -> Unit,
    updateNameValue: (String) -> Unit,
    updatePrintNameValue: (String) -> Unit,
    updatePasswordValue: (String) -> Unit,
    updateActivityValue: (Boolean) -> Unit,
    updateShortNameValue:(String)->Unit,

)
{
    Column()
    {

        CustomEditText(
            modifier = Modifier,
            label = Strings.name,
            value = name,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint ="",
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {
                 updateNameValue(it)
            },
            onInputChange = {

            },
        )


        CustomEditText(
            modifier = Modifier.margin(right = Dimen.extraLargePadding),
            label = Strings.printName,
            value = printName,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint ="",
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {updatePrintNameValue(it)},
            onInputChange = {},
        )

        CustomEditText(
            modifier = Modifier.margin(right = Dimen.extraLargePadding),
            label = Strings.shortName,
            value = shortName,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint ="",
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {updateShortNameValue(it)},
            onInputChange = {},
        )

        CustomEditText(
            modifier = Modifier,
            label = Strings.password,
            value = password,
            endIconPath =if(passwordVisible) Res.PathIcon.hideEye else Res.PathIcon.showEye,
            isRequired = false,
            inputType = if(passwordVisible) InputType.Text else InputType.Password,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint ="",
            isOutlinedEditText = true,
            onFocusIn = {},
            onFocusOut = {updatePasswordValue(it)},
            onEndIconClick = {
              onVisiblePassClick()
            },
            onInputChange = {},
        )


        CheckboxComponent(
            modifier = Modifier
                .margin(
                    top= Dimen.mediumPadding,
                ),
            checkStatus = isActive,
            title = Strings.activate,
            onChecked = {
                updateActivityValue(true)
            },
            onUnChecked = {
                updateActivityValue(false)
            },
            accentColor = Theme.Blue.rgb
        )



    }



}

