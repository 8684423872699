package org.hs_soft.runmynesto.di

import org.hs_soft.runmynesto.data.api_datasource.ApiClientImpl
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.util.IdUtils
import org.hs_soft.runmynesto.domain.util.TokenProvider
import org.koin.core.qualifier.named
import org.koin.dsl.module

val networkModule = module {

    single { TokenProvider() }
    single { RequestManager() }


    single<ApiClient> {
        ApiClientImpl(get())
    }

    single(qualifier = named(IdUtils.authToken)) {
        get<TokenProvider>().token
    }


//    single {
//        ApolloClient
//            .Builder()
//            .serverUrl(ApiUtils.Cognito.API_URL)
//            .build()
//    }

}
