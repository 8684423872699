package org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.update_floor


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class UpdateFloor(
    @SerialName("id")
    val id: String?,
    @SerialName("number")
    val number: Int?,
    @SerialName("objectType")
    val objectType: String?,
    @SerialName("projectId")
    val projectId: String?,
    @SerialName("rooms")
    val rooms: List<Room?>?,
    @SerialName("__typename")
    val typename: String?
)