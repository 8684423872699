package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.dialogs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.generalDialogSetting
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CircleIcon
import org.hs_soft.runmynesto.pages.components.CustomTextArea
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

@Composable
fun ClosingDailyReportDialog(
    modifier: Modifier = Modifier,
    submitClosingDailyReport:(String)->Unit,
    onCloseClick:()->Unit,
)
{
    val report= remember { mutableStateOf("") }

    Box(
        modifier = Modifier
            .fillMaxSize()
            .onClick {
                onCloseClick()
            }
            .backgroundColor(Theme.GrayAlpha12.rgb)
            .zIndex(Constants.ZIndex.normalZIndex)
    ){
        Column(
            modifier = modifier
                .align(Alignment.Center)
                .margin(top = Dimen.normalPadding)
                .width(Dimen.closingDailyReportDialogWidth)
                .height(Dimen.daysFilterDialogHeight)
                .generalDialogSetting()
                .zIndex(Constants.ZIndex.mediumZIndex)
                .padding(Dimen.normalPadding)
                .border(
                    width= Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayCD.rgb
                ) ,
            horizontalAlignment = Alignment.CenterHorizontally

        ) {
            Row(
                modifier = Modifier
                    .margin(bottom = Dimen.mediumPadding)
                    .fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically
            )
            {
                SpanText(
                    text = Strings.disableCompletion,
                    modifier = Modifier
                        .fontSize(FontSizes.extraLargeFontSize)
                        .color(Theme.Secondary.rgb)
                        .fontFamily(FontFamily.Montserrat)
                        .fontWeight(FontWeight.Medium)
                )

                Spacer()

                CircleIcon(
                    size = Dimen.semiLargeIconSize,
                    img = Res.PathIcon.largeClose,
                    borderColor = Theme.Transparent.rgb,
                    onIconClick = {
                        onCloseClick()
                    },
                    modifier = Modifier
                )



            }




            CustomTextArea(
                fillMaxWidth = true,
                value = report.value,
                label = Strings.reason,
                maxHeight =(2*Dimen.normalTextAreaHeight.value.toInt()).px,
                height = Dimen.normalTextAreaHeight,
                onFocusOut = {},
                onValueChange = {
                    report.value=it
                }
            )

            Spacer()

            CustomButton(
                modifier = Modifier.fillMaxWidth(),
                leftRightMargin = Dimen.mediumPadding,
                title = Strings.apply,
                onButtonClick = {
                   submitClosingDailyReport(report.value)
                },
                defaultTextColor = Theme.White.rgb,
            )



        }
    }






}