package org.hs_soft.runmynesto.data.api_datasource.media_center.tabs.media

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.delete_tag.DeleteTagModel
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_detail.MediaDetailModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.DeleteTagRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.MediaDetailRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class DeleteTagRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): DeleteTagRepository {

    override suspend fun deleteTag(
        id: String,
    ): DeleteTagModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                 mutation deleteTag(${'$'}id: ID!, ${'$'}type: TagType!) {
                   cashAssist {
                     deleteTag(id: ${'$'}id, type: ${'$'}type) {
                       ${ApiUtils.Field.id}
                       ${ApiUtils.Field.uid}
                       ${ApiUtils.Field.name}
                       ${ApiUtils.Field.type}
                       ${ApiUtils.Field.__typename}
                     }
                     ${ApiUtils.Field.__typename}
                   }
                 }

            """.trimIndent())

            putJsonObject("variables") {
                put("id",id)
                put("type","MediaTag")
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }

    }

}
