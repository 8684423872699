package org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media

import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_detail.MediaDetailModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.MediaDetailRepository

class MediaDetailUseCase(
    private val repository: MediaDetailRepository
) {
    suspend operator fun invoke(id:String): MediaDetailModel {
        return repository.getMediaDetail(
            id=id
        )
    }
}