package org.hs_soft.runmynesto.pages.components.menu

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Resize
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.resize
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.styleModifier
import org.hs_soft.runmynesto.domain.model.menu.MenuModel
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.menu.MenuItemsEnum
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.animatedWidth
import org.jetbrains.compose.web.css.px

@Composable
fun Menu(
    data: MenuModel,
    isMenuExpandedHorizontally:Boolean,
    closeMenu:()->Unit,
    changeMenuStatus:()->Unit,
    windowSizeMode: WindowSizeModeEnum,
    selectedItem: MenuItemsEnum,
    onMenuItemClick:(MenuItemsEnum)->Unit,
    content: @Composable () -> Unit,
)
{
    var isFirstRender by remember { mutableStateOf(true) }

    LaunchedEffect(isMenuExpandedHorizontally) {
        if (isMenuExpandedHorizontally)
            isFirstRender = false
    }


    Box(modifier = Modifier
        .resize(Resize.Block)
        .fillMaxSize()
    )
    {
        Box(modifier = Modifier
            .fillMaxSize()
            .padding(
                left =if (windowSizeMode!= WindowSizeModeEnum.WINDOWS)
                    0.px else Dimen.extraExtraLargeIconSize,
            )
        ) {
            Box(
                modifier = Modifier
                    .resize(Resize.Block)
                    .fillMaxSize()
                    .onClick {
                        closeMenu()
                    }
            )
            {
                content()
            }


            if (windowSizeMode!= WindowSizeModeEnum.WINDOWS){
                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .margin(
                            top = (Dimen.extraExtraLargeIconSize.value.toInt()).px,
                        )
                        .height(Dimen.smallThickness)
                        .backgroundColor(Theme.GrayCD.rgb)
                )
            }


        }


        Column(
            modifier = Modifier
                .zIndex(Constants.ZIndex.largeZIndex)
                .boxShadow(
                    color = Theme.GrayAlpha12.rgb,
                    blurRadius = Dimen.normalBlurRadius,
                    spreadRadius = Dimen.normalSpreadRadius
                )
                .animatedWidth(
                    widthValue = if (isMenuExpandedHorizontally){
                        if (windowSizeMode== WindowSizeModeEnum.WINDOWS)
                            Dimen.menuItemRowWidth
                        else (Dimen.menuItemRowWidth.value.toInt()*.7).px
                    }

                    else if(getNotExpandedWindowsMode(
                            windowSizeMode=windowSizeMode,
                            isMenuExpanded=isMenuExpandedHorizontally
                        )) 0.px else Dimen.menuDefaultWidth,
                    duration =
                        if(isFirstRender && !isMenuExpandedHorizontally) 0.0 else
                        Constants.fastExpandingTime,
                )
                .backgroundColor(Theme.White.rgb)
                .fillMaxHeight()
            , horizontalAlignment =
                if (isMenuExpandedHorizontally) Alignment.CenterHorizontally
                    else Alignment.Start
        ) {

            MenuTopBar(
                windowSizeMode=windowSizeMode,
                applicationIcon =data.menuHeaderData.appIcon,
                titlePart1 = data.menuHeaderData.title1,
                titlePart2 = data.menuHeaderData.title2 ,
                isExpanded = isMenuExpandedHorizontally,
                backgroundColor = data.headerBackground,
                onMenuClick = {
                    changeMenuStatus()
                },
            )


            if(!getNotExpandedWindowsMode(
                    windowSizeMode=windowSizeMode,
                    isMenuExpanded=isMenuExpandedHorizontally
                ))
            {
                Box(
                    modifier = Modifier
                        .fillMaxWidth()
                        .weight(1)
                        .height(Dimen.menuItemsHeight)
                        .styleModifier {
                            property("overflow-y", "auto")
                            property("::-webkit-scrollbar", "width: 0px;")
                            property("scrollbar-width", "none")
                        }
                        .backgroundColor(data.menuBackground)
                ){
                    Column (
                        modifier = Modifier
                            .fillMaxWidth()
                    )
                    {
                        data.menuItemsData.forEach { model ->

                            MenuItemRow(
                                itemTitle = model.title,
                                imgSrc = model.img,
                                isMenuExpandedHorizontally=isMenuExpandedHorizontally,
                                subData = model.subData,
                                hoverColor = data.hoverColor,
                                backgroundColor = data.clickedItemBackground,
                                isSelected =model.id==selectedItem,
                                onRowClick = {
                                    onMenuItemClick(model.id)
                                }

                            )
                        }
                    }
                }

            }


//            Footer(
//                data=data,
//                isExpanded = isMenuExpanded,
//                backgroundColor = data.footerBackground,
//                hoverColor = data.hoverColor,
//                clicked = clickedItemId==data.footerData.id,
//                onSettingRowClick = {
//                    clickedItemId=it
//                    onSettingClick()
//                },
//                onExitRowClick = {
//                    onExitClick()
//                }
//
//            )

        }



    }



}

private fun getNotExpandedWindowsMode(
    windowSizeMode: WindowSizeModeEnum,
    isMenuExpanded:Boolean):Boolean
{
    return windowSizeMode!= WindowSizeModeEnum.WINDOWS && !isMenuExpanded
}

