package org.hs_soft.runmynesto.pages.home.sub_page.customer_card.components.tabs.customer_card_tab.component.dialogs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.TransactionColumnsEnum
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.CircleIcon
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton

@Composable
fun SelectTransactionColumnsDialog(
    windowSizeMode: WindowSizeModeEnum,
    onBackgroundClick: () -> Unit,
    onCloseDialog: () -> Unit,
    toggleTransactionColumn: (MutableSet<TransactionColumnsEnum>) -> Unit,
    initialTransactionColumns: Set<TransactionColumnsEnum>,

)
{

    val tempSelectedColumns = remember {
        mutableStateOf(initialTransactionColumns.toMutableSet())
    }


    Box(
        modifier = Modifier
            .fillMaxSize()
            .onClick {
                onBackgroundClick()
            }
            .backgroundColor(Theme.GrayAlpha12.rgb)
            .zIndex(Constants.ZIndex.normalZIndex)
    ){

        Column(
            modifier = Modifier
                .onClick {
                    event: SyntheticMouseEvent -> event.stopPropagation()
                }
                .align(Alignment.Center)
                .width(
                    if (windowSizeMode==WindowSizeModeEnum.PHONE)
                        Dimen.selectTransactionDialogPhoneWidth
                    else Dimen.selectTransactionDialogWidth
                )
                .height(
                    if (windowSizeMode==WindowSizeModeEnum.PHONE)
                        Dimen.selectTransactionDialogPhoneHeight
                    else Dimen.selectTransactionDialogHeight
                )
                .backgroundColor(Theme.White.rgb)
                .zIndex(Constants.ZIndex.smallZIndex)
                .boxShadow(
                    color = Theme.GrayCD.rgb,
                    blurRadius = Dimen.smallBlurRadius,
                )
                .borderRadius(r= Dimen.lowBorderRadius),
            horizontalAlignment = Alignment.CenterHorizontally
        ){
            TransactionDialogHeader(
                onCloseDialog = {
                    onCloseDialog()
                }
            )

            Column(
                modifier = Modifier
                    .margin(top = Dimen.normalPadding)
                    .padding(leftRight = Dimen.normalPadding)
                    .fillMaxWidth(),
                horizontalAlignment = Alignment.Start,

            ) {
                TransactionColumnsEnum.entries
                    .chunked(
                        if (windowSizeMode==WindowSizeModeEnum.PHONE) 2
                        else 3
                    ).forEachIndexed {rowIndex,rowItems->
                        Row(
                            modifier = Modifier
                                .margin(bottom = Dimen.largePadding),
                            horizontalArrangement = Arrangement.Start
                        )
                        {
                            rowItems.forEachIndexed{index,model->
                                CheckboxComponent(
                                    modifier = Modifier
                                        .width(
                                            if (windowSizeMode==WindowSizeModeEnum.PHONE)
                                                Dimen.transactionColumnItemPhoneWidth
                                            else Dimen.transactionColumnItemWidth
                                        ),
                                    fontSize = if (windowSizeMode==WindowSizeModeEnum.PHONE)
                                        FontSizes.semiMediumFontSize else FontSizes.mediumFontSize,
                                    checkStatus = tempSelectedColumns.value.contains(model),
                                    title = model.title,
                                    onChecked = {
                                        tempSelectedColumns.value.add(model)
                                    },
                                    onUnChecked = {
                                        tempSelectedColumns.value.remove(model)
                                    }
                                )
                            }

                        }

                    }
            }


            Spacer()

            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .margin(Dimen.mediumPadding)
            )
            {
                CustomButton(
                    modifier = Modifier
                        .align(Alignment.CenterEnd),
                    windowSizeMode = windowSizeMode,
                    title = Strings.submit,
                    backgroundColor = Theme.Blue.rgb,
                    defaultTextColor = Theme.Secondary.rgb,
                    onButtonClick = {
//                        tempSelectedColumns.value.forEach { column ->
//                            toggleTransactionColumn(column)
//                        }
//
                        toggleTransactionColumn(tempSelectedColumns.value)
                    }

                )

            }




        }

    }


}

@Composable
private fun TransactionDialogHeader(
    onCloseDialog:()->Unit,
) {

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .margin(top = Dimen.normalPadding)
    ) {
        Row(
            modifier = Modifier
                .padding(leftRight = Dimen.normalPadding)
                .fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        )
        {
            SpanText(
                text = Strings.selectDesiredColumns,
                modifier = Modifier
                    .fontWeight(FontWeight.Medium)
                    .fontSize(FontSizes.mediumFontSize)
                    .color(Theme.Secondary.rgb)
                    .fontFamily(FontFamily.Montserrat)
            )

            Spacer()

            CircleIcon(
                size = Dimen.normalIconSize,
                img = Res.PathIcon.mediumClose,
                borderColor = Theme.Transparent.rgb,
                onIconClick = {
                    onCloseDialog()
                },
                modifier = Modifier
            )

        }


        Box(
            modifier = Modifier
                .margin(topBottom = Dimen.normalPadding)
                .fillMaxWidth()
                .backgroundColor(Theme.GrayCD.rgb)
                .height(Dimen.smallThickness)
        )


    }

}