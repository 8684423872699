package org.hs_soft.runmynesto.pages.home.sub_page.template.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onTouchEnd
import com.varabyte.kobweb.compose.ui.modifiers.onTouchMove
import com.varabyte.kobweb.compose.ui.modifiers.onTouchStart
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.template.GridItemsFilterEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail.GetCategory
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.getGridWidth
import org.hs_soft.runmynesto.pages.components.grid_function.FilterGridContent
import org.hs_soft.runmynesto.pages.components.grid_function.GridFunction
import org.hs_soft.runmynesto.pages.components.grid_function.GridItemsList
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.FilterRow
import org.w3c.dom.get


@Composable
fun GridTemplateBar(
    infoList: List<dynamic>,
    selectedItem: GetCategory?,
    titleDescending: Boolean,
    selectedFilterOption: GridItemsFilterEnum,
    showFilterComponent:Boolean,
    numberDescending: Boolean = false,
    showNumberSortIcon: Boolean = false,
    showTitleSortIcon: Boolean,
    windowSizeMode: WindowSizeModeEnum,
    onAddRecord: () -> Unit,
    onNumberSortClick: () -> Unit = {},
    onTitleSortClick: () -> Unit,
    onRefreshUserList: () -> Unit,
    updateListAfterDragAndDrop: (List<dynamic>, List<String>, List<Int>) -> Unit,
    onCategoryClick: (String) -> Unit,
    selectFilterType:(GridItemsFilterEnum)->Unit,
    onCloseFilterClick:()->Unit,
    submitFilterItems:()->Unit,
    onFilterComponentClick:()->Unit,
    onSwipeRight:()->Unit,

) {
    var startX by remember { mutableStateOf(0.0) }
    var endX by remember { mutableStateOf(0.0) }
    var tracking by remember { mutableStateOf(false) }

    Column(
        modifier = Modifier
            .fillMaxHeight()
            .then(
                if (windowSizeMode== WindowSizeModeEnum.PHONE){
                    Modifier
                        .onTouchStart { event ->
                            startX = event.touches[0]!!.clientX.toDouble()
                            endX = event.touches[0]!!.clientX.toDouble()
                            tracking = true
                            console.log("start")
                        }
                        .onTouchMove { event ->
                            if (tracking) {
                                endX = event.touches[0]!!.clientX.toDouble()
                                console.log("move")
                            }

                        }
                        .onTouchEnd {event->
                            if (tracking && endX +
                                Constants.Responsive.SwipeWidth < startX) {
//                                onSwipeRight()
                            }
                            tracking = false
                            startX=0.0
                            endX=0.0
                        }
                }else Modifier
            )

    )
    {


        FilterRow(
            width = getGridWidth(windowSizeMode),
            onAddRecordClick = {
                onAddRecord()
            },
            onFilterClick = {
                onFilterComponentClick()
            }
        )

        Box(
            modifier = Modifier
                .weight(1f)
                .margin(
                    bottom = Dimen.normalPadding,
                    top = Dimen.smallPadding,
                )
        )
        {
            if (showFilterComponent){

                FilterGridContent(
                    selectFilterType = {
                        selectFilterType(it)
                    },
                    selectedFilterType=selectedFilterOption,
                    onCloseFilterClick ={
                        onCloseFilterClick()
                    },
                    submitFilterItems = {
                        submitFilterItems()
                    }

                )
            }

            GridFunction(
                modifier = Modifier
                    .margin(top = Dimen.normalPadding),
                title = Strings.name,
                width = getGridWidth(windowSizeMode),
                contentHeight = Constants.Responsive.templateGridContentHeight,
                infoTitle ="",
                footerTitle = Strings.template,
                infoList = infoList,
                onTitleSortClick = {
                    onTitleSortClick()
                },
                onNumberSortClick = {
                    onNumberSortClick()
                },
                refreshList = { onRefreshUserList()},
                content = {
                    GridItemsList(
                        items = infoList,
                        id=selectedItem?.id?:"",
                        onItemClick = {
                            onCategoryClick(it)
                        },
                        onReorder = {newList,idList,positionList,itemIdList->
                            updateListAfterDragAndDrop(
                                newList,
                                idList,
                                positionList,
                            )
                        }
                    )
                },
                titleDescending = titleDescending,
                numberDescending = numberDescending,
                showTitleSortIcon = showTitleSortIcon,
                showNumberSortIcon = showNumberSortIcon,
                showHeaderSeparator = false
            )
        }



    }
}