package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs.devices.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.devices.ClosingTypeEnum
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.DropDownItem
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.jetbrains.compose.web.dom.Text


@Composable
fun ClosingTypeConfigContent(
    closingType: String,
    updateData: (String, dynamic) -> Unit,
    isActive: Boolean
)
{

    Column() {

        MediumContentTitle(
            title = Strings.degree,
        )

        DropDownItem(
            modifier = Modifier
                .margin(bottom = Dimen.normalPadding),
            itemWidth = Dimen.semiMediumEditTextWidth,
            options = ClosingTypeEnum.entries,
            label = Strings.inTheHouse,
            selectedItem = ClosingTypeEnum.entries.find {
                it.value == closingType
            },
            content = {
                if (it is ClosingTypeEnum)
                    Text(it.title)
            },
            onOptionClick = {
                val option: ClosingTypeEnum? = ClosingTypeEnum.entries.find { item ->
                    item.toString() == it
                }

                if (option != null) {
                    updateData(ApiUtils.Field.closingType, option.value)
                }
            }
        )


        CheckboxComponent(
            title = Strings.activateEmployeeCompletion,
            onChecked = {
                updateData(ApiUtils.Field.isEmployeeClosingActive,true)
            },
            onUnChecked = {
                updateData(ApiUtils.Field.isEmployeeClosingActive,false)
            },
            checkStatus = isActive
        )


    }
}