package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.app_constants


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.app_constants.LoadAppConstant

@Serializable
data class CashAssist(
    @SerialName("loadAppConstants")
    val loadAppConstants: List<LoadAppConstant>?,
    @SerialName("__typename")
    val typename: String?,
)