package org.hs_soft.runmynesto.domain.usecase.closing_report

import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model.ClosingReportDetailModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.closing_report.CloseDailyReportRepository

class CloseDailyReportUseCase(
    private val repository:CloseDailyReportRepository
)
{
    suspend operator fun invoke(
        id:String,
        date:String,
        reason:String,
    ): ClosingReportDetailModel {
        return repository.closeDailyReport(
            id=id,
            date = date,
            reason = reason,
        )
    }

}