package org.hs_soft.runmynesto.data.api_datasource.media_center.tabs.media

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_detail.MediaDetailModel
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.update_media_img.UpdateBase64ImgModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.UpdateBase64ImgRepository
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.UpdateMediaRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateBase64ImgRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateBase64ImgRepository {



    override suspend fun updateBase64Img(
        id: String,
        type: String,
        file: String
    ): UpdateBase64ImgModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                mutation uploadImgBase64(${'$'}file: String!, ${'$'}type: ImgType!,${'$'}id: ID!) {
                  cashAssist {
                    img: uploadImgBase64(file:${'$'}file, type:${'$'}type, id:${'$'}id) {
                      ${ApiUtils.Field.id}
                      ${ApiUtils.Field.uid}
                      ${ApiUtils.Field.key}
                      ${ApiUtils.Field.__typename}
                    }
                    ${ApiUtils.Field.__typename}
                  }
                }


            """.trimIndent())

            putJsonObject("variables") {
                put("id",id)
                put("file",file)
                put("type",type)
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }

    }

}
