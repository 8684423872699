package org.hs_soft.runmynesto.data.api_datasource.options.restaurant_manager

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.restaurant_project.RestaurantProjectModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.RestaurantProjectsRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class RestaurantProjectsRepositoryImpl (
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): RestaurantProjectsRepository {


    override suspend fun getRestaurantProjects(): RestaurantProjectModel {
        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {

            put("query", """
                {
                  cashAssist {
                    listRestaurantProject {
                      ${ApiUtils.Field.objectType}
                      ${ApiUtils.Field.caption}
                      ${ApiUtils.Field.id}
                      ${ApiUtils.Field.modVer}
                      ${ApiUtils.Field.floors} {
                        ${ApiUtils.Field.objectType}
                        ${ApiUtils.Field.number}
                        ${ApiUtils.Field.projectId}
                        ${ApiUtils.Field.id}
                        ${ApiUtils.Field.modVer}
                        ${ApiUtils.Field.rooms} {
                          ${ApiUtils.Field.objectType}
                          ${ApiUtils.Field.caption}
                          ${ApiUtils.Field.projectId}
                          ${ApiUtils.Field.floorId}
                          ${ApiUtils.Field.id}
                          ${ApiUtils.Field.modVer}
                          ${ApiUtils.Field.roomColor}
                          ${ApiUtils.Field.furniture} {
                            ${ApiUtils.Field.projectId}
                            ${ApiUtils.Field.floorId}
                            ${ApiUtils.Field.roomId}
                            ${ApiUtils.Field.id}
                            ${ApiUtils.Field.modVer}
                            ${ApiUtils.Field.objectType}
                            ${ApiUtils.Field.objectKind}
                            ${ApiUtils.Field.caption}
                            ${ApiUtils.Field.componentColor}
                            ${ApiUtils.Field.positionTop}
                            ${ApiUtils.Field.positionLeft}
                            ${ApiUtils.Field.rotationDeg}
                            ${ApiUtils.Field.height}
                            ${ApiUtils.Field.width}
                            ${ApiUtils.Field.shape}
                            ${ApiUtils.Field.typename}
                          }
                          ${ApiUtils.Field.typename}
                        }
                        ${ApiUtils.Field.typename}
                      }
                      ${ApiUtils.Field.typename}
                    }
                    ${ApiUtils.Field.typename}
                  }
                }
            """.trimIndent())

            putJsonObject("variables") {}
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)
        }


    }

}
