package org.hs_soft.runmynesto.pages.home.sub_page.user.component.tabs.rights_tab

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.user.UserCancellationReasonEnum
import org.hs_soft.runmynesto.domain.model.home.user.single_user.GetUser
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.CustomTextArea
import org.hs_soft.runmynesto.pages.components.DropDownItem
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.dom.Text


@Composable
fun UserRightsTabContent(
    onCancellationReasonClick: (dynamic) -> Unit,
    cancellationReason: UserCancellationReasonEnum,
    waiterCastleValue:String,
    onWaiterCastleFocusOut:(String)->Unit,
    updateDayEndCheck: (Boolean) -> Unit,
    updateTimeTracking:(Boolean)->Unit,
    updateIsAdmin:(Boolean)->Unit,
    updateIsViewSale:(Boolean)->Unit,
    updateIsManager:(Boolean)->Unit,
    updateInterimFinancialStatement:(Boolean)->Unit,
    selectedUser: GetUser?,
    windowSizeEnum: WindowSizeModeEnum,
) {
    Column(
        modifier = Modifier
            .fillMaxHeight()
            .fillMaxWidth()
            .padding(Dimen.largePadding)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
    ) {

        MediumContentTitle(
            title = Strings.general,
            fontSize = FontSizes.extraExtraLargeFontSize,
            marginBottom = Dimen.mediumPadding,
        )

        if (windowSizeEnum== WindowSizeModeEnum.WINDOWS)
        {
            Row(modifier = Modifier)
            {
                LeftSideUserRightContent(
                    cancellationReason=cancellationReason,
                    onCancellationReasonClick={
                        onCancellationReasonClick(it)
                    },
                    selectedUser=selectedUser,
                    updateTimeTracking= {
                        updateTimeTracking(it)
                    },
                    updateInterimFinancialStatement= {
                        updateInterimFinancialStatement(it)
                    },
                    updateDayEndCheck={
                        updateDayEndCheck(it)
                    },
                    updateIsAdmin= {
                        updateIsAdmin(it)
                    },
                    updateIsManager= {
                        updateIsManager(it)
                    },
                    updateIsViewSale= {
                        updateIsViewSale(it)
                    }
                )


                waiterCastleTextArea(
                    onFocusOut = {
                        onWaiterCastleFocusOut(it)
                    },
                    value =waiterCastleValue,
                    onValueChange = {

                    }
                )



            }
        }else{
            Column(
                modifier = Modifier
            ) {

                LeftSideUserRightContent(
                    cancellationReason=cancellationReason,
                    onCancellationReasonClick={
                        onCancellationReasonClick(it)
                    },
                    selectedUser=selectedUser,
                    updateTimeTracking= {
                        updateTimeTracking(it)
                    },
                    updateInterimFinancialStatement= {
                        updateInterimFinancialStatement(it)
                    },
                    updateDayEndCheck={
                        updateDayEndCheck(it)
                    },
                    updateIsAdmin= {
                        updateIsAdmin(it)
                    },
                    updateIsManager= {
                        updateIsManager(it)
                    },
                    updateIsViewSale= {
                        updateIsViewSale(it)
                    }
                )

                waiterCastleTextArea(
                    onFocusOut = {
                        onWaiterCastleFocusOut(it)
                    },
                    value =waiterCastleValue,
                    onValueChange = {}
                )
            }
        }
    }


}

@Composable
private fun waiterCastleTextArea(
    onFocusOut:(String)->Unit,
    value:String,
    onValueChange:(String)->Unit,
)
{
    CustomTextArea(
        label = Strings.waitersCastle,
        value =value,
        onFocusOut ={
            onFocusOut(it)
        },
        onValueChange = {
            onValueChange(it)
        }
    )

}



@Composable
private fun LeftSideUserRightContent(
    cancellationReason: UserCancellationReasonEnum,
    onCancellationReasonClick: (dynamic) -> Unit,
    selectedUser: GetUser?,
    updateTimeTracking: (Boolean) -> Unit,
    updateInterimFinancialStatement: (Boolean) -> Unit,
    updateDayEndCheck: (Boolean) -> Unit,
    updateIsAdmin: (Boolean) -> Unit,
    updateIsManager: (Boolean) -> Unit,
    updateIsViewSale: (Boolean) -> Unit
) {
    Column(
        modifier = Modifier.
        margin(
            right = Dimen.mediumPadding,
            bottom = Dimen.mediumPadding,
        ))
    {
        DropDownItem(
            modifier = Modifier.margin(bottom = Dimen.mediumPadding),
            options = UserCancellationReasonEnum.entries,
            label = Strings.cancellationRight,
            selectedItem = UserCancellationReasonEnum.fromDisplayName(cancellationReason.title)
                ?: UserCancellationReasonEnum.NO_AUTHORIZATION,
            content = {
                if (it is UserCancellationReasonEnum)
                    Text(it.title)
            },
            onOptionClick = {
                  onCancellationReasonClick(it)
            },
        )


        CheckboxComponent(
            modifier = Modifier
                .margin(
                    top = Dimen.mediumPadding,
                ),
            checkStatus = selectedUser?.isTimeTracking ?: false,
            title = Strings.enableUsersOnlyForTimeTracking,
            accentColor = Theme.Blue.rgb,
            onChecked = {
                updateTimeTracking(true)
            },
            onUnChecked = {
                updateTimeTracking(false)
            },
        )

        CheckboxComponent(
            modifier = Modifier
                .margin(top = Dimen.mediumPadding),
            checkStatus = selectedUser?.isFinStatements ?: false,
            title = Strings.interimFinancialStatement,
            onChecked = {
                updateInterimFinancialStatement(true)
            },
            onUnChecked = {
                updateInterimFinancialStatement(false)
            },
            accentColor = Theme.Blue.rgb
        )

        CheckboxComponent(
            modifier = Modifier
                .margin(top = Dimen.mediumPadding),
            checkStatus = selectedUser?.isDayFinStatements ?: false,
            title = Strings.DAYS_END,
            onChecked = {
                updateDayEndCheck(true)
            },
            onUnChecked = {
                updateDayEndCheck(false)
            },
            accentColor = Theme.Blue.rgb
        )

        CheckboxComponent(
            modifier = Modifier
                .margin(
                    top = Dimen.mediumPadding,
                    bottom = Dimen.mediumPadding,
                ),
            checkStatus = selectedUser?.isAdmin ?: false,
            title = Strings.ADMINISTRATOR,
            onChecked = {
                updateIsAdmin(true)
            },
            onUnChecked = {
                updateIsAdmin(false)
            },
            accentColor = Theme.Blue.rgb
        )

        MediumContentTitle(
            title = Strings.gastronomy,
            marginTop = Dimen.mediumPadding,
            marginBottom = Dimen.mediumPadding,
            fontSize = FontSizes.extraExtraLargeFontSize,
        )



        CheckboxComponent(
            modifier = Modifier,
            checkStatus = selectedUser?.isManager ?: false,
            title = Strings.MANAGER,
            onChecked = {
                updateIsManager(true)
            },
            onUnChecked = {
                updateIsManager(false)
            },
            accentColor = Theme.Blue.rgb
        )

        CheckboxComponent(
            modifier = Modifier
                .margin(
                    top = Dimen.mediumPadding,
                ),
            checkStatus = selectedUser?.isViewSales ?: false,
            title = Strings.NO_SALES_TRIGGER,
            onChecked = {
                updateIsViewSale(true)
            },
            onUnChecked = {
                updateIsViewSale(false)
            },
            accentColor = Theme.Blue.rgb
        )
    }

}
