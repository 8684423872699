package org.hs_soft.runmynesto.pages.home.sub_page.media_center.mvi

import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.media_center.MediaCenterInfoHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.DisplaySizeEnum
import org.hs_soft.runmynesto.domain.model.home.template.GridItemsFilterEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel

sealed class MediaCenterEvent
{
    data class OnSearchCheckoutItems(
        val value:String
    ):MediaCenterEvent()

    data class UpdateCheckout(
        val dataValue:MutableSet<String>
    ): MediaCenterEvent()

    data object GetAllProducts:MediaCenterEvent()

    data class DeleteArticle(val id:String):MediaCenterEvent()

    data class AddItemToArticleList(val id:String) :MediaCenterEvent()

    data class SetArticleDialogStatus(val status:Boolean):MediaCenterEvent()


    data class SetDisplaySize(val displaySize:DisplaySizeEnum)
        :MediaCenterEvent()

    data class SetAddNewGroupDialogStatus(val status:Boolean)
        :MediaCenterEvent()

    data object LoadAppConstants:MediaCenterEvent()

    data class DeleteTag(val tagId:String):MediaCenterEvent()

    data class AddTag(val name:String):MediaCenterEvent()

    data object GetShopList
        :MediaCenterEvent()

    data class UpdateBase64Img(
        val file:String,
        val type:String,
    ):MediaCenterEvent()

    data class OnSelectFromDate(
        val date:MonthModel
    ):MediaCenterEvent()

    data class SetFromCalendarStatus(
        val status:Boolean,
    ):MediaCenterEvent()

    data class OnSelectToDate(
        val date: MonthModel
    ):MediaCenterEvent()

    data class SetToCalendarStatus(
        val status:Boolean
    ):MediaCenterEvent()



    data class UpdateMedia(
        val title:String,
        val value:dynamic
    ):MediaCenterEvent()

    data object GetMediaTags:MediaCenterEvent()

    data object OnChangePageClick:MediaCenterEvent()

    data object GetCheckoutData:MediaCenterEvent()
    data class OnTopTabClick(val tab:MediaCenterInfoHeaderEnum)
        :MediaCenterEvent()
    data object GetMediaList:MediaCenterEvent()

    data class OnSearchMediaInputChange(val value:String): MediaCenterEvent()

    data class OnMediaItemClick(
        val id:String,
        val switchTheSecondPage:()->Unit={}
    ):MediaCenterEvent()

    data object OnAddRecordIconClick:MediaCenterEvent()

    data object OnMainGridTitleSortClick:MediaCenterEvent()

    data object OnMainGridNumberSortClick:MediaCenterEvent()

    data object RefreshProductsCategoryList:MediaCenterEvent()

    data class SetFilterItemsComponentStatus(val status:Boolean):MediaCenterEvent()
    data object SubmitFilterItems: MediaCenterEvent()
    data class SelectFilterType(val type: GridItemsFilterEnum): MediaCenterEvent()

    data class OnChangeRecordName(val value:String):MediaCenterEvent()
    data object OnCancelRecordDialogClick:MediaCenterEvent()

    data class OnSubmitRecordDialogClick(
        val screenMode: WindowSizeModeEnum
    ): MediaCenterEvent()

    data object OnAddRecordDialogBackgroundClick:MediaCenterEvent()


}