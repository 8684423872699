package org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media

import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_tab.MediaTagModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.MediaTagsRepository


class MediaTagsUseCase(
    private val repository: MediaTagsRepository
) {
    suspend operator fun invoke(): MediaTagModel {
         return repository.getMediaTags()
    }
}