package org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.product_general_tab.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.product_item_model.GetProduct
import org.hs_soft.runmynesto.domain.util.getGeneralItemRatio
import org.hs_soft.runmynesto.pages.components.CheckboxComponent

@Composable
fun ProductTabDiscountInfo(
    selectedProduct: GetProduct?,
    updateNoDiscountPossible: (Boolean) -> Unit,
    updateEmployeeNetItems: (Boolean) -> Unit,
    updateEmployeeConsumption: (Boolean) -> Unit,
    windowSizeMode: WindowSizeModeEnum
) {
    Column(
        modifier = Modifier
            .margin(
                top = Dimen.largePadding
            )
    )
    {
        SpanText(
            text = Strings.discount,
            modifier = Modifier
                .color(Theme.Gray3E.rgb)
                .fontFamily(FontFamily.Montserrat)
                .fontWeight(FontWeight.Medium)
                .fontSize(FontSizes.extraExtraLargeFontSize)
                .margin(bottom = Dimen.extraSmallPadding)
        )

        CheckboxComponent(
            modifier = Modifier
                .margin(
                    top = Dimen.smallPadding,
                    left = Dimen.normalPadding,
                ),
            checkStatus = selectedProduct?.isNetto ?: false,
            title = Strings.noDiscountPossible,
            fontSize = getGeneralItemRatio(
                windowSizeMode=windowSizeMode,
                size = FontSizes.mediumFontSize
            ),
            onChecked = {
                updateNoDiscountPossible(true)
            },
            onUnChecked = {
                updateNoDiscountPossible(false)
            },
        )

        CheckboxComponent(
            modifier = Modifier
                .margin(
                    top = Dimen.smallPadding,
                    left = Dimen.normalPadding,
                ),
            checkStatus = selectedProduct?.isNettoForUser ?: false,
            title = Strings.employeeNetItems,
            fontSize = getGeneralItemRatio(
                windowSizeMode=windowSizeMode,
                size = FontSizes.mediumFontSize
            ),
            onChecked = {
                updateEmployeeNetItems(true)
            },
            onUnChecked = {
                updateEmployeeNetItems(false)
            },
        )

        CheckboxComponent(
            modifier = Modifier
                .margin(
                    top = Dimen.smallPadding,
                    left = Dimen.normalPadding,
                ),
            checkStatus = selectedProduct?.isEmployeeArticle ?: false,
            title = Strings.employeeConsumption,
            fontSize = getGeneralItemRatio(
                windowSizeMode=windowSizeMode,
                size = FontSizes.mediumFontSize
            ),
            onChecked = {
                updateEmployeeConsumption(true)
            },
            onUnChecked = {
                updateEmployeeConsumption(false)
            },
        )


    }
}