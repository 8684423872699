package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.closing_reports_content.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.borderTop
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.HorizontalScrollBehavior
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model.SalesGroup
import org.hs_soft.runmynesto.domain.util.formatToTwoDecimals
import org.hs_soft.runmynesto.pages.components.HeaderTitle
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.ShowWhenListIsEmpty
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun ClosingReportsSalesGroup(
    data: List<SalesGroup>?,
    windowSizeMode:WindowSizeModeEnum,
)
{
    MediumContentTitle(
        title = Strings.salesGroups,
        marginTop = Dimen.normalPadding,
        marginBottom = Dimen.normalPadding,
    )
    Column(
        modifier = HorizontalScrollBehavior.toModifier()
            .fillMaxWidth()
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayAlpha12.rgb
            )
            .borderRadius(Dimen.lowBorderRadius)
    ) {

        Row(
            modifier =Modifier
                .thenIf(
                    windowSizeMode== WindowSizeModeEnum.WINDOWS,
                    Modifier.fillMaxWidth()
                )
                .thenIf(
                    windowSizeMode!=WindowSizeModeEnum.WINDOWS,
                    Modifier.width(Dimen.closingReportsPhoneWidth)
                )
                .height(Dimen.gridHeaderHeight)
                .borderBottom(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayAlpha12.rgb
                )
                .backgroundColor(Theme.GrayAlpha04.rgb)
                .padding(leftRight = Dimen.normalPadding),
            verticalAlignment = Alignment.CenterVertically
        ) {

            HeaderTitle(
                title = Strings.salesGroups,
                width = Dimen.headerLargeWidth,
                fillMaxWidth = true,
            )
            HeaderTitle(
                title = Strings.number,
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )
            HeaderTitle(
                title = Strings.discount,
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )
            HeaderTitle(
                title = Strings.reduction,
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )
            HeaderTitle(
                title = Strings.freeVat,
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )
            HeaderTitle(
                title = Strings.normalVat,
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )
            HeaderTitle(
                title = Strings.reducedVat,
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )
            HeaderTitle(
                title = Strings.specialVat,
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )
            HeaderTitle(
                title = Strings.salesVolume,
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )

        }



        Column(
            modifier = VerticalScrollBehavior
                .toModifier()
                .fillMaxWidth()
                .thenIf(
                    windowSizeMode!=WindowSizeModeEnum.WINDOWS,
                    Modifier.width(Dimen.closingReportsPhoneWidth)
                )
                .thenIf(
                    data.isNullOrEmpty(),
                    Modifier.fillMaxWidth()
                )
                .height(Dimen.closingReportTable1Height)
        )
        {
            if (data.isNullOrEmpty())
            {
                ShowWhenListIsEmpty(
                    modifier = Modifier
                        .fillMaxWidth()
                        .height(Dimen.closingReportTable1Height)
                )
            }
            data?.forEach {model->
                Row(
                    modifier =Modifier
                        .fillMaxWidth()
                        .thenIf(
                            windowSizeMode!=WindowSizeModeEnum.WINDOWS,
                            Modifier.width(Dimen.closingReportsPhoneWidth)
                        )
                        .fillMaxWidth()
                        .height(Dimen.mediumTableItemHeight)
                        .borderBottom(
                            width = Dimen.smallThickness,
                            style = LineStyle.Solid,
                            color = Theme.GrayAlpha12.rgb
                        )
                        .padding(leftRight = Dimen.normalPadding),
                    verticalAlignment = Alignment.CenterVertically
                ) {

                    HeaderTitle(
                        title = model.salesGroup?.name ?: "",
                        width = Dimen.headerLargeWidth,
                        fillMaxWidth = true,
                    )
                    HeaderTitle(
                        title =model.amount?.toString()?:"" ,
                        arrangement = Arrangement.End,
                        fillMaxWidth = true,
                    )
                    HeaderTitle(
                        title = formatToTwoDecimals(model.discount),
                        arrangement = Arrangement.End,
                        fillMaxWidth = true,
                    )
                    HeaderTitle(
                        title = formatToTwoDecimals(model.reduction ),
                        arrangement = Arrangement.End,
                        fillMaxWidth = true,
                    )
                    HeaderTitle(
                        title = formatToTwoDecimals(model.mWStFree ),
                        arrangement = Arrangement.End,
                        fillMaxWidth = true,
                    )
                    HeaderTitle(
                        title = formatToTwoDecimals(model.mWStNormal ),
                        arrangement = Arrangement.End,
                        fillMaxWidth = true,
                    )
                    HeaderTitle(
                        title = formatToTwoDecimals(model.mWStReduced ),
                        arrangement = Arrangement.End,
                        fillMaxWidth = true,
                    )
                    HeaderTitle(
                        title = formatToTwoDecimals(model.mWStSpecial ),
                        arrangement = Arrangement.End,
                        fillMaxWidth = true,
                    )
                    HeaderTitle(
                        title = formatToTwoDecimals(model.total ),
                        arrangement = Arrangement.End,
                        fillMaxWidth = true,
                    )

                }
            }

        }


        // Footer Row with Totals
        val totalItems = data?.size ?: 0
        val totalAmount = data?.sumOf { it.amount ?: 0.0 }
        val totalDiscount = data?.sumOf { it.discount ?: 0.0 }
        val totalReduction = data?.sumOf { it.reduction ?: 0.0 }
        val totalFreeVat = data?.sumOf { it.mWStFree ?: 0.0 }
        val totalNormalVat = data?.sumOf { it.mWStNormal ?: 0.0 }
        val totalReducedVat = data?.sumOf { it.mWStReduced ?: 0.0 }
        val totalSpecialVat = data?.sumOf { it.mWStSpecial ?: 0.0 }
        val totalSalesVolume = data?.sumOf { it.total ?: 0.0 }

        Row(
            modifier = Modifier
                .fillMaxWidth()
                .thenIf(
                    windowSizeMode!=WindowSizeModeEnum.WINDOWS,
                    Modifier.width(Dimen.closingReportsPhoneWidth)
                )
                .height(Dimen.gridItemHeight)
                .borderTop(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayAlpha12.rgb
                )
                .borderBottom(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayAlpha12.rgb
                )
                .backgroundColor(Theme.GrayAlpha04.rgb)
                .padding(leftRight = Dimen.normalPadding),
            verticalAlignment = Alignment.CenterVertically
        ) {

            HeaderTitle(
                title = "${Strings.items} : $totalItems",
                width = Dimen.headerLargeWidth,
                fillMaxWidth = true,
            )
            HeaderTitle(
                title =  totalAmount?.toString()?:"",
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )
            HeaderTitle(
                title = formatToTwoDecimals(totalDiscount),
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )
            HeaderTitle(
                title = formatToTwoDecimals(totalReduction),
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )
            HeaderTitle(
                title = formatToTwoDecimals(totalFreeVat),
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )
            HeaderTitle(
                title = formatToTwoDecimals(totalNormalVat),
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )
            HeaderTitle(
                title = formatToTwoDecimals(totalReducedVat),
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )
            HeaderTitle(
                title = formatToTwoDecimals(totalSpecialVat),
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )
            HeaderTitle(
                title = formatToTwoDecimals(totalSalesVolume),
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )

        }

    }




}

