package org.hs_soft.runmynesto.data.api_datasource.media_center.tabs.media

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_model.MediaModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.MediaListRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class MediaListRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): MediaListRepository {

    override suspend fun getMediaList(
        isActive:Boolean?
    ): MediaModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
                query listMedia(${'$'}filter: MediaFilterArgs, ${'$'}params: ListQueryArgs) {
                  cashAssist {
                    listMedia(filter:${'$'}filter, params: ${'$'}params) {
                      ...listMedias
                      __typename
                    }
                    __typename
                  }
                }

                fragment listMedias on MediaConnection {
                  items {
                    ${ApiUtils.Field.id}
                    ${ApiUtils.Field.mod_ver}
                    ${ApiUtils.Field.name}
                    ${ApiUtils.Field.fromTime}
                    timeInterval {
                      ${ApiUtils.Field.from}
                      ${ApiUtils.Field.__typename}
                    }
                    ${ApiUtils.Field.__typename}
                  }
                  cursors {
                    ${ApiUtils.Field.hasNext}
                    ${ApiUtils.Field.hasPrevious}
                    ${ApiUtils.Field.before}
                    ${ApiUtils.Field.after}
                    ${ApiUtils.Field.__typename}
                  }
                  ${ApiUtils.Field.total}
                  ${ApiUtils.Field.hasNextPage}
                  ${ApiUtils.Field.__typename}
                }


            """.trimIndent())

            putJsonObject("variables") {
                putJsonObject("filter"){
                    if (isActive!=null)
                    put("isActive",isActive)
                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }

    }

}
