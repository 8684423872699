package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.dialogs.ClosingDailyReportDialog
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.dialogs.FinancialGroupDialog
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.mvi.ClosingReportsEvent
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.mvi.ClosingReportsState

@Composable
fun ClosingReportsDialog(
    windowSizeMode:WindowSizeModeEnum,
    state:ClosingReportsState,
    event:(ClosingReportsEvent)->Unit,
){
    if (state.showFinancialGroupDialog )
    {
        FinancialGroupDialog(
            modifier = Modifier,
            selectedType = state.selectedFinancialGroup,
            submitFilterItems = {
                event(ClosingReportsEvent.SubmitFinancialGroup(it))
            },
            onCloseFilterClick = {
                event(ClosingReportsEvent.SetFinancialGroupStatus(false))
            }
        )
    }

    if (state.showClosingDailyReportDialog){
        ClosingDailyReportDialog(
            onCloseClick = {
                event(ClosingReportsEvent.SetClosingDailyReportDialogStatus(false))
            },
            submitClosingDailyReport = {
                event(ClosingReportsEvent.CloseDailyReport(reason = it))
            }

        )


    }



}