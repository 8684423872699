package org.hs_soft.runmynesto.pages.home.sub_page.media_center.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.pages.components.dialog.dialog.AddRecordDialog
import org.hs_soft.runmynesto.pages.home.sub_page.media_center.component.media_center_content.tabs.components.AddGroupDialog
import org.hs_soft.runmynesto.pages.home.sub_page.media_center.mvi.MediaCenterEvent
import org.hs_soft.runmynesto.pages.home.sub_page.media_center.mvi.MediaCenterState
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.AddProductToArticleDialog
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.date_picker.DatePickerDialog


@Composable
fun MediaCenterDialogs(
    windowSizeMode: WindowSizeModeEnum,
    state: MediaCenterState,
    event: (MediaCenterEvent)->Unit,
) {
    if (state.showArticleDialog)
    {
        AddProductToArticleDialog(
            errMsg = state.addNewProductToArticleErrMsg,
            searchedProductItems=state.allProducts?: listOf(),
            onBackgroundClick = {
                event(MediaCenterEvent.SetArticleDialogStatus(false))
            },
            onItemClick = {
                event(MediaCenterEvent.AddItemToArticleList(it))
            }
        )
    }

    if (state.showAddGroupDialog)
    {
        AddGroupDialog(
            windowSizeMode=windowSizeMode,
            mediaTags = state.mediaTags,
            closeDialog = {
                event(MediaCenterEvent.SetAddNewGroupDialogStatus(false))
            },
            addTag = {name->
                event(MediaCenterEvent.AddTag(name))
            },
            deleteTag = {id->
                event(MediaCenterEvent.DeleteTag(id))
            },
        )
    }

    if (state.showAddRecordDialog) {
        AddRecordDialog(
            windowSizeMode = windowSizeMode,
            dialogTitle = Strings.addNewMedia,
            nameFieldTitle = Strings.name,
            numberFieldTitle = Strings.number,
            nameValue = state.newRecordName,
            showNumberField = false ,
            isSubmitClickable = state.isAddNewRecordSubmitClickable,
            loading = state.addRecordLoading,
            errorMessage = state.addRecordErrorMessage,
            onNameValueChange = {
                event(MediaCenterEvent.OnChangeRecordName(it))
            },
            onCancelClick = {
                event(MediaCenterEvent.OnCancelRecordDialogClick)
            },
            onSubmitClick = {
                event(MediaCenterEvent.OnSubmitRecordDialogClick(windowSizeMode))
            },
            onBackgroundClick = {
                event(MediaCenterEvent.OnAddRecordDialogBackgroundClick)
            }
        )
    }

    if (state.showFromCalendar) {

        DatePickerDialog(
            selectedDay = state.selectedFromDate,
            modifier = Modifier,
            calendarData = state.calendarData,
            onSelectDay = {
                event(MediaCenterEvent.OnSelectFromDate(it))
            },
            onBackgroundClick = {
                event(MediaCenterEvent.SetFromCalendarStatus(false))
            },
            closeCalendarDialog = {
                event(MediaCenterEvent.SetFromCalendarStatus(false))
            }
        )
    }

    if (state.showToCalendar) {

        DatePickerDialog(
            selectedDay = state.selectedToDate,
            modifier = Modifier,
            calendarData = state.calendarData,
            onSelectDay = {
                event(MediaCenterEvent.OnSelectToDate(it))
            },
            onBackgroundClick = {
                event(MediaCenterEvent.SetToCalendarStatus(false))
            },
            closeCalendarDialog = {
                event(MediaCenterEvent.SetToCalendarStatus(false))
            }
        )
    }

}