package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs.devices.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.addJsonObject
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonArray
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config.ClientButtonName
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config.IsPiecemeal
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config.ReceiptCopy
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.operation.get_cash_register_config.StartPiecemeal
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.jetbrains.compose.web.attributes.InputType

@Composable
fun CreditCardItem(
    name: String,
    clientButtonName: ClientButtonName?,
    isPieceMeal: IsPiecemeal?,
    receiptCopies: ReceiptCopy?,
    startPiecemeal: StartPiecemeal?,
    index: Int,
    updateData:(String,dynamic)->Unit,
)
{
    Row(
        verticalAlignment = Alignment.CenterVertically
    )
    {
        CustomEditText(
            value = clientButtonName?.value?:"",
            marginBottom = Dimen.normalPadding,
            isOutlinedEditText = true,
            width = Dimen.semiMediumEditTextWidth,
            label = name,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint ="",
            onFocusIn = {},
            onFocusOut = {value->
                val updatedCreditCards = getUpdatedCreditCards(
                    value=value,
                    index =index,
                    title = ApiUtils.Field.clientButtonName
                )
                updateData(ApiUtils.Field.creditCards,updatedCreditCards)

            },
            onInputChange = {

            },
        )


        CustomEditText(
            value = if(startPiecemeal?.value==null) ""
                else startPiecemeal.value.toString(),
            isDecimal = true,
            marginBottom =Dimen.normalPadding,
            marginLeft = Dimen.mediumPadding,
            marginRight = Dimen.mediumPadding,
            isOutlinedEditText = true,
            width = Dimen.normalEditTextWidth,
            label = Strings.fromAmount,
            textAlign = TextAlign.End,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint ="",
            onFocusIn = {},
            onFocusOut = {
                val updatedCreditCards = getUpdatedCreditCards(
                    value=it.toDouble(),
                    index =index,
                    title = ApiUtils.Field.startPiecemeal
                )
                updateData(ApiUtils.Field.creditCards,updatedCreditCards)
            },
            onInputChange = {

            },
        )

        CheckboxComponent(
            checkStatus = isPieceMeal?.value?:false,
            title = Strings.variable,
            onChecked = {
                val updatedCreditCards = getUpdatedCreditCards(
                    value=true,
                    index =index,
                    title = ApiUtils.Field.isPiecemeal
                )
                updateData(ApiUtils.Field.creditCards,updatedCreditCards)
            },
            onUnChecked = {
                val updatedCreditCards = getUpdatedCreditCards(
                    value=false,
                    index =index,
                    title = ApiUtils.Field.isPiecemeal
                )
                updateData(ApiUtils.Field.creditCards,updatedCreditCards)
            }
        )


        CustomEditText(
            value = if (receiptCopies?.value==null)""
                else receiptCopies.value.toString(),
            marginBottom = Dimen.normalPadding,
            marginLeft = Dimen.mediumPadding,
            isOutlinedEditText = true,
            width = Dimen.normalEditTextWidth,
            label = Strings.receiptCopy,
            inputType = InputType.Number,
            textAlign = TextAlign.End,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint ="",
            onFocusIn = {},
            onFocusOut = {
                val updatedCreditCards = getUpdatedCreditCards(
                    value=it.toInt(),
                    index =index,
                    title = ApiUtils.Field.receiptCopies
                )
                updateData(ApiUtils.Field.creditCards,updatedCreditCards)
            },
            onInputChange = {

            },
        )



    }



}

private fun getUpdatedCreditCards(
    title:String,
    value: dynamic,
    index: Int
): JsonObject {
    val updatedCreditCards = buildJsonObject {
        putJsonArray(title) {
            addJsonObject {
                put("position", index)
                when(value){
                    is Int -> put("value",value as Int)
                    is String ->put("value",value as String)
                    is Boolean ->put("value",value as Boolean)
                    is Double ->put("value",value as Double)
                }
            }
        }

    }
    return updatedCreditCards
}