package org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media

import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.app_constants.AppConstantModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.LoadAppConstantsRepository


class LoadAppConstantsUseCase(
    private val repository: LoadAppConstantsRepository
) {
    suspend operator fun invoke(
        language:String,
        type:Int,
    ): AppConstantModel {
         return repository.getAppConstants(
             language =language ,
             type = type
         )
    }
}