package org.hs_soft.runmynesto.pages.login

import org.hs_soft.runmynesto.domain.model.WindowSizeModel

sealed class LoginEvent
{
    data class InitWindowsSize(val windowsSize: WindowSizeModel) :LoginEvent()

    data class OnUserNameChange(val value:String): LoginEvent()

    data class OnPasswordChange(val value:String): LoginEvent()

    data class OnMFAChange(val value:String):LoginEvent()

    object OnMouseEnterSubmit : LoginEvent()

    object OnMouseLeaveSubmit: LoginEvent()

    object StartLoading : LoginEvent()

    object PassFirstSignInStep:LoginEvent()

    data class OnSignInClick(
        val userName:String,
        val password:String,
        val mfsCode:String,

        val goToHomePage:()->Unit,
    ): LoginEvent()

    data class OnFailureLogin(val error:String): LoginEvent()

    data object UpdateIsLoggedInStatus :LoginEvent()

}