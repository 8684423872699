package org.hs_soft.runmynesto.domain.model.home.options.menu_card_import.menu_card_model

import kotlinx.serialization.Serializable

@Serializable
data class MenuCardModel(
    var categories: MutableList<String>,
    var items: MutableList<MenuProductItemModel>
)

