package org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager

import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.create_floor.CreateFloorModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.CreateFloorRepository

class CreateFloorUseCase(
    private val repository: CreateFloorRepository,
) {
    suspend operator fun invoke(
        number:Int,
        projectId:String,
    ): CreateFloorModel {
        return repository.createFloor(
            number=number,
            projectId=projectId
        )
    }
}