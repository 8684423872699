package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_model


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Cursors(
    @SerialName("after")
    val after: String?,
    @SerialName("before")
    val before: String?,
    @SerialName("hasNext")
    val hasNext: Boolean?,
    @SerialName("hasPrevious")
    val hasPrevious: Boolean?,
    @SerialName("__typename")
    val typename: String?
)