package org.hs_soft.runmynesto.di.media_center

import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.AddNewMediaUseCase
import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.AddTagUseCase
import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.DeleteTagUseCase
import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.LoadAppConstantsUseCase
import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.MediaDetailUseCase
import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.MediaListUseCase
import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.MediaTagsUseCase
import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.UpdateBase64ImgUseCase
import org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media.UpdateMediaUseCase
import org.koin.dsl.module

val mediaCenterUseCaseModule= module {

    single {
        MediaListUseCase(get())
    }

    single {
        MediaDetailUseCase(get())
    }

    single {
        AddNewMediaUseCase(get())
    }

    single{
        MediaTagsUseCase(get())
    }
    single {
        UpdateMediaUseCase(get())
    }
    single {
        UpdateBase64ImgUseCase(get())
    }
    single {
        LoadAppConstantsUseCase(get())
    }
    single {
        DeleteTagUseCase(get())
    }
    single {
        AddTagUseCase(get())
    }



}