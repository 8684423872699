package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.date_picker

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import kotlinx.datetime.internal.JSJoda.Clock
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.generalDialogSetting
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.YearModel
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.getCurrentMonth
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.date_picker.component.CalendarDayNumbers
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.date_picker.component.CalendarMonthRow
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.date_picker.component.CalendarWeekRow


@Composable
fun DatePickerDialog(
    modifier: Modifier=Modifier,
    selectedDay: MonthModel?,
    calendarData: YearModel,
    onBackgroundClick:()->Unit,
    onSelectDay:(MonthModel)->Unit,
    closeCalendarDialog:()->Unit,
)
{

    val currentMonth = remember {
        mutableStateOf(
            calendarData.monthsOfYear.firstOrNull { monthModel ->
                monthModel.monthNumber == selectedDay?.monthNumber
                        && monthModel.yearNumber==selectedDay.yearNumber
            } ?: calendarData.monthsOfYear.firstOrNull { monthModel ->
                monthModel.monthNumber == getCurrentMonth(Clock.systemDefaultZone())
            }?:calendarData.monthsOfYear.first()
        )
    }
    val currentIndex= remember{ mutableStateOf(0) }




    Box(
        modifier = Modifier
            .fillMaxSize()
            .onClick {
                onBackgroundClick()
            }
            .backgroundColor(Theme.GrayAlpha12.rgb)
            .zIndex(Constants.ZIndex.normalZIndex)
    ){
        Column(
            modifier = modifier
                .align(Alignment.Center)
                .padding(Dimen.mediumPadding)
                .width(Dimen.datePickerWidth)
                .height(Dimen.datePickerHeight)
                .generalDialogSetting()
            ,
            horizontalAlignment = Alignment.CenterHorizontally
        )
        {
            CalendarMonthRow(
                selectedItem = currentMonth.value,
                onPreviousMonthClick = {

                    currentIndex.value = calendarData.monthsOfYear.
                    indexOfFirst {
                        it.yearNumber == currentMonth.value.yearNumber
                                &&
                                it.monthNumber==currentMonth.value.monthNumber
                    }

                    if (currentIndex.value > 0) {
                        val previousMonth = calendarData.monthsOfYear[currentIndex.value - 1]

                        currentMonth.value = previousMonth
                        currentMonth.value=currentMonth.value.copy(
                            days =  previousMonth.days,
                            dayNumber = previousMonth.dayNumber,
                            monthName = previousMonth.monthName,
                            yearNumber = previousMonth.yearNumber
                        )

                    }



                },
                onNextMonthClick = {
                    val allMonths = calendarData.monthsOfYear
                    currentIndex.value = calendarData.monthsOfYear.indexOfFirst {
                        it.yearNumber == currentMonth.value.yearNumber
                                &&
                                it.monthNumber==currentMonth.value.monthNumber
                    }

                    if (currentIndex.value < allMonths.size - 1) {

                        val nextMonth = allMonths[currentIndex.value + 1]
                        currentMonth.value = nextMonth

                        currentMonth.value=currentMonth.value.copy(
                            days =  nextMonth.days,
                            dayNumber = nextMonth.dayNumber,
                            monthNumber = nextMonth.monthNumber,
                            monthName = nextMonth.monthName
                        )

                    }

                },
                onCloseFilterClick = {
                    closeCalendarDialog()
                }

            )

            CalendarWeekRow(
                currentMonth=currentMonth
            )

            CalendarDayNumbers(
                currentMonth=currentMonth.value,
                onDayClick = {
                    onSelectDay(it)
                },
                selectedDay =selectedDay
            )

        }
    }




}










