package org.hs_soft.runmynesto.pages.components.checkout.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Visibility
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.visibility
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.getCheckoutWidth
import org.hs_soft.runmynesto.domain.util.getGeneralItemRatio
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.Separator
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.hs_soft.runmynesto.pages.components.checkout.getArrowImg
import org.jetbrains.compose.web.css.px


@Composable
fun StoreItem(
    isExpandAll: Boolean,
    checkedValue: Boolean,
    title: String,
    unCheckItem: () -> Unit,
    checkItem: () -> Unit,
    content: @Composable () -> Unit,
    ratioChecked: String,
    windowSizeMode: WindowSizeModeEnum,
    showCheckBox: Boolean
)
{
    val isHovered= remember{ mutableStateOf(false) }

    val isExpanded= remember{ mutableStateOf(false) }
    LaunchedEffect(isExpandAll){
        isExpanded.value=isExpandAll
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .cursor(Cursor.Pointer)
            .onClick {
                    event: SyntheticMouseEvent -> event.stopPropagation()
                isExpanded.value=!isExpanded.value
            },
        horizontalAlignment = Alignment.End
    ) {

        Separator()

        Box(
            modifier = Modifier
                .fillMaxWidth()
                .backgroundColor(
                    if (isHovered.value) Theme.GrayAlpha04.rgb
                    else Theme.Transparent.rgb
                )
                .onMouseOver {isHovered.value=true}
                .onMouseLeave { isHovered.value=false }
                .padding(
                    left = Dimen.largePadding,
                    right = Dimen.normalPadding
                )
                .boxShadow(color = Theme.GrayAlpha12.rgb)
                .height(Dimen.checkOutItemHeight),
        )
        {
            Box(
                modifier = Modifier
                    .visibility(
                        if(isExpanded.value) Visibility.Visible
                        else Visibility.Hidden
                    )
                    .align(Alignment.BottomStart)
                    .margin(left = Dimen.semiNormalPadding)
                    .height(((Dimen.checkOutItemHeight.value.toInt())/2).px)
                    .backgroundColor(Theme.Gray9E.rgb)
                    .width(Dimen.smallThickness)
            )
            Row(
                modifier = Modifier
                    .align(Alignment.CenterStart),
                verticalAlignment = Alignment.CenterVertically,
            )
            {
                if (showCheckBox){
                    CheckboxComponent(
                        modifier = Modifier ,
                        checkStatus = checkedValue,
                        title = "",
                        onChecked = {
                            checkItem()
                        },
                        onUnChecked = {
                            unCheckItem()
                        },
                        fontSize = getGeneralItemRatio(
                            windowSizeMode=windowSizeMode,
                            size = FontSizes.mediumFontSize,
                        )
                    )
                }


                VectorIcon(
                    modifier = Modifier
                        .backgroundColor(
                            if (!showCheckBox) Theme.White.rgb
                            else Theme.Transparent.rgb
                        )
                        .zIndex(4f),
                    pathData = Res.PathIcon.store,
                    width = Dimen.normalIconSize,
                    height = Dimen.normalIconSize,
                    color = Theme.GrayAlpha38.hex
                )

                SpanText(
                    text = title,
                    modifier = Modifier
                        .margin(left = Dimen.semiNormalPadding)
                        .fontWeight(FontWeight.Normal)
                        .fontFamily(FontFamily.Montserrat)
                        .fontSize(
                            getGeneralItemRatio(
                                windowSizeMode=windowSizeMode,
                                size = FontSizes.mediumFontSize,
                            )
                        )
                        .color(Theme.Primary.rgb),
                )
            }



            Spacer()

            Row(
                modifier = Modifier
                    .align(Alignment.CenterEnd),
                verticalAlignment = Alignment.CenterVertically,

                )
            {
                SpanText(
                    text =ratioChecked ,
                    modifier = Modifier
                        .cursor(Cursor.Pointer)
                        .margin(leftRight = Dimen.smallPadding)
                        .fontWeight(FontWeight.Medium)
                        .fontFamily(FontFamily.Montserrat)
                        .fontSize(FontSizes.normalFontSize)
                        .color(Theme.Primary.rgb),
                )


                VectorIcon(
                    modifier = Modifier,
                    width = Dimen.mediumIconSize,
                    height = Dimen.mediumIconSize,
                    pathData = getArrowImg(expanded = isExpanded.value),
                    color = Theme.GrayAlpha56.hex
                )
            }



        }

        if (isExpanded.value){
            Column(
                modifier = Modifier.fillMaxWidth() ,
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                content()
            }
        }


    }


}
