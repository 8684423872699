package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_flow.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.cash_flow.CreditCardsInfoEnum
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.MediumContentTitle

@Composable
fun CreditCards(
    creditCards:List<String?>?,
    windowSizeMode: WindowSizeModeEnum,
    updateCreditCards:(List<String?>)->Unit,
)
{
    val data = creditCards?.toMutableList()
    Column()
    {
        MediumContentTitle(
            title = Strings.creditCard,
            marginTop = Dimen.mediumPadding,
            marginBottom = Dimen.mediumPadding,
        )

        CreditCardsInfoEnum.entries
            .windowed(size = 2, step = 2).forEachIndexed {  rowIndex, rowItems ->
            Row{
               rowItems.forEachIndexed{colIndex, creditCardInfo ->
                   val index = rowIndex * 2 + colIndex
                   CustomEditText(
                       modifier = Modifier,
                       width = if (windowSizeMode==WindowSizeModeEnum.PHONE) Dimen.normalEditTextWidth
                        else Dimen.mediumEditTextWidth
                       ,
                       label = creditCardInfo.titleShown,
                       value = creditCards?.getOrNull(index) ?: "",
                       isRequired = false,
                       defaultBorderColor = Theme.GrayCD.rgb,
                       hint ="",
                       isOutlinedEditText = true,
                       onFocusIn = {},
                       onFocusOut = {
                          data?.set(index = index, element = it)
                          updateCreditCards(
                              data?.toList()?: listOf()
                          )
                       },
                       onInputChange = {

                       },
                   )

                   Box(
                       modifier = Modifier
                           .width(Dimen.mediumPadding)
                   )


               }
            }
        }

    }
}