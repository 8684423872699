package org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_product_general

import com.varabyte.kobweb.browser.http.http
import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.delay
import kotlinx.coroutines.withContext
import kotlinx.coroutines.withTimeout
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.GetDataAssistantMessageHistoryRepository
import org.hs_soft.runmynesto.domain.util.ApiPath
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.Constants
import kotlin.time.Duration.Companion.seconds

class GetDataAssistantMessageHistoryRepositoryImpl(
    private val requestManager: RequestManager
) : GetDataAssistantMessageHistoryRepository {

//    private val abortController = requestManager.createController()

    override suspend fun getDataAssistantMessageHistory(email: String): String {

        return withContext(Dispatchers.Default) {
            retry(times = 3, initialDelay = 2.seconds) {
                withTimeout(Constants.timeout.seconds) {
                    fetchMessageHistory(email)
                }
            }
        }
    }

    private suspend fun fetchMessageHistory(email: String): String {
        val abortController = requestManager.createController()

        val headers = mapOf(
            "Content-Type" to "application/json",
            "x-api-key" to ApiUtils.API_KEY.RESTAURANTS_API_KEY_VALUE,
        )

        val result = window.http.post(
            resource = ApiUtils.Domain.dataAssistantDomain +
                    ApiPath.getDataAssistantMessageHistory +
                    "?${ApiUtils.Field.email}=$email",
            headers = headers,
            abortController = abortController,
        ).decodeToString()

        return result
    }


    private suspend fun <T> retry(
        times: Int,
        initialDelay: kotlin.time.Duration,
        factor: Double = 2.0,
        block: suspend () -> T
    ): T {
        var currentDelay = initialDelay
        repeat(times - 1) {
            try {
                return block()
            } catch (e: Exception) {
                // You can add logging here
            }
            delay(currentDelay)
            currentDelay *= factor
        }
        return block() // last attempt
    }
}