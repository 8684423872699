package org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.product_general_tab.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import org.hs_soft.runmynesto.domain.config.*
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.VectorIcon


@Composable
fun ProductTabDataAssistantContent(
    onChatButtonClick: () -> Unit
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .onClick {
                onChatButtonClick()
            },
        horizontalArrangement = Arrangement.End,
        ) {
        VectorIcon(
            modifier = Modifier.align(Alignment.CenterVertically),
            pathData = Res.PathIcon.dataAssistant,
            width= Dimen.dataAssistantButtonSize,
            height= Dimen.dataAssistantButtonSize,
            //color = Theme.White.hex,
        )
    }
}


