package org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.restaurant_project


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class RestaurantProjectModel(
    @SerialName("data")
    val `data`: Data?
)