package org.hs_soft.runmynesto.domain.usecase.options.restaurant_manager

import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.update_furniture.UpdateFurnitureModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.UpdateFurnitureRepository

class UpdateFurnitureUseCase(
    private val repository: UpdateFurnitureRepository,
) {
    suspend operator fun invoke(
        id: String,
        height: Int,
        positionLeft: Int,
        positionTop: Int,
        rotationDeg: Int,
        width: Int
    ): UpdateFurnitureModel {
        return repository.updateFurniture(
            id=id,
            height=height,
            positionLeft=positionLeft,
            positionTop=positionTop,
            rotationDeg=rotationDeg,
            width=width,
        )
    }
}