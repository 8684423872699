package org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.update_floor


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("__typename")
    val typename: String?,
    @SerialName("updateFloor")
    val updateFloor: List<UpdateFloor?>?
)