package org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.allergens_tab.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.LetterSpacing
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.getAllergensWidth
import org.hs_soft.runmynesto.domain.util.getGeneralItemRatio
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px

@Composable
fun AllergensHeader(
    windowSizeMode: WindowSizeModeEnum,
    numberAscending: Boolean,
    showNumberSortIcon: Boolean,
    onNumberSortClick: () -> Unit,
    onContainSortClick: () -> Unit,
    nameAscending: Boolean,
    showNameSortIcon: Boolean,
    onNameSortClick: () -> Unit,
)
{
    Row(
        modifier = Modifier
            .width(
                getAllergensWidth(
                    windowSizeMode=windowSizeMode,
                )
            )
            .height(Dimen.gridHeaderHeight)
            .borderRadius(
                topRight = Dimen.lowBorderRadius,
                topLeft = Dimen.lowBorderRadius,
            )
            .borderBottom(
                width = Dimen.smallThickness,
                color = Theme.GrayCD.rgb,
                style = LineStyle.Solid
            )
            .backgroundColor(Theme.GrayAlpha04.rgb)
            .padding(leftRight = Dimen.normalPadding),
        verticalAlignment = Alignment.CenterVertically
    )
    {
        Row(
            modifier = Modifier
                .width(
                    getGeneralItemRatio(
                        windowSizeMode=windowSizeMode,
                        (Dimen.allergensHeaderItemWidth.value.toInt()/2).px
                    )

                )
                .onClick {
                    onNumberSortClick()
                }
            , verticalAlignment = Alignment.CenterVertically
        )
        {
            SpanText(
                text = Strings.number,
                modifier = Modifier
                    .cursor(Cursor.Pointer)
                    .width( Dimen.gridHeaderNumberWidth)
                    .fontSize(FontSizes.mediumFontSize)
                    .lineHeight(FontLineHeight.lineMedium)
                    .letterSpacing(LetterSpacing.semiMedium)
                    .fontFamily(FontFamily.Montserrat)
                    .color(Theme.Secondary.rgb)
            )

            VectorIcon(
                modifier = Modifier.padding(Dimen.smallPadding),
                pathData = if(numberAscending)
                    Res.PathIcon.sortDesc
                else Res.PathIcon.sortAsc
                ,
                height = Dimen.mediumIconSize,
                width = Dimen.mediumIconSize,
                color = Theme.GrayAlpha56.hex,
                visibilityStatus = showNumberSortIcon
            )

        }
        Box(
            modifier = Modifier
                .width(Dimen.smallThickness)
                .margin(leftRight = Dimen.normalPadding)
        )

        Row(
            modifier = Modifier
                .width(
                    getGeneralItemRatio(
                        windowSizeMode=windowSizeMode,
                        (Dimen.allergensHeaderItemWidth.value.toInt()/2).px
                    )
                )
                .onClick { onContainSortClick() }
            , verticalAlignment = Alignment.CenterVertically
            , horizontalArrangement = Arrangement.Center
        )
        {
            SpanText(
                text = Strings.contain,
                modifier = Modifier
                    .cursor(Cursor.Pointer)
                    .fontSize(FontSizes.mediumFontSize  )
                    .lineHeight(FontLineHeight.lineMedium)
                    .letterSpacing(LetterSpacing.semiMedium)
                    .fontFamily(FontFamily.Montserrat)
                    .color(Theme.Primary.rgb)
            )

        }

        Box(
            modifier = Modifier
                .width(Dimen.smallThickness)
                .margin(leftRight = Dimen.normalPadding)
        )

        Row(
            modifier = Modifier
                .width(
                    getGeneralItemRatio(
                        windowSizeMode=windowSizeMode,
                        (Dimen.allergensHeaderItemWidth.value.toInt()/2).px
                    )
                )
                .onClick { onNameSortClick() }
            , verticalAlignment = Alignment.CenterVertically
        )
        {
            SpanText(
                text = Strings.name,
                modifier = Modifier
                    .cursor(Cursor.Pointer)
                    .fontSize(FontSizes.mediumFontSize)
                    .lineHeight(FontLineHeight.lineMedium)
                    .letterSpacing(LetterSpacing.semiMedium)
                    .fontFamily(FontFamily.Montserrat)
                    .color(Theme.Primary.rgb)
            )

            VectorIcon(
                modifier = Modifier.padding(Dimen.smallPadding),
                pathData = if(nameAscending)
                    Res.PathIcon.sortDesc
                else Res.PathIcon.sortAsc,
                height = Dimen.mediumIconSize,
                width = Dimen.mediumIconSize,
                color = Theme.GrayAlpha56.hex,
                visibilityStatus = showNameSortIcon,
            )






        }




    }

}