package org.hs_soft.runmynesto.pages.home.sub_page.dashboard.mvi

import org.hs_soft.runmynesto.domain.model.home.dashboard.customer_frequency.GetCustomerFrequency
import org.hs_soft.runmynesto.domain.model.home.dashboard.dashboard_config.ListShopItem
import org.hs_soft.runmynesto.domain.model.home.dashboard.get_shop_statics.ShopStaticItem
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.YearModel
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.getYearsData

data class DashboardState(
    val calendarData: YearModel = getYearsData(
        startYear = Constants.startYear,
        endYear = Constants.endYear
    ),
    val today:MonthModel=MonthModel(),
    val dataLoadingProgressVisible:Boolean=false,
    val selectedDate:MonthModel= MonthModel(),
    val comparedDate:MonthModel=MonthModel(),
    val showCalendarDialog:Boolean=false,
    val listShop: List<ListShopItem?>?= null,
    val requestId:String?=null,
    val shopStaticsData: List<ShopStaticItem>? =null,
    val showCompareChartDialog:Boolean=false,
    val selectedCompanyProfile:String?=null,
    val showDashboardDetail:Boolean=false,
    val customerFrequencies:List<GetCustomerFrequency?>?=null,
    val comparedCustomerFrequencies:List<GetCustomerFrequency?>?=null,
    val selectedShopId:String?=null,
    val timeType:String?=null,
    val currencyType:String?=null,
    val selectedDashboardItem:ShopStaticItem?=null,
    val dashboardItemName:String?=null,
)