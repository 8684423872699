package org.hs_soft.runmynesto.data.api_datasource.options.restaurant_manager

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.delete_floor.DeleteRestaurantFloorModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.restaurant_manager.DeleteFloorRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class DeleteFloorRepositoryImpl(
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): DeleteFloorRepository
{
    override suspend fun deleteFloor(floorId: String): DeleteRestaurantFloorModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put("query", """
              mutation deleteFloor(${'$'}id: ID!) {
                cashAssist {
                  deleteFloor(id:${'$'}id)
                  __typename
                }
              }

            """.trimIndent())

            putJsonObject("variables") {
                put("id",floorId)
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }


    }


}