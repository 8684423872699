package org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.create_furniture


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class Data(
    @SerialName("cashAssist")
    val cashAssist: CashAssist?
)