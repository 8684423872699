package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.update_media_img


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("img")
    val img: Img?,
    @SerialName("__typename")
    val typename: String?
)