package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.cash_register_configuration.tabs.devices.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.home.options.cash_register_config.devices.CafeModeEnum
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.DropDownItem
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.jetbrains.compose.web.dom.Text

@Composable
fun VatConfigContent(
    normalVat: Double?,
    reducedVat: Double?,
    specialVat: Double?,
    isItCafeMode: Int?,
    updateData:(String,dynamic)->Unit
)
{

    Column(
        modifier = Modifier
            .margin(top = Dimen.normalPadding)
    )
    {
        MediumContentTitle(
            title = Strings.vat,
        )


        CustomEditText(
            label = Strings.normalVat,
            isDecimal = true,
            value = normalVat?.toString() ?: "",
            marginBottom = Dimen.smallPadding,
            width = Dimen.semiMediumEditTextWidth,
            isOutlinedEditText = true,
            textAlign = TextAlign.End,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint = "",
            onFocusIn = {},
            onFocusOut = {
                 updateData(ApiUtils.Field.normalVat,it.toDouble())
            },
            onInputChange = {

            },
        )
        CustomEditText(
            isDecimal = true,
            width = Dimen.semiMediumEditTextWidth,
            label = Strings.reducedVat,
            value = reducedVat?.toString()?:"",
            isOutlinedEditText = true,
            marginBottom = Dimen.smallPadding,
            marginTop = Dimen.normalPadding,
            textAlign = TextAlign.End,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint = "",
            onFocusIn = {},
            onFocusOut = {
                updateData(ApiUtils.Field.reducedVat,it.toDouble())
            },
            onInputChange = {

            },
        )
        CustomEditText(
            isDecimal = true,
            label = Strings.specialVat,
            value = specialVat?.toString()?:"",
            isOutlinedEditText = true,
            width = Dimen.semiMediumEditTextWidth,
            textAlign = TextAlign.End,
            isRequired = false,
            defaultBorderColor = Theme.GrayCD.rgb,
            hint ="",
            onFocusIn = {},
            onFocusOut = {
                updateData(ApiUtils.Field.specialVat,it.toDouble())
            },
            onInputChange = {

            },
        )



        DropDownItem(
            modifier = Modifier,
            itemWidth = Dimen.semiMediumEditTextWidth,
            options = CafeModeEnum.entries,
            label = Strings.inTheHouse,
            selectedItem = CafeModeEnum.entries.find {
                it.value == isItCafeMode
            },
            content = {
                if (it is CafeModeEnum)
                    Text(it.title)
            },
            onOptionClick = {
                val option: CafeModeEnum? = CafeModeEnum.entries.find { item ->
                    item.toString() == it
                }

                if (option != null) {
                    updateData(ApiUtils.Field.isInCafeModeInt, option.value)
                }
            }
        )




    }

}