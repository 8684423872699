package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.restaurant_manager.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.draggable
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onMouseDown
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseMove
import com.varabyte.kobweb.compose.ui.modifiers.onMouseUp
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.size
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.HorizontalScrollBehavior
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.borderSetting
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.ObjectKinds
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.restaurant_project.Floor
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.restaurant_project.RestaurantProject
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.restaurant_project.Room
import org.hs_soft.runmynesto.pages.components.DropDownItem
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.virtual_card.component.BoxColor
import org.jetbrains.compose.web.attributes.Draggable
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.backgroundImage
import org.jetbrains.compose.web.css.backgroundPosition
import org.jetbrains.compose.web.css.backgroundRepeat
import org.jetbrains.compose.web.css.backgroundSize
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.left
import org.jetbrains.compose.web.css.position
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.top
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.Text


@Composable
fun RestaurantDesign(
    selectedRestaurantProject: RestaurantProject?,
    selectedRoom:Room?,
    onMoveFurniture:(String,Int,Int,Int,Int,Int)->Unit,
)
{
    Column(
        modifier = Modifier.fillMaxWidth()
    )
    {
        RestaurantDesignHeaders(
            selectedRestaurantProject=selectedRestaurantProject,
            selectedRoom = selectedRoom,
        )

        if(selectedRoom!=null){
            DesignCoordinates(
                selectedRoom=selectedRoom,
                onMoveFurniture = {id:String,offsetX:Int,offsetY:Int,rotationDeg:Int,width:Int,height:Int->
                    onMoveFurniture(id,offsetX,offsetY,rotationDeg,width,height)
                }
            )


        }



    }


}




@Composable
fun DesignCoordinates(
    selectedRoom: Room?,
    onMoveFurniture:(String,Int,Int,Int,Int,Int)->Unit,
) {

    val gridSize = 60
    val rectSize = Dimen.designCoordinateHeight.value.toInt() / gridSize

    Box(
        modifier = HorizontalScrollBehavior.toModifier()
            .borderSetting()
            .padding(0.px)
            .fillMaxWidth()
            .height(Dimen.designCoordinateHeight)
    ) {


        Column(
            modifier = HorizontalScrollBehavior.toModifier()
                .fillMaxWidth()
        ) {
            repeat(gridSize) { rowIndex ->
                Row{
                    repeat(gridSize*3) { columnIndex ->
                        Box(
                            modifier = Modifier
                                .border(
                                    width = Dimen.smallThickness,
                                    color = Theme.White.rgb,
                                    style = LineStyle.Solid,
                                )
                                .size(rectSize.px)
                                .backgroundColor(
                                    Theme.GrayAlpha08.rgb
                                )
                        )
                    }
                }
            }
        }

//        createKonvaExample()
//
        selectedRoom?.furniture?.forEach {furniture->

            var offsetX by remember { mutableStateOf(furniture?.positionLeft?:0) }
            var offsetY by remember { mutableStateOf(furniture?.positionTop?:0) }
            var isDragging by remember { mutableStateOf(false) }
            val objectTypeImage = ObjectKinds.entries.find {
                it.value == furniture?.objectKind
            }?.img


            Box(
                modifier = Modifier
                    .draggable(Draggable.True)
                    .styleModifier {
                        position(Position.Relative)
                        left(offsetX.px)
                        top(offsetY.px)
                        furniture?.width?.px?.let { width(it) }
                        furniture?.height?.px?.let { height(it) }
                        objectTypeImage?.let { backgroundImage(it) }
                        backgroundSize("contain")
                        backgroundRepeat("no-repeat")
                        backgroundPosition("center")
                    }
                    .onMouseDown {
                        isDragging = true
                    }
                    .onMouseMove { event ->
                        if (isDragging) {
                            offsetX += event.movementX
                            offsetY += event.movementY
                        }
                    }
                    .onMouseUp {
                        isDragging=false
                        onMoveFurniture(
                            furniture?.id ?: "",
                            offsetX,
                            offsetY,
                            furniture?.rotationDeg ?: 0,
                            furniture?.width ?: 0,
                            furniture?.height ?: 0,
                        )

                    }
                    .onMouseLeave {

                    }
            )


        }





    }
}
@Composable
private fun RestaurantDesignHeaders(
    selectedRestaurantProject: RestaurantProject?,
    selectedRoom: Room?,
) {
    val newRestaurantProject= remember { mutableStateOf(selectedRestaurantProject) }
    LaunchedEffect(selectedRestaurantProject){
        newRestaurantProject.value=selectedRestaurantProject
    }

    

    Row(
        modifier = Modifier ,
        verticalAlignment = Alignment.CenterVertically,
    )
    {
        DropDownItem(
            modifier = Modifier
                .margin(
                    right = Dimen.normalPadding,
                    bottom = Dimen.largePadding,
                ),
            itemWidth = Dimen.highNormalEditTextWidth,
            options =selectedRestaurantProject?.floors
                ?.filter { it?.rooms?.isNotEmpty() == true }
                ?: listOf(),
            label = "",
            selectedItem =newRestaurantProject.value?.floors?.filter {
                  it?.rooms?.isNotEmpty()==true
            }?.get(0),
            content = {
                if (it is Floor?)
                    Text("${it.typename} ${it.number}")
            },
            onOptionClick = {
                val option=selectedRestaurantProject?.floors?.find {item->
                    item.toString()==it
                }


            }
        )

        DropDownItem(
            modifier = Modifier
                .margin(
                    leftRight = Dimen.normalPadding,
                    bottom = Dimen.largePadding,
                ),
            itemWidth = Dimen.highNormalEditTextWidth,
            options = listOf(),
            label = "",
            selectedItem = null,//selectedDisplaySize,
            content = {
//                    if (it is DisplaySizeEnum)
//                        Text(it.title)
            },
            onOptionClick = {
//                    val option=DisplaySizeEnum.entries.find {item->
//                        item.toString()==it
//                    }
//                    if (option!=null)
//                        setDisplaySize(option)

            }
        )

        BoxColor(
            modifier = Modifier
                .margin(leftRight = Dimen.normalPadding),
            title = "",
            color = selectedRoom?.roomColor,
            onColorBoxClick = {

            }
        )


        CustomButton(
            modifier = Modifier
                .margin(left = Dimen.normalPadding),
            height = Dimen.dropDownHeight,
            topBottomMargin = Dimen.normalPadding,
            title = Strings.show3D,
            leftRightMargin = 0.px,
            onButtonClick = {

            },
            defaultTextColor = Theme.White.rgb,
        )


    }
}