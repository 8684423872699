package org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.product_general_tab.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.MutableState
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.product.product_item_model.Img
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.UploadImage

@Composable
fun CashBidUploadImgContent(
    cashBidImg: Img?,
    showImgAIGeneratorDialog: () -> Unit,
    getImageUrl:(MutableState<String>,String)->Unit
) {

    val imageUrl = remember { mutableStateOf(Res.PathIcon.defaultRectPhoto) }

    LaunchedEffect(cashBidImg) {
        if (cashBidImg==null)
            imageUrl.value= Res.PathIcon.defaultRectPhoto
        else{
            val keyToUse = cashBidImg.key
            getImageUrl(
                imageUrl,
                keyToUse
            )
        }
    }

    Column(
        modifier = Modifier
            .margin(topBottom = Dimen.normalPadding)
    )
    {
        MediumContentTitle(
            title = Strings.img,
            marginTop = Dimen.mediumPadding,
            marginBottom = Dimen.mediumPadding,
            fontSize = FontSizes.extraExtraLargeFontSize,
        )

        UploadImage(
            imgUrl = imageUrl.value,
            onImgContentClick = {
                showImgAIGeneratorDialog()
            },
        )

    }
}
