package org.hs_soft.runmynesto.pages.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.transitionBorder
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.showSelectedDate
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit

@Composable
fun CalendarBox(
    modifier: Modifier = Modifier,
    selectedDate: MonthModel ? ,
    label:String,
    width: CSSSizeValue<CSSUnit.px> = Dimen.mediumEditTextWidth,
    onClick:()->Unit,
) {


    OutlinedBox(
        modifier = modifier,
        width =width,
        label = label,
        boxContent = {
            Row(
                modifier = Modifier
                    .padding(leftRight = Dimen.normalPadding)
                    .fillMaxHeight(),
                verticalAlignment = Alignment.CenterVertically
            )
            {
                VectorIcon(
                    modifier = Modifier,
                    pathData = Res.PathIcon.calendar,
                    width = Dimen.mediumIconSize,
                    height = Dimen.mediumIconSize,
                    color = Theme.Gray3E.hex
                )

                SpanText(
                    text =if (selectedDate==null) ""
                        else showSelectedDate(selectedDate = selectedDate),
                    modifier = Modifier
                        .margin(left = Dimen.normalPadding)
                        .fontSize(FontSizes.semiMediumFontSize)
                        .fontFamily(FontFamily.Montserrat)
                        .fontWeight(FontWeight.Normal)
                        .color(Theme.Primary.rgb)
                )

            }


        },
        onClick = {
            onClick()
        }
    )
}