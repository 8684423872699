package org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_product_general.chat_model

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ChatLinkMessagesModel(
    val path: String,
    @SerialName("file_format")
    val fileFormat: String
)
