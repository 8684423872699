package org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media

import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.delete_tag.DeleteTagModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.DeleteTagRepository


class DeleteTagUseCase(
    private val repository: DeleteTagRepository
) {
    suspend operator fun invoke(
        id:String,
    ): DeleteTagModel {
         return repository.deleteTag(
            id =id,
         )
    }
}