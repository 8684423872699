package org.hs_soft.runmynesto.data.api_datasource.options.kitchen_location

import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import kotlinx.serialization.json.putJsonObject
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.ApiClient
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.printer_location.PrinterLocationModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.options.kitchen_location.UpdateKitchenLocationRepository
import org.hs_soft.runmynesto.domain.util.ApiUtils

class UpdateKitchenLocationRepositoryImpl (
    private val apiClient: ApiClient,
    private val requestManager: RequestManager,
): UpdateKitchenLocationRepository
{
    override suspend fun updateKitchenLocation(printerLocation: List<String?>): PrinterLocationModel {

        val abortController = requestManager.createController()

        val graphQLQuery = buildJsonObject {
            put(
                "query", """
                    mutation updateAppConfig(${'$'}fields: AppConfigInput) {
                      cashAssist {
                        updateAppConfig(fields:${'$'}fields) {
                         ...printerLocations
                          ${ApiUtils.Field.__typename}
                        }
                        ${ApiUtils.Field.__typename}
                      }
                    }
                    
                    fragment printerLocations on AppConfig {
                      cashRegisterGlobal {
                        printerLocations {
                          ${ApiUtils.Field.dbIndex}
                          ${ApiUtils.Field.value}
                          ${ApiUtils.Field.__typename}
                        }
                        ${ApiUtils.Field.__typename}
                      }
                      ${ApiUtils.Field.__typename}
                    }
            """.trimIndent()
            )

            putJsonObject("variables") {
                putJsonObject("fields") {
                    putJsonObject("cashRegisterGlobal") {
                        put("printerLocations", Json.encodeToJsonElement(printerLocation))
                    }
                }
            }
        }

        val result = apiClient.requestResult(
            graphQLQuery = graphQLQuery,
            resource = ApiUtils.Cognito.API_URL,
            abortController = abortController,
        )

        return if (result != null) {
            Json.decodeFromString<PrinterLocationModel>(result.decodeToString())
        } else {
            throw Exception(Strings.timeoutErr)

        }
    }

}
