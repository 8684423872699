package org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.restaurant_tab

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.product.product_item_model.GetProduct
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.kitchen_info.ProductAdditions
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.printer_location.PrinterLocation
import org.hs_soft.runmynesto.domain.model.home.product.tabs.tab_restaurant.restaurant_data.GetRestaurant
import org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.restaurant_tab.component.KitchenGeneral
import org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.restaurant_tab.component.KitchenInformation
import org.hs_soft.runmynesto.pages.home.sub_page.product.component.tabs.restaurant_tab.component.KitchenLocations
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun RestaurantTabContent(
    selectedProduct: GetProduct?,
    kitchenInfo: List<ProductAdditions?>?,
    windowSizeMode: WindowSizeModeEnum,
    tempItemsDescendingSort:Boolean?,
    updatePrintOnlyInCafe: (Boolean) -> Unit,
    updateIsAdditionRequired: (Boolean) -> Unit,
    restaurantData: GetRestaurant?,
    getRestaurantData: () -> Unit,
    getPointerLocations: () -> Unit,
    printerLocation: List<PrinterLocation?>?,
    updatePrinterLocation: (List<Boolean?>?) -> Unit,
    getKitchenInfo:()->Unit,
    onSearchKitchenInfo:(String)->Unit,
    onKitchenInfoTitleSortClick:()->Unit,
    onRefreshKitchenInfoClick:()->Unit,
    onAddClick:()->Unit,
    onKitchenInfoItemClick:(ProductAdditions?)->Unit,
    activeKitchenInfo:(String)->Unit,
    deActiveKitchenInfo:(String)->Unit,
)
{
    val printerLocationArray =restaurantData?.printerLocations
        ?.let { ArrayList(it) }


    LaunchedEffect(selectedProduct?.id){
        console.log(selectedProduct?.id)
        getRestaurantData()
        getPointerLocations()
    }
    LaunchedEffect(Unit){
        getKitchenInfo()
    }



    Column(
        Modifier
            .fillMaxWidth()
            .fillMaxHeight()
            .padding(Dimen.normalPadding)
            .borderRadius(Dimen.lowBorderRadius)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            )
    )
    {

        KitchenGeneral(
            restaurantData=restaurantData,
            windowSizeMode=windowSizeMode,
            updatePrintOnlyInCafe={
                updatePrintOnlyInCafe(it)
            },
            updateIsAdditionRequired={
                updateIsAdditionRequired(it)
            }
        )

        KitchenLocations(
            windowSizeMode=windowSizeMode,
            printerLocation=printerLocation,
            restaurantData=restaurantData,
            printerLocationArray=printerLocationArray,
            updatePrinterLocation={
                updatePrinterLocation(it)
            },
        )
        Spacer()

        KitchenInformation(
            windowSizeMode=windowSizeMode,
            kitchenInfoItems=kitchenInfo,
            descending = tempItemsDescendingSort,
            listAddition=restaurantData?.additions,
            onKitchenInfoTitleSortClick = {
                onKitchenInfoTitleSortClick()
            },
            onSearchKitchenInfo = {
                onSearchKitchenInfo(it)
            },
            onRefreshClick = {
                onRefreshKitchenInfoClick()
            },
            activeKitchenInfo = {
                activeKitchenInfo(it)
            },
            onKitchenInfoItemClick = {
                onKitchenInfoItemClick(it)
            },
            onAddClick = {
                onAddClick()
            },
            deActivateKitchenInfo = {
                deActiveKitchenInfo(it)
            }
        )


    }

}



