package org.hs_soft.runmynesto.pages.home.sub_page.template.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.dialog.dialog.AddRecordDialog
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.color_pallet.ColorPallet
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.date_picker.DatePickerDialog
import org.hs_soft.runmynesto.pages.components.dialog.dialog.ImageAIDialog
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.AddProductToArticleDialog
import org.hs_soft.runmynesto.pages.home.sub_page.template.mvi.TemplateEvent
import org.hs_soft.runmynesto.pages.home.sub_page.template.mvi.TemplateState

@Composable
fun TemplateDialogs(
    state: TemplateState,
    windowSizeMode: WindowSizeModeEnum,
    event: (TemplateEvent) -> Unit
) {
    if (state.showAddRecordDialog) {
        AddRecordDialog(
            windowSizeMode = windowSizeMode,
            dialogTitle = Strings.addNewCategory,
            nameFieldTitle = Strings.name,
            numberFieldTitle = Strings.number,
            nameValue = state.newRecordName,
            showNumberField = false ,
            isSubmitClickable = state.isAddNewRecordSubmitClickable,
            loading = state.addRecordLoading,
            errorMessage = state.addRecordErrorMessage,
            onNameValueChange = {
                event(TemplateEvent.OnChangeRecordName(it))
            },
            onCancelClick = {
                event(TemplateEvent.OnCancelRecordDialogClick)
            },
            onSubmitClick = {
                event(TemplateEvent.OnSubmitRecordDialogClick(windowSizeMode))
            },
            onBackgroundClick = {
                event(TemplateEvent.OnAddRecordDialogBackgroundClick)
            }
        )
    }

    if (state.showCalendar) {

        DatePickerDialog(
            selectedDay = state.selectedDate,
            modifier = Modifier,
            calendarData = state.calendarData,
            onSelectDay = {
                event(TemplateEvent.OnSelectDate(it))
            },
            onBackgroundClick = {
                event(TemplateEvent.SetCalendarStatus(false))
            },
            closeCalendarDialog = {
                event(TemplateEvent.SetCalendarStatus(false))
            }
        )
    }

    if (state.showColorPalletDialog){
        ColorPallet(
            onColorClick = {
                event(TemplateEvent.OnPalletColorClick(it))
            },
            onBackgroundClick = {
                event(TemplateEvent.SetColorPalletStatus(false))
            }
        )
    }

    if (state.showGenerateAIImgDialog)
    {
        ImageAIDialog(
            windowSizeMode=windowSizeMode,
            isSubmitClickable = state.imageAiGeneratorDescription.isNotEmpty()
                    && state.imageAiGeneratorUrl!= Res.PathIcon.defaultRectPhoto,
            id = state.selectedCategory?.id?:"",
            description = state.imageAiGeneratorDescription,
            imgGeneratedUrl = state.imageAiGeneratorUrl,
            loading = state.isImageAiGeneratorLoading,
            onDescriptionInputChange = {
                 event(TemplateEvent.OnChangeAiImageDescription(it))
            },
            onBackgroundClick = {},
            onCancelClick = {
                event(TemplateEvent.SetGenerateAIImgDialogStatus(false))
            },
            onSubmitClick = {id,imgAiGeneratorUrl->
                event(TemplateEvent.OnSubmitNewImage(
                    id=id,
                    url=imgAiGeneratorUrl,
                ))
            },
            onGenerateImgClick={desc->
                event(TemplateEvent.GenerateTemplateImg(
                    description=desc,
                ))
            },
            onSelectImg = {url:String->
                event(
                    TemplateEvent.SetLocalCategoryImg(
                       url = url,
                ))
            }

        )
    }

    if (state.showArticleListDialog){

        AddProductToArticleDialog(
            errMsg = state.addNewProductToArticleErrMsg,
            searchedProductItems=state.searchedProducts,
            onBackgroundClick = {
                event(TemplateEvent.OnAddProductToArticleDialogBackgroundClick)
            },
            onItemClick = {
                event(TemplateEvent.AddNewProductToArticleList(it))
            }
        )
    }


}