package org.hs_soft.runmynesto.pages.home.sub_page.media_center.component.media_center_content.tabs.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.DisplaySizeEnum
import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_detail.WithProduct
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.IdUtils
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.openLocalForUniqueSelectingFile
import org.hs_soft.runmynesto.pages.components.DropDownItem
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.UploadImage
import org.jetbrains.compose.web.dom.Text

@Composable
fun Advertising(
    windowSizeMode: WindowSizeModeEnum,
    mediaImg169: String?,
    mediaImg: String?,
    selectedDisplaySize: DisplaySizeEnum,
    selectedArticles: List<WithProduct>?,
    updateMediaBase64Img: (String, String) -> Unit,
    setDisplaySize: (DisplaySizeEnum) -> Unit,
    showArticleDialog: () -> Unit,
    deleteArticle:(String)->Unit,
) {
    Column(
        modifier = Modifier
            .margin(right = Dimen.normalPadding)
    )
    {
        MediumContentTitle(
            title = Strings.fullDisplayAdvertising
        )

        DropDownItem(
            modifier = Modifier,
            itemWidth = Dimen.semiLargeEditTextWidth,
            options = DisplaySizeEnum.entries,
            label = "",
            selectedItem = selectedDisplaySize,
            content = {
                if (it is DisplaySizeEnum)
                    Text(it.title)
            },
            onOptionClick = {
                val option=DisplaySizeEnum.entries.find {item->
                    item.toString()==it
                }
                if (option!=null)
                    setDisplaySize(option)

            }
        )

        UploadImage(
            height =if (selectedDisplaySize==DisplaySizeEnum.STANDARD)
                Dimen.selectRectPhoneHeight else Dimen.selectRectPhoneWidth ,
            modifier = Modifier
                .margin(top = Dimen.mediumPadding),
            imgUrl = mediaImg169 ?: Res.PathIcon.defaultRectPhoto,
            setImageDirectlyStatus = true,
            imgId = IdUtils.fullDisplayAdvertising,
            onImgContentClick = {
                openLocalForUniqueSelectingFile(IdUtils.fullDisplayAdvertising)
            },
            setImageDirectly = { file ->
                updateMediaBase64Img(
                    file,
                    ApiUtils.Type.Media9_16
                )
            }
        )

    }

    Column(
        modifier = Modifier
            .margin(right = Dimen.normalPadding)
    )
    {
        MediumContentTitle(
            title = Strings.advertisingDuringTyping,
            marginBottom = Dimen.normalPadding
        )

        UploadImage(
            imgUrl = mediaImg ?: Res.PathIcon.defaultRectPhoto,
            setImageDirectlyStatus = true,
            imgId = IdUtils.advertisingDuringTyping,
            onImgContentClick = {
                openLocalForUniqueSelectingFile(IdUtils.advertisingDuringTyping)
            },
            setImageDirectly = { file ->
                updateMediaBase64Img(
                    file,
                    ApiUtils.Type.Media
                )
            }
        )


    }

    Column()
    {
        MediumContentTitle(
            title = Strings.chooseAnArticle,
        )

        ChooseAnArticle(
            selectedArticles = selectedArticles,
            windowSizeMode = windowSizeMode,
            onBoxClick = {
                showArticleDialog()
            },
            deleteArticle = {
                deleteArticle(it)
            }
        )
    }

}

