package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.model.home.customer_card.tabs.card.PeriodDaysEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.formatDate
import org.hs_soft.runmynesto.pages.components.FilterComponent
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.days_filter_dialog.ClosingReportsDaysFilterDialog
import org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.reports_table.component.calendar_filter_dialog.ClosingReportsCalendarDialog
import org.jetbrains.compose.web.css.LineStyle


@Composable
fun ReportsTableFilterOptions(
    selectedPeriodType: PeriodDaysEnum?,
    showDaysFilterDialog: Boolean,
    showCalendarFilterDialog:Boolean,
    selectedFrom:String,
    selectedCalendarHeaderYear:Int,
    selectedTo:String,
    selectedDate:MonthModel?,
    setDaysFilterDialogStatus: (Boolean) -> Unit,
    setCalendarFilterDialogStatus:(Boolean)->Unit,
    submitFilterItemsBasedOnDays:(String, String, PeriodDaysEnum)->Unit,
    onSelectDate:(MonthModel)->Unit,
    filterDate:(String,String)->Unit,
    closeCalendarDialog:()->Unit,
    setNewCalendarHeaderYear:(Int)->Unit,
) {
    Column(
        modifier = Modifier
            .height(Dimen.mediumOutlinedEditTextHeight)
            .margin(bottom = Dimen.mediumPadding)
            .fillMaxWidth(),
    )
    {
        Row(
            modifier = Modifier.fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        )
        {
            FilterComponent(
                title = Strings.filter,
                onFilterClick = {
                    setDaysFilterDialogStatus(!showDaysFilterDialog)
                }
            )


            DateFilterComponent(
                modifier = Modifier.weight(1f),
                from=selectedFrom,
                to=selectedTo,
                onDateFilterClick={
                    setCalendarFilterDialogStatus(!showCalendarFilterDialog)
                }
            )
        }

        Box(
            modifier = Modifier.fillMaxWidth()
        )
        {
            if (showDaysFilterDialog){
                ClosingReportsDaysFilterDialog(
                    modifier = Modifier.align(Alignment.CenterStart),
                    selectedPeriodType =selectedPeriodType?:PeriodDaysEnum.TODAY,
                    onCloseFilterClick = {
                        setDaysFilterDialogStatus(false)
                    },
                    submitFilterItems = {
                        submitFilterItemsBasedOnDays(
                            it.from,
                            it.to,
                            it
                        )
                    }
                )
            }

            if (showCalendarFilterDialog) {
                ClosingReportsCalendarDialog(
                    modifier = Modifier
                        .align(Alignment.CenterEnd),
                    selectedDate = selectedDate,
                    selectedFrom =selectedFrom ,
                    selectedTo = selectedTo,
                    selectedCalendarHeaderYear =selectedCalendarHeaderYear ,
                    onSelectDate = {
                        onSelectDate(it)
                    },
                    filterDate = {from:String,to:String->
                        filterDate(from,to)
                    },
                    closeCalendarDialog = {
                        closeCalendarDialog()
                    },
                    setNewCalendarHeaderYear = {
                        setNewCalendarHeaderYear(it)
                    }
                )
            }
        }




    }
}

 

@Composable
private fun DateFilterComponent(
    modifier: Modifier = Modifier,
    onDateFilterClick: () -> Unit,
    from: String,
    to: String,
) {
    Row(
        modifier = modifier
            .cursor(Cursor.Pointer)
            .height(Dimen.mediumOutlinedEditTextHeight)
            .padding(
                topBottom = Dimen.normalPadding
            )
            .margin(left = Dimen.mediumPadding)
            .borderRadius(Dimen.lowBorderRadius)
            .rippleEffect {
                onDateFilterClick()
            }
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayCD.rgb
            ),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Center

    )
    {
        SpanText(
            text = if (from.isNotEmpty()) "${formatDate(from)} - ${formatDate(to)}"
                else "",
            modifier = Modifier
                .cursor(Cursor.Pointer)
                .margin(right = Dimen.smallPadding)
                .fontSize(FontSizes.mediumFontSize)
                .color(Theme.Secondary.rgb)
                .fontWeight(FontWeight.Medium)
                .fontFamily(FontFamily.Montserrat)
        )

        VectorIcon(
            pathData = Res.PathIcon.calendar2,
            color = Theme.GrayAlpha56.hex,
            width = Dimen.normalIconSize,
            height = Dimen.normalIconSize
        )


    }
}


