package org.hs_soft.runmynesto.pages.home.sub_page.statistic.component.tabs.bill_search.components

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.HorizontalScrollBehavior
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.BillSearchTableHeadersEnum
import org.hs_soft.runmynesto.domain.model.home.statistic.tabs.search_bill.statistic_data.BillSearchStatisticDataItem
import org.hs_soft.runmynesto.domain.util.extractDate
import org.hs_soft.runmynesto.domain.util.extractTime
import org.hs_soft.runmynesto.domain.util.formatToTwoDecimals
import org.hs_soft.runmynesto.pages.components.HeaderTitle
import org.hs_soft.runmynesto.pages.components.InfiniteScrollGrid
import org.hs_soft.runmynesto.pages.components.TableItemRow
import org.hs_soft.runmynesto.pages.components.grid_function.GridFooter
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun BillSearchTable(
    windowSizeMode: WindowSizeModeEnum,
    statisticData: List<BillSearchStatisticDataItem>?,
    onItemClick: (BillSearchStatisticDataItem) -> Unit,
    selectedBillSearchItem: BillSearchStatisticDataItem?,
    getBillPdf:(String,String,String)->Unit,
)
{



    Column(
        modifier = Modifier
            .fillMaxWidth()
            .fillMaxHeight()
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayAlpha12.rgb
            )
            .borderRadius(Dimen.lowBorderRadius)
    )
    {

        Column(
            modifier = HorizontalScrollBehavior.toModifier()
                .fillMaxWidth()
        )
        {
            BillSearchTableHeaders(
                windowSizeMode=windowSizeMode,
            )


            Column(
                modifier = VerticalScrollBehavior
                    .toModifier()
                    .fillMaxWidth()
                    .thenIf(
                        windowSizeMode!= WindowSizeModeEnum.WINDOWS
                                || statisticData.isNullOrEmpty(),
                        VerticalScrollBehavior
                            .toModifier().width(Dimen.billSearchPhoneTabletWidth)
                    )
                    .height(Dimen.closingReportsTableHeight)
            ){
                if (!statisticData.isNullOrEmpty()){
                    InfiniteScrollGrid(
                        items = statisticData,
                        onLoadMore = {}
                    ){
                        val model= statisticData[it]
                        BillSearchTableItem(
                            model = model,

                            isSelected = selectedBillSearchItem?.id==model.id,
                            onItemClick = {id->
                                onItemClick(model)
                            },
                            getBillPdf = {number:String,dateTime:String,cashRegisterId:String->
                                getBillPdf(
                                    number,
                                    dateTime,
                                    cashRegisterId,
                                )
                            }
                        )
                    }
                }
            }

        }


        Spacer()

        GridFooter(
            title = Strings.items,
            width = null,
            dataSize =statisticData?.size?:0,
            onRefreshClick = {

            }
        )





    }

}

@Composable
private fun BillSearchTableHeaders(
    windowSizeMode: WindowSizeModeEnum
) {
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .thenIf(
                windowSizeMode!=WindowSizeModeEnum.WINDOWS,
                Modifier.width(Dimen.billSearchPhoneTabletWidth)
            )
            .backgroundColor(Theme.GrayAlpha04.rgb)
            .padding(
                leftRight = Dimen.normalPadding,
                topBottom = Dimen.smallPadding,
            )
            .borderBottom(
                width = Dimen.smallThickness,
                color = Theme.GrayCD.rgb,
                style = LineStyle.Solid,
            )
    )
    {
        BillSearchTableHeadersEnum.entries.forEach {
            HeaderTitle(
                title = it.title,
                fillMaxWidth = true,
                arrangement = it.arrangement
            )
        }

    }
}


@Composable
fun BillSearchTableItem(
    model: BillSearchStatisticDataItem,
    isSelected:Boolean,
    onItemClick: (String) -> Unit,
    getBillPdf:(String,String,String)->Unit,
)
{
    TableItemRow(
        id = model.id?:"",
        isSelected =isSelected,
        onItemClick = {
            onItemClick(it)
        }
    ){
        HeaderTitle(
            title = extractDate(model.dateTime?:""),
            fillMaxWidth = true,
            arrangement = BillSearchTableHeadersEnum.DATE.arrangement
        )
        HeaderTitle(
            title = extractTime(model.dateTime?:""),
            fillMaxWidth = true,
            arrangement = BillSearchTableHeadersEnum.TIME.arrangement
        )
        HeaderTitle(
            title = "PDF",
            onTitleClick = {
               event: SyntheticMouseEvent -> event.stopPropagation()
               getBillPdf(
                   model.number?:"",
                   model.dateTime?:"",
                   model.cashRegisterId?:"",
               )
            },
            fillMaxWidth = true,
            arrangement = BillSearchTableHeadersEnum.PDF.arrangement
        )
        HeaderTitle(
            title = model.cashRegisterName,
            fillMaxWidth = true,
            arrangement = BillSearchTableHeadersEnum.DEVICES.arrangement,
        )
        HeaderTitle(
            title = model.number.toString(),
            fillMaxWidth = true,
            arrangement = BillSearchTableHeadersEnum.NUMBER.arrangement
        )
        HeaderTitle(
            title = model.userName,
            fillMaxWidth = true,
            arrangement = BillSearchTableHeadersEnum.USER_NAME.arrangement
        )

        HeaderTitle(
            title = if (model.isCancelled==true) Strings.cancelled else
                    Strings.notCancelled,
            fillMaxWidth = true,
            arrangement = BillSearchTableHeadersEnum.CANCELLATION.arrangement
        )

        HeaderTitle(
            title = formatToTwoDecimals(model.total),
            fillMaxWidth = true,
            arrangement = BillSearchTableHeadersEnum.TOTAL.arrangement
        )
    }
}