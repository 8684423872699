package org.hs_soft.runmynesto.pages.components.top_toolbar.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.letterSpacing
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontLineHeight
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.LetterSpacing
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.pages.components.MenuVectorIcon

@Composable
fun TopToolbarPageInfo(
    iconPath: String,
    title: String,
    windowSizeMode: WindowSizeModeEnum
) {
    Row(
        modifier = Modifier
            .padding(left = Dimen.mediumPadding),
        verticalAlignment = Alignment.CenterVertically,
    )
    {
        MenuVectorIcon(
            modifier = Modifier
                .margin(
                    right = Dimen.normalPadding,
                ),
            pathData = iconPath,
            color = Theme.Blue.hex
        )


        SpanText(
            text = title,
            modifier = Modifier
                .fontSize(
                    if (windowSizeMode != WindowSizeModeEnum.PHONE)
                        FontSizes.semiExtraLargeFontSize
                    else FontSizes.largeFontSize
                )
                .fontWeight(FontWeight.Medium)
                .lineHeight(FontLineHeight.lineLarge)
                .letterSpacing(LetterSpacing.large)
                .color(Theme.Gray3E.rgb)
                .fontFamily(FontFamily.Montserrat)
        )
    }
}
