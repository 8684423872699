package org.hs_soft.runmynesto.domain.usecase.media_center.tabs.media

import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_detail.MediaDetailModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.UpdateMediaRepository

class UpdateMediaUseCase(
    private val repository: UpdateMediaRepository
) {
    suspend operator fun invoke(
        id:String,
        dataTitle:String,
        dataValue:dynamic,
        updateMediaType:String,
    ): MediaDetailModel {
        return repository.updateMedia(
            id=id,
            dataTitle=dataTitle,
            dataValue=dataValue,
            updateMediaType=updateMediaType,
        )
    }
}