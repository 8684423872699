package org.hs_soft.runmynesto.pages.components.grid_function

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseEnter
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.CSSUnitLength
import org.jetbrains.compose.web.css.percent

@Composable
fun GridFooter(
    modifier:Modifier=Modifier,
    title:String,
    height: CSSSizeValue<CSSUnit.px> = Dimen.mediumGridFooterHeight,
    width: CSSSizeValue<out CSSUnitLength> ?= Dimen.gridWidth,
    onRefreshClick:()->Unit={},
    dataSize:Int,
    showRefreshIcon:Boolean=true,
)
{
    val isHovered= remember{ mutableStateOf(false) }
    Box(
        modifier = modifier
            .then(if (width != null) Modifier.width(width)
                else Modifier.fillMaxWidth())
            .height(height)
            .backgroundColor(Theme.WhiteFA.rgb)
            .padding(
                topBottom = Dimen.normalPadding,
                leftRight = Dimen.normalPadding,
            )
            .border(
                width = 1.px,
                style = LineStyle.Solid,
                color = Theme.GrayAlpha12.rgb,
            )
            .borderRadius(
                bottomLeft = Dimen.lowBorderRadius,
                bottomRight = Dimen.lowBorderRadius,
            ),

        )
    {
        SpanText(
            text = "$title : $dataSize",
            modifier = Modifier
                .align(Alignment.CenterStart)
                .fontFamily(FontFamily.Montserrat)
                .fontSize(FontSizes.mediumFontSize)
                .color(Theme.Secondary.rgb)
                .fontWeight(FontWeight.Medium)
        )

        if (showRefreshIcon)
        {
            Box(
                modifier = Modifier
                    .align(Alignment.CenterEnd)
                    .backgroundColor(
                        if(isHovered.value) Theme.GrayAlpha04.rgb
                        else Theme.Transparent.rgb
                    )
                    .onMouseEnter {isHovered.value=true}
                    .onMouseLeave {isHovered.value=false}
                    .padding(Dimen.normalPadding)
                    .borderRadius(50.percent)
                    .onClick {
                        onRefreshClick()
                    }
            )
            {
                VectorIcon(
                    width = Dimen.mediumIconSize,
                    height = Dimen.mediumIconSize,
                    pathData = Res.PathIcon.refresh,
                    color = Theme.GrayAlpha56.hex,
                )
            }

        }




    }




}