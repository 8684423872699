package org.hs_soft.runmynesto.domain.usecase.home

import org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.shop_list.ShopModel
import org.hs_soft.runmynesto.domain.repository.network_datasource.media_center.tabs.media.ShopListRepository


class ShopListUseCase(
    private val repository: ShopListRepository
) {
    suspend operator fun invoke(): ShopModel? {
         return repository.getShopList()
    }
}