package org.hs_soft.runmynesto.data.api_datasource.product.tabs.tab_product_general


import com.varabyte.kobweb.browser.http.http
import kotlinx.browser.window

import kotlinx.coroutines.*
import org.hs_soft.runmynesto.data.api_datasource.RequestManager
import org.hs_soft.runmynesto.domain.util.*
import kotlin.time.Duration.Companion.seconds
import org.hs_soft.runmynesto.domain.repository.network_datasource.product.tab_product_general.GetDataAssistantAnswerRepository


class GetDataAssistantAnswerRepositoryImpl(
    private val requestManager: RequestManager
) : GetDataAssistantAnswerRepository {

    private val abortController = requestManager.createController()

    override suspend fun getDataAssistantAnswer(email: String, question: String): String {

        return withContext(Dispatchers.Default) {
            retry(times = 3, initialDelay = 2.seconds) {
                withTimeout(Constants.timeout.seconds) {
                    fetchAnswer(email, question)
                }
            }
        }
    }

    private suspend fun fetchAnswer(email: String, question: String): String {
        val abortController = requestManager.createController()

        val headers = mapOf(
            "Content-Type" to "application/json",
            "x-api-key" to ApiUtils.API_KEY.RESTAURANTS_API_KEY_VALUE,
        )

        val result = window.http.post(
            resource = ApiUtils.Domain.dataAssistantDomain +
                    ApiPath.getDataAssistantAnswer +
                    "?${ApiUtils.Field.question}=$question" +
                    "&${ApiUtils.Field.email}=$email",
            headers = headers,
            abortController = abortController,
        ).decodeToString()

        return result.parseData<String>()
    }


    private suspend fun <T> retry(
        times: Int,
        initialDelay: kotlin.time.Duration,
        factor: Double = 2.0,
        block: suspend () -> T
    ): T {
        var currentDelay = initialDelay
        repeat(times - 1) {
            try {
                return block()
            } catch (e: Exception) {
                // You can add logging here
            }
            delay(currentDelay)
            currentDelay *= factor
        }
        return block() // last attempt
    }
}




