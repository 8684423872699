package org.hs_soft.runmynesto.pages.home.sub_page.user.mvi

import org.hs_soft.runmynesto.domain.model.home.template.GridItemsFilterEnum
import org.hs_soft.runmynesto.domain.model.home.user.single_user.GetUser
import org.hs_soft.runmynesto.domain.model.home.user.users_info.UserModel
import org.hs_soft.runmynesto.domain.model.home.user.UserCancellationReasonEnum
import org.hs_soft.runmynesto.domain.model.home.user.UserInfoHeaderEnum
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.Children
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.user_checkout_model.ChildrenX
import org.hs_soft.runmynesto.domain.util.Res

data class UserState(
    val selectedTab: UserInfoHeaderEnum = UserInfoHeaderEnum.GENERAL,
    val addRecordLoading:Boolean=false,
    val selectedUser: GetUser?=null,
    val usersInfo:List<UserModel> = listOf(),
    val cashList: List<Children> ?= null,
    val cancellationReason: UserCancellationReasonEnum =
        UserCancellationReasonEnum.NO_AUTHORIZATION,
    val userInfoDetailPasswordVisibility:Boolean=false,
    val isNumberSortedAsc:Boolean=true,
    val checkOutExpandAll:Boolean=false,
    val checkOutSearchValue:String="",
    val isNameSortedAsc:Boolean=true,
    val showNumberSortIcon:Boolean=true,
    val showTitleSortIcon:Boolean=false,
    val searchValue:String="",
    val imgUrl:String = Res.PathIcon.defaultCirclePhoto,
    val firstPageActive:Boolean=true,
    val dataLoadingProgressVisible:Boolean=false,
    val showAddRecordDialog:Boolean=false,
    val newRecordName:String="",
    val newRecordNumber:String="",
    val isAddNewRecordSubmitClickable:Boolean=false,
    val addRecordErrorMessage:String="",

    val showItemsFilterComponent:Boolean=false,
    val selectedFilterItemsType: GridItemsFilterEnum = GridItemsFilterEnum.ALL_ITEMS,

)