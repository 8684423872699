package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_detail


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class GetMedia(
    @SerialName("animationType")
    val animationType: String?,
    @SerialName("cashRegisterId")
    val cashRegisterId: List<String>?,
    @SerialName("dateInterval")
    val dateInterval: DateInterval?,
    @SerialName("id")
    val id: String?,
    @SerialName("img")
    val img: Img?,
    @SerialName("img16_9")
    val img169: Img?,
    @SerialName("imgMobile")
    val imgMobile: ImgMobile?,
    @SerialName("isActive")
    val isActive: Boolean?,
    @SerialName("mod_ver")
    val modVer: Int?,
    @SerialName("name")
    val name: String?,
    @SerialName("refreshTime")
    val refreshTime: Int?,
    @SerialName("shopId")
    val shopId: String?,
    @SerialName("stayActive")
    val stayActive: Boolean?,
    @SerialName("tagId")
    val tagId: String?,
    @SerialName("timeInterval")
    val timeInterval: TimeInterval?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("weekDays")
    val weekDays: Int?,
    @SerialName("withProducts")
    val withProducts: List<WithProduct>?
)