package org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.template_category_detail

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable


@Serializable
data class GetCategory(

    @SerialName("cashRegisterId")
    val cashRegisterId: List<String> ?=null,
    @SerialName("surchargeAmount")
    val surchargeAmount:List<Int?> ?=null,
    @SerialName("surchargeFrom")
    val surchargeFrom:List<Int?>?=null,
    @SerialName("surchargePercent")
    val surchargePercent:List<Int?> ?=null,
    @SerialName("validAfter")
    val validAfter:String ?=null,
    @SerialName("color")
    val color:String?=null,
    @SerialName("weekDays")
    val weekDays:Int?=null,
    @SerialName("gridProducts")
    val gridProducts: List<ArticleModel> ?=null,
    val id: String ?=null,
    val img: Img?=null,
    val isActive: Boolean?=null,
    val name: String ?=null,
    val priceLevel:Int?=null,
    val position: Int?=null,



)