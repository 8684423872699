package org.hs_soft.runmynesto.pages.components.checkout.component

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.onMouseLeave
import com.varabyte.kobweb.compose.ui.modifiers.onMouseOver
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.rippleEffect
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.user.tabs.user_checkout.UserDeviceType
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.domain.util.getCheckoutWidth
import org.hs_soft.runmynesto.domain.util.getGeneralItemRatio
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.jetbrains.compose.web.css.px


@Composable
fun DeviceItem(
    showCheckBoxes: Boolean,
    checkedValue: Boolean,
    title: String,
    isLastItem: Boolean,
    deviceType: String,
    windowSizeMode: WindowSizeModeEnum,
    onItemClick:()->Unit,
    checkItem: () -> Unit,
    unCheckItem: () -> Unit,
)
{
    val isHovered= remember{ mutableStateOf(false) }
    Row(
        modifier = Modifier
            .fillMaxWidth()
            .onClick {
                event: SyntheticMouseEvent -> event.stopPropagation()
            }
            .rippleEffect {
                onItemClick()
            }
            .backgroundColor(
                if (isHovered.value) Theme.GrayAlpha04.rgb
                else Theme.Transparent.rgb
            )
            .onMouseOver {isHovered.value=true}
            .onMouseLeave { isHovered.value=false }
            .fillMaxWidth()
            .padding(
                left = Dimen.largePadding,
                right = Dimen.normalPadding
            )
            .boxShadow(color = Theme.GrayAlpha12.rgb)
            .height(Dimen.checkOutItemHeight),
        verticalAlignment = Alignment.CenterVertically
    )
    {

        Box(
            modifier = Modifier
                .height(Dimen.checkOutItemHeight)
                .margin(left = Dimen.semiNormalPadding)
        )
        {
            Box(
                modifier = Modifier
                    .align(Alignment.Center)
                    .width(Dimen.checkOutSmallLineWidth)
                    .backgroundColor(Theme.Gray9E.rgb)
                    .height(Dimen.smallThickness)
            )

            Box(
                modifier = Modifier
                    .align(Alignment.TopStart)
                    .height(
                        if(!isLastItem)
                            Dimen.checkOutItemHeight
                        else (Dimen.checkOutItemHeight.value.toInt()/2).px
                    )
                    .backgroundColor(Theme.Gray9E.rgb)
                    .width(Dimen.smallThickness)
            )



        }

        if (showCheckBoxes){
            CheckboxComponent(
                modifier = Modifier,
                checkStatus = checkedValue,
                title = "",
                onChecked = {
                    checkItem()
                },
                onUnChecked = {
                    unCheckItem()
                },
                fontSize = getGeneralItemRatio(
                    windowSizeMode=windowSizeMode,
                    size = FontSizes.mediumFontSize,
                )
            )
        }


        SpanText(
            text = title,
            modifier = Modifier
                .margin(left = Dimen.semiNormalPadding)
                .fontWeight(FontWeight.Normal)
                .fontFamily(FontFamily.Montserrat)
                .fontSize(
                    getGeneralItemRatio(
                        windowSizeMode=windowSizeMode,
                        size = FontSizes.mediumFontSize,
                    )
                )
                .color(Theme.Primary.rgb),
        )



        Spacer()

        when(deviceType)
        {
            UserDeviceType.OLD_CASHREGISTER.name->{

            }
            UserDeviceType.SELF_CHECKOUT.name->{
                VectorIcon(
                    color = Theme.Blue.hex,
                    width = Dimen.normalIconSize,
                    height = Dimen.normalIconSize,
                    pathData = Res.PathIcon.android
                )

            }
            UserDeviceType.SMART_CHECKOUT.name->{
                VectorIcon(
                    color = Theme.Blue.hex,
                    width = Dimen.normalIconSize,
                    height = Dimen.normalIconSize,
                    pathData = Res.PathIcon.android
                )
            }
            UserDeviceType.SMART_LABEL.name->{

            }
            UserDeviceType.MOBILE_WAITER.name->{

            }
            UserDeviceType.KDS.name->{

            }
        }


    }



}
