package org.hs_soft.runmynesto.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import org.hs_soft.runmynesto.navigation.Screen

@Page
@Composable
fun Index() {

    val context= rememberPageContext()

    context.router.navigateTo(
        Screen.HomePage.route,
        updateHistoryMode = UpdateHistoryMode.REPLACE
    )


}