package org.hs_soft.runmynesto.pages.home.sub_page.closing_reports.components.closing_reports_content.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderBottom
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.style.toModifier
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.HorizontalScrollBehavior
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.VerticalScrollBehavior
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model.Cashbook
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model.Coupon
import org.hs_soft.runmynesto.domain.model.home.closing_reports.closing_report_detail_model.PaymentMethod
import org.hs_soft.runmynesto.domain.util.formatToTwoDecimals
import org.hs_soft.runmynesto.pages.components.HeaderTitle
import org.hs_soft.runmynesto.pages.components.MediumContentTitle
import org.hs_soft.runmynesto.pages.components.ShowWhenListIsEmpty
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px


@Composable
fun CashBookAndCoupons(
    windowSizeMode:WindowSizeModeEnum,
    paymentMethod: List<PaymentMethod>?,
    cashBook: List<Cashbook>?,
    coupon: List<Coupon>?,
) {
    Column(
        modifier = Modifier
            .fillMaxWidth()
    ) {
        MediumContentTitle(
            title = Strings.cashBookAndCoupons
        )


        if (windowSizeMode==WindowSizeModeEnum.PHONE)
        {
            Column(
                modifier = Modifier
                    .fillMaxWidth()
            )
            {
                PaymentsMethod(
                    paymentMethod=paymentMethod
                )

                CashbookAndCoupons(
                    coupon=coupon,
                    cashBook=cashBook,
                    windowSizeMode = windowSizeMode,
                )
            }
        }else{
            Row(
                modifier = Modifier
                    .fillMaxWidth()
            )
            {
                PaymentsMethod(
                    paymentMethod=paymentMethod
                )

                CashbookAndCoupons(
                    coupon=coupon,
                    cashBook=cashBook,
                    windowSizeMode = windowSizeMode,
                )
            }
        }

    }


}

@Composable
private fun CashbookAndCoupons(
    windowSizeMode: WindowSizeModeEnum,
    coupon: List<Coupon>?,
    cashBook: List<Cashbook>?,
)
{
    Column(
        modifier = HorizontalScrollBehavior.toModifier()
            .fillMaxWidth()
            .margin(
                left = if (windowSizeMode==WindowSizeModeEnum.PHONE) 0.px
                    else Dimen.normalPadding,
                bottom = Dimen.mediumPadding,
                top = Dimen.mediumPadding
            )
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayAlpha12.rgb
            )
            .borderRadius(Dimen.lowBorderRadius)
    ) {

        Box(
            modifier = HorizontalScrollBehavior.toModifier()
                .fillMaxWidth()
                .height(Dimen.gridHeaderHeight)
                .borderBottom(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayAlpha12.rgb
                )
                .backgroundColor(Theme.GrayAlpha04.rgb)
                .padding(leftRight = Dimen.normalPadding),
        ) {

            HeaderTitle(
                modifier = Modifier
                    .align(Alignment.CenterStart),
                title = Strings.incomeAndExpanse,
                width = Dimen.headerExtraLargeWidth,
            )

            Spacer()

            HeaderTitle(
                modifier = Modifier
                    .align(Alignment.CenterEnd),
                title = Strings.amount,
                arrangement = Arrangement.End,
            )


        }

        Column(
            modifier = VerticalScrollBehavior
                .toModifier()
                .fillMaxWidth()
                .height(Dimen.closingReportTable3Height)
        )
        {
            if (cashBook.isNullOrEmpty())
            {
                ShowWhenListIsEmpty(
                    modifier = Modifier
                )
            }

            cashBook?.forEachIndexed{index,model->
                Box(
                    modifier = HorizontalScrollBehavior.toModifier()
                        .fillMaxWidth()
                        .height(Dimen.gridItemHeight)
                        .borderBottom(
                            width = Dimen.smallThickness,
                            style = LineStyle.Solid,
                            color = Theme.GrayAlpha12.rgb
                        )
                        .backgroundColor(Theme.GrayAlpha04.rgb)
                        .padding(leftRight = Dimen.normalPadding),
                ) {

                    HeaderTitle(
                        modifier = Modifier
                            .align(Alignment.CenterStart),
                        title = cashBook[index].description ,
                    )


                    HeaderTitle(
                        modifier = Modifier
                            .align(Alignment.CenterEnd),
                        title = coupon?.get(index)?.name,
                    )


                }
            }
        }




    }
}


@Composable
private fun PaymentsMethod(
    paymentMethod: List<PaymentMethod>?,
) {
    Column(
        modifier = HorizontalScrollBehavior.toModifier()
            .fillMaxWidth()
            .margin(topBottom = Dimen.mediumPadding)
            .border(
                width = Dimen.smallThickness,
                style = LineStyle.Solid,
                color = Theme.GrayAlpha12.rgb
            )
            .borderRadius(Dimen.lowBorderRadius)
    ) {

        Row(
            modifier = HorizontalScrollBehavior.toModifier()
                .fillMaxWidth()
                .height(Dimen.gridHeaderHeight)
                .borderBottom(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayAlpha12.rgb
                )
                .backgroundColor(Theme.GrayAlpha04.rgb)
                .padding(leftRight = Dimen.normalPadding),
            verticalAlignment = Alignment.CenterVertically,
        ) {

            HeaderTitle(
                modifier = Modifier ,
                title = Strings.credit,
                fillMaxWidth = true,
            )
            HeaderTitle(
                modifier = Modifier,
                title = Strings.number,
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )
            HeaderTitle(
                modifier = Modifier,
                title = Strings.amount,
                arrangement = Arrangement.End,
                fillMaxWidth = true,
            )


        }


        Column(
            modifier = VerticalScrollBehavior.toModifier()
                .fillMaxWidth()
                .height(Dimen.closingReportTable3Height)
        )
        {
            if (paymentMethod.isNullOrEmpty())
            {
                ShowWhenListIsEmpty(
                    modifier = Modifier
                )
            }

            paymentMethod?.forEach {model->
                Row(
                    modifier = HorizontalScrollBehavior.toModifier()
                        .fillMaxWidth()
                        .height(Dimen.gridItemHeight)
                        .borderBottom(
                            width = Dimen.smallThickness,
                            style = LineStyle.Solid,
                            color = Theme.GrayAlpha12.rgb
                        )
                        .padding(leftRight = Dimen.normalPadding),
                    verticalAlignment = Alignment.CenterVertically,
                ) {

                    HeaderTitle(
                        modifier = Modifier,
                        title = model.name,
                        fillMaxWidth = true,
                    )
                    HeaderTitle(
                        modifier = Modifier,
                        title = formatToTwoDecimals(model.count) ,
                        arrangement = Arrangement.End,
                        fillMaxWidth = true,
                    )
                    HeaderTitle(
                        modifier = Modifier,
                        title = formatToTwoDecimals(model.amount) ,
                        arrangement = Arrangement.End,
                        fillMaxWidth = true,
                    )


                }
            }
        }

    }
}
