package org.hs_soft.runmynesto

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.browser.document
import org.hs_soft.runmynesto.di.appModules
import org.hs_soft.runmynesto.domain.util.configureAmplify
import org.hs_soft.runmynesto.modules.Auth
import org.hs_soft.runmynesto.modules.configureSentry
import org.jetbrains.compose.web.css.vh
import org.koin.core.context.GlobalContext
import org.koin.core.context.startKoin
import org.w3c.dom.HTMLLinkElement

@App
@Composable
fun AppEntry(content: @Composable () -> Unit) {
    KoinInitializer {
        SilkApp {
            injectGoogleFonts()
            Surface(com.varabyte.kobweb.silk.style.
            common.SmoothColorStyle.toModifier().minHeight(100.vh)) {
                content()
            }
        }
    }



}

@Composable
fun KoinInitializer(content: @Composable () -> Unit) {
    val isKoinInitialized = remember { mutableStateOf(false) }


    LaunchedEffect(Unit) {
        startKoin {
            modules(appModules)
        }
        console.log(Auth)
        configureAmplify()
        configureSentry()
    }
    LaunchedEffect(GlobalContext.getOrNull()!=null){
        isKoinInitialized.value=true
    }
    if (isKoinInitialized.value) {
        content()
    }
}



fun injectGoogleFonts() {
    val link = document.createElement("link") as HTMLLinkElement
    link.href = "https://fonts.googleapis.com/css2?family=" +
            "Montserrat:wght@100;200;300;400;500;700&display=swap"
    link.rel = "stylesheet"
    document.head?.appendChild(link)
}


