package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.shop_list


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ListShops(
    @SerialName("hasNextPage")
    val hasNextPage: Boolean?,
    @SerialName("items")
    val items: List<ShopItem>?,
    @SerialName("__typename")
    val typename: String?
)