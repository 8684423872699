package org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.restaurant_manager

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import konva.Layer
import konva.Rect
import konva.RectConfig
import konva.Stage
import konva.StageConfig
import kotlinx.browser.document
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.borderSetting
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.ObjectKinds
import org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.ShapeEnum
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.restaurant_manager.components.InteriorDecor
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.restaurant_manager.components.RestaurantProject
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.restaurant_manager.components.RestaurantProjectContent
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.restaurant_manager.components.RestaurantTable
import org.hs_soft.runmynesto.pages.home.sub_page.options.component.tabs.restaurant_manager.components.RestaurantWall
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsEvent
import org.hs_soft.runmynesto.pages.home.sub_page.options.mvi.OptionsState

@Composable
fun RestaurantManager(
    state: OptionsState,
    event: (OptionsEvent) -> Unit,
    windowSizeMode: WindowSizeModeEnum,
){
    LaunchedEffect(Unit){
        event(OptionsEvent.GetRestaurantProjects)
    }


//    Box(
//        modifier = Modifier.fillMaxSize()
//    ){
//        createKonvaExample()
//    }

    Row(
        modifier = Modifier
            .fillMaxSize()
            .borderSetting()
    )
    {
        Column(
            modifier = Modifier
                .fillMaxHeight()
        )
        {

            RestaurantProject(
                restaurantProjects=state.restaurantProjects,
                selectedRestaurantProject=state.selectedRestaurantProject,
                selectedRoom=state.selectedRestaurantRoom,
                windowSizeMode = windowSizeMode,
                fillMaxWidth = false,
                searchValue = "",
                onSearchValueChange = {
                    event(OptionsEvent.OnSearchInRestaurantProject(it))
                },
                onProjectClick = {
                    event(OptionsEvent.OnRestaurantProjectClick(it))
                },
                onRoomClick = {room,restaurantProject->
                    event(OptionsEvent.OnRestaurantRoomClick(
                        room,restaurantProject
                    ))
                },
                onFloorClick = {restaurantProject ->
                    event(OptionsEvent.OnRestaurantFloorClick(restaurantProject))
                }
            )

            if (state.selectedRestaurantRoom!=null){

                RestaurantTable(
                    windowSizeMode=windowSizeMode,
                    selectedTableColor=state.selectedRestaurantDesignTableColor?:
                        Theme.Transparent.hex,
                    onRestaurantTableColorClick = {
                        event(OptionsEvent.SetRestaurantTableColorDialogStatus(true))
                    },
                    onSubmitClick = { shape: ShapeEnum, number:String->
                        event(OptionsEvent.CreateFurniture(
                            shape = shape,
                            number=number,
                            objectKind = ObjectKinds.TABLE,
                        ))
                    }
                )

                RestaurantWall(
                    onSubmitClick={objectKind:ObjectKinds->
                        event(OptionsEvent.CreateFurniture(
                            shape = ShapeEnum.RECTAGNLE,
                            objectKind = objectKind,
                        ))
                    }
                )

                InteriorDecor(
                    onSubmitClick = {
                        if (it.shape!=null){
                            event(OptionsEvent.CreateFurniture(
                                shape = it.shape,
                                objectKind = it,
                            ))
                        }

                    },

                )
            }


        }

        if (state.selectedRestaurantProject!=null){

            RestaurantProjectContent(
                windowSizeMode=windowSizeMode,
                selectedRestaurantProject=state.selectedRestaurantProject,
                selectedRoom = state.selectedRestaurantRoom,
                onMoveFurniture = {id:String,offsetX:Int,offsetY:Int,rotationDeg:Int,width:Int,height:Int->
                    event(
                        OptionsEvent.UpdateFurniture(
                            id=id,
                            height =height,
                            positionLeft =offsetX ,
                            positionTop =offsetY,
                            rotationDeg=rotationDeg,
                            width=width
                        )
                    )
                },
                showRestaurantFloorDialog = {
                    event(
                        OptionsEvent.SetAddRestaurantFloorDialogStatus(true)
                    )
                },
                showDeleteFloorDialog = {
                    event(OptionsEvent.SetDeleteFloorDialogStatus(
                        status = true,
                        floorId=it,
                    ))
                },
                showDeleteRoomDialog = {
                    event(OptionsEvent.SetDeleteRoomDialogStatus(
                        status = true,
                        roomId = it
                    ))
                },
                showEditFloorDialog = {

                }
            )
        }


    }


}

fun createKonvaExample() {

    document.addEventListener("DOMContentLoaded", {
        val containerElement = document.getElementById("container")
        if (containerElement != null) {
            console.log("Container found, initializing Konva")
            val stage = Stage(object : StageConfig {
                override var container = "container"
                override var width = 800
                override var height = 600
            })

            val layer = Layer()

            val rect = Rect(object : RectConfig {
                override var x = 50
                override var y = 50
                override var width = 100
                override var height = 100
                override var fill = "red"
            })

            layer.add(rect)
            stage.add(layer)
        } else {
            console.error("Container element not found")
        }
    })
}

