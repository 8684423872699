package org.hs_soft.runmynesto.pages.components.grid_function

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.color
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.config.generalDialogSetting
import org.hs_soft.runmynesto.domain.model.home.template.GridItemsFilterEnum
import org.hs_soft.runmynesto.domain.util.Constants
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CircleIcon
import org.hs_soft.runmynesto.pages.components.dialog.component.CustomButton
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.RadioGroup
import org.jetbrains.compose.web.dom.RadioInput

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun FilterGridContent(
    selectedFilterType: GridItemsFilterEnum,
    selectFilterType:(GridItemsFilterEnum)->Unit,
    submitFilterItems:()->Unit,
    onCloseFilterClick:()->Unit,
)
{
    Column(
        modifier = Modifier
            .margin(top = Dimen.smallPadding)
            .fillMaxWidth()
            .height(Dimen.filterGridDialogHeight)
            .generalDialogSetting()
            .zIndex(Constants.ZIndex.mediumZIndex)
            .padding(Dimen.normalPadding)

    ) {
        Row(
            modifier = Modifier
                .padding(Dimen.normalPadding)
                .fillMaxWidth(),
            verticalAlignment = Alignment.CenterVertically
        )
        {
           SpanText(
               text = Strings.filter,
               modifier = Modifier
                   .fontSize(FontSizes.extraExtraLargeFontSize)
                   .color(Theme.Secondary.rgb)
                   .fontFamily(FontFamily.Montserrat)
                   .fontWeight(FontWeight.Normal)
           )

           Spacer()

           CircleIcon(
               modifier = Modifier,
               size = Dimen.semiLargeIconSize,
               img = Res.PathIcon.largeClose,
               borderColor = Theme.Transparent.rgb,
               onIconClick = {
                    onCloseFilterClick()
               },
           )



        }

        Column(
            modifier = Modifier
                .fillMaxWidth()
                .weight(1f)
                .padding(
                    leftRight = Dimen.normalPadding,
                    topBottom = Dimen.normalPadding,
                )
        )
        {
            RadioGroup(selectedFilterType.title) {
                GridItemsFilterEnum.entries.forEach { filter ->
                    Row(
                        verticalAlignment = Alignment.CenterVertically,
                        modifier = Modifier
                            .height(Dimen.radioButtonRowHeight)
                            .cursor(Cursor.Pointer)
                            .onClick {
                                if (filter!=selectedFilterType)
                                    selectFilterType(filter)
                            }
                            .margin(topBottom = Dimen.smallPadding)
                    )
                    {

                        RadioInput(
                            value = filter.title,
                            attrs = Modifier
                                .padding(Dimen.normalPadding)
                                .toAttrs{
                                style {
                                    color(Theme.Secondary.hex)
                                    width(Dimen.mediumRadioButton)
                                    height(Dimen.mediumRadioButton)
                                }
                                onClick {
                                    if (filter!=selectedFilterType)
                                    selectFilterType(filter)
                                }
                                checked(filter==selectedFilterType)

                            }
                        )
                        SpanText(
                            text = filter.title,
                            modifier = Modifier
                                .cursor(Cursor.Pointer)
                                .margin(leftRight = Dimen.normalPadding)
                                .fontSize(FontSizes.semiLargeFontSize)
                                .color(Theme.Secondary.rgb)
                                .fontWeight(FontWeight.Normal)
                                .fontFamily(FontFamily.Montserrat)
                        )
                    }


                }
            }

            Spacer()

            CustomButton(
                modifier = Modifier
                    .fillMaxWidth(),
                title = Strings.apply,
                leftRightMargin = 0.px,
                defaultTextColor = Theme.White.rgb,
                onButtonClick = {
                    submitFilterItems()
                },

            )


        }


    }





}