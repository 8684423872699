package org.hs_soft.runmynesto.domain.model.menu

import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.util.Res

enum class MenuItemsEnum(
    val title: String,
    val iconPath: String
) {
    PRODUCT(
        title=Strings.product,
        iconPath=Res.PathIcon.production
    ),
    TEMPLATE(
        title=Strings.template,
        iconPath=Res.PathIcon.template
    ),
    USER(
        title=Strings.user,
        iconPath=Res.PathIcon.user
    ),
    MEDIA_CENTER(
        title = Strings.mediaCenter,
        iconPath =Res.PathIcon.mediaCenter
    ),
    CUSTOMER_CARD(
        title=Strings.customerCard,
        iconPath=Res.PathIcon.creditCard
    ),
    CLOSING_REPORTS(
        title=Strings.closingReports,
        iconPath=Res.PathIcon.closingReport
    ),
    STATISTIC(
        title = Strings.statistic,
        iconPath = Res.PathIcon.statistic,
    ),
    OPTIONS(
        title=Strings.options,
        iconPath=Res.PathIcon.options
    ),
    DASHBOARD(
        title=Strings.dashboard,
        iconPath=Res.PathIcon.dashboard
    )
}