package org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager

import org.hs_soft.runmynesto.domain.util.ApiUtils

enum class ShapeEnum(
    val value:String
)
{
    RECTAGNLE(
        value=ApiUtils.Field.rectangle
    ),
    ROUND(
        value=ApiUtils.Field.round
    )
}