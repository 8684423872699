package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_detail


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class ImgMobile(
    @SerialName("id")
    val id: String?,
    @SerialName("key")
    val key: String?,
    @SerialName("__typename")
    val typename: String?,
    @SerialName("uid")
    val uid: String?
)