package org.hs_soft.runmynesto.domain.model.home.media_center.tabs.media.media_detail


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("getMedia")
    val getMedia: GetMedia?=null,
    @SerialName("createMutation")
    val createMedia:GetMedia?=null,
    @SerialName("updateMedia")
    val updateMedia:GetMedia?=null,
    @SerialName("__typename")
    val typename: String?
)