package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_surcharge_in_house

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.text.SpanText
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.FontFamily
import org.hs_soft.runmynesto.domain.config.FontSizes
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.util.ApiUtils
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.LineStyle
import kotlin.math.max

@Composable
fun SurchargeInHouse(
    windowSizeMode: WindowSizeModeEnum,
    surchargeFrom: List<Int?>?,
    surchargeAmount: List<Int?>?,
    surchargePercent: List<Int?>?,
    updateSurcharge: (String, List<Int?>) -> Unit,

)
{
    if (windowSizeMode== WindowSizeModeEnum.WINDOWS)
    {
        Row(
            modifier = Modifier
                .fillMaxHeight()
                .fillMaxWidth()
                .padding(leftRight = Dimen.normalPadding)
                .borderRadius(Dimen.lowBorderRadius)
                .border(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayCD.rgb
                )
        ){

            content(
                surchargeFrom=surchargeFrom,
                surchargeAmount=surchargeAmount,
                surchargePercent=surchargePercent,
                updateSurcharge={fieldName,updatedList->
                    updateSurcharge(fieldName,updatedList)
                },
            )


        }
    }else
    {
        Column(
            modifier = Modifier
//                .margin(
//                    leftRight = Dimen.mediumPadding,
//                )
                .fillMaxHeight()
                .fillMaxWidth()
                .padding(leftRight = Dimen.normalPadding)
                .borderRadius(Dimen.lowBorderRadius)
                .border(
                    width = Dimen.smallThickness,
                    style = LineStyle.Solid,
                    color = Theme.GrayCD.rgb
                )
        ){

            content(
                surchargeFrom=surchargeFrom,
                updateSurcharge={fieldName,updatedList->
                    updateSurcharge(fieldName,updatedList)
                },
                surchargeAmount=surchargeAmount,
                surchargePercent=surchargePercent
            )


        }
    }

}

@Composable
private fun content(
    surchargeFrom: List<Int?>?,
    updateSurcharge: (String, List<Int?>) -> Unit,
    surchargeAmount: List<Int?>?,
    surchargePercent: List<Int?>?
) {
    SurchargeContent(
        surchargeList = surchargeFrom?: listOf(),
        title = Strings.away,
        updateSurcharge = {
            updateSurcharge(
                ApiUtils.Field.surchargeFrom,
                it
            )
        }
    )

    SurchargeContent(
        surchargeList = surchargeAmount?: listOf(),
        title = Strings.surchargeAmount,
        updateSurcharge = {
            updateSurcharge(
                ApiUtils.Field.surchargeAmount,
                it
            )
        }
    )
    SurchargeContent(
        surchargeList = surchargePercent?: listOf(),
        title = Strings.surchargePercent,
        updateSurcharge = {
            updateSurcharge(
                ApiUtils.Field.surchargePercent,
                it
            )
        }
    )
}

@Composable
private fun SurchargeContent(
    surchargeList: List<Int?>,
    updateSurcharge:(List<Int?>)->Unit,
    title: String,
) {

    val mutableSurchargeList = remember {
        mutableStateOf(surchargeList.toMutableList())
    }

    val maxIndex = remember { mutableStateOf(1) }
    LaunchedEffect(surchargeList)
    {
        mutableSurchargeList.value = surchargeList.toMutableList()
        var initialCount = 0
        var i = surchargeList.size - 1
        while (i >= 0) {
            if (surchargeList[i] != null) {
                initialCount = i + 1
                break
            }
            i--
        }
        maxIndex.value = max(1, initialCount+1)
    }



    Column(
        modifier = Modifier.margin(Dimen.normalPadding)
    ) {
        SpanText(
            text = title,
            modifier = Modifier
                .fontWeight(FontWeight.Medium)
                .fontFamily(FontFamily.Montserrat)
                .color(Theme.Primary.rgb)
                .fontSize(FontSizes.mediumFontSize)
        )

        mutableSurchargeList.value.forEachIndexed { index, value ->

            val isFocused= remember { mutableStateOf(false) }
            if (index < maxIndex.value) {
                Row(
                    verticalAlignment = Alignment.CenterVertically
                ){
                    CustomEditText(
                        modifier = Modifier,
                        width = Dimen.extraSemiMediumEditText,
                        value = value?.toString() ?: "",
                        isRequired = false,
                        inputType = InputType.Number,
                        endIconPath =
                            if (isFocused.value)
                                Res.PathIcon.mediumClose
                            else null,
                        onEndIconClick ={
                            mutableSurchargeList.value[index]=null
                            mutableSurchargeList.value.removeAt(index)
                            updateSurcharge(mutableSurchargeList.value)

                        },
                        isOutlinedEditText = false,
                        onFocusIn = {
                            isFocused.value=true

                        },
                        onFocusOut = {
                            isFocused.value=false
                            if (it.isEmpty())
                                mutableSurchargeList.value[index] = null
                            else
                                mutableSurchargeList.value[index] = it.toInt()
                            updateSurcharge(mutableSurchargeList.value)

                        },
                        onInputChange = {
                            if (index == maxIndex.value - 1 && maxIndex.value < surchargeList.size) {
                                maxIndex.value++
                            }

                        },
                    )



                }



            }
        }
    }
}