package org.hs_soft.runmynesto.domain.model.home.options.restaurant_manager.delete_room


import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class CashAssist(
    @SerialName("deleteRoom")
    val deleteRoom: Int?,
    @SerialName("__typename")
    val typename: String?
)