package org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.hs_soft.runmynesto.domain.config.Dimen
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.config.Theme
import org.hs_soft.runmynesto.domain.model.WindowSizeModeEnum
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.util.Res
import org.hs_soft.runmynesto.pages.components.CalendarBox
import org.hs_soft.runmynesto.pages.components.CheckboxComponent
import org.hs_soft.runmynesto.pages.components.CustomEditText
import org.hs_soft.runmynesto.pages.components.VectorIcon
import org.hs_soft.runmynesto.pages.home.sub_page.template.component.tabs.tab_general.component.select_days.SelectDays
import org.jetbrains.compose.web.css.LineStyle

@Composable
fun TemplateInfoDetail(
    windowSizeMode: WindowSizeModeEnum,
    receiptName: String,
    activityStatus: Boolean,
    selectedColor:String,
    selectedDays:Int,
    openColorPanel:()->Unit,
    selectedDate: MonthModel,
    onChangeActivity: (Boolean) -> Unit,
    onChangeReceiptName: (String) -> Unit,
    setCalendarStatus: (Boolean) -> Unit,
    onChangeDayCheckStatus:(Int)->Unit,
)
{
    if (windowSizeMode== WindowSizeModeEnum.WINDOWS){
        Row()
        {
            InfoDetailContent(
                windowSizeMode=windowSizeMode,
                receiptName = receiptName,
                activityStatus=activityStatus,
                selectedColor=selectedColor,
                selectedDate=selectedDate,
                selectedDays=selectedDays,
                onChangeReceiptName={
                    onChangeReceiptName(it)
                },
                setCalendarStatus={
                    setCalendarStatus(it)
                },
                openColorPanel={
                    openColorPanel()
                },
                onChangeDayCheckStatus={
                    onChangeDayCheckStatus(it)
                },
                onChangeActivity={
                    onChangeActivity(it)
                }
            )


        }
    }else{
        Column()
        {
            InfoDetailContent(
                windowSizeMode=windowSizeMode,
                receiptName = receiptName,
                activityStatus=activityStatus,
                selectedColor=selectedColor,
                selectedDate=selectedDate,
                selectedDays=selectedDays,
                onChangeReceiptName={
                    onChangeReceiptName(it)
                },
                setCalendarStatus={
                    setCalendarStatus(it)
                },
                openColorPanel={
                    openColorPanel()
                },
                onChangeDayCheckStatus={
                    onChangeDayCheckStatus(it)
                },
                onChangeActivity={
                    onChangeActivity(it)
                }
            )


        }
    }

}

@Composable
private fun InfoDetailContent(
    windowSizeMode:WindowSizeModeEnum,
    receiptName: String,
    onChangeReceiptName: (String) -> Unit,
    selectedDate: MonthModel,
    setCalendarStatus: (Boolean) -> Unit,
    selectedColor: String,
    openColorPanel: () -> Unit,
    selectedDays: Int,
    onChangeDayCheckStatus: (Int) -> Unit,
    activityStatus: Boolean,
    onChangeActivity: (Boolean) -> Unit
) {
    Column(
        modifier = Modifier
    ) {

        Row(
            verticalAlignment = Alignment.CenterVertically
        )
        {
            CustomEditText(
                modifier = Modifier.margin(right = Dimen.extraLargePadding),
                label = Strings.name,
                value = receiptName,
                width = Dimen.mediumEditTextWidth,
                defaultBorderColor = Theme.GrayCD.rgb,
                hint = "",
                isOutlinedEditText = true,
                onFocusIn = {},
                onFocusOut = {
                    onChangeReceiptName(it)
                },
                onInputChange = {},
            )

            CheckboxComponent(
                modifier = Modifier.margin(left = Dimen.mediumPadding),
                checkStatus = activityStatus,
                title = Strings.activate,
                onChecked = { onChangeActivity(true) },
                onUnChecked = { onChangeActivity(false) },
                accentColor = Theme.Blue.rgb
            )

        }

        Row(
            verticalAlignment = Alignment.CenterVertically
        )
        {
            CalendarBox(
                modifier = Modifier.margin(
                    right = Dimen.normalPadding,
                    bottom = Dimen.normalPadding,
                ),
                selectedDate =selectedDate,
                label = Strings.changesValidFrom,
                onClick = {
                    setCalendarStatus(true)
                }
            )

            Box(
                modifier = Modifier
                    .margin(left = Dimen.mediumPadding)
                    .border(
                        width = Dimen.smallThickness,
                        style = LineStyle.Solid,
                        color = Theme.GrayCD.rgb
                    )
                    .borderRadius(Dimen.lowBorderRadius)
                    .width(Dimen.mediumOutlinedEditTextHeight)
                    .height(Dimen.mediumOutlinedEditTextHeight)
                    .onClick {
                        openColorPanel()
                    }
            ){
                VectorIcon(
                    modifier = Modifier.align(Alignment.Center),
                    pathData =Res.PathIcon.paint ,
                    width = Dimen.mediumIconSize,
                    height = Dimen.mediumIconSize,
                    color = selectedColor
                )

            }

        }


        SelectDays(
            windowSizeMode = windowSizeMode,
            selectedDaysNumber = selectedDays,
            onChangeDayCheckStatus = {
                onChangeDayCheckStatus(it)
            }
        )


    }


}


