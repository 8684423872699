package org.hs_soft.runmynesto.domain.model.home.dashboard


import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.DayOfWeek
import kotlinx.datetime.minus
import org.hs_soft.runmynesto.domain.config.Strings
import org.hs_soft.runmynesto.domain.model.home.template.tabs.tab_general.calendar.MonthModel
import org.hs_soft.runmynesto.domain.util.toLocalDate

enum class CompareChartDaysEnum {
    SUNDAY,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    LAST_WEEK,
    TWO_WEEKS_AGO,
    THREE_WEEKS_AGO;

    companion object {
        fun create(selectedDate: MonthModel): List<CompareChartDay> {
            return values().map { dayEnum ->
                val dayOfWeek = when (dayEnum) {
                    SUNDAY -> DayOfWeek.SUNDAY
                    MONDAY -> DayOfWeek.MONDAY
                    TUESDAY -> DayOfWeek.TUESDAY
                    WEDNESDAY -> DayOfWeek.WEDNESDAY
                    THURSDAY -> DayOfWeek.THURSDAY
                    FRIDAY -> DayOfWeek.FRIDAY
                    SATURDAY -> DayOfWeek.SATURDAY
                    else -> null
                }

                val title = if (dayOfWeek != null) {
                    getTitleForDay(dayOfWeek, selectedDate)
                } else {
                    when (dayEnum) {
                        LAST_WEEK -> Strings.lastWeek
                        TWO_WEEKS_AGO -> Strings.twoWeeksAgo
                        THREE_WEEKS_AGO -> Strings.threeWeeksAgo
                        else -> ""
                    }
                }

                val date = if (dayOfWeek != null) {
                    getDateForDay(dayOfWeek, selectedDate)
                } else {
                    when (dayEnum) {
                        LAST_WEEK -> getDateWeeksAgo(1, selectedDate)
                        TWO_WEEKS_AGO -> getDateWeeksAgo(2, selectedDate)
                        THREE_WEEKS_AGO -> getDateWeeksAgo(3, selectedDate)
                        else -> MonthModel()
                    }
                }

                CompareChartDay(dayEnum, title, date)
            }
        }
    }
}

data class CompareChartDay(
    val dayEnum: CompareChartDaysEnum,
    val title: String,
    val date: MonthModel
)

private fun getDateForDay(dayOfWeek: DayOfWeek, selectedDate: MonthModel): MonthModel {
    val selectedLocalDate = selectedDate.toLocalDate()
    val daysToSubtract = (selectedLocalDate.dayOfWeek.ordinal - dayOfWeek.ordinal + 7) % 7
    val targetDate = selectedLocalDate.minus(daysToSubtract.toLong(), DateTimeUnit.DAY)
    return MonthModel(
        dayNumber = targetDate.dayOfMonth,
        monthNumber = targetDate.monthNumber,
        yearNumber = targetDate.year,
        monthName = targetDate.month.name
    )
}

private fun getDateWeeksAgo(weeksAgo: Int, selectedDate: MonthModel): MonthModel {
    val selectedLocalDate = selectedDate.toLocalDate()
    val targetDate = selectedLocalDate.minus(weeksAgo.toLong(), DateTimeUnit.WEEK)
    return MonthModel(
        dayNumber = targetDate.dayOfMonth,
        monthNumber = targetDate.monthNumber,
        yearNumber = targetDate.year
    )
}

private fun getTitleForDay(dayOfWeek: DayOfWeek, selectedDate: MonthModel): String {
    val selectedLocalDate = selectedDate.toLocalDate()
    return if (selectedLocalDate.dayOfWeek == dayOfWeek) {
        "${dayOfWeek.name.lowercase().replaceFirstChar { it.uppercase() }} (Today)"
    } else {
        dayOfWeek.name.lowercase().replaceFirstChar { it.uppercase() }
    }
}